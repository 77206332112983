import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography, Button, Box } from "@mui/material";
import { Fragment, useState, useEffect } from "react";

export default function DialogConferma(props) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [icon, setIcon] = useState(null);
    const [text, setText] = useState('');
    const [confermaButton, setConfermaButton] = useState('');
    const [severity, setSeverity] = useState('');
    const [disabledButton, setDisabledButton] = useState(false);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [listAlunniLength, setListAlunniLength] = useState(0);
    const [showAlunni, setShowAlunni] = useState(false);
    const [showOnlyOneButton, setShowOnlyOneButton] = useState(false)

    useEffect(() => {
        if (props.isDialogOpen !== undefined) setIsDialogOpen(props.isDialogOpen)
    }, [props.isDialogOpen])

    useEffect(() => {
        if (props.showOnlyOneButton)
            setShowOnlyOneButton(props.showOnlyOneButton);
    }, [props.showOnlyOneButton])

    useEffect(() => {
        if (props.showAlunni !== undefined) setShowAlunni(props.showAlunni)
    }, [props.showAlunni])

    useEffect(() => {
        if (props.text !== undefined) setText(props.text)
    }, [props.text])

    useEffect(() => {
        if (props.icon !== undefined) setIcon(props.icon)
    }, [props.icon])

    useEffect(() => {
        if (props.title !== undefined) setTitle(props.title)
    }, [props.title])

    useEffect(() => {
        if (props.severity !== undefined) setSeverity(props.severity)
    }, [props.severity])

    useEffect(() => {
        if (props.confermaButton !== undefined) setConfermaButton(props.confermaButton)
    }, [props.confermaButton])

    useEffect(() => {
        if (props.disabledButton !== undefined) setDisabledButton(props.disabledButton)
    }, [props.disabledButton])

    useEffect(() => {
        if (props.listAlunniLength !== undefined && props.listAlunniSelezionati !== undefined) {
            // console.log(props.listAlunniSelezionati, props.listAlunniLength)
            setAlunniSelezionati([...props.listAlunniSelezionati])
            setListAlunniLength(props.listAlunniLength)
        }
    }, [props.listAlunniLength, props.listAlunniSelezionati])

    function closeDialog() {
        if (props.closeDialog) props.closeDialog()
    }

    function conferma() {
        if (props.conferma) props.conferma()
    }

    return (
        <Dialog  disableEnforceFocus fullWidth maxWidth={"xs"} open={isDialogOpen} sx={{ mt: '8vh' }}>
            <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>{title}</Typography>
                <IconButton onClick={closeDialog}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {icon}
                <Typography textAlign={'center'}>{text}</Typography>
                {showAlunni && (<Fragment>
                    {alunniSelezionati.length === listAlunniLength ? (
                        <Typography><b>Tutta la classe</b></Typography>
                    ) : (
                        <Box sx={{ border: '1px solid #bdbdbd', borderRadius: '5px', maxHeight: '15vh', overflowY: 'scroll', p: 1, mt: 1, width: '80%' }}>
                            {alunniSelezionati.map((alunno) => (
                                <Typography key={alunno?.id}><b>{alunno?.nome} {alunno?.cognome}</b></Typography>
                            ))}
                        </Box>
                    )}
                </Fragment>)}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                    <Button onClick={conferma} variant="contained" fullWidth color={severity} disabled={disabledButton}>{confermaButton}</Button>
                    {!showOnlyOneButton && (
                        <Button sx={{ mt: 2 }} onClick={closeDialog} variant="outlined" fullWidth color={'error'} >Annulla e chiudi</Button>
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    )
}