import { CloudUpload } from "@mui/icons-material";
import { Box, Button, Typography, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "src/context/snackbarContext";

export default function UploadButton(props: Props) {

    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [maxFileDimension, setMaxFileDimension] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const { showSnackbar } = useSnackbar();

    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });

    useEffect(() => {
        if (props.maxFileDimension !== undefined) setMaxFileDimension(props.maxFileDimension);
    }, [props.maxFileDimension]);

    useEffect(() => {
        if (props.isUploadingFile !== undefined) setIsUploading(props.isUploadingFile);
    }, [props.isUploadingFile]);

    function extractFileExtension(mimeType: string): string {
        return mimeType.split("/").pop() || mimeType;
    }

    function changeFileToUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files || e.target.files.length === 0) return;

        const file = e.target.files[0];

        if (!props.acceptedFormats.includes(file.type)) {
            setFileToUpload(null);
            const acceptedExtensions = props.acceptedFormats.map(extractFileExtension).join(", ");
            showSnackbar(`Il file aggiunto non è supportato. Formati accettati: ${acceptedExtensions}`, "error");
            if (fileInputRef.current) fileInputRef.current.value = ""; // Reset input
            return;
        }

        if (maxFileDimension !== 0 && file.size > maxFileDimension) {
            setFileToUpload(null);
            let maxDimensionMB = convertBytesToMB();
            showSnackbar(`Il file non può superare una dimensione di ${maxDimensionMB} MB`, "error");
            if (fileInputRef.current) fileInputRef.current.value = ""; // Reset input
            return;
        }

        setFileToUpload(file);
        setIsButtonDisabled(true);
        setTimeout(() => setIsButtonDisabled(false), 2000); // Disabilita il pulsante per 2 secondi

        if (props.fileChanged) props.fileChanged(file);
        if (fileInputRef.current) fileInputRef.current.value = ""; // Reset input dopo selezione

    }

    function convertBytesToMB() {
        return (maxFileDimension / (1024 * 1024)).toFixed(2);
    }

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button disabled={isUploading || isButtonDisabled} component="label" variant="contained" startIcon={<CloudUpload />}>
                    {fileToUpload ? "Cambia" : "Carica"} file
                    <VisuallyHiddenInput key={fileToUpload ? fileToUpload.name : "upload"} ref={fileInputRef} type="file" accept={props.acceptedFormats.join(",")} onChange={changeFileToUpload} />
                </Button>
                <Typography variant="body2">
                    {fileToUpload ? fileToUpload.name : "Nessun file selezionato"}
                </Typography>
            </Box>
            <Typography color={"primary"} variant="caption" sx={{ mt: 1 }}>
                Formati accettati:
                <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                    {props.acceptedFormats.map(extractFileExtension).join(", ")}
                </span>
            </Typography>
        </Box>
    );
}

interface Props {
    isUploadingFile: boolean;
    maxFileDimension: number;
    fileChanged: (file: File) => void;
    acceptedFormats: string[];
}
