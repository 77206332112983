import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Typography, useTheme, useMediaQuery, Divider, Chip, Skeleton } from '@mui/material';
import { AccessibilityNew, AccessibilityNewRounded, Mediation, Search } from "@mui/icons-material";
import { regexInserimentoVoto } from "../../shared/utilityMethods";

export default function TableGestioneTirocinio(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [listAlunni, setListAlunni] = useState([]);
    const [nomeCorso, setNomeCorso] = useState("");



    useEffect(() => {

        if (props.listAlunni) {
            let listAlunniTmp = [];
            for (let alunno of props.listAlunni) {
                let alunnoTmp = alunno;

                if (alunnoTmp.valutazioni.valutazioneGenerale === 0 || alunnoTmp.valutazioni.valutazioneGenerale === -1) {
                    let valutazioneTmp = alunnoTmp.valutazioni.valutazioneGenerale === 0 ? "N.V." : "/";
                    alunnoTmp.valutazioni.valutazioneGenerale = valutazioneTmp;
                }

                if (alunnoTmp.valutazioni.frequenza === 0 || alunnoTmp.valutazioni.frequenza === -1) {
                    let frequenzaTmp = alunnoTmp.valutazioni.frequenza === 0 ? "N.V." : "/";
                    alunnoTmp.valutazioni.frequenza = frequenzaTmp;
                }

                if (alunnoTmp.valutazioni.tenutaDocumentazione === 0 || alunnoTmp.valutazioni.tenutaDocumentazione === -1) {
                    let tenutaDocumentazioneTmp = alunnoTmp.valutazioni.tenutaDocumentazione === 0 ? "N.V." : "/";
                    alunnoTmp.valutazioni.tenutaDocumentazione = tenutaDocumentazioneTmp;
                }

                if (alunnoTmp.valutazioni.disponibilitaProposta === 0 || alunnoTmp.valutazioni.disponibilitaProposta === -1) {
                    let disnpobilitaPropostaTmp = alunnoTmp.valutazioni.disponibilitaProposta === 0 ? "N.V." : "/";
                    alunnoTmp.valutazioni.disponibilitaProposta = disnpobilitaPropostaTmp;
                }

                if (alunnoTmp.valutazioni.media === 0 || alunnoTmp.valutazioni.media === -1) {
                    let mediaTmp = alunnoTmp.valutazioni.media === 0 ? "N.V." : "/";
                    alunnoTmp.valutazioni.media = mediaTmp;
                }
                listAlunniTmp.push(alunnoTmp);
            }
            setListAlunni([...props.listAlunni]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100)
        }
    }, [props.listAlunni])

    useEffect(() => {
        if (props.nomeCorso) setNomeCorso(props.nomeCorso);
    }, [props.nomeCorso])

    function openDialogAnnotazioni(alunno, index) {
        if (props.openDialogAnnotazioni)
            props.openDialogAnnotazioni(alunno, index)
    }

    function openDettaglioAlunno(alunno, showBes) {
        if (props.openDettaglioAlunno)
            props.openDettaglioAlunno(alunno, showBes)
    }


    function calcolaColorePercentuale(percentualeString) {
        if (percentualeString !== undefined) {
            let nuovaString = percentualeString.split("%", 2);
            let percentualeIntera = parseInt(nuovaString);
            if (percentualeIntera < 25) return "green"
            else return "red";
        } else return "black"
    }
    function changeColorVoto(voto) {
        if (voto !== null && voto !== undefined) {
            if (voto == -1) return "black"
            else if (voto >= 18) {
                return "green"
            } else return "red";
        }
    }


    return (

        <TableContainer sx={{ width: '100%' }} component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
                <TableHead>
                    <TableRow >
                        <TableCell align="center"
                            className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 6 }}>
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                            }}>
                                <Typography color={'primary'}
                                    variant={"caption"}
                                    sx={{ fontWeight: 'bold', textAlign: 'start' }}>
                                    {(nomeCorso ?? "").toUpperCase()}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="center"
                            className="row-head-secondColumn" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 12 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                textAlign: 'center'
                            }}>
                                <Typography variant="body2" >Ore</Typography>
                                <Typography variant='caption' >assenze/svolte/totale</Typography>
                            </Box>
                        </TableCell>

                        <TableCell align="center"
                            className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                            }}>
                                <Typography variant="body2" textAlign={"center"}>Valutazione generale stage</Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="center"
                            className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                            }}>
                                <Typography variant="body2" textAlign={"center"}>Frequenza</Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="center"
                            className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                            }}>
                                <Typography variant="body2" textAlign={"center"}>Tenuta documentazione</Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="center"
                            className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                            }}>
                                <Typography variant="body2" textAlign={"center"}>Disponibilità alla proposta formativa</Typography>
                            </Box>
                        </TableCell>

                        <TableCell align="center"
                            className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '2px solid black', borderBottom: '1px solid rgba(240, 240, 240, 1)', zIndex: 5 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(240, 240, 240, 1)',
                                backgroundColor: 'rgba(224, 224, 224, 1)',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                            }}>
                                <Typography variant="body2" textAlign={"center"}>Media</Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="center"
                            className="row-head" sx={{ p: 0, minWidth: '300px', borderLeft: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                        >
                            <Box sx={{
                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                backgroundColor: 'white',
                                height: '100px',
                                px: 1,
                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                            }}>
                                <Typography variant="body2" textAlign={"center"}>Annotazione del tutor</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {listAlunni.map((alunno, index) => (
                        <TableRow style={{ height: '40px' }} key={alunno?.id}>
                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', px: 2, py: 2 }}>

                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                            <Typography onClick={() => openDettaglioAlunno(alunno, false)} variant={mobile ? 'caption' : 'body2'} sx={{ cursor: 'pointer', mr: 2, fontWeight: alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                                {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                            </Typography>

                                            {alunno?.besObj?.hasBes && (
                                                <Box onClick={() => openDettaglioAlunno(alunno, true)} width={"10px"} height={"10px"} sx={{ boxShadow: alunno?.besObj?.recentlyModified ? "0 0 10px 2px rgb(255, 100, 100)" : "",cursor: 'pointer', p: 1, mr: 1, borderRadius: '100%', border: '1px solid ' + alunno?.besObj?.colorBes, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1976d2', ml: 1 }}   >
                                                    <AccessibilityNewRounded sx={{ color: alunno?.besObj?.colorBes }} />
                                                </Box>
                                            )}
                                        </Box>
                                        {alunno.msgIscrizione != null ? (
                                            <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                        ) : null}
                                    </Box>
                                ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell className="colonna-nomi-secondColumn" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ py: 0.5, borderRight: '1px solid rgba(224, 224, 224, 1)', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', backgroundColor: 'white' }}>
                                        <Typography variant={mobile ? 'caption' : 'body2'}> {alunno?.oreMateria?.oreAssenza + '/' + alunno?.oreMateria?.oreSvolte + '/' + alunno?.oreMateria?.oreTotali}</Typography>
                                        <Typography variant={mobile ? 'caption' : 'body2'} color={calcolaColorePercentuale(alunno?.oreMateria?.percentuale)}>{alunno?.oreMateria?.percentuale}</Typography>
                                    </Box>
                                ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>

                            <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center' }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ color: changeColorVoto(alunno?.valutazioni?.valutazioneGenerale) }}>  {alunno?.valutazioni?.valutazioneGenerale}</Typography>
                                        </Box>
                                    </Box>) : (
                                    <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center' }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }}  >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ color: changeColorVoto(alunno?.valutazioni?.frequenza) }}>  {alunno?.valutazioni?.frequenza}</Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center' }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ color: changeColorVoto(alunno?.valutazioni?.tenutaDocumentazione) }}>  {alunno?.valutazioni?.tenutaDocumentazione}</Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center' }}>
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }}>
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ color: changeColorVoto(alunno?.valutazioni?.disponibilitaProposta) }}>  {alunno?.valutazioni?.disponibilitaProposta}</Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell sx={{ p: 0, borderRight: '2px solid black', borderLeft: '2px solid black', borderBottom: '1px solid rgba(240, 240, 240, 1)', height: '100%' }}>
                                {!isLoading ? (
                                    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', backgroundColor: 'rgba(224, 224, 224, 1)' }}>
                                        <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ color: changeColorVoto(alunno?.valutazioni?.media) }}>  {alunno?.valutazioni?.media}</Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                            <TableCell sx={{ p: 1, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {!isLoading ? (
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"start"} sx={{ height: '100%' }}>
                                        <Chip icon={<Search />} label="Dettaglio" variant="outlined" color="primary" onClick={() => openDialogAnnotazioni(alunno, index)} />
                                        <Fragment>
                                            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                                            <Typography variant={"mobile ? 'caption' : 'body2'"} >  {alunno?.valutazioni?.annotazioneBreve ? alunno?.valutazioni?.annotazioneBreve : " "}</Typography>
                                        </Fragment>
                                    </Box>
                                ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    )
}