import React from 'react';
import { useState, useEffect, Fragment, useRef } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, useTheme, useMediaQuery, Button, CircularProgress } from '@mui/material';
import { ScrutiniAPI } from '../../../api/scrutiniApi';
import { TableVirtuoso } from 'react-virtuoso'
import { convertDataDiNascita } from '../../shared/metodiDateUtility';
import CryptoJS from 'crypto-js';
import DialogConferma from '../../shared/dialogConferma';

export default function InserimentoVotiPrescrutinio(props) {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const votiRef = useRef([]);
    const provaPassaggioRef = useRef([]);

    const tableRef = useRef();

    const [idCorso, setIdCorso] = useState(-1);
    const [idPeriodo, setIdPeriodo] = useState(-1);
    const [nomeCorso, setNomeCorso] = useState("");

    const [listMaterie, setListMaterie] = useState([]);
    const [listAlunni, setListAlunni] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);
    const [showProvaPassaggio, setShowProvaPassaggio] = useState(false);

    const [isConfirmSalvataggioCoordinatoreDirettoreOpen, setIsConfirmSalvataggioCoordinatoreDirettoreOpen] = useState(false);

    const TableComponents = {
        Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
        Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
        TableHead: TableHead,
        TableRow: TableRow,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    }

    useEffect(() => {
        if (sessionStorage.getItem("listAlunniScrutinio"))
            sessionStorage.clear();
    }, [])

    useEffect(() => {
        if (props.idCorso !== undefined && props.nomeCorso !== undefined && props.idPeriodo !== undefined && props.listMaterie !== undefined && props.listAlunni !== undefined) {
            setListMaterie([...props.listMaterie])
            setIdCorso(props.idCorso);
            setNomeCorso(props.nomeCorso)
            setIdPeriodo(props.idPeriodo);
            getVotiPrescrutinio(props.idCorso, props.idPeriodo, props.listMaterie, props.listAlunni)
        }
    }, [props.idCorso, props.nomeCorso, props.idPeriodo, props.listMaterie, props.listAlunni])


    function closeDialog() {
        if (props.onClose !== undefined)
            props.onClose()
    }

    function save() {
        if (props.save !== undefined)
            props.save()
    }

    //INPUT VOTO
    function changeVoto(e, indexAlunno, indexMateria) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        } else if (newValue === "/") {
            newStr = "/";
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        if (votiRef.current[(indexAlunno * listMaterie.length) + (indexMateria)] !== undefined) {
            votiRef.current[(indexAlunno * listMaterie.length) + (indexMateria)].value = newStr;
        }

        //L'index materia corrisponde all'index della colonna
        //setColPositionTable(indexMateria)
    }
    function changeVotoOnBlur(e, idAlunno, idMateria, indexAlunno, indexMateria) {
        if (e.target.value !== "") {
            let newValue = e.target.value;

            let newStr = 0;

            if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
                newStr = 0;
            }
            else if (newValue === "/") {
                newStr = -1;
            }
            else if (/[nN]/g.test(newValue) === true) {
                newStr = 0
            }
            else if (/[0-9]/g.test(newValue) === true) {
                let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
                newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
                if (newStr > 30) newStr = 30; //non permette di superare voto 30
            } else {
                newStr = 0; //resetta a ''
            }
            let listAlunniTmp = [];

            if (sessionStorage.getItem("listAlunniScrutinio")) {
                listAlunniTmp = JSON.parse(sessionStorage.getItem("listAlunniScrutinio"));
            } else listAlunniTmp = listAlunni;

            if (indexAlunno !== -1) {
                if (listAlunniTmp[indexAlunno].votiPrescrutinio !== undefined) { //Già c'è qualche voto inserito
                    let votiPrescrutinioTmp = listAlunniTmp[indexAlunno].votiPrescrutinio;
                    if (votiPrescrutinioTmp.some(v => v.idMateria === idMateria)) { //Una materia ha già un voto e lo modifico
                        let indexVotoPrescrutinio = votiPrescrutinioTmp.findIndex(v => v.idMateria === idMateria);
                        if (indexVotoPrescrutinio !== -1)
                            votiPrescrutinioTmp[indexVotoPrescrutinio].voto = parseInt(newStr);
                    } else { //Una materia che non è presente nell'array, quindi l'aggiungo
                        listAlunniTmp[indexAlunno].votiPrescrutinio.push({
                            idMateria: idMateria,
                            voto: newStr
                        })
                    }
                } else { //Il primo voto che inserisco
                    listAlunniTmp[indexAlunno].votiPrescrutinio = [
                        {
                            idMateria: idMateria,
                            voto: parseInt(newStr)
                        }
                    ]
                }

                //Ho sostituito il set dello stato con un json, senno ri-renderizzava tutto
                //setListAlunni([...listAlunniTmp])
                sessionStorage.setItem('listAlunniScrutinio', JSON.stringify(listAlunniTmp));




            }
        }
    }
    function changeInputFocused(e, index) {
        if (e.key === 'Enter' || e.key === 'ArrowRight') {
            if (votiRef?.current[index + 1] !== undefined)
                votiRef.current[index + 1].focus();
        } else if (e.key === 'ArrowLeft') {
            if (votiRef?.current[index - 1] !== undefined)
                votiRef.current[index - 1].focus();
        }
    }

    //INPUT CONDOTTA
    function changeVotoCondottaRef(e, indexRef) {
        e.preventDefault();
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }

        if (votiRef.current[indexRef] !== undefined) {
            votiRef.current[indexRef].value = newStr;
        }
    }
    function changeVotoCondottaOnBlur(e, indexAlunno, tipoVoto) {
        if (e.target.value !== "") {
            let newValue = e.target.value;

            let newStr = 0;
            if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
                newStr = 0;
            }
            else if (/[nN]/g.test(newValue) === true) {
                newStr = 0
            }
            else if (/[0-9]/g.test(newValue) === true) {
                let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
                newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
                if (newStr > 30) newStr = 30; //non permette di superare voto 30
            } else {
                newStr = 0; //resetta a ''
            }
            let listAlunniTmp = [];

            if (sessionStorage.getItem("listAlunniScrutinio")) {
                listAlunniTmp = JSON.parse(sessionStorage.getItem("listAlunniScrutinio"));
            } else listAlunniTmp = listAlunni;

            if (indexAlunno !== -1) {

                if (listAlunniTmp[indexAlunno].condottaPrescrutinio !== undefined) { //Già c'è qualche voto inserito
                    if (tipoVoto == "relazione") {
                        listAlunniTmp[indexAlunno].condottaPrescrutinio.relazione = parseInt(newStr);
                    }
                    if (tipoVoto === "interazione")
                        listAlunniTmp[indexAlunno].condottaPrescrutinio.interazione = parseInt(newStr);
                    if (tipoVoto === "motivazione")
                        listAlunniTmp[indexAlunno].condottaPrescrutinio.motivazione = parseInt(newStr);

                } else {
                    listAlunniTmp[indexAlunno].condottaPrescrutinio = {
                        relazione: null,
                        interazione: null,
                        motivazione: null
                    };

                    if (tipoVoto == "relazione")
                        listAlunniTmp[indexAlunno].condottaPrescrutinio.relazione = parseInt(newStr);
                    if (tipoVoto === "interazione")
                        listAlunniTmp[indexAlunno].condottaPrescrutinio.interazione = parseInt(newStr);
                    if (tipoVoto === "motivazione")
                        listAlunniTmp[indexAlunno].condottaPrescrutinio.motivazione = parseInt(newStr);

                }

                //Ho sostituito il set dello stato con un json, senno ri-renderizzava tutto
                //setListAlunni([...listAlunniTmp])
                sessionStorage.setItem('listAlunniScrutinio', JSON.stringify(listAlunniTmp));
            }
        }
    }

    //INPUT PROVA PASSAGGIO
    function changeProvaPassaggioRef(e, indexRef) {
        e.preventDefault();
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }

        if (provaPassaggioRef.current[indexRef] !== undefined) {
            provaPassaggioRef.current[indexRef].value = newStr;
        }
    }

    function changeProvaPassaggioOnBlur(e, indexAlunno) {
        if (e.target.value !== "") {
            let newValue = e.target.value;

            let newStr = 0;
            if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
                newStr = 0;
            }
            else if (/[nN]/g.test(newValue) === true) {
                newStr = 0
            }
            else if (/[0-9]/g.test(newValue) === true) {
                let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
                newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
                if (newStr > 30) newStr = 30; //non permette di superare voto 30
            } else {
                newStr = 0; //resetta a ''
            }
            let listAlunniTmp = [];

            if (sessionStorage.getItem("listAlunniScrutinio")) {
                listAlunniTmp = JSON.parse(sessionStorage.getItem("listAlunniScrutinio"));
            } else listAlunniTmp = listAlunni;

            if (indexAlunno !== -1) {

                if (listAlunniTmp[indexAlunno].provaDiPassaggio !== undefined) { //Già c'è qualche voto inserito
                    listAlunniTmp[indexAlunno].provaDiPassaggio = parseInt(newStr);
                } else {
                    listAlunniTmp[indexAlunno].provaDiPassaggio = parseInt(newStr)

                    //Ho sostituito il set dello stato con un json, senno ri-renderizzava tutto
                    //setListAlunni([...listAlunniTmp])
                    sessionStorage.setItem('listAlunniScrutinio', JSON.stringify(listAlunniTmp));
                }
            }
        }
    }

    async function getVotiPrescrutinio(idCorso, idPeriodo, listMaterie, listAlunni) {
        const obj = {
            idCorso: idCorso,
            idPeriodo: idPeriodo
        }
        if (idCorso !== undefined && idPeriodo !== undefined) {
            ScrutiniAPI.getVotiPrescrutinio(obj)
                .then((result) => {
                    let listAlunniTmp = [...listAlunni];
                    //let resultIndexMap = new Map(listAlunni.map((a, index) => [a.idAlunno, index]));
                    let materieIndexMap = new Map(listMaterie.map((m, index) => [m.idMateria, index]));
                    setShowProvaPassaggio(result?.showProvaPassaggio ?? false);
                    for (let i = 0; i < listAlunniTmp.length; i++) {



                        //GET VOTI PROPOSTI
                        let indexAlunno = result?.votiProposti.findIndex(v => v.idAlunno === listAlunni[i].id)
                        let objCondottaTmp = {};


                        if (indexAlunno !== undefined) {
                            let votiPropostiTmp = result?.votiProposti[indexAlunno]?.materie ?? [];
                            for (let j = 0; j < votiPropostiTmp.length; j++) {
                                let indexMateria = materieIndexMap.get(votiPropostiTmp[j].idMateria);
                                if (indexMateria !== undefined) {
                                    votiPropostiTmp[j].indexMateria = indexMateria;
                                }
                            }
                            listAlunniTmp[i].condottaProposta = result?.votiProposti[indexAlunno]?.condotta;
                            objCondottaTmp = result?.votiProposti[indexAlunno]?.condotta;
                            listAlunniTmp[i].voti = votiPropostiTmp;
                        }

                        //GET VOTI SCRUTINIO
                        indexAlunno = result?.votiPrescrutinio.findIndex(v => v.idAlunno === listAlunni[i].id)
                        if (indexAlunno !== undefined) {

                            if (idPeriodo === 3)
                                listAlunniTmp[i].provaDiPassaggio = result?.votiPrescrutinio[indexAlunno]?.provaPassaggio;


                            let votiPrescrutinioTmp = result?.votiPrescrutinio[indexAlunno]?.materie ?? [];
                            for (let j = 0; j < votiPrescrutinioTmp.length; j++) {
                                let indexMateria = materieIndexMap.get(votiPrescrutinioTmp[j].idMateria);
                                if (indexMateria !== undefined) {
                                    votiPrescrutinioTmp[j].indexMateria = indexMateria;
                                }
                            }
                            let objCondottaPrescrutinio = {
                                motivazione: objCondottaTmp.motivazione,
                                interazione: objCondottaTmp.interazione,
                                relazione: objCondottaTmp.relazio,
                                mediaCondotta: objCondottaTmp.mediaCondotta,

                            }

                            if (result?.votiPrescrutinio[indexAlunno]?.condotta?.motivazione != null)
                                objCondottaPrescrutinio.motivazione = result?.votiPrescrutinio[indexAlunno]?.condotta?.motivazione;

                            if (result?.votiPrescrutinio[indexAlunno]?.condotta?.interazione != null)
                                objCondottaPrescrutinio.interazione = result?.votiPrescrutinio[indexAlunno]?.condotta?.interazione;

                            if (result?.votiPrescrutinio[indexAlunno]?.condotta?.relazione != null)
                                objCondottaPrescrutinio.relazione = result?.votiPrescrutinio[indexAlunno]?.condotta?.relazione;

                            if (result?.votiPrescrutinio[indexAlunno]?.condotta?.mediaCondotta != null)
                                objCondottaPrescrutinio.mediaCondotta = result?.votiPrescrutinio[indexAlunno]?.condotta?.mediaCondotta;

                            listAlunniTmp[i].condottaPrescrutinio = objCondottaPrescrutinio;//result?.votiPrescrutinio[indexAlunno]?.condotta;
                            listAlunniTmp[i].votiPrescrutinio = votiPrescrutinioTmp;
                        }



                        //Controllo, se ho voti proposti e non di scrutinio, copio quelli proposti nei voti di scrutinio
                        for (let materia of listMaterie) {
                            let objVotoPrescrutinio = {
                                idMateria: materia?.idMateria,
                                voto: null
                            }
                            //Se non c'è un voto di prescrutinio di quella materia 
                            let indexPrescrutinio = listAlunniTmp[i].votiPrescrutinio.findIndex(v => v.idMateria == materia.idMateria)
                            if (indexPrescrutinio === -1) {
                                //Se c'è un voto proposto di quella materia
                                let indexProposto = listAlunniTmp[i].voti.findIndex(v => v.idMateria == materia.idMateria)
                                if (indexProposto !== -1) {
                                    objVotoPrescrutinio = {
                                        idMateria: materia?.idMateria,
                                        voto: listAlunniTmp[i].voti[indexProposto].voto
                                    }
                                } // Se non c'è manco un voto proposto allora voto = null
                            } else { //Se c'è un voto di prescrutinio lo metto
                                objVotoPrescrutinio = {
                                    idMateria: materia?.idMateria,
                                    voto: listAlunniTmp[i].votiPrescrutinio[indexPrescrutinio].voto
                                }
                            }
                            listAlunniTmp[i].votiPrescrutinio.push(objVotoPrescrutinio)
                        }
                    }

                    //((alunni * materie) + (alunni * 4))  4 sono gli input delle medie
                    votiRef.current = votiRef.current.slice(0, ((listAlunniTmp.length * listMaterie.length) + (listAlunniTmp.length * 4)));
                    setListAlunni([...listAlunniTmp])
                    setTimeout(() => {
                        setHasLoaded(true);
                    }, 400)
                })
                .catch((error) => {
                    setHasLoaded(true);
                    //setHasErrors(true);
                    // setErrorMsg(error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile consultare la scheda alunno. Riprovare tra qualche minuto.")
                })
        }
    }

    function checkIfShowAlertSavePrescrutinio() {
        let ruoloTmp = localStorage.getItem("ruolo");
        if (props.isScrutinioIniziato) { //Solo se è non è ancora iniziato mostro l'alert
            let decryptedRuolo = (CryptoJS.AES.decrypt(ruoloTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8)).toLowerCase();
            if (decryptedRuolo === "coordinatore" || decryptedRuolo === "direttore")
                setIsConfirmSalvataggioCoordinatoreDirettoreOpen(true)
        } else savePrescrutinio();
    }

    async function savePrescrutinio() {
        let alunniObj = [];
        let listAlunniTmp = [];
        setIsConfermaLoading(true); //Blocco il pulsante

        if (sessionStorage.getItem("listAlunniScrutinio")) {
            listAlunniTmp = JSON.parse(sessionStorage.getItem("listAlunniScrutinio"));
        } else listAlunniTmp = listAlunni;

        setIsConfirmSalvataggioCoordinatoreDirettoreOpen(false)
        for (let alunno of listAlunniTmp) {

            //RIABILITARE SE SI SPORCANO I DATI SALVATI IN BE
            /*let votiPrescrutinioReset = [];
            for (let materia of listMaterie) {
                votiPrescrutinioReset.push({
                    idMateria: materia?.idMateria,
                    voto: null
                })
            }*/

            let votiPrescrutinioTmp = [];
            for (let alunnoVotoPre of alunno.votiPrescrutinio) {
                if (!votiPrescrutinioTmp.some(votoTmp => votoTmp.idMateria === alunnoVotoPre.idMateria)) {
                    votiPrescrutinioTmp.push({
                        idMateria: alunnoVotoPre?.idMateria,
                        voto: alunnoVotoPre?.voto ?? null
                    })
                }
            }


            let objAlunno = {
                alunno: alunno.id,
                votiPrescrutinio: votiPrescrutinioTmp, //votiPrescrutinioReset,

                condotta: {
                    motivazione: alunno?.condottaPrescrutinio?.motivazione ?? null,
                    interazione: alunno?.condottaPrescrutinio?.interazione ?? null,
                    relazione: alunno?.condottaPrescrutinio?.relazione ?? null,
                }
            }

            if (idPeriodo === 3)
                objAlunno.provaDiPassaggio = alunno?.provaDiPassaggio ?? null;

            alunniObj.push(objAlunno);
        }
        const objData = {
            idCorso: idCorso,
            idPeriodo: idPeriodo,
            alunni: alunniObj,

        }


        ScrutiniAPI.savePrescrutinio(objData)
            .then((result) => {
                props.save();
            })
            .catch((error) => { //Se va in errore sblocco il pulsante di salvataggio
                setIsConfermaLoading(false);
            })


    }


    const Row = React.memo(({ index, studente, listMaterie }) => {
        const renderCell = (materia, indexMateriaTmp, indexStudente) => (
            <Fragment>
                <TableCell key={studente?.id + materia?.idMateria + "prop"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '1px solid #e8e8e8' }}
                >
                    <Box className="fakeInputBox" sx={{ ml: 3 }}>
                        {studente?.voti !== undefined && (
                            studente?.voti.map((voto) => (
                                (voto.indexMateria === indexMateriaTmp && voto?.voto != null) && (
                                    <Typography>
                                        {returnVotoConverted(voto?.voto)}
                                    </Typography>
                                )
                            ))
                        )
                        }
                    </Box>
                </TableCell>
                <TableCell key={studente?.id + materia?.idMateria + "voto"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '2px solid black' }}

                >
                    <input className='inputEdit'
                        ref={el => votiRef.current[(indexStudente * listMaterie.length) + (indexMateriaTmp)] = el}
                        defaultValue={returnVotoConverted(listAlunni[indexStudente]?.votiPrescrutinio[indexMateriaTmp]?.voto, false)}
                        // defaultValue={listAlunni[indexStudente] && listAlunni[indexStudente].votiPrescrutinio && listAlunni[indexStudente].votiPrescrutinio[indexMateriaTmp] ?
                        //     (listAlunni[indexStudente].votiPrescrutinio[indexMateriaTmp].voto != 0 ? listAlunni[indexStudente].votiPrescrutinio[indexMateriaTmp].voto : "N.V.") : ""}
                        onKeyDown={(e) => changeInputFocused(e, (indexStudente * listMaterie.length) + (indexMateriaTmp))}
                        onChange={(e) => { changeVoto(e, indexStudente, indexMateriaTmp) }}
                        onBlur={(e) => changeVotoOnBlur(e, studente?.id, materia?.idMateria, indexStudente, indexMateriaTmp)}>
                    </input>
                </TableCell>
            </Fragment>
        );

        return (
            <>
                <TableCell sx={{ minWidth: matches ? '300px' : '400px', p: 0, borderRight: '2px solid black', position: 'sticky', left: 0 }} >
                    <Box sx={{
                        backgroundColor: 'white',
                        height: studente?.cognome.length + studente?.nome.length > 40 ? '100px' : '80px',
                        px: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Box display={"flex"} width={'100%'} alignItems={"center"} justifyContent={'space-between'}>
                            <Box>
                                <Box display={"flex"} flexDirection={'row'} alignItems={"center"} justifyContent={'start'} textAlign={'start'}>
                                    <Typography variant="caption" mr={2}> {studente.numeroRegistro ? studente.numeroRegistro : (index + 1) + ') '}</Typography>
                                    <Typography variant="caption"
                                        sx={{ mr: 2 }}>
                                        {(studente?.cognome).toUpperCase() + " " + (studente?.nome).toUpperCase()}
                                    </Typography>
                                </Box>
                                {(studente?.isRitirato !== undefined && studente?.isRitirato === 1) && (
                                    <Box >
                                        <Typography color="primary" variant="subtitle2">Ritirato il {convertDataDiNascita(studente?.iscrittoAl)}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </TableCell>

                {listMaterie.length > 0 && listMaterie.map((materia, indexMateria) => renderCell(materia, indexMateria, index))}


                <TableCell key={studente?.id + "mot-prop"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '1px solid #e8e8e8' }}
                >
                    <Box className="fakeInputBox" sx={{ ml: 3 }}>
                        <Typography>
                            {returnVotoConverted(listAlunni[index]?.condottaProposta?.motivazione)}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell key={studente?.id + "mot-vot"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '2px solid black' }}
                >
                    <input className='inputEdit'
                        ref={el => votiRef.current[(index + 1 * (listAlunni.length * listMaterie.length)) + 1] = el}
                        onChange={(e) => { changeVotoCondottaRef(e, ((index + 1 * (listAlunni.length * listMaterie.length)) + 1)) }}
                        onBlur={(e) => { changeVotoCondottaOnBlur(e, index, 'motivazione') }}
                        defaultValue={returnVotoConverted(listAlunni[index]?.condottaPrescrutinio?.motivazione, false)}

                    // defaultValue={listAlunni[index] && listAlunni[index].condottaPrescrutinio ?
                    //     (listAlunni[index]?.condottaPrescrutinio?.motivazione != 0 ? listAlunni[index]?.condottaPrescrutinio?.motivazione : 'N.V.') : ""}
                    >
                    </input>
                </TableCell>


                <TableCell key={studente?.id + "int-prop"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '1px solid #e8e8e8' }}
                >
                    <Box className="fakeInputBox" sx={{ ml: 3 }}>
                        <Typography>
                            {returnVotoConverted(listAlunni[index]?.condottaProposta?.interazione)}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell key={studente?.id + "int-vot"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '2px solid black' }}
                >
                    <input className='inputEdit'
                        ref={el => votiRef.current[(index + 1 * (listAlunni.length * listMaterie.length) + listAlunni.length) + 1] = el}
                        onChange={(e) => { changeVotoCondottaRef(e, ((index + 1 * (listAlunni.length * listMaterie.length) + listAlunni.length) + 1)) }}
                        onBlur={(e) => { changeVotoCondottaOnBlur(e, index, 'interazione') }}
                        defaultValue={returnVotoConverted(listAlunni[index]?.condottaPrescrutinio?.interazione, false)}
                    // defaultValue={listAlunni[index] && listAlunni[index].condottaPrescrutinio ?
                    //     (listAlunni[index]?.condottaPrescrutinio?.interazione != 0 ? listAlunni[index]?.condottaPrescrutinio?.interazione : 'N.V.') : ""}
                    >
                    </input>
                </TableCell>

                <TableCell key={studente?.id + "rel-prop"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '1px solid #e8e8e8' }}
                >
                    <Box className="fakeInputBox" sx={{ ml: 3 }}>
                        <Typography>
                            {returnVotoConverted(listAlunni[index]?.condottaProposta?.relazio)}
                        </Typography>
                    </Box>
                </TableCell>

                <TableCell key={studente?.id + "rel-vot"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '2px solid black' }}
                >
                    <input className='inputEdit'
                        ref={el => votiRef.current[(index + 1 * (listAlunni.length * listMaterie.length) + (listAlunni.length * 2)) + 1] = el}
                        onChange={(e) => { changeVotoCondottaRef(e, ((index + 1 * (listAlunni.length * listMaterie.length) + (listAlunni.length * 2)) + 1)) }}
                        onBlur={(e) => { changeVotoCondottaOnBlur(e, index, 'relazione') }}
                        defaultValue={returnVotoConverted(listAlunni[index]?.condottaPrescrutinio?.relazione, false)}
                    // defaultValue={listAlunni[index] && listAlunni[index].condottaPrescrutinio ?
                    //     (listAlunni[index]?.condottaPrescrutinio?.relazione != 0 ? listAlunni[index]?.condottaPrescrutinio?.relazione : 'N.V.') : ""}
                    >
                    </input>
                </TableCell>

                <TableCell key={studente?.id + "media-con-prop"} align="center"
                    sx={{ minWidth: '100px', p: 0, borderRight: '1px solid #e8e8e8' }}
                >
                    <Box className="fakeInputBox" sx={{ ml: 3 }}>
                        {returnVotoConverted(listAlunni[index]?.condottaProposta?.mediaCondotta)}
                    </Box>
                </TableCell>
                {(idPeriodo === 3 && showProvaPassaggio) && (
                    <TableCell key={studente?.id + "provapassaggio-con-prop"} align="center"
                        sx={{ minWidth: '100px', p: 0, borderRight: '1px solid #e8e8e8' }}
                    >
                        <input className='inputEdit'
                            ref={el => provaPassaggioRef.current[(index + 1 * (listAlunni.length * listMaterie.length) + (listAlunni.length * 2)) + 1] = el}
                            onChange={(e) => { changeProvaPassaggioRef(e, ((index + 1 * (listAlunni.length * listMaterie.length) + (listAlunni.length * 2)) + 1)) }}
                            onBlur={(e) => { changeProvaPassaggioOnBlur(e, index) }}
                            defaultValue={returnVotoConverted(listAlunni[index]?.provaDiPassaggio, false)}
                        >
                        </input>
                    </TableCell>
                )}
            </>
        );
    });

    function returnVotoConverted(voto, returnDash) {
        if (voto != null && voto !== undefined && !isNaN(voto)) {
            if (voto > 0) {
                return voto;
            } else if (voto === 0) {
                return "N.V."
            } else return "/"
        } else {
            if (returnDash !== undefined) {
                if (returnDash === true) return "-"
                else return ""
            } else return "-"
        }
    }

    return (
        hasLoaded ? (
            <Fragment>
                <TableVirtuoso
                    ref={tableRef}
                    overscan={10000} //10k PX TODO: Capire se posso eliminare l'effetto virtualizzato
                    style={{ height: 800 }}
                    data={listAlunni}
                    components={TableComponents}
                    fixedHeaderContent={() => (
                        <Fragment>
                            <TableRow>
                                <TableCell align="center" rowSpan={2} sx={{ position: 'sticky', left: 0, p: 0, borderTop: '1px solid white', borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)' }} >
                                    <Box sx={{
                                        borderTop: '1px solid white',
                                        backgroundColor: 'white',
                                        height: '100px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                    }}>
                                        <Typography color={'primary'} variant={matches ? 'caption' : 'body1'} sx={{ fontWeight: 'bold', textAlign: "start" }}>
                                            {nomeCorso}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                {listMaterie.length > 0 && (
                                    listMaterie.map((materia, index) => (
                                        <TableCell colSpan={2} key={materia?.idMateria + "mat"} sx={{ p: 0, borderRight: '2px solid black' }}
                                        >
                                            <Box sx={{
                                                backgroundColor: '#1976d3',
                                                height: '50px',
                                                px: 2,
                                                display: 'flex', flexDirection: 'column', color: 'white', justifyContent: 'center'
                                            }}>
                                                <Typography variant='caption' sx={{ textAlign: 'center' }}>{materia?.nomeMateria}</Typography>
                                            </Box>
                                        </TableCell>
                                    ))
                                )}
                                <TableCell colSpan={2} key={"mot-head"} sx={{ p: 0, borderRight: '2px solid black' }}
                                >
                                    <Box sx={{
                                        backgroundColor: '#7300a5',
                                        height: '50px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', color: 'white', justifyContent: 'center'
                                    }}>
                                        <Typography variant='caption' sx={{ textAlign: 'center' }}>Motivazione ed interesse</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell colSpan={2} key={"int-head"} sx={{ p: 0, borderRight: '2px solid black' }}
                                >
                                    <Box sx={{
                                        backgroundColor: '#7300a5',
                                        height: '50px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', color: 'white', justifyContent: 'center'
                                    }}>
                                        <Typography variant='caption' sx={{ textAlign: 'center' }}>Interazione con il contesto</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell colSpan={2} key={"rel-head"} sx={{ p: 0, borderRight: '2px solid black' }}
                                >
                                    <Box sx={{
                                        backgroundColor: '#7300a5',
                                        height: '50px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', color: 'white', justifyContent: 'center'
                                    }}>
                                        <Typography variant='caption' sx={{ textAlign: 'center' }}>Relazione con gli altri</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell colSpan={1} key={"med-condotta-head"} sx={{ p: 0, borderRight: '2px solid black' }}
                                >
                                    <Box sx={{
                                        backgroundColor: '#7300a5',
                                        height: '50px',
                                        px: 2,
                                        display: 'flex', flexDirection: 'column', color: 'white', justifyContent: 'center'
                                    }}>
                                        <Typography variant='caption' sx={{ textAlign: 'center' }}>Media</Typography>
                                    </Box>
                                </TableCell>
                                {(idPeriodo === 3 && showProvaPassaggio) && (
                                    <TableCell colSpan={1} key={"med-condotta-head"} sx={{ p: 0, borderRight: '2px solid black' }}
                                    >
                                        <Box sx={{
                                            backgroundColor: '#7300a5',
                                            height: '50px',
                                            px: 2,
                                            display: 'flex', flexDirection: 'column', color: 'white', justifyContent: 'center'
                                        }}>
                                            <Typography variant='caption' sx={{ textAlign: 'center' }}>Prova di passaggio</Typography>
                                        </Box>
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableRow>
                                {listMaterie.length > 0 && (
                                    listMaterie.map((materia, index) => (
                                        <Fragment>
                                            <TableCell key={materia?.idMateria + "prop"} align="center"
                                                sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                            >
                                                Proposta
                                            </TableCell>
                                            <TableCell key={materia?.idMateria + "voto"} align="center"
                                                sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '2px solid black' }}
                                            >
                                                Voto
                                            </TableCell>
                                        </Fragment>
                                    ))
                                )}


                                <TableCell key={"mot-prop"} align="center"
                                    sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    Proposta
                                </TableCell>
                                <TableCell key={"mot-vot"} align="center"
                                    sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '2px solid black' }}
                                >
                                    Voto
                                </TableCell>

                                <TableCell key={"int-prop"} align="center"
                                    sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    Proposta
                                </TableCell>
                                <TableCell key={"int-vot"} align="center"
                                    sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '2px solid black' }}
                                >
                                    Voto
                                </TableCell>

                                <TableCell key={"rel-prop"} align="center"
                                    sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    Proposta
                                </TableCell>
                                <TableCell key={"rel-vot"} align="center"
                                    sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '2px solid black' }}
                                >
                                    Voto
                                </TableCell>

                                <TableCell key={"med-cond-prop"} align="center"
                                    sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    Proposta
                                </TableCell>
                                {(idPeriodo === 3 && showProvaPassaggio) && (
                                    <TableCell key={"prova-pass-prop"} align="center"
                                        sx={{ height: '50px', backgroundColor: 'white', minWidth: '100px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                    >
                                        Voto
                                    </TableCell>
                                )}
                            </TableRow>
                        </Fragment>
                    )}
                    itemContent={(index, studente) => (
                        <Row index={index} studente={studente} listMaterie={listMaterie} />

                    )}
                />




                <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={'end'} sx={{ mt: 2 }}>
                    <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'end'}>
                        <Button disabled={isConfermaLoading} onClick={closeDialog} variant='outlined' color="error" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }}>
                            annulla modifica
                        </Button>
                        <Button disabled={isConfermaLoading} onClick={checkIfShowAlertSavePrescrutinio} variant='contained' color="success" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }}>
                            conferma e salva
                        </Button>
                    </Box>
                </Box>

                <DialogConferma isDialogOpen={isConfirmSalvataggioCoordinatoreDirettoreOpen}
                    severity={'success'}
                    title={"Conferma salvataggio"}
                    closeDialog={() => { setIsConfirmSalvataggioCoordinatoreDirettoreOpen(false) }}
                    conferma={savePrescrutinio}
                    confermaButton={'Conferma salvataggio'}
                    text={"Procedendo con il salvataggio del pre-scrutinio non sarà più possibile per il tutor d'aula inserire o modificare la sua proposta di voto. Sei sicuro di voler procedere?"} />
            </Fragment>

        ) : (
            <Box sx={{ height: '80vh', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
                <Typography sx={{ mt: 2 }}>Caricamento in corso...</Typography>
            </Box>
        )


    )
}