import { Box, Paper, Typography, Snackbar, Alert, Button, Dialog, DialogTitle, DialogContent, IconButton, TextField, Divider, DialogActions } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Close, Check, CheckCircleOutline } from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { itIT } from "@mui/x-date-pickers/locales";
import { DesktopTimePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";

import { convertDataDiNascita, convertToSqlFormat } from "../../shared/metodiDateUtility";
import { PresenzeAPI } from "../../../api/presenzeApi";
import { UserAPI } from "../../../api/userApi";
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../../shared/utilityMethods";

export default function AssenzeAlunno(props) {

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [idAlunno, setIdAlunno] = useState(-1)
    const [testoNota, setTestoNota] = useState("");
    const [idDocente, setIdDocente] = useState(-1)
    const [dataAttuale, setDataAttuale] = useState(null);

    const { t } = useTranslation();
    const [elencoAssenzeGiustificate, setElencoAssenzeGiustificate] = useState([])
    const [elencoAssenzeNonGiustificate, setElencoAssenzeNonGiustificate] = useState([])

    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
    const [openDialogUscita, setOpenDialogUscita] = useState(false);


    const [objDataDaGiustificare, setObjDataDaGiustificare] = useState(null);
    const [objDataGiustificato, setObjDataGiustificato] = useState(null);
    const [dialogDettaglioGiustificaIsOpen, setDialogDettaglioGiustificaIsOpen] = useState(false);

    const [canAddUA, setCanAddUA] = useState(false);
    const [canAddRitardo, setCanAddRitardo] = useState(false);
    const [isPresenzaValidata, setIsPresenzaValidata] = useState(false);

    //Dati alunno
    const [nomeAlunno, setNomeAlunno] = useState("");
    const [cognomeAlunno, setCognomeAlunno] = useState("");

    const [data, setData] = useState(null);
    const [ora, setOra] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [inizioPeriodo, setInizioPeriodo] = useState(null)
    const [finePeriodo, setFinePeriodo] = useState(null)
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true);

    useEffect(() => {
        let date = DateTime.local();
        let roundedOrario = date.startOf('minute').plus({ minutes: Math.round(date.minute / 5) * 5 - date.minute });
        let objDateInteresse = getDataInizioFinePeriodo();
        let dataDiInteresseTmp = objDateInteresse?.dataDiInteresse ?? DateTime.now();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        setInizioPeriodo(inizioPeriodoTmp ?? null);
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
        setFinePeriodo(finePeriodoTmp ?? null);

        setIsAnnoFormativoCorrente(objDateInteresse?.isAnnoFormativoCorrente ?? true)
        getPresenze(idAlunno, dataDiInteresseTmp)
        setOra(roundedOrario)
    }, [])


    useEffect(() => {
        if (props.permessiUtente !== undefined && props.tipo !== undefined && props.tipoRegistro) {
            let permessiTmp = props.permessiUtente;
            let objRuoli = props.ruoliUtente ?? {};
            let isTutor = checkIsOnlyTutorTrue(objRuoli);
            //Se è su consulta registro di classe lo mostro sempre tranne se si è tutor
            if (props.tipo && props.tipoRegistro === 'classe' && props.comesFromConsultaRegistro == true && isTutor == false) {
                setCanAddUA(true);
                setCanAddRitardo(true);
            }
            else if (props.tipo && props.tipoRegistro !== 'classe') {
                if (permessiTmp.some(i => i.nome === "canAddUA")) setCanAddUA(true);
                if (permessiTmp.some(i => i.nome === "canAddRitardo")) setCanAddRitardo(true);
            } else {
                setCanAddUA(false);
                setCanAddRitardo(false);
            }


        }
    }, [props.permessiUtente, props.tipo, props.tipoRegistro, props.comesFromConsultaRegistro, props.ruoliUtente])

    useEffect(() => {
        if (props.idAlunno !== undefined && props.dataAttuale !== undefined) {
            let dataTmp = convertToSqlFormat(props.dataAttuale != null ? props.dataAttuale : DateTime.local());
            setIdAlunno(props.idAlunno)
            let annoFormativoTmp = getAnnoFormativo();
            getDettaglioAlunno(props.idAlunno, annoFormativoTmp)
            setDataAttuale(dataTmp)
            let objDateInteresse = getDataInizioFinePeriodo();
            if (objDateInteresse.isAnnoFormativoCorrente) {
                let inizioPeriodoTmp = convertToSqlFormat(objDateInteresse?.inizioPeriodo != null ? objDateInteresse?.inizioPeriodo : null);
                getElencoAssenze(inizioPeriodoTmp, dataTmp, props.idAlunno);
            }
            else { //Se stiamo in anni formativi precedenti
                let inizioPeriodoTmp = convertToSqlFormat(objDateInteresse?.inizioPeriodo != null ? objDateInteresse?.inizioPeriodo : null);
                let finePeriodoTmp = convertToSqlFormat(objDateInteresse?.finePeriodo != null ? objDateInteresse?.finePeriodo : DateTime.local());
                getElencoAssenze(inizioPeriodoTmp, finePeriodoTmp, props.idAlunno)
            }
        }
    }, [props.idAlunno, props.dataAttuale])

    useEffect(() => {
        if (props.idDocente !== undefined)
            setIdDocente(props.idDocente);
    }, [props.idDocente])


    function checkIsOnlyTutorTrue(obj) {
        if (obj.isTutor === true) {
            for (let key in obj) {
                if (key !== 'isTutor' && key !== 'isDocente' && obj[key] === true) {
                    return false;  //Se tutor è true e qualsiasi propietà (diversa da isDocente) é true allora non è tutor, posso aggiungere UA e ER 
                }
            }
            return true
        }
        return false; //Se non è tutor vedo ER e UA
    }

    function openDialog(assenza) {
        setDialogIsOpen(true);
        setObjDataDaGiustificare(assenza);
    }

    function openDialogDettaglioGiustificazione(assenza) {
        setDialogDettaglioGiustificaIsOpen(true)
        setObjDataGiustificato(assenza);
    }

    function closeDialogDettaglioGiustificazione() {
        setDialogDettaglioGiustificaIsOpen(false)
        //setObjDataGiustificato(null);
    }


    function changeTestoNota(e) {
        setTestoNota(e.target.value)
    }

    function getGravitaAssenza(dataAssenza) {
        const dataPartenza = DateTime.fromISO(dataAssenza);
        const dataOggi = DateTime.local();
        const diff = dataOggi.diff(dataPartenza, 'days').toObject();
        if (Math.floor(diff.days) > 2) return "red"
        else return "orange"
    }

    function confirmGiustificazione() {
        giustificaAssenza();
    }

    function openDialogRitardoUscita(type) {
        type === 'uscita' ? setOpenDialogUscita(true) : setOpenDialogRitardo(true)
    }

    function closeDialog() {
        setDialogIsOpen(false);
        setOpenDialogRitardo(false)
        setOpenDialogUscita(false)

        let date = DateTime.local();
        let roundedOrario = date.startOf('minute').plus({ minutes: Math.round(date.minute / 5) * 5 - date.minute });

        setOra(roundedOrario)
        setData(null)

        setIsPresenzaValidata(false)
    }

    function changeData(value) {
        setData(value);
        if (value != null)
            getPresenze(idAlunno, value)
    }


    function changeOra(value) {
        setOra(value)
    }

    function aggiuntaRitardoUscita(isRitardo) {
        addRitardoUscita(isRitardo)
    }

    async function addRitardoUscita(ritardo) {
        let dataFormatted = data.toFormat('yyyy-MM-dd')
        let oraFormatted = ora.toFormat('HH:mm:ss');
        let dataObj;
        if (ritardo) {
            dataObj = {
                "data": dataFormatted, //yyyy-MM-dd
                "idAlunno": idAlunno,
                "statoPresenza": "ER",
                "orarioIngressoReale": oraFormatted,
                "idUtenteAggiornamento": idDocente,
                "note": ""

            }
        } else {
            dataObj = {
                "data": dataFormatted, //yyyy-MM-dd
                "idAlunno": idAlunno,
                "statoPresenza": "UA",
                "orarioUscitaReale": oraFormatted,
                "idUtenteAggiornamento": idDocente,
                "note": ""

            }
        }

        PresenzeAPI.aggiungiRitardoUscita(dataObj)
            .then((result) => {
                closeDialog();
                setAlertSeverity("success");
                setAlertMsg(ritardo ? ' Ritardo inserito correttamente' : 'Uscita anticipata inserita correttamente');
                setAlertOpen(true);

            })
            .catch((error) => {
                let errorMsg = "";

                if (error.response.status === 500) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                } else {
                    errorMsg = "Al momento non è possibile completare l'operazione";
                }

                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setAlertOpen(true);
            })
    }
    async function getDettaglioAlunno(idStudente, annoFormativoTmp) {

        let dataObj = {
            "idAlunno": idStudente,
            "annoFormativo": annoFormativoTmp
        }
        if (idStudente !== -1) {
            UserAPI.getDettaglioAlunno(dataObj)
                .then((result) => {
                    setNomeAlunno(result[0]?.nome);
                    setCognomeAlunno(result[0]?.cognome);
                })
                .catch((error) => {
                    if (error.response.status === 403) props.logout();
                })
        }
    }
    async function getElencoAssenze(dataDal, dataAl, idAlunno) {
        let dataObj = {
            "dataDal": dataDal,
            "dataAl": dataAl,
            idAlunno: idAlunno
        }
        PresenzeAPI.getDettaglioAssenze(dataObj)
            .then((result) => {
                if (result[0]?.assenze?.non_giustificate != null) {
                    setElencoAssenzeNonGiustificate([...result[0]?.assenze?.non_giustificate])
                } else setElencoAssenzeNonGiustificate([])
                if (result[0]?.assenze?.giustificate != null) {
                    let elencoAssenzeGiustificateTmp = result[0]?.assenze?.giustificate
                    elencoAssenzeGiustificateTmp.sort((a, b) => b.assenzaDal.localeCompare(a.assenzaDal));
                    setElencoAssenzeGiustificate([...elencoAssenzeGiustificateTmp])
                } else setElencoAssenzeGiustificate([])

                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err);
            })
    }
    async function getPresenze(idAlunno, dataRichiestaTmp) {
        let idAlunni = [idAlunno];

        let dataRichiesta = convertToSqlFormat(dataRichiestaTmp)

        let dataObj = {
            "data": dataRichiesta,
            "idAlunno": idAlunni
        }

        PresenzeAPI.getPresenzeGiornaliere(dataObj)
            .then((result) => {
                if (result.alunni.length > 0) {
                    if (result.alunni[0].validata === true) {
                        setIsPresenzaValidata(true);
                    } else {
                        setIsPresenzaValidata(false);
                    }
                } else {
                    setIsPresenzaValidata(false);
                }
            })
            .catch((error) => { })
    }

    async function giustificaAssenza() {
        let dataAssenzaDalSql = objDataDaGiustificare?.assenzaDal
        let dataAssenzaAlSql = objDataDaGiustificare?.assenzaAl

        let dataObj = {
            "dataDal": dataAssenzaDalSql,
            "dataAl": dataAssenzaAlSql,
            "idAlunno": idAlunno,
            "idUtenteInserimento": idDocente,
            "noteGiustificazione": testoNota
        }
        PresenzeAPI.inserisciGiustificazione(dataObj)
            .then((result) => {
                let todayDate = DateTime.now().toISODate(); //YYYY-MM-DD
                let objDateInteresse = getDataInizioFinePeriodo();

                if (objDateInteresse.isAnnoFormativoCorrente)
                    getElencoAssenze(null, dataAttuale, idAlunno)
                else { //Se stiamo in anni formativi precedenti
                    let inizioPeriodoTmp = convertToSqlFormat(objDateInteresse?.inizioPeriodo != null ? objDateInteresse?.inizioPeriodo : null);
                    let finePeriodoTmp = convertToSqlFormat(objDateInteresse?.finePeriodo != null ? objDateInteresse?.finePeriodo : DateTime.local());
                    getElencoAssenze(inizioPeriodoTmp, finePeriodoTmp, idAlunno)
                }
                closeDialog();
                setAlertSeverity("success");
                setAlertMsg("Giustificazione aggiunta correttamente");
                setAlertOpen(true);
            })
            .catch((err) => {
                setAlertSeverity("error");
                setAlertMsg("Errore durante l'inserimento della giustificazione");
                setAlertOpen(true);
            })
    }

    return (
        <Fragment>
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' }, alignItems: 'end', justifyContent: 'end' }}>
                {(canAddRitardo && isAnnoFormativoCorrente) && (
                    <Button fullWidth onClick={() => openDialogRitardoUscita('ritardo')} data-testid='ritardoBtn' variant="contained" color='warning'
                        sx={{ mr: { xs: 0, md: 1 }, width: { xs: '100%', md: '50%' } }} >
                        Aggiungi ritardo
                    </Button>)}
                {(canAddUA && isAnnoFormativoCorrente) && (
                    <Button fullWidth onClick={() => openDialogRitardoUscita('uscita')} data-testid='uscitaBtn' variant="contained"
                        sx={{ mt: { xs: 1, md: 0 }, width: { xs: '100%', md: '50%' } }} >
                        Aggiungi uscita
                    </Button>
                )}
            </Box>

            <Typography sx={{ mt: 2, mb: 1 }}>{t("pages.assenze.assenzaDaGiustificare")}</Typography>
            <Paper variant="outlined" sx={{ minHeight: '10vh', p: 2, overflowX: 'scroll' }}>
                {!isLoading ? (<Fragment>
                    {elencoAssenzeNonGiustificate.map((assenza, index) => (
                        <Box key={index} display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            {/* <Checkbox sx={{ mr: 1 }} /> */}
                            <ReportProblemRoundedIcon sx={{ mr: 2, color: getGravitaAssenza(assenza?.assenzaAl) }} />
                            <Typography>Assente {(assenza.assenzaAl != null && assenza.assenzaAl !== assenza.assenzaDal) ? "dal" : "il"} <b>{convertDataDiNascita(assenza.assenzaDal)} </b></Typography>
                            {(assenza.assenzaAl != null && assenza.assenzaAl !== assenza.assenzaDal) ? (
                                <Typography>&nbsp;al <b>{convertDataDiNascita(assenza.assenzaAl)}</b></Typography>
                            ) : null}
                            {assenza.assenzaDal != convertToSqlFormat(DateTime.now()) && (
                                <Button onClick={() => openDialog(assenza, index)} sx={{ ml: 3 }} size="small" variant="contained" data-testid='openDialogGiustificaAssenzaBtn'>Giustifica</Button>
                            )}
                        </Box>
                    ))}
                </Fragment>) : (
                    <Fragment>
                        <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            <ReportProblemRoundedIcon sx={{ mr: 2, color: 'rgb(220,220,220)' }} />
                            <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />
                            <Button disabled sx={{ ml: 3, px: 6 }} size="small" variant="contained" data-testid='openDialogGiustificaAssenzaBtn'>{'            .'}</Button>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            <ReportProblemRoundedIcon sx={{ mr: 2, color: 'rgb(220,220,220)' }} />
                            <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />
                            <Button disabled sx={{ ml: 3, px: 6 }} size="small" variant="contained" data-testid='openDialogGiustificaAssenzaBtn'>{'            .'}</Button>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            <ReportProblemRoundedIcon sx={{ mr: 2, color: 'rgb(220,220,220)' }} />
                            <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />
                            <Button disabled sx={{ ml: 3, px: 6 }} size="small" variant="contained" data-testid='openDialogGiustificaAssenzaBtn'>{'            .'}</Button>
                        </Box>
                    </Fragment>
                )}
            </Paper>

            <Typography sx={{ mt: 2, mb: 1 }}>{t("pages.assenze.assenzaGiustificata")}</Typography>
            <Paper variant="outlined" sx={{ minHeight: '10vh', p: 2, overflowX: 'scroll' }}>
                {!isLoading ? (<Fragment>
                    {elencoAssenzeGiustificate.map((assenza, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CheckCircleOutline color="success" sx={{ mr: 1 }} />
                                <Box>
                                    <Typography>
                                        {`Assente ${(assenza.assenzaAl != null && assenza.assenzaAl !== assenza.assenzaDal) ? "dal" : "il"} `}
                                        <b>{convertDataDiNascita(assenza.assenzaDal)}</b>
                                        {(assenza.assenzaAl != null && assenza.assenzaAl !== assenza.assenzaDal) && (
                                            <> al <b>{convertDataDiNascita(assenza.assenzaAl)}</b></>
                                        )}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'normal' }} variant="subtitle2">Giustificata da {assenza.nomeUtente}</Typography>
                                </Box>
                                <Button onClick={() => openDialogDettaglioGiustificazione(assenza)} sx={{ ml: 3 }} size="small" variant="contained" >
                                    Apri dettaglio
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </Fragment>) : (
                    <Fragment>
                        <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            <Box sx={{
                                mr: 2, width: '20px', height: '20px',
                                borderRadius: '100%', border: '1px rgb(220,220,220) solid',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Check sx={{ color: 'rgb(220,220,220)' }} fontSize='5px' />
                            </Box>
                            <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />
                            <Button disabled sx={{ ml: 3, px: 6 }} size="small" variant="contained" data-testid='openDialogGiustificaAssenzaBtn'>{'            .'}</Button>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            <Box sx={{
                                mr: 2, width: '20px', height: '20px',
                                borderRadius: '100%', border: '1px rgb(220,220,220) solid',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Check sx={{ color: 'rgb(220,220,220)' }} fontSize='5px' />
                            </Box>
                            <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />
                            <Button disabled sx={{ ml: 3, px: 6 }} size="small" variant="contained" data-testid='openDialogGiustificaAssenzaBtn'>{'            .'}</Button>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} sx={{ my: 1 }}>
                            <Box sx={{
                                mr: 2, width: '20px', height: '20px',
                                borderRadius: '100%', border: '1px rgb(220,220,220) solid',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Check sx={{ color: 'rgb(220,220,220)' }} fontSize='5px' />
                            </Box>
                            <Box sx={{ height: '0.75em', width: '15em', backgroundColor: 'rgb(220,220,220)', borderRadius: '2px' }} />
                            <Button disabled sx={{ ml: 3, px: 6 }} size="small" variant="contained" data-testid='openDialogGiustificaAssenzaBtn'>{'            .'}</Button>
                        </Box>
                    </Fragment>
                )}
            </Paper>

            <Dialog hideBackdrop open={openDialogRitardo || openDialogUscita} maxWidth="md" onClose={closeDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"} variant="h6">
                    {openDialogRitardo ? 'Aggiunta ritardo' : 'Aggiunta uscita'} {'(' + nomeAlunno + " " + cognomeAlunno + ')'}
                    <IconButton onClick={closeDialog} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid xs={12} md={6} sx={{ width: '100%', pt: 2 }}>
                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                    minDate={inizioPeriodo}
                                    maxDate={isAnnoFormativoCorrente === false ? DateTime.now() : finePeriodo}
                                    label="Data"
                                    value={data}
                                    format="dd/MM/yyyy"
                                    onChange={(value) => changeData(value)}
                                    // onAccept={changeData}
                                    dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                            </LocalizationProvider>
                        </Grid>

                        <Grid xs={12} md={6} sx={{ width: '100%', pt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DesktopTimePicker
                                    sx={{ width: '100%' }}
                                    label={openDialogRitardo ? 'Ora entrata' : 'Ora uscita'}
                                    views={['hours', 'minutes']}
                                    format="HH:mm"
                                    ampm={false}
                                    onChange={(value) => changeOra(value)}
                                    value={ora}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    {isPresenzaValidata ? (
                        <Typography sx={{ mt: 2 }} variant="subtitle2" color="error">
                            Per il giorno selezionato, non è possibile modificare lo stato di presenza dell'alunno, in quanto la sua presenza risulta <b>validata</b>.
                        </Typography>
                    ) : null}
                    <Box display="flex" justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, width: '100%', mt: 3 }}>
                        <Button onClick={closeDialog} data-testid='uscitaBtn' variant="outlined" color="error" sx={{ mr: { xs: 0, md: 2 }, width: { xs: '100%', md: '40%' } }} >
                            annulla
                        </Button>
                        {!isPresenzaValidata ? (
                            <Button onClick={() => aggiuntaRitardoUscita(openDialogRitardo, openDialogRitardo ? 'Ritardo aggiunto' : 'Uscita anticipata aggiunta', 'success')} disabled={ora === null || data === null} data-testid='ritardoBtn' variant="contained" color='success' sx={{ mt: { xs: 2, md: 0 }, width: { xs: '100%', md: '40%' } }} >
                                {openDialogRitardo ? t("pages.anagrafica.ritardoBtn") : 'aggiungi uscita'}
                            </Button>
                        ) : null}
                    </Box>

                </DialogContent>
            </Dialog>


            <Dialog hideBackdrop open={dialogIsOpen} fullWidth maxWidth="md" onClose={closeDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ textAlign: 'start', fontWeight: 'bold' }}>
                        Giustificare assenza {'(' + nomeAlunno + ' ' + cognomeAlunno + ')'}
                    </Typography>
                    <IconButton onClick={closeDialog} sx={{ ml: 5, position: 'absolute', top: 5, right: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid xs={12} md={12} sx={{ width: '100%', pt: 2 }}>
                            <Typography>Confermi di voler giustificare l'assenza
                                {(objDataDaGiustificare?.assenzaAl != null && objDataDaGiustificare?.assenzaAl !== objDataDaGiustificare?.assenzaDal) ? " del periodo che va dal" : " del"} <b>{convertDataDiNascita(objDataDaGiustificare?.assenzaDal)}&nbsp;</b>
                                {(objDataDaGiustificare?.assenzaAl != null && objDataDaGiustificare?.assenzaAl !== objDataDaGiustificare?.assenzaDal) ? (
                                    <span>&nbsp;al <b>{convertDataDiNascita(objDataDaGiustificare?.assenzaAl)}&nbsp;</b></span>
                                ) : null}
                                dell'alunno <b>{nomeAlunno + ' ' + cognomeAlunno}</b>?</Typography>
                        </Grid>

                        <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                            <TextField fullWidth sx={{ mt: 1 }}
                                onChange={changeTestoNota} placeholder="Scrivi qui..." multiline rows={4} label='Note' />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box display="flex" justifyContent={"end"} flexDirection={'column'} sx={{ width: '100%', p: 2 }}>
                        <Button onClick={closeDialog} data-testid='uscitaBtn' variant="outlined" color="error" sx={{ mb: 1 }} >
                            annulla
                        </Button>
                        <Button onClick={confirmGiustificazione} data-testid='giustificaAssenzaBtn' variant="contained" color='success'  >
                            giustifica assenza
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogDettaglioGiustificaIsOpen} fullWidth maxWidth="md" onClose={closeDialogDettaglioGiustificazione}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ textAlign: 'start', fontWeight: 'bold' }}>
                        Dettaglio giustificazione {'(' + nomeAlunno + ' ' + cognomeAlunno + ')'}
                    </Typography>
                    <IconButton onClick={closeDialogDettaglioGiustificazione} sx={{ ml: 5, position: 'absolute', top: 5, right: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex">
                        <Typography>Assente {(objDataGiustificato?.assenzaAl != null && objDataGiustificato?.assenzaAl !== objDataGiustificato?.assenzaDal) ? "dal" : "il"} <b>{convertDataDiNascita(objDataGiustificato?.assenzaDal)} </b></Typography>
                        {(objDataGiustificato?.assenzaAl != null && objDataGiustificato?.assenzaAl !== objDataGiustificato?.assenzaDal) ? (
                            <Typography>&nbsp;al <b>{convertDataDiNascita(objDataGiustificato?.assenzaAl)}</b></Typography>
                        ) : null}
                    </Box>
                    <Typography>Giustificata il giorno: <b>{convertDataDiNascita(objDataGiustificato?.data_giustificazione)}</b></Typography>
                    {objDataGiustificato?.nomeUtente && (
                        <Typography>Giustificata da: <b>{objDataGiustificato?.nomeUtente}</b></Typography>
                    )}
                    <Typography>Note: {objDataGiustificato?.note}</Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box display={'flex'} flexDirection={'column'} width={'100%'} p={2}>
                        <Button onClick={closeDialogDettaglioGiustificazione} fullWidth variant="contained" >
                            Chiudi dettaglio
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
            <Helmet>
                <title>{t("name")} - {t("pages.assenze.title")}</title>
            </Helmet>
        </Fragment>
    )
}