export function calcolaColorePercentuale(percentualeString?: string): string {
    if (percentualeString) {
        const percentualeIntera = parseInt(percentualeString.replace('%', '').trim(), 10);
        return percentualeIntera < 25 ? "green" : "red";
    }
    return "black";
}

export function calcolaFontWeightPercentuale(percentualeString?: string | number): "normal" | "bold" {
    if (typeof percentualeString === 'string') {
        const percentualeIntera = parseInt(percentualeString.replace('%', '').trim(), 10);
        return percentualeIntera < 25 ? "normal" : "bold";
    }
    return "normal";
}

export function calcolaColoreMedia(mediaString?: string | number | null): string {
    if (mediaString !== undefined && mediaString !== -1 && mediaString !== null) {
        const mediaIntera = typeof mediaString === 'string' ? parseInt(mediaString.trim(), 10) : mediaString;
        return mediaIntera < 18 ? "red" : "green";
    }
    return "black";
}

export function returnVotoConverted(voto?: number | null, returnDash: boolean = true): string | number {
    if (voto !== null && voto !== undefined && !isNaN(voto)) {
        if (voto > 0) {
            return voto;
        } else if (voto === 0) {
            return "N.V.";
        } else {
            return "/";
        }
    }
    return returnDash ? "-" : "";
}