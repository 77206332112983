import { useState, useEffect, Fragment, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Typography, useTheme, useMediaQuery, Grid } from '@mui/material';
import { copyArrayWithoutReference } from "../../shared/utilityMethods";


export default function ModificaVotiRegistroDidattico(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

    const [nomeMateria, setNomeMateria] = useState("");
    const [idMateria, setIdMateria] = useState(-1);
    const [nomeCorso, setNomeCorso] = useState("");

    const [listAlunni, setListAlunni] = useState([]);
    const [listAlunniSupporto, setListAlunniSupporto] = useState([]);

    const [voti, setVoti] = useState([]);
    const [votiSupporto, setVotiSupporto] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false); //false

    const [tutor, setTutor] = useState(false);
    const [slashCondottaEnabled, setSlashCondottaEnabled] = useState(false);
    const [slashVotiEnabled, setSlashVotiEnabled] = useState(false);

    const votiPropostiRef = useRef([])
    const votiMotEInteresseRef = useRef([])
    const votiInterazioneRef = useRef([])
    const votiRelazioneRef = useRef([])
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [votiCondottaDaAltraMateria, setVotiCondottaDaAltraMateria] = useState('');
    const [periodiPassati, setPeriodiPassati] = useState([]);
    const [periodoSelezionato, setPeriodoSelezionato] = useState(-1);

    useEffect(() => {
        if (props.idMateria !== undefined) {
            setIdMateria(props.idMateria)
        }
    }, [props.idMateria])
    useEffect(() => {
        if (props.nomeMateria !== undefined) {
            setNomeMateria(props.nomeMateria)
        }
    }, [props.nomeMateria])

    useEffect(() => {
        if (props.periodiPassati !== undefined)
            setPeriodiPassati([...props.periodiPassati])
    }, [props.periodiPassati])

    useEffect(() => {
        if (props.nomeCorso !== undefined) {
            setNomeCorso(props.nomeCorso)
        }
    }, [props.nomeCorso])

    useEffect(() => {
        if (props.tutor !== undefined) {
            setTutor(props.tutor)
        }
    }, [props.tutor])

    useEffect(() => {
        if (props.votiCondottaDaAltraMateria !== undefined) {
            setVotiCondottaDaAltraMateria(props.votiCondottaDaAltraMateria)
        }
    }, [props.votiCondottaDaAltraMateria])

    useEffect(() => {
        if (props.listAlunni !== undefined) {
            votiPropostiRef.current = votiPropostiRef.current.slice(0, props.listAlunni.lenght);
            votiMotEInteresseRef.current = votiMotEInteresseRef.current.slice(0, props.listAlunni.lenght);
            votiInterazioneRef.current = votiInterazioneRef.current.slice(0, props.listAlunni.lenght);
            votiRelazioneRef.current = votiRelazioneRef.current.slice(0, props.listAlunni.lenght);

            setPeriodoSelezionato(props?.periodoSelezionato ?? 1)
            setListAlunniSupporto([...props.listAlunni]);
            setListAlunni([...props.listAlunni]);
            gestioneVoti(props.listAlunni, props.periodoSelezionato);
            setHasLoaded(true);
        }
    }, [props.listAlunni, props.periodoSelezionato])

    function gestioneVoti(listaAlunni, periodoSelezionatoTmp) {
        let votiTmp = [];

        for (let a of listaAlunni) {
            {
                let voto = {
                    idAlunno: a.alunno.id,
                    motivazione: parseInt(a.condotta.motivazione),
                    interazione: parseInt(a.condotta.interazione),
                    relazione: parseInt(a.condotta.relazio),
                    //votoMateria: parseInt(a.votoProposto != null ? a.votoProposto : a?.medie?.mediaSuggerita),
                }

                if (periodoSelezionatoTmp === 3)
                    voto.votoMateria = parseInt(a.votoProposto != null ? a.votoProposto : a?.votiFinali?.mediaFinale);
                else
                    voto.votoMateria = parseInt(a.votoProposto != null ? a.votoProposto : a?.medie?.mediaSuggerita);

                votiTmp.push(voto)
            }
        }
        setVoti([...votiTmp]);

        let votiSupportoTmp = copyArrayWithoutReference(votiTmp) //Senno aveva in memoria il riferimento dell'altro array
        setVotiSupporto([...votiSupportoTmp])

        if (votiTmp.some(v => v.votoMateria === -1)) {
            console.log(votiTmp)

            //TODO: Controllare tutti i voti condotta, se tutti sono -1 allora slashCondottaEnabled è true
            if (votiTmp.some(v => v.motivazione !== -1) || votiTmp.some(v => v.interazione !== -1) || votiTmp.some(v => v.relazione !== -1)) {
                //Vuol dire che almeno un voto di condotta non è -1 quindi gli slash sono attivi solo sui voti proposti
                setSlashCondottaEnabled(false);
                if (!votiTmp.some(v => v.votoMateria !== -1)) //Se TUTTI i voti proposi sono -1 blocco la colonna e abilito lo slash
                    setSlashVotiEnabled(true);
                else  setSlashVotiEnabled(false);
            } else { //Se tutti i voti di condotta sono a -1
                setSlashCondottaEnabled(true);
                setSlashVotiEnabled(true);
            }
        } else {
            setSlashVotiEnabled(false)
            //Se non i voti non sono a -1, neanche la condotta lo è
            setSlashCondottaEnabled(false);
        }
        setTimeout(() => {
            gestioneVotiRef(listaAlunni, periodoSelezionatoTmp)
        }, 300)
    }
    function gestioneVotiRef(listAlunni, periodoSelezionatoTmp) {
        for (let i = 0; i < listAlunni.length; i++) {
            if (votiPropostiRef.current[i] !== undefined && votiPropostiRef.current[i] != null) {
                if (listAlunni[i].votoProposto != null) {
                    votiPropostiRef.current[i].value = returnVotoConverted(listAlunni[i].votoProposto)
                } else {
                    if (periodoSelezionatoTmp === 3)
                        votiPropostiRef.current[i].value = listAlunni[i]?.votiFinali?.mediaFinale != null ? returnVotoConverted(listAlunni[i]?.votiFinali?.mediaFinale) : ""
                    else
                        votiPropostiRef.current[i].value = listAlunni[i]?.medie?.mediaSuggerita != null ? returnVotoConverted(listAlunni[i]?.medie?.mediaSuggerita) : ""
                }
            }

            if (votiMotEInteresseRef.current[i] !== undefined && votiMotEInteresseRef.current[i] != null) {
                if (listAlunni[i]?.condotta?.motivazione !== undefined) {
                    votiMotEInteresseRef.current[i].value = returnVotoConverted(listAlunni[i]?.condotta?.motivazione, false)
                } else votiMotEInteresseRef.current[i].value = ""
            }

            if (votiInterazioneRef.current[i] !== undefined && votiInterazioneRef.current[i] != null) {
                if (listAlunni[i]?.condotta?.interazione !== undefined) {
                    votiInterazioneRef.current[i].value = returnVotoConverted(listAlunni[i]?.condotta?.interazione, false)
                } else votiInterazioneRef.current[i].value = ""
            }

            if (votiRelazioneRef.current[i] !== undefined && votiRelazioneRef.current[i] != null) {
                if (listAlunni[i]?.condotta?.relazio !== undefined) {
                    votiRelazioneRef.current[i].value = returnVotoConverted(listAlunni[i]?.condotta?.relazio, false)
                } else votiRelazioneRef.current[i].value = ""
            }
        }
    }

    function changeConfermaModifica() {
        setIsConfermaLoading(true);
        if (props.conferma !== undefined)
            props.conferma(voti)
    }

    function changeOpenDialogModifica() {
        if (props.closeDialog !== undefined)
            props.closeDialog()
    }

    function setSlashCondotta() {
        let votiTmp = voti
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto));
        let slashCondottaEnabledTmp = !slashCondottaEnabled
        for (let i = 0; i < listAlunni.length; i++) {
            if (slashCondottaEnabledTmp) {
                votiRelazioneRef.current[i].value = "/";
                listAlunniTmp[i].condotta.relazio = "-1";
                votiInterazioneRef.current[i].value = "/";
                listAlunniTmp[i].condotta.interazione = "-1";
                votiMotEInteresseRef.current[i].value = "/";
                listAlunniTmp[i].condotta.motivazione = "-1";
                votiPropostiRef.current[i].value = "/";
                listAlunniTmp[i].votoProposto = "-1";

                setListAlunniSupporto([...listAlunniTmp]);

                votiTmp[i].votoMateria = parseInt("-1");
                votiTmp[i].relazione = parseInt("-1");
                votiTmp[i].interazione = parseInt("-1");
                votiTmp[i].motivazione = parseInt("-1");

            } else {

                votiRelazioneRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.relazione, true);
                listAlunniTmp[i].condotta.relazio = returnVotoConvertedNotSlash(votiSupporto[i]?.relazione, false);
                votiInterazioneRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.interazione, true);
                listAlunniTmp[i].condotta.interazione = returnVotoConvertedNotSlash(votiSupporto[i]?.interazione, false);
                votiMotEInteresseRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.motivazione, true);
                listAlunniTmp[i].condotta.motivazione = returnVotoConvertedNotSlash(votiSupporto[i]?.motivazione, false);
                votiPropostiRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.votoMateria, true);
                listAlunniTmp[i].votoProposto = returnVotoConvertedNotSlash(votiSupporto[i]?.votoMateria, false);

                setListAlunniSupporto([...listAlunniTmp]);

                //La precedente funzione ritornava N.V. come stringa e non andava bene, votiTmp deve contenere solo interi o null 
                votiTmp[i].votoMateria = returnVotoMateria(votiSupporto[i]?.votoMateria)//returnVotoConvertedNotSlash(votiSupporto[i]?.votoMateria, false);
                votiTmp[i].relazione = returnVotoMateria(votiSupporto[i]?.relazione);
                votiTmp[i].interazione = returnVotoMateria(votiSupporto[i]?.interazione);
                votiTmp[i].motivazione = returnVotoMateria(votiSupporto[i]?.motivazione);
            }
        }
        setSlashCondottaEnabled(slashCondottaEnabledTmp);
        setSlashVotiEnabled(slashCondottaEnabledTmp)
        setVoti([...votiTmp])

    }
    function setSlashVotiProposti() {
        let votiTmp = voti
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto));
        let setSlashVotiEnabledTmp = !slashVotiEnabled;
        if (setSlashVotiEnabledTmp === false) {
            for (let i = 0; i < listAlunni.length; i++) {

                if (votiSupporto[i]?.votoMateria != -1) { //Se quando tolgo gli slash, in precedenza c'era un -1 lo imposto a NULL
                    votiPropostiRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.votoMateria, true);
                    listAlunniTmp[i].votoProposto = returnVotoConvertedNotSlash(votiSupporto[i]?.votoMateria, false);
                } else {
                    votiPropostiRef.current[i].value = null;
                    listAlunniTmp[i].votoProposto = null;
                }

                // Se disabilito lo slash ai voti devo anche rimuovere eventuali slash dalle condotte
                if (votiTmp[i].relazione == -1) {
                    votiRelazioneRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.relazione, true);
                    votiTmp[i].relazione = returnVotoConvertedNotSlash(votiSupporto[i]?.relazione, false);
                }
                if (votiTmp[i].interazione == -1) {
                    votiInterazioneRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.interazione, true);
                    votiTmp[i].interazione = returnVotoConvertedNotSlash(votiSupporto[i]?.interazione, false);
                }
                if (votiTmp[i].motivazione == -1) {
                    votiMotEInteresseRef.current[i].value = returnVotoConvertedNotSlash(votiSupporto[i]?.motivazione, true);
                    votiTmp[i].motivazione = returnVotoConvertedNotSlash(votiSupporto[i]?.motivazione, false);
                }

                setListAlunniSupporto([...listAlunniTmp]);
                //La precedente funzione ritornava N.V. come stringa e non andava bene, votiTmp deve contenere solo interi o null 
                if (votiSupporto[i]?.votoMateria != -1) //Se quando tolgo gli slash, in precedenza c'era un -1 lo imposto a NULL
                    votiTmp[i].votoMateria = returnVotoMateria(votiSupporto[i]?.votoMateria)//returnVotoConvertedNotSlash(votiSupporto[i]?.votoMateria, false);
                else votiTmp[i].votoMateria = null;
            }
        } else {
            for (let i = 0; i < listAlunni.length; i++) {
                votiPropostiRef.current[i].value = "/";
                listAlunniTmp[i].votoProposto = "-1";
                setListAlunniSupporto([...listAlunniTmp]);
                votiTmp[i].votoMateria = parseInt("-1");
            }
        }

        setSlashVotiEnabled(setSlashVotiEnabledTmp);
        setVoti([...votiTmp])

    }

    function changeRelazio(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        } else if (newValue === "/") {
            if (slashVotiEnabled)
                newStr = '/'
            else newStr = ""
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'
        } else {
            newStr = ""; //resetta a ''
        }
        votiRelazioneRef.current[index].value = newStr;

    }
    function onBlurRelazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        }
        else if (newValue === "/") {
            if (slashVotiEnabled)
                newStr = '-1'
            else newStr = ""
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = ""; //resetta a ''
        }
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto)); //Fixa problemi di cambio listAlunni

        listAlunniTmp[index].condotta.relazio = newStr;
        setListAlunniSupporto([...listAlunniTmp])

        let votiTmp = voti
        votiTmp[index].relazione = parseInt(newStr);
        setVoti([...votiTmp])
    }


    function changeInterazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (newValue === "/") {
            if (slashVotiEnabled)
                newStr = '/'
            else newStr = ""
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        votiInterazioneRef.current[index].value = newStr;

    }
    function onBlurInterazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        }
        else if (newValue === "/") {
            if (slashVotiEnabled)
                newStr = '-1'
            else newStr = ""
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = ""; //resetta a ''
        }
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto)); //Fixa problemi di cambio listAlunni

        listAlunniTmp[index].condotta.interazione = newStr;
        setListAlunniSupporto([...listAlunniTmp])

        let votiTmp = voti
        votiTmp[index].interazione = parseInt(newStr);
        setVoti([...votiTmp])
    }



    function changeMotivazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (newValue === "/") {
            if (slashVotiEnabled)
                newStr = '/'
            else newStr = ""
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        votiMotEInteresseRef.current[index].value = newStr;

    }
    function onBlurMotivazione(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (newValue === "/") {
            if (slashVotiEnabled)
                newStr = '-1'
            else newStr = ""
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = ""; //resetta a ''
        }
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto)); //Fixa problemi di cambio listAlunni

        listAlunniTmp[index].condotta.motivazione = newStr;
        setListAlunniSupporto([...listAlunniTmp])

        let votiTmp = voti
        votiTmp[index].motivazione = parseInt(newStr);
        setVoti([...votiTmp])
    }




    function changeVotoProposto(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (newValue === "/") {
            newStr = ''
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        votiPropostiRef.current[index].value = newStr;
    }
    function onBlurVotoProposto(id, e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        }
        else if (newValue === "/") {
            newStr = '';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = ""; //resetta a ''
        }
        let listAlunniTmp = JSON.parse(JSON.stringify(listAlunniSupporto)); //Fixa problemi di cambio listAlunni

        listAlunniTmp[index].votoProposto = newStr;
        setListAlunniSupporto([...listAlunniTmp]);

        let votiTmp = voti
        votiTmp[index].votoMateria = parseInt(newStr);
        setVoti([...votiTmp])
    }

    function returnVotoConverted(voto, returnDash) {
        if (voto != null && voto !== undefined) {
            if (voto > 0) {
                return voto;
            } else if (voto === 0) {
                return "N.V."
            } else return "/"
        } else {
            if (returnDash !== undefined) {
                if (returnDash === true) return "-"
                else return ""
            } else return "-"
        }
    }
    function returnVotoConvertedNotSlash(voto, returnString) {
        //votiSupporto[i]?.relazione == -1 ? "" : votiSupporto[i]?.relazione ?? "";
        if (voto != null && voto !== undefined && !isNaN(voto)) {
            if (voto > 0) {
                return voto;
            } else if (voto === 0) {
                return "N.V."
            } else {
                if (returnString) return ""
                else return null;
            }
        } else {
            if (returnString) return ""
            else return null;
        }
    }

    function returnVotoMateria(voto) {
        if (voto != null && voto !== undefined && !isNaN(voto)) {
            return voto
        } else {
            return null
        }
    }
    function changeColorVoto(voto) {
        if (voto != null && voto !== undefined) {
            if (voto == -1) return "black"
            else if (voto >= 18) {
                return "green"
            } else return "red";
        }
    }


    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Box sx={{ mt: 3, mb: { xs: 1, md: 3 }, width: '100%', maxHeight: { xs: '69vh', md: '75vh' }, display: "flex", justifyContent: 'center' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 100 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" rowSpan={2}
                                            className="colonna-nomi row-head" sx={{ width: '200px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}
                                        >
                                            {!tutor ? (<Box sx={{
                                                backgroundColor: 'white',
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                height: '100px',
                                                px: (isTablet || mobile) ? 1 : 2,
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                            }}>
                                                <Typography variant="caption">
                                                    Materia:
                                                </Typography>
                                                <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
                                                    variant={(isTablet || mobile) ? 'caption' : 'body1'}>
                                                    {nomeMateria}
                                                </Typography>




                                            </Box>) : (
                                                <Box sx={{
                                                    backgroundColor: 'white',
                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                    height: '100px',
                                                    px: (isTablet || mobile) ? 1 : 2,
                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                                }}>
                                                    <Typography variant="caption">
                                                        Corso:
                                                    </Typography>
                                                    <Typography color={'secondary'} textAlign={"start"} sx={{ fontWeight: 'bold', mr: 1 }}
                                                        variant={(isTablet || mobile) ? 'caption' : 'body1'}>
                                                        {nomeCorso}
                                                    </Typography>
                                                </Box>)
                                            }
                                        </TableCell>
                                        {(idMateria !== 96 && idMateria !== 97) && (<Fragment>
                                            {periodiPassati.map((periodo, index) => (
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ width: '70px', p: 0, borderLeft: index === 0 ? '3px solid black' : '0px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: (isTablet || mobile) ? 1 : 2,
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography textAlign={"center"} variant={(isTablet || mobile) ? 'caption' : 'body1'}>Voto scrutinio {periodo?.nomePeriodo}</Typography>
                                                    </Box>
                                                </TableCell>
                                            ))}
                                        </Fragment>
                                        )}
                                        {!tutor && (<TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ width: '70px', p: 0, borderRight: periodoSelezionato !== 3 ? '3px solid green' : '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >
                                            <Box sx={{
                                                backgroundColor: 'white',
                                                height: '100px',
                                                px: (isTablet || mobile) ? 1 : 2,
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                            }}>
                                                <Typography variant={(isTablet || mobile) ? 'caption' : 'body1'}>Media periodo corrente</Typography>
                                            </Box>
                                        </TableCell>)}
                                        {periodoSelezionato === 3 && (
                                            <TableCell align="center" rowSpan={2}
                                                className="row-head" sx={{ width: '70px', p: 0, borderRight: '3px solid green', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                            >
                                                <Box sx={{
                                                    backgroundColor: 'white',
                                                    height: '100px',
                                                    px: (isTablet || mobile) ? 1 : 2,
                                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                }}>
                                                    <Typography variant={(isTablet || mobile) ? 'caption' : 'body1'}>Media finale suggerita</Typography>
                                                </Box>
                                            </TableCell>
                                        )}
                                        {!tutor && (<TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ width: '70px', p: 0, borderRight: '3px solid green', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >
                                            <Box sx={{
                                                backgroundColor: '#cecece',
                                                height: '100px',
                                                px: (isTablet || mobile) ? 1 : 2,
                                                borderBottom: '1px solid white',
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                            }}>
                                                <Typography variant={(isTablet || mobile) ? 'caption' : 'body1'} >
                                                    {periodoSelezionato === 3 ? "Voto finale proposto" : "Voto proposto"}
                                                </Typography>

                                                {tutor && (<Grid container>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Proposta docenti' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Voto proposto' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>)}
                                            </Box>
                                        </TableCell>)}
                                        <TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ width: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >
                                            <Box sx={{
                                                backgroundColor: 'white',
                                                height: '100px',
                                                px: (isTablet || mobile) ? 1 : 2,
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                            }}>
                                                <Typography textAlign={"center"} variant={(isTablet || mobile) ? 'caption' : 'body1'}>Motivazione e interesse</Typography>
                                                {/* TODO: */}
                                                {votiCondottaDaAltraMateria !== '' && (<Typography variant="caption" textAlign={"center"}>{'(voti da ' + votiCondottaDaAltraMateria + ')'}</Typography>)}

                                                {tutor && (<Grid container>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Proposta docenti' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Voto proposto' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>)}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ width: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >
                                            <Box sx={{
                                                backgroundColor: 'white',
                                                height: '100px',
                                                px: (isTablet || mobile) ? 1 : 2,
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                            }}>
                                                <Typography textAlign={"center"} variant={(isTablet || mobile) ? 'caption' : 'body1'}>Interazione con il contesto</Typography>
                                                {votiCondottaDaAltraMateria !== '' && (<Typography variant="caption" textAlign={"center"}>{'(voti da ' + votiCondottaDaAltraMateria + ')'}</Typography>)}

                                                {tutor && (<Grid container>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Proposta docenti' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >{'(' + 'Voto proposto' + ')'}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>)}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}
                                            className="row-head" sx={{ width: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >
                                            <Box sx={{
                                                backgroundColor: 'white',
                                                height: '100px',
                                                px: (isTablet || mobile) ? 1 : 2,
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                            }}>
                                                <Typography textAlign={"center"} variant={(isTablet || mobile) ? 'caption' : 'body1'}>Relazione con gli altri</Typography>
                                                {votiCondottaDaAltraMateria !== '' && (<Typography variant="caption" textAlign={"center"}>{'(voti da ' + votiCondottaDaAltraMateria + ')'}</Typography>)}

                                                {tutor && (<Grid container>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >(Proposta docenti)</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Box>
                                                            <Typography variant="caption" >(Voto proposto)</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>)}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listAlunniSupporto.map((alunno, index) => (
                                        <TableRow style={{ minHeight: '20px' }} key={alunno?.alunno?.id}>

                                            <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{ backgroundColor: 'white', px: 2, py: 1, height: '100px', display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <Typography sx={{ mr: 2 }}>  {alunno.alunno.numeroRegistro ? alunno.alunno.numeroRegistro : (index + 1) + ")"}</Typography>
                                                        <Typography variant={(isTablet || mobile) ? 'caption' : 'body1'}
                                                            sx={{ mr: 2, fontWeight: alunno?.alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                                            {(alunno?.alunno?.cognome).toUpperCase() + " " + (alunno?.alunno?.nome).toUpperCase()}
                                                        </Typography>
                                                    </Box>
                                                    {alunno.msgIscrizione != null ? (
                                                        <Typography color="primary" variant="subtitle2">{alunno.msgIscrizione}</Typography>
                                                    ) : null}
                                                </Box>
                                            </TableCell>
                                            {(idMateria !== 96 && idMateria !== 97) && (<Fragment>
                                                {alunno?.votiScrutiniPassati !== undefined && alunno?.votiScrutiniPassati.length > 0 && (
                                                    alunno?.votiScrutiniPassati.map((obj, index) => (
                                                        <TableCell sx={{
                                                            px: (isTablet || mobile) ? 1 : 2, py: 1, borderLeft: index === 0 ? '3px solid black' : '0px', borderRight: '1px solid rgba(224, 224, 224, 1)'
                                                        }} key={"voto" + alunno?.alunno.id + obj?.nomePeriodo}>
                                                            <Typography textAlign={"center"} sx={{ color: changeColorVoto(obj?.voto) }}>{returnVotoConverted(obj?.voto)}</Typography>
                                                        </TableCell>
                                                    ))
                                                )}
                                            </Fragment>)}
                                            {!tutor && (
                                                <TableCell sx={{ px: 2, borderRight: periodoSelezionato !== 3 ? '3px solid green' : '1px solid rgba(224, 224, 224, 1)', }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        color: alunno?.medie?.mediaSuggerita !== null ? parseInt(alunno?.medie?.mediaSuggerita) >= 18 ? 'green' : 'red' : 'black',
                                                        justifyContent: 'space-around'
                                                    }}>
                                                        <Typography>
                                                            {returnVotoConverted(alunno?.medie?.mediaSuggerita)}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            {periodoSelezionato === 3 && (
                                                <TableCell sx={{ px: 2, borderRight: '3px solid green' }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        color: changeColorVoto(alunno?.votiFinali?.mediaFinale),
                                                        justifyContent: 'space-around'
                                                    }}>
                                                        <Typography>
                                                            {returnVotoConverted(alunno?.votiFinali?.mediaFinale)}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )}
                                            {!tutor && (
                                                <TableCell sx={{ py: 1.5, px: (isTablet || mobile) ? 1 : 2, borderRight: '3px solid green', backgroundColor: '#cecece', }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-around',
                                                        alignItems: 'center'
                                                    }}>
                                                        {/* <Box className="fakeInputBox">
                                                            <Typography>
                                                                {listAlunni[index]?.votoProposto ?? '-'}
                                                            </Typography>
                                                        </Box> */}
                                                        <input
                                                            className="inputEdit"
                                                            disabled={slashCondottaEnabled || slashVotiEnabled}
                                                            ref={el => votiPropostiRef.current[index] = el}
                                                            defaultValue={returnVotoConverted(alunno?.votoProposto)}
                                                            style={{ width: '50px' }}
                                                            onChange={(e) => changeVotoProposto(alunno?.alunno?.id, e, index)}
                                                            onBlur={(e) => onBlurVotoProposto(alunno?.alunno?.id, e, index)}
                                                        />
                                                    </Box>
                                                </TableCell>
                                            )}

                                            <TableCell sx={{ py: 1.5, px: (isTablet || mobile) ? 1 : 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}>
                                                    {/* <Box className="fakeInputBox">
                                                        <Typography>
                                                            {listAlunni[index]?.condotta?.motivazione ?? '-'}
                                                        </Typography>
                                                    </Box> */}
                                                    <input
                                                        disabled={slashCondottaEnabled}
                                                        className="inputEdit"
                                                        ref={el => votiMotEInteresseRef.current[index] = el}
                                                        defaultValue={returnVotoConverted(alunno?.condotta?.motivazione)}
                                                        style={{ width: '50px' }}
                                                        onChange={(e) => changeMotivazione(alunno?.alunno?.id, e, index)}
                                                        onBlur={(e) => onBlurMotivazione(alunno?.alunno?.id, e, index)}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ py: 1.5, px: (isTablet || mobile) ? 1 : 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}>
                                                    {/* <Box className="fakeInputBox">
                                                        <Typography>
                                                            {listAlunni[index]?.condotta?.interazione ?? '-'}
                                                        </Typography>
                                                    </Box> */}

                                                    <input
                                                        disabled={slashCondottaEnabled}

                                                        className="inputEdit"
                                                        ref={el => votiInterazioneRef.current[index] = el}
                                                        defaultValue={returnVotoConverted(alunno?.condotta?.interazione)}
                                                        style={{ width: '50px' }}
                                                        onChange={(e) => changeInterazione(alunno?.alunno?.id, e, index)}
                                                        onBlur={(e) => onBlurInterazione(alunno?.alunno?.id, e, index)}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ py: 1.5, px: (isTablet || mobile) ? 1 : 2, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}>
                                                    {/* <Box className="fakeInputBox">
                                                        <Typography>
                                                            {listAlunni[index]?.condotta?.relazio ?? '-'}
                                                        </Typography>
                                                    </Box> */}

                                                    <input
                                                        disabled={slashCondottaEnabled}
                                                        className="inputEdit"
                                                        ref={el => votiRelazioneRef.current[index] = el}
                                                        defaultValue={returnVotoConverted(alunno?.condotta?.relazio)}
                                                        style={{ width: '50px' }}
                                                        onChange={(e) => changeRelazio(alunno?.alunno?.id, e, index)}
                                                        onBlur={(e) => onBlurRelazione(alunno?.alunno?.id, e, index)}
                                                    />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"space-between"}>
                        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'start'}>
                            <Button onClick={setSlashVotiProposti} disabled={slashCondottaEnabled} variant="outlined" color="success" sx={{ mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' }, }}
                            // sx={{ width: 'fit-content' }}
                            >
                                {slashVotiEnabled ? 'Rimuovi "/" ai voti proposti' : 'Aggiungi "/" ai voti proposti'}
                            </Button>

                            <Button onClick={setSlashCondotta} variant="outlined" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }}
                            // sx={{ ml: 1, width: 'fit-content' }}
                            >
                                {slashCondottaEnabled ? 'Rimuovi "/" alla condotta' : 'Aggiungi "/" alla condotta'}
                            </Button>
                        </Box>
                        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'end'}>
                            <Button disabled={isConfermaLoading} variant='outlined' color="error" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={changeOpenDialogModifica}>
                                annulla modifica
                            </Button>
                            <Button disabled={isConfermaLoading} variant='contained' color="success" sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={changeConfermaModifica}>
                                conferma e salva
                            </Button>
                        </Box>
                    </Box>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                    {/* <CircularProgress /> */}
                    <Typography sx={{ mt: 2 }}>Caricamento voti...</Typography>
                </Box>
            )}

        </Fragment>);
}