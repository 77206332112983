import { DateTime } from "luxon";
import { PresenzeAPI } from "src/api/presenzeApi";
import { RegistroAPI } from "src/api/registroApi";

export default async function functionCheckAlunniSelezionabili(listAlunni: any[], listAlunniSelezionati: any[], dataRichiesta: DateTime, idDocente: number, idCorso: number, idMateria: number, oraSelezionataId?: number) {

    //Rimuovo i non iscritti/ritirati dagli alunni e dagli alunni selezionati
    const listAlunniFiltered = listAlunni.filter(alunno => {
        let iscrittoDal = alunno?.iscrittoDal ? DateTime.fromISO(alunno.iscrittoDal).toUTC() : null;
        let iscrittoAl = alunno?.iscrittoAl ? DateTime.fromISO(alunno.iscrittoAl).toUTC().endOf("day") : null;
        return iscrittoDal && iscrittoAl && dataRichiesta >= iscrittoDal && dataRichiesta <= iscrittoAl;
    }).map((obj, index) => ({ ...obj, n: index }));
    const listAlunniSelezionatiFiltered = listAlunniSelezionati.filter(alunno => {
        let iscrittoDal = alunno?.iscrittoDal ? DateTime.fromISO(alunno.iscrittoDal).toUTC() : null;
        let iscrittoAl = alunno?.iscrittoAl ? DateTime.fromISO(alunno.iscrittoAl).toUTC().endOf("day") : null;
        return iscrittoDal && iscrittoAl && dataRichiesta >= iscrittoDal && dataRichiesta <= iscrittoAl;
    }).map((obj, index) => ({ ...obj, n: index }));

    const objProssimeLezioni = await getOreLezione(dataRichiesta, idCorso, idDocente, idMateria);
console.log(idCorso, idDocente, idMateria)
    let objAlunni;
    //Se mi arriva l'id dell'oraSelezionata devo gestire la chiamata in un modo differente, per prima cosa filtro l'array delle lezioni e mi prendo l'oggetto della lezione (ora)
    let ora = oraSelezionataId !== undefined ? objProssimeLezioni.listOre.filter(ora => ora.idOrario === oraSelezionataId) : [];
    if (oraSelezionataId !== undefined && ora.length > 0) {
        objAlunni =  await getAlunniPresenti(listAlunniFiltered, dataRichiesta, listAlunniSelezionatiFiltered, ora[0]);
    } else {
        objAlunni = await getAlunniPresenti(listAlunniFiltered, dataRichiesta, listAlunniSelezionatiFiltered, objProssimeLezioni.listOre[0]);

    }
    const alunniSelezionabiliNumber = Math.max(listAlunniFiltered.length - objAlunni?.alunniNonSelezionabili.length, 0);
    const listAlunniSorted = listAlunniFiltered.sort((a, b) => {
        if (a.n < b.n) {
            return -1;
        }
        if (a.n > b.n) {
            return 1;
        }
        return 0;
    });

    //Gestisce sia il caso in cui mi arriva l'idOrario dal parent, sia quando quest'ultimo è undefined
    const idMateriaSelected = oraSelezionataId !== undefined ? ora[0].materia.idMateria : (objProssimeLezioni?.idMateriaSelected ?? -1)

    return {
        listAlunniTotali: listAlunniSorted,
        listOre: objProssimeLezioni.listOre,
        alunniNonSelezionabili: objAlunni?.alunniNonSelezionabili ?? [],
        listAlunniSelezionati: objAlunni?.listAlunniSelezionati ?? [],
        numberAlunniSelezionabili: alunniSelezionabiliNumber,
        oraSelected: objProssimeLezioni?.oraSelected ?? -1,
        idMateriaSelected: idMateriaSelected
    };
}


async function getOreLezione(dataRichiesta: DateTime, idCorso: number, idDocente: number, idMateria: number): Promise<{ listOre: OrarioLezione[]; oraSelected: number; idMateriaSelected: number }> {

    const formattedData = convertLuxonInUtc(dataRichiesta);

    let objOreLezioni = {
        listOre: [],
        oraSelected: -1,
        idMateriaSelected: -1,
    };



    const dataObj = {
        dataRichiesta: formattedData,
        idDocente,
        idCorso,
        ...((idMateria !== undefined && idMateria !== -1) && { idMateria }) 
    };


    try {
        const result = await RegistroAPI.getProssimeLezioni(dataObj);
        const prossimeOreTmp = result.filter((ora: { data: string | null }) => ora.data === formattedData);

        objOreLezioni = {
            listOre: prossimeOreTmp,
            oraSelected: prossimeOreTmp.length > 0 ? prossimeOreTmp[0].idOrario : -1,
            idMateriaSelected: prossimeOreTmp.length > 0 ? prossimeOreTmp[0].materia.idMateria : idMateria //idMateriaSelected non deve mai essere -1
        };
    } catch (error: any) {
        if (error.response && error.response.status === 403) {
            //TODO:
            // props.logout(); //
        }
    }

    return objOreLezioni;
}

async function getAlunniPresenti(listAlunniTotali: any[], dataRichiesta: DateTime, alunniSelezionati: any[], oraSelezionata: OrarioLezione): Promise<{ alunniNonSelezionabili: number[]; listAlunniSelezionati: Alunno[] }> {

    const dataSql: string = convertToSqlFormat(dataRichiesta);
    const idAlunni: number[] = listAlunniTotali.map(alunno => alunno?.id);
    const dataObj: DataObj = { data: dataSql, idAlunno: idAlunni };

    try {
        const result = await PresenzeAPI.getPresenzeGiornaliere(dataObj);
        let alunniAssenti: Alunno[] = []; //Qui dentro metto gli alunni assenti/in ritardo/usciti prima, cosi li posso escludere dalla selezione
        const idNonSelezionabili = new Set<number>();

        result.alunni.forEach((alunno: PresenzaAlunno) => {
            const indexAlunno = listAlunniTotali.findIndex(a => a.id === alunno?.idAlunno);
            if (indexAlunno === -1) return;

            let objAlunno: Alunno = { ...listAlunniTotali[indexAlunno], presenza: true };
            let orarioFine = oraSelezionata.oraFine.slice(0, -3);
            let orarioInizio = oraSelezionata.oraInizio.slice(0, -3);

            const dataFineLezione = new Date(`1970-01-01T${orarioFine}:00Z`);
            const dataInizioLezione = new Date(`1970-01-01T${orarioInizio}:00Z`);

            if (alunno.stato_presenza === "A") {
                alunniAssenti.push(objAlunno);
                idNonSelezionabili.add(objAlunno.id);
            } else if (["UA", "ER/UA", "ER"].includes(alunno.stato_presenza)) {
                let orarioIngresso = alunno.orario_ingresso_reale?.slice(0, -3);
                let orarioUscita = alunno.orario_uscita_reale?.slice(0, -3);

                const dataIngresso = orarioIngresso ? new Date(`1970-01-01T${orarioIngresso}:00Z`) : null;
                const dataUscita = orarioUscita ? new Date(`1970-01-01T${orarioUscita}:00Z`) : null;

                if (
                    (alunno.stato_presenza === "ER" && dataIngresso && dataIngresso > dataFineLezione) ||
                    (alunno.stato_presenza === "UA" && dataUscita && dataUscita < dataInizioLezione) ||
                    (alunno.stato_presenza === "ER/UA" &&
                        dataIngresso &&
                        dataIngresso > dataFineLezione &&
                        dataUscita &&
                        dataUscita < dataInizioLezione)
                ) {
                    alunniAssenti.push(objAlunno);
                    idNonSelezionabili.add(objAlunno.id);
                }
            }
        });

        //Non piu ncessario, ora vnon vengono piu mostrati gli alunni ritirati, quindi è inutile salvare in un nuovo stato gli id di quelli non selezionabili
        //const alunniNonSelezionabiliTmp: Alunno[] = [] //isAlunnoIscrittoInData(dataRichiesta, listAlunniTotali); 

        //Dall'array degli alunni selezionati, escludo quelli assenti
        const listSelezionatiFiltered = alunniSelezionati.filter(a => !idNonSelezionabili.has(a.id));
        return {
            alunniNonSelezionabili: [...idNonSelezionabili], // Convert Set<number> to number[]
            listAlunniSelezionati: listSelezionatiFiltered
        }

    } catch (error) {
        console.error("Errore nel recupero delle presenze:", error);
        return { alunniNonSelezionabili: [], listAlunniSelezionati: [] }; // Ritorna un oggetto vuoto in caso di errore
    }
}

function convertLuxonInUtc(date: DateTime | null) {
    if (date !== null) {
        const luxonDate = date;
        const utcString = luxonDate.toUTC().toISO({ includeOffset: false }) + 'Z';
        return utcString;
    } else return null
}
function convertToSqlFormat(data: DateTime) {
    const dateString = convertLuxonInUtc(data);
    if (dateString) {
        const date = DateTime.fromISO(dateString);
        const formattedDate = date.toFormat('yyyy-MM-dd')
        return formattedDate;
    } else return ""
}
interface Alunno {
    id: number;
    iscrittoDal?: string;
    iscrittoAl?: string;
    presenza?: boolean;
}
interface PresenzaAlunno {
    idAlunno: number;
    stato_presenza: "A" | "UA" | "ER/UA" | "ER";
    orario_ingresso_reale?: string;
    orario_uscita_reale?: string;
}
interface OrarioLezione {
    oraInizio: string;
    oraFine: string;
    idOrario: number;
    materia: {
        idMateria: number
    }
}
interface DataObj {
    data: string;
    idAlunno: number[];
}