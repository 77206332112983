import { useEffect, useState } from "react"
import { getAnnoFormativo } from "../../shared/utilityMethods";
import CryptoJS from 'crypto-js';
import { TirociniAPI } from "../../../api/tirociniApi";
import { Alert, Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, Snackbar, TextField, Typography } from "@mui/material";
import { convertDataGetProssimeLezione, convertUtcInDatetime } from "../../shared/metodiDateUtility";
import Grid from "@mui/material/Unstable_Grid2";
import Breadcrumb from "../../registri/shared/breadcrumb";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import DialogConferma from "../../shared/dialogConferma";
import SelezioneClasseOperatore from "../../registri/selezioneClasseOperatore";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function GestioneDatiRicorrentiCorsi() {
    const { t, i18n } = useTranslation();

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(true);
    const [annoFormativo, setAnnoFormativo] = useState("")
    const [sedeId, setSedeId] = useState(-1)
    const [showError, setShowError] = useState(false);
    const [isAlertSaveConfirmOpen, setIsAlertSaveConfirmOpen] = useState(false);
    const [idCorso, setIdCorso] = useState(-1);
    const [corsoSelezionato, setCorsoSelezionato] = useState("");
    const [codiceDeterminazione, setCodiceDeterminazione] = useState("");
    const [dataDeterminazione, setDataDeterminazione] = useState(null);
    const [durataCorso, setDurataCorso] = useState("");
    const [tipoFinanziamento, setTipoFinanziamento] = useState("");
    const [denominazioneQualifica, setDenominazioneQualifica] = useState("");
    const [codiceProgetto, setCodiceProgetto] = useState("");
    const [codiceSigem, setCodiceSigem] = useState("");
    const [codiceAzione, setCodiceAzione] = useState("");
    const [dataInizioTirocinio, setDataInizioTirocinio] = useState(null);
    const [dataFineTirocinio, setDataFineTirocinio] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    useEffect(() => {
        const annoFormativoTmp = getAnnoFormativo('');
        setAnnoFormativo(annoFormativoTmp);
        let sedeTmp = localStorage.getItem("sede");
        let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        let sedeParsed = JSON.parse(decryptedSede).id;
        setSedeId(sedeParsed);
    }, [])

    function openIsAlertConfirmDialog() {
        setIsAlertSaveConfirmOpen(prev => !prev)
    }

    function openChangeCorsoDialog() {
        setDialogSelezionaClasseOpen(true);
    }

    function changeIdSedeSelezionata(nome, idSede) {
        if (idSede !== undefined)
            setSedeId(idSede);
    }
    function changeIdClasseSelezionata(idCorso, dataInizio, dataFine, idMateria, nomeClasse, oreLezione, idOrario, isDocente, nomeMateria, oraMateria, codiceCorso, codiceSimon) {
        setIdCorso(idCorso);
        const corsoName = codiceCorso + " - " + nomeClasse + " (" + codiceSimon + ")";
        setCorsoSelezionato(corsoName)
        setDialogSelezionaClasseOpen(false);
        getDatiPrecompilazione(idCorso);

    }

    async function getDatiPrecompilazione(idCorsoTmp) {
        const objData = {
            id_corso: idCorsoTmp,
        };

        TirociniAPI.getPrecompilazioneTirocini(objData)
            .then((result) => {
                setShowError(false);
                setCodiceDeterminazione(result?.determinazione_dirigenziale_codice || "");
                setDataDeterminazione(result?.determinazione_dirigenziale_data ? convertUtcInDatetime(result?.determinazione_dirigenziale_data) : null);
                setDurataCorso(result?.durata_corso ? result.durata_corso.toString() : "");
                setTipoFinanziamento(result?.finanziamento_tipo || "");
                setDenominazioneQualifica(result?.qualifica_denominazione || "");
                setCodiceProgetto(result?.codice_progetto || "");
                setCodiceAzione(result?.codice_azione || "");
                setDataInizioTirocinio(result?.tirocinio_data_inizio ? convertUtcInDatetime(result?.tirocinio_data_inizio) : null);
                setDataFineTirocinio(result?.tirocinio_data_fine ? convertUtcInDatetime(result?.tirocinio_data_fine) : null);
                setCodiceSigem(result?.codice_sigem || "");
            })
            .catch(function (error) {
                if (error.response) {
                    setShowError(true);
                }
            });
    }
    async function confirmSalvataggio() {
        const objData = {
            "id_corso": parseInt(idCorso), // INT obbligatorio
            "id_sede": parseInt(sedeId), // INT obbligatorio
            "determinazione_dirigenziale_codice": codiceDeterminazione || null, // STR | NULL
            "determinazione_dirigenziale_data": dataDeterminazione ? convertDataGetProssimeLezione(dataDeterminazione) : null, // DATE | NULL
            "durata_corso": durataCorso ? parseInt(durataCorso) : null, // INT | NULL
            "finanziamento_tipo": tipoFinanziamento || null, // STR | NULL
            "qualifica_denominazione": denominazioneQualifica || null,
            "codice_progetto": codiceProgetto || null, // STR | NULL
            "codice_azione": codiceAzione || null, // STR | NULL
            "tirocinio_data_inizio": dataInizioTirocinio ? convertDataGetProssimeLezione(dataInizioTirocinio) : null, // DATE | NULL
            "tirocinio_data_fine": dataFineTirocinio ? convertDataGetProssimeLezione(dataFineTirocinio) : null, // DATE | NULL
            "codice_sigem": codiceSigem || null, // STR | NULL
        };


        TirociniAPI.savePrecompilazioneTirocini(objData)
            .then((result) => {
                setIsAlertSaveConfirmOpen(false);
                setAlertMsg("Dati salvati correttamente");
                setAlertSeverity("success");
                setAlertOpen(true);
            })
            .catch(function (error) {
                if (error.response) {
                    const errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio dei dati";
                    setAlertMsg(errorMsg);
                    setAlertSeverity("error");
                    setAlertOpen(true);
                }
            })
    }

    return (
        <>
            <Breadcrumb isTirocinio={true} sections={["Gestione dati ricorrenti per corsi"]} />
            <Container>
                {showError ? (
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
                        <Alert severity="error">Errore durante il recupero dei dati. Riprovare tra qualche minuto.</Alert>
                        <Button color="primary" sx={{ mt: 2 }} variant="contained" onClick={openChangeCorsoDialog}>
                            Cambia corso
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Typography variant="h4">Gestione dati ricorrenti per corsi </Typography>
                        <Typography color={"primary"} sx={{ mb: 2 }} variant="body1">{corsoSelezionato}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    value={codiceDeterminazione}
                                    label="Codice determinazione dirigenziale"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    onChange={(e) => setCodiceDeterminazione(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LocalizationProvider
                                    localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    dateAdapter={AdapterLuxon} adapterLocale='it'
                                >
                                    <MobileDatePicker
                                        views={['year', 'month', 'day']}
                                        sx={{ width: '100%' }}
                                        label="Data determinazione dirigenziale"
                                        value={dataDeterminazione}
                                        format="dd/MM/yyyy"
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                        onChange={(newValue) => setDataDeterminazione(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    value={durataCorso}
                                    label="Durata del corso"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, ''); // Rimuovi tutto tranne i numeri
                                        setDurataCorso(value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    value={tipoFinanziamento}
                                    label="Tipo di finanziamento"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    onChange={(e) => setTipoFinanziamento(e.target.value)}
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={8}>
                                <TextField
                                    value={denominazioneQualifica}
                                    label="Denominazione della qualifica/specializzazione/frequenza"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    onChange={(e) => setDenominazioneQualifica(e.target.value)}
                                />
                            </Grid> */}
                            <Grid item xs={12} md={4}>
                                <TextField
                                    value={codiceProgetto}
                                    label="Codice progetto"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => setCodiceProgetto(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    value={codiceSigem}
                                    label="Codice Sigem"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => setCodiceSigem(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={codiceAzione}
                                    value={codiceAzione}
                                    label="Codice Azione"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => setCodiceAzione(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LocalizationProvider
                                    localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    dateAdapter={AdapterLuxon} adapterLocale='it'
                                >
                                    <MobileDatePicker
                                        views={['year', 'month', 'day']}
                                        sx={{ width: '100%' }}
                                        label="Data inizio tirocinio"
                                        value={dataInizioTirocinio}
                                        format="dd/MM/yyyy"
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                        onChange={(newValue) => setDataInizioTirocinio(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LocalizationProvider
                                    localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    dateAdapter={AdapterLuxon} adapterLocale='it'
                                >
                                    <MobileDatePicker
                                        views={['year', 'month', 'day']}
                                        sx={{ width: '100%' }}
                                        label="Data fine tirocinio"
                                        value={dataFineTirocinio}
                                        format="dd/MM/yyyy"
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                        onChange={(newValue) => setDataFineTirocinio(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    helperText="Specificare le lettere maiuscole. Non inserire l'anno"
                                    value={denominazioneQualifica}
                                    label="Nome corso per documentazione"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    onChange={(e) => setDenominazioneQualifica(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                            <Button color="primary" sx={{ mr: 1 }} variant="contained" onClick={openChangeCorsoDialog}>
                                Cambia corso
                            </Button>
                            <Button color="success" variant="contained" onClick={openIsAlertConfirmDialog}>
                                Conferma e salva
                            </Button>
                        </Box>

                        <DialogConferma
                            isDialogOpen={isAlertSaveConfirmOpen}
                            title={'Conferma modifiche'}
                            // icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                            text={`Confermi di voler procedere con il salvataggio delle modifiche?`}
                            showAlunni={false}
                            confermaButton={'Conferma salvataggio'}
                            severity={'success'}
                            closeDialog={openIsAlertConfirmDialog}
                            conferma={confirmSalvataggio}
                            disabledButton={false}
                        />

                    </>
                )}
            </Container>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertOpen} autoHideDuration={8000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen} sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Seleziona corso </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>

                    <SelezioneClasseOperatore dontShowDate={true} tipoRegistro={"classe"} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeIdSedeSelezionata} />

                </DialogContent>
            </Dialog>

            <Helmet>
                <title>{t("name")} - Gestione dati ricorrenti corsi</title>
            </Helmet>
        </>
    )
}
