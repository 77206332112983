import { Box, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, FormControl, Select, Breadcrumbs, Link } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment } from "react"
import ElencoAlunniSelezionabili from "./elencoAlunniSelezionabili";

import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { EventiAPI } from "../../api/eventiApi";
import { RegistroAPI } from "../../api/registroApi";
import { convertDataGetProssimeLezione, convertToSqlFormat } from "../shared/metodiDateUtility";
import { PresenzeAPI } from "../../api/presenzeApi";
import { CellMeasurer, CellMeasurerCache, List, AutoSizer } from "react-virtualized";
import DialogConferma from "../shared/dialogConferma";
import { Check } from "@mui/icons-material";
import functionCheckAlunniSelezionabili from "./functionCheckAlunniSelezionabili";

export default function AggiuntaVoto(props) {

    const [data, setData] = useState(null);
    const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    // eslint-disable-next-line no-unused-vars
    const [tipoRegistro, setTipoRegistro] = useState("classe");

    const [elencoOre, setElencoOre] = useState([]);
    const [oraSelected, setOraSelected] = useState(-1);

    const [typeVoto, setTypeVoto] = useState(-1);
    // eslint-disable-next-line no-unused-vars
    const [hideSelectTypeVoto, setHideSelectTypeVoto] = useState(false);

    const [valType, setValType] = useState(1);

    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);

    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [exAnte, setExAnte] = useState(false);

    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)

    const [elencoMaterieCorsoPeriodoDocente, setElencoMaterieCorsoPeriodoDocente] = useState([])
    const [materiaAlternativa, setMateriaAlternativa] = useState(null)
    const [idMateriaAlternativa, setIdMateriaAlternativa] = useState(-1)

    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const [errorMsg, setErrorMsg] = useState("Non è possibile continuare con il salvataggio. C'è un errore.")

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 75,
    });
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("")
    const [dataFinePeriodo, setDataFinePeriodo] = useState("")
    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.typeLezione !== undefined) {
            setTypeVoto(props.typeLezione);
            if (props.typeLezione !== -1)
                setHideSelectTypeVoto(true)
            else setHideSelectTypeVoto(false);
        }
    }, [props.typeLezione])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined && props.alunniSelezionati !== undefined && props.dataRichiesta !== undefined) {

            if (props.idMateria === 101) getElencoMaterieCorsoPeriodoDocente(props.idCorso, props.dataRichiesta)

            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)

            let listAlunniTmp = [];
            let dataRichiesta = DateTime.fromSQL(props.dataRichiesta);
            let todayDate = DateTime.now();
            if (dataRichiesta > todayDate)
                setData(todayDate)
            else setData(dataRichiesta)
            if (props.listAlunni == null) { //Ma qui ci entra mai?
                getAlunniByClasseID();
            } else {
                let dataRichiestaTmp = DateTime.fromSQL(props.dataRichiesta).toUTC(); //Datetime
                manageStatesAlunni(props.listAlunni, props.alunniSelezionati, dataRichiestaTmp, props.idDocente, props.idCorso, props.idMateria)
                setGetAlunniIsLoading(false);
            }
        }
    }, [props.idCorso, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati, props.dataRichiesta, props.tipoRegistro]);

    useEffect(() => {
        if (props.nota !== undefined) {
            let newDataTmp = props?.nota?.dataLezione;
            newDataTmp = newDataTmp.replaceAll("-", "/");
            setData(newDataTmp);
            setMaterie([...materie, props.nota.materia])
        }
    }, [props.nota]);


    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])

    async function getElencoMaterieCorsoPeriodoDocente(idCorso, data) {
        let obj = {
            "idCorso": idCorso,
            "data": data
        }
        RegistroAPI.getElencoMaterieCorsoPeriodoDocente(obj)
            .then((result) => {
                setElencoMaterieCorsoPeriodoDocente(result);
                if (result.length === 1) {
                    setMateriaAlternativa(result[0]);
                    setIdMateriaAlternativa(result[0].idMateria)
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) props.logout();
                }
                // console.error(error)
            })
    }
    async function inserisciVoto() {

        let alunniSelezionatiTmp = alunniSelezionati;
        let votiFalliti = [];
        for (let alunno of alunniSelezionati) {

            const obj = {
                voto: alunno?.voto === 'N.V.' ? "0" : alunno?.voto,
                note: '',
                idAlunno: [alunno?.id],
                idOrario: oraSelected, // oraSelected, // TODO: int - da definire 
                is_ex_ante: exAnte,
                tipologia: valoreTypeVoto(),
            }

            let materiaAlternativaTmp = {
                "idMateria": materiaAlternativa?.idMateria,
                "nomeBreve": materiaAlternativa?.nomeBreve,
                "nomeMateria": materiaAlternativa?.nomeMateria
            }

            if (idMateria === 101) obj.materiaAlternativa = materiaAlternativaTmp;

            setPulsanteConfermaDisabled(true);

            const result = await inserimentoVoto(obj);
            if (result != null) {

                votiFalliti.push(result)
            }

        }

        if (votiFalliti.length === 0) {
            if (isDialog) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto("Voto aggiunto", "success");
            } else {
                setAlunniSelezionati([]);
                setAlertSeverity("success");
                setAlertMsg("Voto aggiunto");
                setAlertIsOpen(true);
            }
        } else {
            for (let i = 0; i < alunniSelezionatiTmp.length; i++) {
                let indexVoti = votiFalliti.findIndex(a => a.id === alunniSelezionatiTmp[i].id)
                if (indexVoti !== -1)
                    alunniSelezionatiTmp[i].msgError = votiFalliti[indexVoti].errorMsg;
            }

            let newAlunniSelezionati = alunniSelezionatiTmp.filter(a => a.msgError !== undefined)
            setAlunniSelezionati([...newAlunniSelezionati])
        }
    }
    async function inserisciValutazione() {
        let alunniSelezionatiTmp = alunniSelezionati;
        let votiFalliti = [];
        for (let alunno of alunniSelezionati) {

            let arrayValutazione = [];
            for (let char of alunno.valutazione) {
                arrayValutazione.push(char)
            }
            const obj = {
                tipologia: arrayValutazione,
                //grado: '',
                idAlunno: [alunno?.id],
                idOrario: oraSelected, // TODO: int - da definire 
            }

            let materiaAlternativaTmp = {
                "idMateria": materiaAlternativa?.idMateria,
                "nomeBreve": materiaAlternativa?.nomeBreve,
                "nomeMateria": materiaAlternativa?.nomeMateria
            }

            if (idMateria === 101) obj.materiaAlternativa = materiaAlternativaTmp;

            setPulsanteConfermaDisabled(true);

            const result = await inserimentoValutazione(obj);
            if (result != null) {

                votiFalliti.push(result)
            }

        }

        if (votiFalliti.length === 0) {
            if (isDialog) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto("Valutazione aggiunte", "success");
            } else {
                setAlunniSelezionati([]);
                setAlertSeverity("success");
                setAlertMsg("Vakutazione aggiunte");
                setAlertIsOpen(true);
            }
        } else {
            for (let i = 0; i < alunniSelezionatiTmp.length; i++) {
                let indexVoti = votiFalliti.findIndex(a => a.id === alunniSelezionatiTmp[i].id)
                if (indexVoti !== -1)
                    alunniSelezionatiTmp[i].msgError = votiFalliti[indexVoti].errorMsg;
            }

            let newAlunniSelezionati = alunniSelezionatiTmp.filter(a => a.msgError !== undefined)
            setAlunniSelezionati([...newAlunniSelezionati])
        }

    }
    async function inserimentoVoto(obj) {
        try {
            const result = await EventiAPI.insertVoto(obj);
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            return null;

        } catch (error) {
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            let errorMsg = "";
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)

            if (error.response.status === 409) {
                errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                // setAlertMsg(error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione");
            } else {
                errorMsg = "Al momento non è possibile completare l'operazione";
                //setAlertMsg("Al momento non è possibile completare l'operazione");
            }
            return {
                id: obj.idAlunno[0],
                errorMsg: errorMsg
            };
            // setConfirmAlertIsOpen(false);
            // setAlertSeverity("error");
            // setAlertIsOpen(true);
        }
    }
    async function inserimentoValutazione(obj) { //TODO: da testare
        try {
            await EventiAPI.insertValutazione(obj);
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            return null;


        } catch (error) {
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            let errorMsg = "";
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)

            if (error.response.status === 409) {
                errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                // setAlertMsg(error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione");
            } else {
                errorMsg = "Al momento non è possibile completare l'operazione";
                //setAlertMsg("Al momento non è possibile completare l'operazione");
            }
            return {
                id: obj.idAlunno[0],
                errorMsg: errorMsg
            };
            // setConfirmAlertIsOpen(false);
            // setAlertSeverity("error");
            // setAlertIsOpen(true);
        }
    }

    function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);
                manageStatesAlunni(result.alunni, [], DateTime.now(), props.idDocente, props.idCorso, props.idMateria)

            })
    }


    function selectAlunni(listAlunni) {
        let alunniSelezionatiTmp = alunniSelezionati;
        if (listAlunni !== undefined) {
            let arrayTmp = listAlunni;
            for (let i = 0; i < arrayTmp.length; i++) {
                let votoTmp = "";
                let valutazioneTmp = "";
                if (alunniSelezionatiTmp.some(alunno => alunno.id === arrayTmp[i].id)) {
                    let index = alunniSelezionatiTmp.findIndex(alunno => alunno.id === arrayTmp[i].id);
                    if (index !== -1) {
                        votoTmp = alunniSelezionatiTmp[index].voto;
                        valutazioneTmp = alunniSelezionatiTmp[index].valutazione;

                    }
                }
                arrayTmp[i].voto = votoTmp;
                arrayTmp[i].valutazione = valutazioneTmp;
            }
            // setAlunniSelezionati([...arrayTmp]);
            let test = arrayTmp.sort((a, b) => {
                if (a.n < b.n) {
                    return -1;
                }
                if (a.n > b.n) {
                    return 1;
                }
                return 0;
            });

            setAlunniSelezionati([...test]);
        }
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        manageStatesAlunni(listAlunni, alunniSelezionati, value, idDocente, idCorso, idMateria);
    }

    function changeTypeVal(e) {
        setValType(e.target.value)
        let arrayTmp = alunniSelezionati;
        for (let a of arrayTmp) {
            a.voto = '';
            a.valutazione = ''
        }
    }

    function changeIdMateriaAlternativa(e) {
        let idMateriaTmp = e.target.value;
        setIdMateriaAlternativa(idMateriaTmp)
        let materiaTmp = elencoMaterieCorsoPeriodoDocente.find((materia) => materia.idMateria === idMateriaTmp);
        setMateriaAlternativa(materiaTmp);
        if (idMateriaTmp === -1) {
            setErrorMsg("Non è possibile lasciare il campo 'materia' vuoto")
            setShowErrorMsg(true);
        } else setShowErrorMsg(false);
    }


    function changeTypeVoto(e) {
        setTypeVoto(e.target.value)
    }

    function valoreTypeVoto() {
        switch (typeVoto) {
            case -1:
                return '';
            case 0:
                return 'Scritta';
            case 1:
                return 'Orale';
            case 2:
                return 'Pratica';

        }
    }

    function changeVoti(e, idAlunno) {

        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            //  newStr = newValue.replace(/(\d)[a-zA-Z]/g, '$1');
            // newStr = newValue.replace(/[-/]/g, "");
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr === 0) newStr = 'N.V.';
        } else {
            newStr = '' //resetta a ''
        }

        let alunniSelezionatiTmp = alunniSelezionati;
        let index2 = alunniSelezionatiTmp.findIndex((element) => element.id === idAlunno);
        alunniSelezionatiTmp[index2].voto = newStr
        setAlunniSelezionati([...alunniSelezionatiTmp])
    }

    function changeValutazione(e, idAlunno) {
        let newValue = e.target.value;
        //let newStr = newValue.replace(/[^cCmMdDrR]/g, '');
        let newStr = "";
        const regExValutazione = idMateria === 97 ? /[rRiIpP]/g : /[cCmMdDrRpP]/g;
        for (const char of newValue) {
            const regEx = idMateria === 97 ? /[rRiIpP]/g : /[cCmMdDrRpP]/g;
            if (regEx.test(char.toUpperCase()) && !newStr.includes(char.toUpperCase())) {
                newStr += char.toUpperCase();
            }
        }
        let alunniSelezionatiTmp = alunniSelezionati;
        let index2 = alunniSelezionatiTmp.findIndex((element) => element.id === idAlunno);

        if (regExValutazione.test(newValue) === false) {
            alunniSelezionatiTmp[index2].valutazione = newStr.toUpperCase();
            alunniSelezionatiTmp[index2].valutazioneValida = false;
            alunniSelezionatiTmp[index2].valutazioneHelperMessage = idMateria === 97 ? 'Valori ammissibili: R/I/P' : 'Valori ammissibili: C/M/P/R/D';
            setAlunniSelezionati([...alunniSelezionatiTmp])
        } else {

            alunniSelezionatiTmp[index2].valutazione = newStr.toUpperCase();
            alunniSelezionatiTmp[index2].valutazioneValida = true;
            alunniSelezionatiTmp[index2].valutazioneHelperMessage = '';
            setAlunniSelezionati([...alunniSelezionatiTmp])

        }
    }

    function changeOra(e) {
        setOraSelected(e.target.value);
        manageStatesAlunni(listAlunni, alunniSelezionati, data, idDocente, idCorso, idMateria, e.target.value);
    }

    function votoIsNotValid() {
        return alunniSelezionati.findIndex((element) => element.voto === '') !== -1 ? true : false
    }

    function valutazioneIsNotValid() {
        return alunniSelezionati.findIndex((element) => element.valutazione === '') !== -1 ? true : false
    }

    function isAlunnoIscrittoInData(data, listAlunni) {
        if (data !== null) {
            let dataConvertedIso = data.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp; //setAlunniNonSelezionabili([...listTmp]);
        }
    }

    function isAddButtonDisabled() {
        // if (idMateria === 101 && materiaAlternativa !== null) {

        if (alunniSelezionati.length > 0 && data != null && oraSelected != -1 &&
            ((idMateria === 101 && materiaAlternativa !== null && idMateriaAlternativa !== -1) || idMateria !== 101)) {
            if (valType === 0) {
                if (!votoIsNotValid() && typeVoto !== -1) return false
                else return true
            } else {
                if (!valutazioneIsNotValid()) return false
                else return true
            }
        } else return true

    }

    function manageStatesAlunni(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp) {
        functionCheckAlunniSelezionabili(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp ?? undefined)
            .then(objCheckAlunniSelezionabili => {
                console.log(objCheckAlunniSelezionabili)
                setListAlunni([...objCheckAlunniSelezionabili.listAlunniTotali]);
                setElencoOre([...objCheckAlunniSelezionabili.listOre]);

                if (idOrarioTmp === undefined) //Se non è undefined ho già settato l'ora in precedenza, quindi non la risetto
                    setOraSelected(objCheckAlunniSelezionabili.oraSelected);
                setIdMateria(objCheckAlunniSelezionabili.idMateriaSelected)
                setAlunniSelezionati([...objCheckAlunniSelezionabili.listAlunniSelezionati]);
                const alunniNonSelezionabiliObj = objCheckAlunniSelezionabili.alunniNonSelezionabili.map(id => {
                    return { id: id };
                });
                setAlunniNonSelezionabili([...alunniNonSelezionabiliObj]);
                setAlunniSelezionabiliNumber(objCheckAlunniSelezionabili.numberAlunniSelezionabili)
            });
    }




    function rowRenderer({ index, key, parent, style }) {
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => (

                    <div onLoad={measure} style={style}>
                        <Grid container spacing={2} mb={1} sx={{ p: 1, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderBottom: 'solid 1px rgb(210,210,210)' }}>
                            <Grid xs={12} md={8} sx={{ width: '100%' }}>
                                <Typography>{alunniSelezionati[index]?.cognome} {alunniSelezionati[index]?.nome}  </Typography>
                                {alunniSelezionati[index]?.msgError ? (
                                    <Typography variant="subtitle2" color="error">{alunniSelezionati[index]?.msgError}</Typography>
                                ) : null}
                            </Grid>
                            <Grid xs={12} md={4} sx={{ width: '100%' }}>
                                {valType !== 0 ? (
                                    <TextField sx={{ width: '100%' }} data-testid='valutazioneInput'
                                        label="Valutazione"
                                        inputProps={{ maxLength: 5 }}
                                        onChange={(e) => changeValutazione(e, alunniSelezionati[index]?.id)}
                                        value={alunniSelezionati[index]?.valutazione ? alunniSelezionati[index]?.valutazione : ''}
                                        helperText={alunniSelezionati[index]?.valutazioneHelperMessage}
                                        error={(!alunniSelezionati[index]?.valutazioneValida && !alunniSelezionati[index]?.valutazioneHelperMessage !== '' && alunniSelezionati[index]?.valutazioneValida !== undefined)}
                                    />) :
                                    (<TextField sx={{ width: '100%' }} data-testid='votoInput'
                                        label="Voto"
                                        onChange={(e) => changeVoti(e, alunniSelezionati[index]?.id)}
                                        value={alunniSelezionati[index]?.voto ? alunniSelezionati[index]?.voto : ''}
                                        inputProps={{ maxLength: 4 }}
                                    />)}
                            </Grid>
                        </Grid>
                    </div>
                )}
            </CellMeasurer>
        )

    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!isDialog ? (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                        <Typography color="text.disabled">Aggiunta eventi</Typography>
                        <Typography color="text.disabled">{valType === 0 ? "Voto" : "Valutazione"}</Typography>
                    </Breadcrumbs>) : null}
                <Typography variant="h4" mt={1}>Aggiunta voto/valutazione</Typography>

                <Grid container spacing={2}>
                    <Grid xs={12} md={6} alignItems="stretch">
                        {!getAlunniIsLoading ? (
                            <ElencoAlunniSelezionabili checkAssenze={true} alunniSelezionabiliNumber={alunniSelezionabiliNumber}
                                canSelezioneMultipla={true} selectAlunni={selectAlunni} alunniNonSelezionabili={alunniNonSelezionabili} listAlunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                        ) : (
                            <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                <CircularProgress />
                                <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                            </Paper>
                        )}
                    </Grid>
                    <Grid xs={12} md={6} alignItems="stretch" direction="column" justifyContent="flex-end">
                        <Box sx={{ display: "flex", flexDirection: "column", height: '100%' }}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                            minDate={dataInizioPeriodo}
                                            maxDate={DateTime.now() || dataFinePeriodo}
                                            label="Data"
                                            value={data}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="materia-label">
                                            {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                            Seleziona ora</InputLabel>
                                        <Select
                                            data-testid='selOraVotoSelect'
                                            label='Seleziona ora '
                                            labelId="materia-label"
                                            value={oraSelected}
                                            onChange={changeOra}
                                            disabled={elencoOre.length === 0}
                                        >
                                            <MenuItem key={-1} value={-1}>
                                            </MenuItem>
                                            {elencoOre.map((ora) => (
                                                <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                    {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {data != null && elencoOre.length === 0 ? (<Typography variant="caption">Nessuna ora di lezione nella data scelta</Typography>) : null}
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid xs={12} md={valType === 0 ? 3 : 6} sx={{ width: '100%', pt: { xs: 2, md: 3 } }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="selTypeVal-label">
                                            {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                            Voto / Valutazione </InputLabel>
                                        <Select
                                            data-testid='selTypeValutazioneSelect'
                                            labelId="selTypeVal-label"
                                            label='voto / valutazione  '
                                            value={valType}
                                            onChange={changeTypeVal}
                                        // disabled={!isModificaMode}
                                        >
                                            <MenuItem key={0} value={0} >
                                                Voto
                                            </MenuItem>
                                            <MenuItem key={1} value={1}>
                                                Valutazione
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {valType === 0 && (
                                    <Grid xs={12} md={valType === 0 ? 3 : 6} sx={{ width: '100%', pt: { xs: 2, md: 3 } }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="typeVoto-label">
                                                {/* {classeName === '' ? t("pages.ricercaAlunni.classe") : t("pages.ricercaAlunni.classeS")} */}
                                                Tipologia di prova
                                            </InputLabel>
                                            <Select
                                                data-testid='selTypeVotoSelect'
                                                labelId="typeVoto-label"
                                                label='Tipologia di prova'
                                                value={typeVoto}
                                                onChange={changeTypeVoto}
                                            >
                                                <MenuItem key={-1} value={-1} >

                                                </MenuItem>
                                                <MenuItem key={0} value={0} >
                                                    Scritta
                                                </MenuItem>
                                                <MenuItem key={1} value={1} >
                                                    Orale
                                                </MenuItem>
                                                <MenuItem key={2} value={2} >
                                                    Pratica
                                                </MenuItem>


                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {idMateria === 101 && (<Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 2, md: 3 } }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="selMateria-label">
                                            Materia </InputLabel>
                                        <Select
                                            data-testid='selTypeValutazioneSelect'
                                            labelId="selMateria-label"
                                            label='Materia  '
                                            value={idMateriaAlternativa}
                                            onChange={changeIdMateriaAlternativa}
                                            disabled={elencoMaterieCorsoPeriodoDocente.length < 2}
                                        >
                                            <MenuItem key={-1} value={-1} >

                                            </MenuItem>
                                            {elencoMaterieCorsoPeriodoDocente.map((materia, index) => (
                                                <MenuItem key={materia?.idMateria} value={materia?.idMateria}>
                                                    {materia?.nomeBreve}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>)}
                                {/* {valType === 0 && (<Grid xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Checkbox onChange={changeExAnte} data-testid='exAnteValutazioneCheckBox' checked={exAnte} />
                                        } label="Ex ante/accertamento prerequisiti" />
                                    </FormGroup>
                                </Grid>)} */}

                            </Grid>
                            <Grid xs={12} md={12}>
                                <Typography mb={1}>Lista alunni selezionati</Typography>
                            </Grid>
                            <Grid container sx={{ height: { xs: '50vh', md: "100%" } }}>
                                <Grid xs={12} md={12} sx={{ height: '100%' }}>
                                    <Container mt={1} sx={{ p: 1, border: '1px solid rgba(200, 200, 200, 1)', height: '100%', overflow: 'hidden', borderRadius: '4px' }}>
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    deferredMeasurementCache={cache}
                                                    height={height}
                                                    rowCount={alunniSelezionati.length}
                                                    rowHeight={cache.rowHeight}
                                                    rowRenderer={rowRenderer}
                                                    width={width}
                                                />
                                            )}
                                        </AutoSizer>

                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                    {(showErrorMsg) && (
                        <Alert sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 1, md: 0 }, height: '1.75em', display: 'flex', alignItems: 'center' }} severity="error">{errorMsg}</Alert>
                    )}
                    {isDialog ? (
                        <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                    ) : null}
                    <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isAddButtonDisabled()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                        color="success" variant="contained" >
                        Aggiungi {valType === 0 ? 'Voto' : 'Valutazione'}
                    </Button>
                </Box>
            </Container>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={valType === 0 ? 'Conferma aggiunta voto' : 'Conferma aggiunta valutazione'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere ${valType === 0 ? 'un voto a ' : 'una valutazione a '}`}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={valType === 0 ? inserisciVoto : inserisciValutazione}
                disabledButton={pulsanteConfermaDisabled}
            />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}