import { useState, useEffect, Fragment, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Divider, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography, useTheme, DialogActions, useMediaQuery, IconButton, Alert, Snackbar, Link, } from '@mui/material';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import { ArrowBack, Help, EditCalendar, ZoomIn, Close, AttachFile } from "@mui/icons-material";
import RangeDate from "../shared/rangeDate";
import { DateTime, Interval } from "luxon";

import RegistroDiClasse from "./registroDiClasse";
import EventoChip from "./shared/eventoChip";

import { convertDateObjectToString, convertISODate, getDayOfTheWeek, getDayAndMonth, getDayMonthAndYearErrorDialog, convertDataGetProssimeLezione, convertDataDiNascita } from '../shared/metodiDateUtility';
import RegistroDocente from "./registroDocente";
import Presenza from "./presenza";
import { PresenzeAPI } from "../../api/presenzeApi";
import { RegistroAPI } from "../../api/registroApi";
import SelezioneClasseOperatore from "./selezioneClasseOperatore";
import { useLocation } from "react-router-dom";
import Legende from "./shared/legende";
import DettaglioAlunno from "../dettaglio-utente/dettaglioAlunno";
import SelezioneLezioneNew from "./selezioneLezioneNew";
import Breadcrumb from "./shared/breadcrumb";
import RiepilogoArgomentiSvolti from "../eventi/riepilogoArgomentiSvolti";

export default function RegistroDiSintesi(props) {


    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [tipoRegistro, setTipoRegistro] = useState("classe")
    const [tipo, setTipo] = useState(false)

    const [dialogSelezionaClasseOpen, setDialogSelezionaClasseOpen] = useState(true)

    const [isDocente, setIsDocente] = useState(true);

    const [permessiUtente, setPermessiUtente] = useState([]);

    const [idDocente, setIdDocente] = useState(-1);
    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [idOrarioSelezionato, setIdOrarioSelezionato] = useState(-1);
    const [nomeClasse, setNomeClasse] = useState("");
    const [oraMateria, setOraMateria] = useState('');
    const [codiceCorso, setCodiceCorso] = useState('');
    const [codiceSimon, setCodiceSimon] = useState('');

    const [oreDiLezioneClasseSelezionata, setOreDiLezioneClasseSelezionata] = useState([]);
    const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState("");
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);
    const [ruoliUtente, setRuoliUtente] = useState({});

    const [dataRichiestaDettaglio, setDataRichiestaDettaglio] = useState(null);


    const [arrayGiorni, setArrayGiorni] = useState([])
    const [listAlunni, setListAlunni] = useState([]);
    const [dialogDettaglioRegistroOpen, setDialogDettaglioRegistroOpen] = useState(false);


    //Periodo di interesse
    const [inizioPeriodo, setInizioPeriodo] = useState(null);
    const [finePeriodo, setFinePeriodo] = useState(null);

    const [periodoDiInteresseInizio, setPeriodoDiInteresseInizio] = useState(null);
    const [periodoDiInteresseFine, setPeriodoDiInteresseFine] = useState(null);

    const [formattedPeriodoDiInteresseInizio, setFormattedPeriodoDiInteresseInizio] = useState("");
    const [formattedPeriodoDiInteresseFine, setFormattedPeriodoDiInteresseFine] = useState("");
    const [changePeriodoDiInteresseOpen, setChangePeriodoInteresseOpen] = useState(false);

    const [hasDocenteFunctions, setHasDocenteFunctions] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);

    //DETTAGLIO ALUNNO
    const [presenzaObj, setPresenzaObj] = useState(null);
    const [dettaglioAlunnoOpen, setDettaglioAlunnoOpen] = useState(false);
    const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
    const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(null);
    const [indexTabToOpen, setIndexTabToOpen] = useState(0);
    const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [sedeUtente, setSedeUtente] = useState('');
    const [isOrarioAlternativo, setIsOrarioAlternativo] = useState(false);
    const [msgOrarioAlternativo, setMsgOrarioAlternativo] = useState("");

    const [oreFirmate, setOreFirmate] = useState();
    const [scrollPositionY, setScrollPositionY] = useState(0);
    const [scrollPositionX, setScrollPositionX] = useState(0);
    const tableRef = useRef();

    const [isRiepilogoArgomentiSvoltiDialogOpen, setIsRiepilogoArgomentiSvoltiDialogOpen] = useState(false);
    const [objRiepilogoArgomentiSvolti, setObjRiepilogoArgomentiSvolti] = useState({});


    useEffect(() => { //Quando clicco su un altra route che usa sempre registro di sintesi, resetto questi valori
        if (props.isDocente !== undefined) setIsDocente(props.isDocente);
        else setIsDocente(true)
        setHasLoaded(false);
        setDialogDettaglioRegistroOpen(false);
        setDialogSelezionaClasseOpen(true);
        setDettaglioAlunnoOpen(false);
    }, [location]);



    useEffect(() => {
        setDialogDettaglioRegistroOpen(false)
        setScrollPositionY(0)
        setScrollPositionX(0);
        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            setPermessiUtente(permessiParsed);
        }

        if (sessionStorage.getItem("annoFormativoObj")) {
            let decryptedAnnoFormativoObj = sessionStorage.getItem("annoFormativoObj");
            let annoFormativoObjParsed = JSON.parse(decryptedAnnoFormativoObj);
            setInizioPeriodo(annoFormativoObjParsed?.dataDA ?? null);
            setFinePeriodo(annoFormativoObjParsed?.dataA ?? null);
        }

        if (localStorage.getItem("doc")) {
            let idDocenteTmp = localStorage.getItem("doc");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)

        } else if (localStorage.getItem("id")) {
            let idDocenteTmp = localStorage.getItem("id");
            let idDocenteDecrypted = CryptoJS.AES.decrypt(idDocenteTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

            let idDocenteInt = parseInt(idDocenteDecrypted)
            setIdDocente(idDocenteInt)
        }
    }, [])

    useEffect(() => {
        if (props.ruoliUtente !== undefined) {
            setRuoliUtente(props.ruoliUtente);
        }
    }, [props.ruoliUtente])

    useEffect(() => {
        if (props.isDocente !== undefined)
            setIsDocente(props.isDocente)
    }, [props.isDocente])

    useEffect(() => {
        setTipoRegistro(props.tipoRegistro);
    }, [props.tipoRegistro])

    function goToSelezionaOra() {
        setDialogSelezionaClasseOpen(true);
    }

    function saveScrollPosition() {
        if (tableRef.current != null) {
            setScrollPositionY(tableRef.current.scrollTop)
            setScrollPositionX(tableRef.current.scrollLeft);
        }
    }

    function openDettaglioRegistro(dataRichiesta) {
        setScrollPositionY(tableRef.current.scrollTop)
        setScrollPositionX(tableRef.current.scrollLeft);
        setDataRichiestaDettaglio(dataRichiesta)
        setDialogDettaglioRegistroOpen(true);
    }

    function changeIdClasseSelezionata(idClasse, dataInizio, dataFine, idMateria, nomeClasse, oreLezione, idOrario, isDocente, nomeMateria, oraMateria, codiceCorso, codiceSimon) {


        if (tipoRegistro == "docente") {
            sessionStorage.setItem("matDoc", idMateria);
        }
        if (idClasse !== undefined) {

            let idCorsoCrypted = CryptoJS.AES.encrypt(idClasse.toString(), process.env.REACT_APP_PRIVATE_KEY).toString();
            sessionStorage.setItem("classe", idCorsoCrypted);
            setIdClasseSelezionata(idClasse);
            setIdMateriaSelezionata(idMateria);
            setIdOrarioSelezionato(idOrario);
            setNomeClasse(nomeClasse);
            setDialogSelezionaClasseOpen(false);
            setCodiceCorso(codiceCorso);
            setCodiceSimon(codiceSimon);

            setPeriodoDiInteresseInizio(dataInizio);
            setPeriodoDiInteresseFine(dataFine);

            setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
            setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));

            setNomeMateriaSelezionata(nomeMateria);
            setOraMateria(oraMateria);
            setOreDiLezioneClasseSelezionata([...oreLezione])

            getRegistroDiSintesi(idClasse, dataInizio, dataFine, idMateria);
        }

    }




    //Periodo di interesse
    function changePeriodoDiInteresse(dataInizio, dataFine) {
        let dataInizioTmp = dataInizio ?? new DateTime().toISO();
        let dataFineTmp = dataFine ?? new DateTime().toISO();


        setScrollPositionX(0)

        setPeriodoDiInteresseInizio(dataInizioTmp);
        setPeriodoDiInteresseFine(dataFineTmp);

        setFormattedPeriodoDiInteresseInizio(convertISODate(dataInizio));
        setFormattedPeriodoDiInteresseFine(convertISODate(dataFine));
        setChangePeriodoInteresseOpen(false)



        changeIdClasseSelezionata(idClasseSelezionata, dataInizioTmp, dataFineTmp, idMateriaSelezionata, nomeClasse, oreDiLezioneClasseSelezionata, idOrarioSelezionato, hasDocenteFunctions, nomeMateriaSelezionata, oraMateria, codiceCorso, codiceSimon)
    }
    function openPeriodoDiInteresseDialog() {
        setChangePeriodoInteresseOpen(true);
    }
    function closeRegistro() {
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
        setDialogDettaglioRegistroOpen(false)
    }
    function closeDettaglioAlunno() {
        setDettaglioAlunnoOpen(false);
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function eventoModificato(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity);
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }
    function vediDettaglioAlunno(id, indexTab, nome, cognome, ritardo, presenzaObj) {

        if (tableRef.current != null) {
            setScrollPositionY(tableRef.current.scrollTop)
            setScrollPositionX(tableRef.current.scrollLeft)
        }
        setPresenzaObj(presenzaObj)
        setOpenDialogRitardo(ritardo)
        setIdAlunnoSelezionato(id);
        setNomeAlunnoSelezionato(nome + " " + cognome);
        setDettaglioAlunnoOpen(true);
        setIndexTabToOpen(indexTab);
    }

    function isAlunnoIscrittoInData(dataCella, alunno) {
        let dataSql = DateTime.fromSQL(dataCella);
        let dataConvertedIso = dataSql.toUTC().toISO()
        let iscrittoDalIso = alunno?.iscrittoDal;
        let iscrittoAlIso = alunno?.iscrittoAl;

        let startDate = DateTime.fromISO(iscrittoDalIso);
        let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
        let someDate = DateTime.fromISO(dataConvertedIso);
        let interval = Interval.fromDateTimes(startDate, endDate);
        if (interval.contains(someDate)) {
            return true
        } else return false;
    }

    function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
        let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
        let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

        let dataInizioObject = DateTime.fromISO(dataInizioTmp);
        let dataFineObject = DateTime.fromISO(dataFineTmp);

        let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
        let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
        if (alunno?.showDataRitiro == 1 || alunno?.showDataIscritto == 1) {
            if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
            {
                return "Ritirato il " + convertDataDiNascita(iscrittoAl)
            }
            if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
            {
                return "Ritirato il " + convertDataDiNascita(iscrittoAl)
            }
            else //if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
            {
                return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
            }
        } else return "";
        // else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
        //     return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
        // }
        // else return null
    }

    async function getRegistroDiSintesi(idClasseSelezionata, dataDiInteresseInizio, dataDiInteresseFine, idMateria) {
        let dataObj = {
            dataRichiestaDa: convertDataGetProssimeLezione(dataDiInteresseInizio, true),
            dataRichiestaA: convertDataGetProssimeLezione(dataDiInteresseFine, true),
            idCorso: idClasseSelezionata
        }
        setHasLoaded(false)

        if (tipoRegistro == "docente") {
            dataObj.idMateria = idMateria;

            getPercentualeOreFirma(idOrarioSelezionato, idDocente, idClasseSelezionata, idMateria);

            RegistroAPI.getRegistroDocenteDiSintesi(dataObj)
                .then((result) => {
                    let alunniTmp = result.alunni;
                    let arrayGiorniTmp = result.giorni;
                    setIsOrarioAlternativo(result?.isOrarioAlternativo ?? false);
                    setMsgOrarioAlternativo(result?.messaggioAlternativo ?? "");

                    setIsOrarioAlternativo(result?.isOrarioAlternativo ?? false);
                    setMsgOrarioAlternativo(result?.messaggioAlternativo ?? "");

                    for (let i = 0; i < arrayGiorniTmp.length; i++) {
                        let dataIso = arrayGiorniTmp[i]?.data;
                        let dataConverted = convertDateObjectToString(new Date(dataIso));
                    
                        arrayGiorniTmp[i].dataIso = dataIso;
                        arrayGiorniTmp[i].data = dataConverted;
                    
                        let eventiGiorno = arrayGiorniTmp[i]?.comunicazioni;
                        for (let y = 0; y < eventiGiorno.length; y++) {
                            let evento = eventiGiorno[y];
                    
                            if ([1, 4, 5, 7, 8].includes(evento?.tipologia_comunicazioni)) {
                                let idAlunni = evento?.albero_destinatari?.idAlunno || [];
                    
                                if (idAlunni.length > 0 && idAlunni.length !== listAlunni.length) {
                                    // Trova tutti gli alunni con gli ID presenti in idAlunni
                                    alunniTmp
                                        .filter(al => idAlunni.includes(al.id)) // Filtra tutti gli alunni con lo stesso ID
                                        .forEach(al => {
                                            let eventoTmp = { ...evento, indexColonna: i }; // Clona l'evento per evitare side-effects
                                            al.comunicazioni.push(eventoTmp); // Inserisce l'evento in ogni alunno trovato
                                        });
                                }
                            }
                        }
                    }
                    

                    for (let i = 0; i < alunniTmp.length; i++) {
                        alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i]);

                    }



                    getPresenzeSintesi(alunniTmp, arrayGiorniTmp, dataDiInteresseInizio, dataDiInteresseFine);


                })
                .catch(function (error) {
                    setHasLoaded(true)

                })
        } else {
            RegistroAPI.getRegistroDiSintesi(dataObj)
                .then((result) => {
                    setIsOrarioAlternativo(result?.isOrarioAlternativo ?? false);
                    setMsgOrarioAlternativo(result?.messaggioAlternativo ?? "");

                    let alunniTmp = result?.alunni;
                    let arrayGiorniTmp = result?.giorni;
                    setIsOrarioAlternativo(result?.isOrarioAlternativo ?? false);
                    setMsgOrarioAlternativo(result?.messaggioAlternativo ?? "");

                    for (let i = 0; i < arrayGiorniTmp.length; i++) {
                        let dataIso = arrayGiorniTmp[i]?.data;
                        let dataConverted = convertDateObjectToString(new Date(dataIso));
                        //Passare dataISO a oggetto Date 
                        arrayGiorniTmp[i].data = dataConverted
                        arrayGiorniTmp[i].dataIso = dataIso;
                        let eventiGiorno = arrayGiorniTmp[i]?.comunicazioni;
                        for (let y = 0; y < eventiGiorno.length; y++) {
                            let evento = eventiGiorno[y];

                            if (evento?.tipologia_comunicazioni === 1 || evento?.tipologia_comunicazioni === 2 || evento?.tipologia_comunicazioni === 3) {
                                let idAlunni = evento?.albero_destinatari?.idAlunno;
                                if ((idAlunni.length !== listAlunni.length && idAlunni.length > 0)) {
                                    for (let id of idAlunni) {
                                        let indexTmp = alunniTmp.findIndex(al => al.id === id)
                                        if (indexTmp !== -1) {
                                            if (indexTmp !== -1) {
                                                let eventoTmp = evento;
                                                eventoTmp.indexColonna = i;
                                                alunniTmp[indexTmp].comunicazioni.push(evento);
                                            }
                                        }
                                    }
                                } //else ok nota/verifica è per tutta la classe

                            }
                        }
                    }

                    for (let i = 0; i < alunniTmp.length; i++) {

                        alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunniTmp[i]);

                        if (alunniTmp[i]?.comunicazioni[0].length > 0) {
                            for (let y = 0; y < alunniTmp[i]?.comunicazioni[0].length; y++) {
                                for (let x = 0; x < arrayGiorniTmp.length; x++) {
                                    let data = DateTime.fromISO(alunniTmp[i]?.comunicazioni[0][y]?.data_comunicazione);
                                    let sqlDate = data.toFormat('yyyy-MM-dd');
                                    if (arrayGiorniTmp[x].data === sqlDate) {
                                        alunniTmp[i].comunicazioni[0][y].indexColonna = x;
                                    }
                                }
                            }
                        }
                    }
                    getPresenzeSintesi(alunniTmp, arrayGiorniTmp, dataDiInteresseInizio, dataDiInteresseFine);
                })
                .catch(function (error) {
                    setHasLoaded(true)
                })
        }

    }

    async function getPercentualeOreFirma(idOrario, idDocente, idCorso, idMateria) {
        let dataObj = {
            idOrario: idOrario,
            idDocente: idDocente,
            idCorso: idCorso,
            idMateria: idMateria
        }
        RegistroAPI.getPercentualeOreFirma(dataObj)
            .then((result) => {
                setOreFirmate(result)
            })
            .catch((error) => { })

    }

    async function getPresenzeSintesi(alunniTmp, arrayGiorniTmp, dataInizio, dataFine) {

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataInizioConverted = DateTime.fromISO(dataInizio);
        let dataInizioSql = dataInizioConverted.toISODate();


        let dataFineConverted = DateTime.fromISO(dataFine);
        let dataFineSql = dataFineConverted.toISODate();


        let dataObj = {
            "dataDal": dataInizioSql,
            "dataAl": dataFineSql,
            "idAlunno": idAlunni
        }


        let alunniArray = alunniTmp;
        PresenzeAPI.getPresenzeSintesi(dataObj)
            .then((result) => {
                if (result.alunni.length > 0) {
                    let arrayAppello = result.alunni;
                    for (let i = 0; i < alunniArray.length; i++) {
                        alunniArray[i].presenze = [];
                        for (let j = 0; j < arrayAppello.length; j++) {
                            if (alunniArray[i].id === arrayAppello[j]?.idAlunno) {
                                let statoPresenzaTmp = arrayAppello[j]?.stato_presenza;
                                let uscitaConverted = null;
                                let ingressoConverted = null;
                                if (arrayAppello[j]?.orario_uscita_reale != null) {
                                    uscitaConverted = arrayAppello[j]?.orario_uscita_reale.substring(0, arrayAppello[j]?.orario_uscita_reale.length - 3);
                                    if (tipoRegistro === "docente") statoPresenzaTmp = "P"

                                }
                                if (arrayAppello[j]?.orario_ingresso_reale != null) {
                                    ingressoConverted = arrayAppello[j]?.orario_ingresso_reale.substring(0, arrayAppello[j]?.orario_ingresso_reale.length - 3)
                                    if (tipoRegistro === "docente") statoPresenzaTmp = "P"
                                }

                                if (tipoRegistro === "classe" || (tipoRegistro === "docente" && statoPresenzaTmp !== "P" && statoPresenzaTmp !== "ER")) {
                                    let objPresenza = {
                                        id: arrayAppello[j]?.id,
                                        data: arrayAppello[j]?.data,
                                        statoPresenza: statoPresenzaTmp,
                                        note: arrayAppello[j]?.note,
                                        orario_uscita_reale: uscitaConverted,
                                        orario_ingresso_reale: ingressoConverted
                                    }
                                    alunniArray[i].presenze.push(objPresenza)
                                }
                            }
                        }
                    }

                    //Se sto qua dentro vuol dire che l'appello è stato fatto, quindi se manca un alunno lo segno come ND (Non definito)
                }

                setListAlunni([...alunniArray]);
                setArrayGiorni([...arrayGiorniTmp]);
                getAssenzeGiustificate(alunniArray, dataInizio, dataFine, arrayGiorniTmp)

            })
            .catch((error) => {
                setHasLoaded(true);
            })
    }
    async function getAssenzeGiustificate(alunniTmp, dataInizio, dataFine, giorniTmp) {

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataInizioConverted = DateTime.fromISO(dataInizio);
        let dataInizioSql = dataInizioConverted.toISODate();


        let dataFineConverted = DateTime.fromISO(dataFine);
        let dataFineSql = dataFineConverted.toISODate();

        let alunniArray = alunniTmp;

        let dataObj = {
            "dataDal": dataInizioSql,
            "dataAl": dataFineSql,
            "idAlunno": idAlunni
        }


        PresenzeAPI.getAssenzeGiustificate(dataObj)
            .then((result) => {

                if (result.alunni.length > 0) {
                    for (let i = 0; i < result.alunni.length; i++) {
                        let indexAlunno = alunniArray.findIndex(alunno => alunno.id === result?.alunni[i]?.idAlunno)
                        if (indexAlunno !== -1) {
                            alunniArray[indexAlunno].giustificate = [];
                            let indexGiorno = giorniTmp.findIndex(giorno => giorno?.dataIso === result?.alunni[i]?.dataGiustificazione)
                            let objGiustificata = {
                                idAlunno: result.alunni[i]?.idAlunno,
                                dataGiustificazione: result.alunni[i]?.dataGiustificazione,
                                statoPresenza: result.alunni[i]?.dataGiustificazione,
                                indexColonna: indexGiorno
                            }
                            alunniArray[indexAlunno].giustificate.push(objGiustificata)
                        }
                    }
                }


                setListAlunni([...alunniArray]);
                setHasLoaded(true)
                setTimeout(() => {
                    if (tableRef.current != undefined && tableRef.current != null) {
                        tableRef.current.scrollLeft = scrollPositionX;
                        tableRef.current.scrollTop = scrollPositionY;
                    }
                }, 200)

            })
            .catch((error) => {
                setHasLoaded(true)
            })
    }

    function getRegistro() {
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }
    function eventoAggiunto(alertMsg, alertSeverity) {
        openAlertMsg(alertMsg, alertSeverity)
        getRegistroDiSintesi(idClasseSelezionata, periodoDiInteresseInizio, periodoDiInteresseFine, idMateriaSelezionata);
    }

    function changeSede(nome) {
        setSedeUtente(nome)
    }

    function changeTipo() {
        setTipo(true);
    }

    function openRiepilogoArgomentiSvoltiDialog() {
        setIsRiepilogoArgomentiSvoltiDialogOpen(prev => !prev)
        let obj = {
            idCorsoSelezionato: idClasseSelezionata,
            idMateriaSelezionata: idMateriaSelezionata,
            nomeClasse: nomeClasse,
            codiceCorso: codiceCorso,
            codiceSimon: codiceSimon,
            periodoDiInteresseInizio: null,
            periodoDiInteresseFine: null,
        }
        setObjRiepilogoArgomentiSvolti(obj)
    }

    function closeRiepilogoArgomentiSvoltiDialog() {
        setIsRiepilogoArgomentiSvoltiDialogOpen(false)
    }

    return (
        <Fragment>
            {(idClasseSelezionata !== -1 && dialogSelezionaClasseOpen === false) || (dialogDettaglioRegistroOpen === true) ? (
                hasLoaded ? (
                    <Fragment>
                        {isDocente ? (
                            <Breadcrumb sections={[tipoRegistro === "classe" ? "Registro di classe" : "Registro del docente", nomeClasse + ' (' + sedeUtente + ')', 'Vista di sintesi (' + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + " )"]} />
                        ) : (
                            <Breadcrumb sections={[tipoRegistro === "classe" ? "Consulta registro di classe" : "Consulta registro del docente", nomeClasse + ' (' + sedeUtente + ')', 'Vista di sintesi (' + formattedPeriodoDiInteresseInizio + " - " + formattedPeriodoDiInteresseFine + " )"]} />
                        )}
                        <Box sx={{ px: 5 }}>



                            <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"start"}>
                                <Button onClick={goToSelezionaOra} sx={{ width: { xs: '100%', md: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                    Torna alla selezione della classe
                                </Button>
                                <Button sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                    Cambia periodo di interesse ({formattedPeriodoDiInteresseInizio}
                                    {formattedPeriodoDiInteresseFine != null && formattedPeriodoDiInteresseFine !== formattedPeriodoDiInteresseInizio ? (
                                        " - " + formattedPeriodoDiInteresseFine
                                    ) : ""})
                                </Button>
                                {tipoRegistro === 'docente' && (<Button startIcon={<AttachFile />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="success" onClick={openRiepilogoArgomentiSvoltiDialog}>
                                    Riepilogo argomenti svolti
                                </Button>)}
                                <Button startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                    Mostra legenda
                                </Button>
                            </Box>
                            {(isOrarioAlternativo && msgOrarioAlternativo !== "") && (
                                <Alert severity="info" sx={{ mt: 3 }}>{msgOrarioAlternativo}</Alert>
                            )}
                            <Box sx={{ border: tipoRegistro === "classe" ? '5px solid green' : '5px solid orange', mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center' }}>
                                {arrayGiorni.length > 0 ? (
                                    <TableContainer ref={tableRef} sx={{ width: '100%' }} component={Paper}>
                                        <Table >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='row-head' style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)', backgroundColor: 'white', p: 2 }}>
                                                        {tipoRegistro === "classe" ? (
                                                            <Box>
                                                                {isDocente ? (
                                                                    <Typography color={'green'} fontWeight={'bold'}>{("Registro di classe").toUpperCase()}</Typography>
                                                                ) : (
                                                                    <Typography color={'green'} fontWeight={'bold'}>{("Consulta registro di classe").toUpperCase()}</Typography>
                                                                )}
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                {isDocente ? (
                                                                    <Typography color={'orange'} fontWeight={'bold'}>{("Registro del docente").toUpperCase()}</Typography>
                                                                ) : (
                                                                    <Typography color={'orange'} fontWeight={'bold'}>{("Consulta registro del docente").toUpperCase()}</Typography>
                                                                )}
                                                                <Typography variant="caption" mt={1}>Materia: </Typography>
                                                                <Typography color={'secondary'} sx={{ fontWeight: 'bold', mr: 1 }}
                                                                    variant={mobile ? 'caption' : 'body1'}>{nomeMateriaSelezionata !== undefined ? nomeMateriaSelezionata.toUpperCase() : ""}</Typography>
                                                                {oreFirmate != null && hasDocenteFunctions ? (
                                                                    <Fragment>
                                                                        <Typography><b>Ore totali:</b> {oreFirmate?.oreAssegnate ?? 0} </Typography>
                                                                        <Typography><b>Ore  teoria</b> (firmate): {oreFirmate?.teoria?.numeroOre ?? 0} - {(oreFirmate?.teoria?.percentuale ?? 0) + "%"}</Typography>
                                                                        <Typography sx={{ mb: 2 }}><b>Ore pratica</b> (firmate): {oreFirmate?.pratica?.numeroOre ?? 0} - {(oreFirmate?.pratica?.percentuale ?? 0) + "%"}</Typography>
                                                                    </Fragment>
                                                                ) : null}
                                                            </Box>
                                                        )}

                                                    </TableCell>
                                                    {arrayGiorni.map((giorno) => (
                                                        <TableCell className='row-head' key={"giorno_" + giorno?.data} style={{ backgroundColor: 'white', p: 2, textAlign: 'center', minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{getDayAndMonth(giorno?.data)}</Typography>
                                                            <Typography variant="subtitle2">{getDayOfTheWeek(giorno?.data)}</Typography>
                                                            {giorno?.chiusura ? (
                                                                <Typography color="primary" variant="subtitle2"><b>CHIUSURA</b></Typography>

                                                            ) : null}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                <TableRow >
                                                    <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                        <Box sx={{ backgroundColor: 'white', p: 2, display: 'flex', flexDirection: 'column' }}>
                                                            <Typography variant="caption">Corso:</Typography>
                                                            <Typography color={'primary'}
                                                                variant={mobile ? 'caption' : 'body1'}
                                                                sx={{ fontWeight: 'bold', mr: 1 }}>{(codiceCorso + " - " + nomeClasse + " (" + codiceSimon + ")" ?? "").toUpperCase()}</Typography>
                                                            <Typography variant="caption" sx={{ mt: 1 }}>Sede:</Typography>
                                                            <Typography
                                                                variant={mobile ? 'caption' : 'subtitle2'}
                                                                sx={{ fontWeight: 'bold', mr: 1 }}>{sedeUtente !== '' ? sedeUtente : ''}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    {arrayGiorni.map((giorno, index) => (
                                                        <TableCell className={giorno?.chiusura ? 'disabledCell' : ""} key={"dettaglio_" + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Fragment>
                                                                <Box justifyContent={"center"} alignItems={"center"} display="flex" flexDirection={"column"} >
                                                                    {giorno?.chiusura === false ? (
                                                                        <Box onClick={() => openDettaglioRegistro(giorno?.data)} width={"30px"} height={"30px"}
                                                                            border={"1px solid #1976d2"}
                                                                            borderColor={"primary"}
                                                                            backgroundColor={"white"}
                                                                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                            sx={{ mx: 1, p: 1, borderRadius: '100%', color: '#1976d2', cursor: 'pointer' }}>
                                                                            <ZoomIn />
                                                                        </Box>
                                                                    ) : null}
                                                                    <Box onClick={saveScrollPosition} sx={{ mt: 2 }} justifyContent={"center"} alignItems={"center"} display="flex" >
                                                                        {giorno?.flagComunicazioni?.hasArgomentiSvolti ? (
                                                                            <EventoChip dataRichiesta={giorno?.data} openElenco={true} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={{ tipologia_comunicazioni: 6 }} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        ) : null}
                                                                        {giorno?.flagComunicazioni?.hasVerificheProgrammate ? (
                                                                            <EventoChip dataRichiesta={giorno?.data} openElenco={true} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={{ tipologia_comunicazioni: 3 }} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        ) : null}
                                                                        {giorno?.flagComunicazioni?.hasCompiti ? (
                                                                            <EventoChip dataRichiesta={giorno?.data} openElenco={true} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={{ tipologia_comunicazioni: 2 }} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        ) : null}
                                                                        {giorno?.flagComunicazioni?.hasNoteDisciplinariDaDBInteraClasse ? (
                                                                            <EventoChip dataRichiesta={giorno?.data} openElenco={true} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={{ tipologia_comunicazioni: 1 }} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        ) : null}
                                                                        {giorno?.flagComunicazioni?.hasAnnotazioni ? (
                                                                            <EventoChip dataRichiesta={giorno?.data} openElenco={true} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={{ tipologia_comunicazioni: 7 }} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        ) : null}

                                                                        {/* {giorno.comunicazioni.map((comunicazione) => (
                                                                        (comunicazione?.albero_destinatari?.idAlunno.length === listAlunni.length || comunicazione?.albero_destinatari?.idAlunno.length == 0 || comunicazione?.contenuto?.isInteraClasse ) && (
                                                                            <EventoChip idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} key={comunicazione.id} objComunicazione={comunicazione} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                        )
                                                                    ))} */}
                                                                    </Box>
                                                                </Box>
                                                            </Fragment>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                                {listAlunni.map((alunno, index) => (
                                                    <TableRow style={{ height: '80px' }} key={alunno?.id} >
                                                        <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Box sx={{ backgroundColor: 'white', p: 2 }}>
                                                                <Box display={"flex"} alignItems={"center"}>
                                                                    <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                                                                    <Typography variant={mobile ? 'caption' : 'body1'} onClick={() => vediDettaglioAlunno(alunno?.id, 0, alunno.nome, alunno?.cognome, false)} sx={{ cursor: 'pointer', mr: 2, fontWeight: alunno?.isMaggiorenne ? 'bold' : 'unset' }}>
                                                                        {(alunno?.cognome).toUpperCase() + " " + (alunno?.nome).toUpperCase()}
                                                                    </Typography>
                                                                </Box>
                                                                {alunno?.msgIscrizione != null ? (
                                                                    <Typography color="primary" variant="subtitle2">{alunno?.msgIscrizione}</Typography>
                                                                ) : null}
                                                            </Box>
                                                        </TableCell>
                                                        {arrayGiorni.map((giorno, indexGiorno) => (
                                                            <TableCell className={(isAlunnoIscrittoInData(giorno?.data, alunno) === false) || (giorno?.chiusura) ? 'disabledCell' : ""} key={"alunno_" + alunno?.id + giorno?.data} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                                {isAlunnoIscrittoInData(giorno?.data, alunno) === false ? (
                                                                    <span></span>
                                                                ) : (
                                                                    <Box justifyContent={"center"} display="flex" onClick={saveScrollPosition}>

                                                                        {tipoRegistro === "classe" ? (
                                                                            alunno?.comunicazioni[0] !== undefined && (
                                                                                alunno?.comunicazioni[0].length > 0 ? (
                                                                                    alunno?.comunicazioni[0].map((comunicazione) => (
                                                                                        < Fragment key={alunno?.id + comunicazione?.id} >
                                                                                            {comunicazione?.indexColonna === indexGiorno ? (
                                                                                                <EventoChip idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                                            ) : null}
                                                                                        </Fragment>
                                                                                    ))
                                                                                ) : null
                                                                            )
                                                                        ) : (
                                                                            alunno?.comunicazioni.length > 0 ? (
                                                                                alunno?.comunicazioni.map((comunicazione) => (
                                                                                    < Fragment key={alunno?.id + comunicazione?.id} >
                                                                                        {comunicazione?.indexColonna === indexGiorno && (comunicazione?.contenuto?.isInteraClasse === false || comunicazione?.contenuto?.isInteraClasse === undefined) ? (
                                                                                            <EventoChip idDocente={idDocente} tipoRegistro={tipoRegistro} eventoAggiunto={eventoAggiunto} isDocente={false} getRegistro={getRegistro} permessiUtente={permessiUtente} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={listAlunni} />
                                                                                        ) : null}
                                                                                    </Fragment>
                                                                                ))
                                                                            ) : null
                                                                        )}


                                                                        {alunno?.presenze != undefined && (
                                                                            alunno?.presenze.map((presenza, index) => (
                                                                                presenza?.data == giorno?.dataIso ? (

                                                                                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: presenza?.statoPresenza !== "P" && presenza?.statoPresenza !== "A" && presenza?.statoPresenza !== "ND" ? 'pointer' : 'default' }}
                                                                                        onClick={() => presenza?.statoPresenza !== "P" && presenza?.statoPresenza !== "A" && presenza?.statoPresenza !== "ND" ? vediDettaglioAlunno(alunno?.id, 2, alunno?.nome, alunno?.cognome, false, presenza) : null}>
                                                                                        <Presenza key={"pres_" + index} presenza={presenza} isRegistroDettaglio={false} />
                                                                                    </Box>
                                                                                ) : null))
                                                                        )}

                                                                        {(alunno?.giustificate !== undefined && alunno?.giustificate.length > 0 && tipoRegistro === "classe") && (
                                                                            alunno?.giustificate[0]?.indexColonna == indexGiorno ? (
                                                                                <Box width={"30px"} height={"30px"} onClick={() => vediDettaglioAlunno(alunno?.id, 1, alunno?.nome, alunno?.cognome, false)}
                                                                                    backgroundColor={"green"} border={"1px solid green"}
                                                                                    display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                                    sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white', cursor: 'pointer' }}>

                                                                                    <Typography fontWeight={"bold"} color={"success"} >G</Typography>
                                                                                </Box>
                                                                            ) : null)
                                                                        }

                                                                    </Box>
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box height={"70vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                        <Typography variant="h5">Per il periodo selezionato (<b>{formattedPeriodoDiInteresseInizio} - {formattedPeriodoDiInteresseFine}</b>) non è possibile visualizzare il registro di sintesi</Typography>
                                        <Button sx={{ mt: 2 }} variant="contained" onClick={openPeriodoDiInteresseDialog} startIcon={<EditCalendar />}>
                                            Cambia periodo di interesse
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Fragment>
                ) : (
                    <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento registro...</Typography>
                    </Box>
                )
            ) : null
            }

            <Dialog open={changePeriodoDiInteresseOpen} sx={{ mt: '8vh' }}>
                <DialogTitle>
                    <Typography variant="h6">
                        <b> Seleziona il periodo di interesse</b>
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RangeDate idClasse={idClasseSelezionata} dataDiInteresseInizio={periodoDiInteresseInizio} dataDiInteresseFine={periodoDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaClasseOpen} sx={{ mt: '8vh' }}>
                <DialogTitle >
                    <Typography variant="h6">
                        <b> Seleziona classe di riferimento</b>
                    </Typography>
                </DialogTitle>
                <Divider />

                <DialogContent>
                    {isDocente ? (
                        <SelezioneLezioneNew isConsulta={false} tipoRegistro={tipoRegistro} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} />
                    ) : (
                        <SelezioneClasseOperatore tipoRegistro={tipoRegistro} changeIdClasseSelezionata={changeIdClasseSelezionata} changeSede={changeSede} changeTipo={changeTipo} />
                    )}
                </DialogContent>
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dettaglioAlunnoOpen} onClose={closeDettaglioAlunno}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({nomeAlunnoSelezionato})</Typography>
                    <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAlunno ruoliUtente={ruoliUtente} canTouchAssenze={!isDocente} comesFromConsultaRegistro={!isDocente} showBes={false} tabIndex={indexTabToOpen} idDocente={idDocente} id={idAlunnoSelezionato} dataAttuale={periodoDiInteresseFine} tipo={tipoRegistro !== 'classe' || tipo ? true : false} tipoRegistro={tipoRegistro}
                        nomeAlunno={nomeAlunnoSelezionato} ritardo={openDialogRitardo} closeDettaglioAlunno={closeDettaglioAlunno} openAlertMsg={openAlertMsg} permessiUtente={permessiUtente} idCorso={idClasseSelezionata} presenzaObj={presenzaObj} />
                </DialogContent>
            </Dialog>

            <Dialog fullScreen maxWidth="none" open={dialogDettaglioRegistroOpen}>
                <DialogTitle>{tipoRegistro == "classe" ? 'Registro di classe' : 'Registro del docente'} ({getDayMonthAndYearErrorDialog(dataRichiestaDettaglio)})</DialogTitle>
                {tipoRegistro == "classe" ? (
                    <RegistroDiClasse ruoliUtente={ruoliUtente} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} nomeClasse={nomeClasse} hasDocenteFunctions={isDocente} isConsultaRegistro={!isDocente} codiceCorso={codiceCorso} codiceSimon={codiceSimon}
                        dataRichiesta={dataRichiestaDettaglio} closeRegistro={closeRegistro} oreDiLezioneClasseSelezionata={oreDiLezioneClasseSelezionata} sedeUtente={sedeUtente} nomeMateriaSelezionata={nomeMateriaSelezionata} oraMateria={oraMateria} idOrario={idOrarioSelezionato} tipo={tipo} />
                ) : (
                    <RegistroDocente ruoliUtente={ruoliUtente} permessiUtente={permessiUtente} idDocente={idDocente} idCorso={idClasseSelezionata} idMateria={idMateriaSelezionata} nomeClasse={nomeClasse} hasDocenteFunctions={isDocente} codiceCorso={codiceCorso} codiceSimon={codiceSimon}
                        dataRichiesta={dataRichiestaDettaglio} closeRegistro={closeRegistro} nomeMateria={nomeMateriaSelezionata} sedeUtente={sedeUtente} idOrario={idOrarioSelezionato} isConsultaRegistro={!isDocente} />
                )}
            </Dialog>

            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />

                <DialogContent>
                    <Legende />
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>
            <Dialog sx={{ marginTop: '7vh' }} open={isRiepilogoArgomentiSvoltiDialogOpen} fullWidth maxWidth="xl" onClose={closeRiepilogoArgomentiSvoltiDialog}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }} variant="h6">
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Riepilogo argomenti svolti
                    </Typography>
                    <IconButton onClick={closeRiepilogoArgomentiSvoltiDialog} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RiepilogoArgomentiSvolti objRiepilogoArgomentiSvolti={objRiepilogoArgomentiSvolti} isDialog={true} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>


            <Helmet>
                <title>{t("name")} - {tipoRegistro === "docente" ? 'Registro del docente' : 'Registro di classe'}</title>
            </Helmet>
        </Fragment >
    )
}

