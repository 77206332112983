import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ComunicazioniAPI = {
    getReminderAppelli: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/reminderAppelli`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getPermessiComunicazioni: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/permessi`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getTipologieComunicazioni: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/tipologie`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    nuovaComunicazione: async function (obj, cancel = false) {
        const formData = new FormData();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                formData.append(key, obj[key]);
            }
        }
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/nuova`,
            data: formData,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    },
    dettaglioComunicazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/dettaglio`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    downloadPdfComunicazione: async function (fileName, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/scaricaPDF/${fileName}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    elencoComunicazioniInviate: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/elencoInviate`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    elencoComunicazioniRicevute: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/elencoRicevute`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    numeroComunicazioniDaLeggere: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/comunicazioni/daLeggere`,
            data: obj,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
 
}
const cancelApiObject = defineCancelApiObject(ComunicazioniAPI)