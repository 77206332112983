import { Box, useTheme, Step, StepLabel, Stepper, useMediaQuery, CircularProgress, Typography, DialogTitle, Divider, Button } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import AnagraficaAzienda from "./dettaglio-progetto-formativo/anagraficaAzienda";
import GestioneOrario from "./dettaglio-progetto-formativo/gestioneOrario";

export default function DettaglioVariazione(props) {

    const [idProgettoFormativo, setIdProgettoFormativo] = useState(-1);
    const [tabIndex, setTabIndex] = useState(0);
    const [hasLoaded, setHasLoaded] = useState(true);

    const [isVariazioneSede, setIsVariazioneSede] = useState(false);
    const [idCorso, setIdCorso] = useState(-1);
    const [dataSede, setDataSede] = useState(null);

    useEffect(() => {
        if (props.idProgettoFormativo !== undefined) {
            setIdProgettoFormativo(props.idProgettoFormativo);
        }
    }, [props.idProgettoFormativo])

    useEffect(() => {
        if (props.idCorso)
            setIdCorso(props.idCorso)
    }, [props.idCorso])

    useEffect(() => {
        if (props.isVariazioneSede !== undefined) {
            setIsVariazioneSede(props.isVariazioneSede);
            if (props.isVariazioneSede === true) setTabIndex(0);
            else setTabIndex(1);

            setTimeout(() => { setHasLoaded(true) }, 100)
        }
    }, [props.isVariazioneSede])


    // function changeTabIndex(event, newValue) {
    //     setTabIndex(newValue);
    // }


    function previousTab() {
        setTabIndex(prev => prev - 1);
    }

    function nextTab() {
        setTabIndex(prev => prev + 1);
    }

    function closeDialog() {
        if (props.closeDialog) props.closeDialog()
        setTabIndex(0);
        changeIdProgettoFormativo(-1);
        // setIsVisualizza(false);
    }

    function changeIdProgettoFormativo(idProgettoFormativo) {
        setIdProgettoFormativo(idProgettoFormativo);
        if (props.changeIdProgettoFormativo) props.changeIdProgettoFormativo(idProgettoFormativo);
    }

    function proceduraConclusa() {
        if (props.proceduraConclusa) props.proceduraConclusa();
    }

    function changeDataSede(data) {
        setDataSede(data);
    }

    function closeVariazione() {
        if (props.closeVariazione) props.closeVariazione();
        setDataSede(null)
    }

    return (
        <Fragment>
            <Box sx={{ p: 0 }}>
                {hasLoaded ? (
                    <Fragment>
                        {tabIndex === 0 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: dati Azienda e sede - Variazione</Typography>
                                </DialogTitle>
                                <Divider />
                                <AnagraficaAzienda
                                    idProgettoFormativo={idProgettoFormativo}
                                    closeVariazione={closeVariazione}
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                    isVariazioneSede={isVariazioneSede}
                                    isVariazione={true}
                                    changeDataSede={changeDataSede}
                                    dataSede={dataSede}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 1 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: orario attività formativa - Variazione</Typography>
                                </DialogTitle>
                                <Divider />
                                <GestioneOrario
                                    idCorso={idCorso}
                                    idProgettoFormativo={idProgettoFormativo}
                                    closeVariazione={closeVariazione}
                                    nextTab={nextTab}
                                    previousTab={previousTab}
                                    isVariazioneSede={isVariazioneSede}
                                    isVariazione={true}
                                    dataSede={dataSede}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress sx={{ mb: 4 }} />
                        <Typography >Caricamento in corso...</Typography>
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}