import { Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, FormControl, Select, IconButton } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"
import ElencoAlunniSelezionabili from "../elencoAlunniSelezionabili";

import DialogNoItemInSessionStorage from "../../shared/dialogNoItemInSessionStorage";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";
import CryptoJS from 'crypto-js';
import { convertISODate, convertDataGetProssimeLezione, convertToSqlFormat } from "../../shared/metodiDateUtility";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { EventiAPI } from "../../../api/eventiApi";
import { RegistroAPI } from "../../../api/registroApi";
import { Close, Delete, Edit } from "@mui/icons-material";
import { PresenzeAPI } from "../../../api/presenzeApi";
import DialogConferma from "../../shared/dialogConferma";
import functionCheckAlunniSelezionabili from "../functionCheckAlunniSelezionabili";

export default function ModificaCompiti(props) {
    const [permessiUtente, setPermessiUtente] = useState([]);
    const [data, setData] = useState(null);
    const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [materiaSelected, setMateriaSelected] = useState(-1);
    const [elencoOre, setElencoOre] = useState([]);
    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);
    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [oraSelected, setOraSelected] = useState(-1);
    const [tipoRegistro, setTipoRegistro] = useState("classe");

    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [compitoSel, setCompitoSel] = useState("");
    const [idCompito, setIdCompito] = useState(-1);

    const [modificaMode, setModificaMode] = useState(false);


    const inputDescrizioneCompitiRef = useRef(null);
    const [descrizioneCompiti, setDescrizioneCompiti] = useState("");

    const [showPulsanteEdit, setShowPulsanteEdit] = useState(false);
    const [showPulsanteDelete, setShowPulsanteDelete] = useState(false);
    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] = useState(false);
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(props.dataFinePeriodo)
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined) {
            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
        }
    }, [props.idCorso, props.idDocente, props.idMateria]);

    useEffect(() => {
        if (props.compitoSel != undefined && props.listAlunni != undefined && props.permessiUtente !== undefined) {
            setPermessiUtente([...props.permessiUtente])
            setTipoRegistro(props.setTipoRegistro)
            getDataCompito(props.permessiUtente, props.listAlunni, props.tipoRegistro);
        }
    }, [props.compitoSel, props.listAlunni, props.permessiUtente, props.tipoRegistro]);


    function getDataCompito(permessiUtenteTmp, listAlunni, tipoRegistro) {

        setCompitoSel(props.compitoSel)
        const date = DateTime.fromISO(props.compitoSel?.contenuto?.dataLezione);
        setData(props.compitoSel?.contenuto?.dataLezione != null ? date : null);

        // isAlunnoIscrittoInData(props.compitoSel?.contenuto?.dataLezione != null ? date : null, listAlunni)

        setDescrizioneCompiti(props.compitoSel?.contenuto?.note);
        setIdCompito(props.compitoSel?.id);

        let idMateriaTmp = props.compitoSel?.contenuto?.materia?.idMateria;
        let idDocenteTmp = props.compitoSel?.contenuto?.docente?.idDocente;
        let idCorsoTmp = props.compitoSel?.albero_destinatari?.idCorso[0];
        let arrayIdAlunno = props.compitoSel?.albero_destinatari?.idAlunno;
        let idOrarioTmp = props.compitoSel?.contenuto?.idOrario;
        let alunniSelezionatiTmp = []

        //Filtro subito l'array, altrimenti se ci sono 2 alunni con lo stesso id, in questo punto mi seleziona il primo
        //poi nel manageStatesAlunni mi elimina il primo e mi lascia il secondo
        const listAlunniFiltered = listAlunni.filter(alunno => {
            let iscrittoDal = alunno?.iscrittoDal ? DateTime.fromISO(alunno.iscrittoDal).toUTC() : null;
            let iscrittoAl = alunno?.iscrittoAl ? DateTime.fromISO(alunno.iscrittoAl).toUTC().endOf("day") : null;
            return iscrittoDal && iscrittoAl && date >= iscrittoDal && date <= iscrittoAl;
        }).map((obj, index) => ({ ...obj, n: index }));
        if (arrayIdAlunno.length > 0) {
            for (let idAlunno of arrayIdAlunno) {
                alunniSelezionatiTmp.push(listAlunniFiltered.filter(a => a.id === idAlunno)[0])
            }
        } else { //In questo caso è tutta la classe
            alunniSelezionatiTmp = listAlunniFiltered;
        }

        setAlunniSelezionati([...alunniSelezionatiTmp])
        setIdMateria(idMateriaTmp);
        setIdDocente(idDocenteTmp);
        setIdCorso(idCorsoTmp);
        setOraSelected(idOrarioTmp)

        canAccessButtons(props.compitoSel, permessiUtenteTmp)

        manageStatesAlunni(listAlunni, alunniSelezionatiTmp, date, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp, tipoRegistro)
    }


    useEffect(() => {
        if (isDialog === false) {
            if (sessionStorage.getItem("classe") != null) {
                let encryptedIdCorso = sessionStorage.getItem("classe");
                let decryptedIdCorso = CryptoJS.AES.decrypt(encryptedIdCorso, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
                //TODO: Poi imposterò questo ID
                setAlertNoIdIsOpen(false);

            } else {
                //Se non trovo l'item ho acceduto tramite url e allora mostro l'errore
                setAlertNoIdIsOpen(true);
            }
        }
    }, [isDialog])

    useState(() => {
        if (props.nota !== undefined) {
            let newDataTmp = props?.nota?.dataLezione;
            newDataTmp = newDataTmp.replaceAll("-", "/");
            setData(newDataTmp);
            setMaterie([...materie, props.nota.materia])
            setMateriaSelected(props?.nota?.materia.idMateria)
        }
    }, [props.nota]);

    useEffect(() => {
        if (props.listAlunni !== undefined) {
            if (props.listAlunni == null)
                getAlunniByClasseID();
            else {
                setListAlunni([...props.listAlunni]);
                setGetAlunniIsLoading(false);
            }
        }
    }, [props.listAlunni])


    useEffect(() => {
        if (props.alunniSelezionati !== undefined) {
            setAlunniSelezionati([...props.alunniSelezionati])
        }
    }, [props.alunniSelezionati])

    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])


    async function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);

            })
    }
    async function modificaCompito() {


        let arrayAlunniSelezionatiID = [];
        for (let alunno of alunniSelezionati) {
            arrayAlunniSelezionatiID.push(alunno.id)
        }

        const obj = {
            note: descrizioneCompiti,
            idAlunno: arrayAlunniSelezionatiID,
            idDocente: idDocente,
            idMateria: idMateria,
            idOrario: oraSelected !== -1 ? oraSelected : null,
            is_interaClasse: alunniSelezionati.length === alunniSelezionabiliNumber ? true : false,
            idClasse: idCorso,
            idCorso: idCorso

        }

        EventiAPI.modificaCompiti(obj, idCompito)
            .then((result) => {
                if (isDialog) {
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Compito modificato", "success");
                } else {
                    setAlunniSelezionati([]);
                    setDescrizioneCompiti("");

                    if (inputDescrizioneCompitiRef != null)
                        inputDescrizioneCompitiRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Compito modificato");
                    setAlertIsOpen(true);
                }
                setConfirmAlertIsOpen(false);
                closeDialog()
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) logout();
                }
                setConfirmAlertIsOpen(false);
                setAlertSeverity("error");
                setAlertMsg("Al momento non è possibile completare l'operazione");
                setAlertIsOpen(true);
            })
    }
    async function canAccessButtons(objEvento, permessiUtenteTmp) {
        let idEventoTmp = objEvento?.id;
        let isEditable = objEvento?.contenuto?.editable;

        let dataObj = {
            idEvento: idEventoTmp
        }
        if (idEventoTmp !== -1 && isEditable && permessiUtenteTmp.some(permesso => permesso.nome === "canEditEventiRE" && permesso.value === true)) {
            RegistroAPI.showPulsantiEvento(dataObj)
                .then((result) => {

                    setShowPulsanteEdit(result?.showPulsanteEdit ?? false)
                    setShowPulsanteDelete(result?.showPulsanteDelete ?? false)
                })
                .catch(function (error) { })
        }
    }
    async function deleteEvento() {
        let dataObj = {
            idEvento: compitoSel?.id,
            tipo: compitoSel?.tipologia_comunicazioni
        }
        RegistroAPI.deleteEvento(dataObj)
            .then((result) => {
                if (props.eventoAggiunto) {
                    props.eventoAggiunto("Compiti eliminati", "success");

                }
                closeDialog()
            })
            .catch(function (error) {
                if (props.eventoAggiunto)
                    props.eventoAggiunto(error.response.data.data[0].msg, "error");
            })
    }



    function logout() {
        if (props.logout) props.logout()
    }

    function selectAlunni(listAlunni) {
        if (listAlunni !== undefined)
            setAlunniSelezionati([...listAlunni]);
    }
    function changeTestoVerifica(e) {
        setDescrizioneCompiti(e.target.value)
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        manageStatesAlunni(listAlunni, alunniSelezionati, value, idDocente, idCorso, idMateria);
    }
    function changeOra(e) {
        setOraSelected(e.target.value);
        manageStatesAlunni(listAlunni, alunniSelezionati, data, idDocente, idCorso, idMateria, e.target.value);
    }

    function annullaModifiche() {
        getDataCompito(permessiUtente, listAlunni);
        setModificaMode(false);
    }


    function modalitaModificaOn() {
        setModificaMode(true);
        manageStatesAlunni(listAlunni, alunniSelezionati, data, idDocente, idCorso, idMateria, undefined, tipoRegistro);
    }

    function resetData() {
        setData(null);
        setOraSelected(-1);
        setElencoOre([]);
    }

    function isButtonSaveDisabled() {
        if (descrizioneCompiti === "" || alunniSelezionati.length === 0 || (data != null && (oraSelected === -1 || oraSelected === undefined)))
            return true
        else return false;
    }



    function manageStatesAlunni(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp, tipoRegistroTmp) {
        functionCheckAlunniSelezionabili(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp ?? undefined)
            .then(objCheckAlunniSelezionabili => {
                setListAlunni([...objCheckAlunniSelezionabili.listAlunniTotali]);
                setElencoOre([...objCheckAlunniSelezionabili.listOre]);
                if (idOrarioTmp === undefined) //Se non è undefined ho già settato l'ora in precedenza, quindi non la risetto
                    setOraSelected(objCheckAlunniSelezionabili.oraSelected);
                setIdMateria(tipoRegistroTmp !== undefined && tipoRegistroTmp === "docente" ? idMateriaTmp : objCheckAlunniSelezionabili.idMateriaSelected)
                setAlunniSelezionati([...objCheckAlunniSelezionabili.listAlunniSelezionati]);
                const alunniNonSelezionabiliObj = objCheckAlunniSelezionabili.alunniNonSelezionabili.map(id => {
                    return { id: id };
                });
                setAlunniNonSelezionabili([...alunniNonSelezionabiliObj]);
                setAlunniSelezionabiliNumber(objCheckAlunniSelezionabili.numberAlunniSelezionabili)
            });
    }
    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!alertNoIdIsOpen ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            {!modificaMode && (<Grid xs={12} md={12}>
                                <Typography><b>Data di inserimento:</b>  {convertISODate(compitoSel?.created_at)} {'(Inserito da: ' + compitoSel?.created_by_obj?.nome + ')'};</Typography>
                            </Grid>)}
                            {(!modificaMode && compitoSel?.modified_at !== null) && (<Grid xs={12} md={12}>
                                <Typography><b>Data di modifica:</b>  {convertISODate(compitoSel?.modified_at)}  {'(Modificato da: ' + compitoSel?.modified_by_obj?.nome + ')'};</Typography>
                            </Grid>)}
                            {(!modificaMode && alunniSelezionati.length > 0) && (<Grid xs={12} md={12}>
                                <Typography><b>Destinatari:</b>

                                    {compitoSel?.contenuto?.isInteraClasse ? (
                                        <Typography><b>Tutta la classe</b></Typography>
                                    ) : (
                                        <Box sx={{ p: 1, border: '1px solid #bdbdbd', borderRadius: '5px', maxHeight: '10vh', overflowY: 'scroll' }}>
                                            {alunniSelezionati.map((alunno) => (
                                                <Typography key={alunno.id}>{alunno.nome} {alunno.cognome}</Typography>
                                            ))}
                                        </Box>
                                    )}

                                </Typography>
                            </Grid>)}
                            {modificaMode && (<Grid xs={12} md={6}
                            // mt={{ xs: 3, md: 0 }}
                            >
                                {!getAlunniIsLoading ? (
                                    <ElencoAlunniSelezionabili alunniSelezionabiliNumber={alunniSelezionabiliNumber} selectAlunni={selectAlunni} alunniNonSelezionabili={alunniNonSelezionabili} listAlunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                                ) : (
                                    <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                                    </Paper>
                                )}
                            </Grid>)}
                            <Grid xs={12} md={modificaMode ? 6 : 12}>
                                <Box>

                                    <Grid container rowSpacing={2} columnSpacing={2}
                                    // sx={{ width: '100%', pt: 2 }}
                                    >

                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                                    <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                                        minDate={dataInizioPeriodo}
                                                        maxDate={dataFinePeriodo}
                                                        disabled={!modificaMode}
                                                        label="Data compito"
                                                        value={data}
                                                        format="dd/MM/yyyy"
                                                        onChange={(value) => changeData(value)}
                                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                                </LocalizationProvider>
                                                {modificaMode && (<IconButton onClick={resetData} sx={{ border: '1px rgba(190, 190, 190, 1) solid', borderRadius: '0 4px 4px 0', borderLeft: 'none' }}>
                                                    <Close />
                                                </IconButton>)}
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            {elencoOre.length > 0 ? (
                                                <FormControl sx={{ width: '100%' }}>
                                                    <InputLabel id="materia-label">Seleziona ora</InputLabel>
                                                    <Select
                                                        data-testid='selOraCompitiSelect'
                                                        label='Seleziona ora '
                                                        labelId="materia-label"
                                                        value={oraSelected}
                                                        onChange={changeOra}
                                                        disabled={!modificaMode || elencoOre.length === 0}
                                                    >
                                                        <MenuItem
                                                            key={-1}
                                                            value={-1}
                                                        >
                                                        </MenuItem>
                                                        {elencoOre.map((ora) => (
                                                            <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                                {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                    {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta.</Typography>) : null}
                                                </FormControl>
                                            ) : (

                                                <Select disabled fullWidth >
                                                    <MenuItem
                                                        key={-1}
                                                        value={undefined}
                                                    >
                                                        Caricamento...
                                                    </MenuItem>
                                                </Select>
                                            )
                                            }
                                        </Grid>
                                    </Grid>

                                </Box>
                                <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                    <TextField fullWidth sx={{ mt: 1 }} inputRef={inputDescrizioneCompitiRef} value={descrizioneCompiti} disabled={!modificaMode}
                                        onChange={changeTestoVerifica} placeholder="Scrivi qui..." multiline rows={4} label={'Descrizione'} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"space-between"} sx={{ mt: 3, width: '100%' }}>
                            <Box>
                                {showPulsanteDelete && !modificaMode ? (
                                    <Button onClick={() => { setConfirmDeleteDialogIsOpen(true) }} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Elimina compiti</Button>
                                ) : null}
                            </Box>
                            <Box>
                                {showPulsanteEdit ? (
                                    <Box>
                                        {!modificaMode ? (
                                            <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">
                                                chiudi dettaglio
                                            </Button>
                                        ) : (
                                            <Button onClick={() => annullaModifiche()} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">
                                                Annulla modifiche
                                            </Button>
                                        )}

                                        {!modificaMode ? (
                                            <Button onClick={() => modalitaModificaOn()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                                variant="contained" >
                                                Modifica Compito
                                            </Button>
                                        ) : (
                                            <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isButtonSaveDisabled()}
                                                sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }} color="success" variant="contained" >
                                                Conferma Modifica
                                            </Button>
                                        )}
                                    </Box>
                                ) : (
                                    <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">chiudi dettaglio</Button>
                                )}


                            </Box>
                        </Box>
                    </Fragment>
                ) : null
                }

            </Container >

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma modifica compito'}
                icon={<Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler modificare il compito per `}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma modifica'}
                severity={'warning'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={modificaCompito}
                disabledButton={false}
            />

            <DialogConferma
                isDialogOpen={confirmDeleteDialogIsOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare il compito? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setConfirmDeleteDialogIsOpen(false) }}
                conferma={deleteEvento}
                disabledButton={false}
            />

            <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} />


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}