import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import  { AlertProps } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';

// Tipo per il contesto dello Snackbar
interface SnackbarContextType {
    showSnackbar(message: string, severity: AlertProps['severity']): void;
}

// Creazione del contesto
const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
    // Stato per gestire lo Snackbar
    const [snackbar, setSnackbar] = useState<{
        message: string;
        severity: AlertProps['severity'];
        open: boolean;
    }>({
        message: '',
        severity: 'info',
        open: false,
    });

    // Mostra lo Snackbar
    function showSnackbar(message: string, severity: AlertProps['severity'] = 'info') {
        setSnackbar({ message, severity, open: true });
    }

    // Chiude lo Snackbar
    function handleClose() {
        setSnackbar((prev) => ({ ...prev, open: false }));
    }

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={10000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity={snackbar.severity} >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
}

// Hook per usare il contesto
export function useSnackbar(): SnackbarContextType {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar deve essere usato all’interno di un SnackbarProvider');
    }
    return context;
}
