import { Box, Divider, Typography } from "@mui/material";
import { Assignment, AssignmentLate, CalendarToday, Info, Description, DoneAll, AccessibilityNew } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";


export default function Legende() {
    return (
        <Grid container >
            <Grid xs={12} md={3}
                sx={{
                    pb: { xs: 4, md: 0 }, borderBottom: { xs: 'rgb(210,210,210) 1px solid', md: 'none' },
                    borderRight: { md: 'rgb(210,210,210) 1px solid', xs: 'none' }
                }}
            >
                <Box display={"flex"} alignItems={"center"} >
                    <Box width={"30px"} height={"30px"} backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <AssignmentLate />
                    </Box>
                    <Typography>Una (o più) note disciplinari</Typography>
                </Box>
                {/* <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"black"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <AssignmentLate />
                    </Box>
                    <Typography>Provvedimento disciplinare</Typography>
                </Box> */}
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"green"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <Typography>G</Typography>
                    </Box>
                    <Typography>Assenza giustificata</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"warning.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <Typography>AdG</Typography>
                    </Box>
                    <Typography>Assenza da giustificare</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <Typography>AdG</Typography>
                    </Box>
                    <Typography>Assenza da giustificare (in ritardo)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"primary.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <DoneAll/>
                    </Box>
                    <Typography>Presenze validate</Typography>
                </Box>
            
            </Grid>
            {/* <Divider orientation="vertical"/> */}
            <Grid xs={12} md={3} sx={{
                pb: { xs: 4, md: 0 }, borderBottom: { xs: 'rgb(210,210,210) 1px solid', md: 'none' },
                borderRight: { md: 'rgb(210,210,210) 1px solid', xs: 'none' }, pl: { xs: 0, md: 4 }
            }}>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border:'1px solid #4169e1' ,color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#4169e1" }} />
                    </Box>
                    <Typography>BES (PDP/B E.S.)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #1ebf24' , color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#1ebf24" }} />
                    </Box>
                    <Typography>BES (PDP/D S.A.)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #ffbfaa', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#ffbfaa" }} />
                    </Box>
                    <Typography>BES (P.E.I. ORDINARIO)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #ff7b5a', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#ff7b5a" }} />
                    </Box>
                    <Typography>BES (P.E.I. PERSONALIZZATO)</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid #d11507', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "#d11507" }} />
                    </Box>
                    <Typography>BES (P.E.I. DIFFERENZIATO)</Typography>
                </Box>

                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ boxShadow:"0 0 10px 2px rgb(255, 100, 100)", mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px solid grey', color: 'white' }}>
                        <AccessibilityNew sx={{ color: "grey" }} />
                    </Box>
                    <Typography>BES (MODIFICATO DI RECENTE)</Typography>
                </Box>
            </Grid>
            <Grid xs={12} md={3} sx={{
                pb: { xs: 4, md: 0 }, borderBottom: { xs: 'rgb(210,210,210) 1px solid', md: 'none' },
                borderRight: { md: 'rgb(210,210,210) 1px solid', xs: 'none' }, pl: { xs: 0, md: 4 }
            }}>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: { xs: 4, md: 0 } }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"white"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'orange', border: '1px solid orange' }}>
                        {/* <Typography>AdG</Typography> */}
                        <Description sx={{ fontSize: "2rem" }} />
                    </Box>
                    <Typography>Annotazione</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"grey"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <Info></Info>
                    </Box>
                    <Typography>Argomenti svolti</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"primary.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        <Assignment />
                    </Box>
                    <Typography>Compiti assegnati</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px green solid', color: 'green' }}>
                        <Typography fontWeight={'700'}>18</Typography>
                    </Box>
                    <Typography>Accertamento prerequisiti</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ba68c8 solid', color: '#9c27b0' }}>
                        <Typography fontWeight={'700'}>23</Typography>
                    </Box>
                    <Typography>Voto</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ba68c8 solid', color: '#9c27b0' }}>
                        <Typography fontWeight={'700'}>R</Typography>
                    </Box>
                    <Typography>Valutazione</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"} backgroundColor={"warning.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', color: 'white' }}>
                        {/* <Typography>AdG</Typography> */}
                        <CalendarToday />
                    </Box>
                    <Typography>Verifica Programmata</Typography>
                </Box>
            </Grid>
            <Grid xs={12} md={3} sx={{ pl: { xs: 0, md: 4 } }}>
             
                <Box display={"flex"} alignItems={"center"} sx={{ mt: { xs: 4, md: 0 } }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #4caf50 solid', color: '#4caf50' }}>
                        <Typography fontWeight={'700'} >P</Typography>
                    </Box>
                    <Typography>Presente</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ef5350 solid', color: '#ef5350' }}>
                        <Typography fontWeight={'700'}>A</Typography>
                    </Box>
                    <Typography>Assente</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ff9800 solid', color: '#ff9800' }}>
                        <Typography fontWeight={'700'}>UA</Typography>
                    </Box>
                    <Typography>Uscita anticipata</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ff9800 solid', color: '#ff9800' }}>
                        <Typography fontWeight={'700'}>R</Typography>
                    </Box>
                    <Typography>Ritardo</Typography>
                </Box>
            
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px #ff9800 solid', color: '#ff9800' }}>
                        <Typography fontSize={"0.8rem"} fontWeight={'700'}>ER/UA</Typography>
                    </Box>
                    <Typography>Ritardo e uscita anticipata</Typography>
                </Box>

                <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                    <Box width={"30px"} height={"30px"}
                        // backgroundColor={"error.light"}
                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                        sx={{ mx: 1, p: 1, mr: 2, borderRadius: '100%', border: '1px grey solid', color: 'grey' }}>
                        <Typography fontWeight={'700'}>ND</Typography>
                    </Box>
                    <Typography>Presenza non definita</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}