import { useEffect, useRef, useState } from "react"
import { getAnnoFormativo } from "../../shared/utilityMethods";
import CryptoJS from 'crypto-js';
import { TirociniAPI } from "../../../api/tirociniApi";
import { Alert, Box, Button, Container, Divider, IconButton, TextField, Typography } from "@mui/material";
import { convertDataGetProssimeLezione, convertUtcInDatetime } from "../../shared/metodiDateUtility";
import Grid from "@mui/material/Unstable_Grid2";
import Breadcrumb from "../../registri/shared/breadcrumb";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import DialogConferma from "../../shared/dialogConferma";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import UploadButton from "../../ui/uploadButton";
import { useSnackbar } from "src/context/snackbarContext";
import { Close } from "@mui/icons-material";

export default function GestioneDatiRicorrentiSedi() {
    const { t } = useTranslation();

    const { showSnackbar } = useSnackbar();
    const [annoFormativo, setAnnoFormativo] = useState("")
    const [sedeId, setSedeId] = useState(-1)
    const [showError, setShowError] = useState(false);

    const [isAlertSaveConfirmOpen, setIsAlertSaveConfirmOpen] = useState(false)
    const [objDirettore, setObjDirettore] = useState({
        nome: null,
        cognome: null,
        dataNascita: null,
        luogoNascita: null
    });
    const [inail, setInail] = useState(null);
    const [rcd, setRcd] = useState(null);
    const [polizzaAssicurativa, setPolizzaAssicurativa] = useState(null);

    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [isConfirmUploadLogoOpen, setIsConfirmUploadLogoOpen] = useState(false);
    const [fileLoghiGenerali, setFileLoghiGenerali] = useState(null);
    const [fileLogoComuneRoma, setFileLogoComuneRoma] = useState(null);
    const [indexRefreshImmagine, setIndexRefreshImmagine] = useState(0);

    const [alertLoghiObj, setAlertLoghiObj] = useState({ //Va gestito cosi perchè lo snackbar fa buggare la dialog dell'input file
        isOpen: false,
        msg: "",
        severity: "info",
        indexRiga: 0 //In base a quale logo ho caricato
    })

    useEffect(() => {
        const annoFormativoTmp = getAnnoFormativo('');
        setAnnoFormativo(annoFormativoTmp);
        let sedeTmp = localStorage.getItem("sede");
        let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        let sedeParsed = JSON.parse(decryptedSede).id;
        setSedeId(sedeParsed);

        getPrecompilazioneConvenzioni(sedeParsed, annoFormativoTmp);
    }, [])

    function openIsAlertConfirmDialog() {
        setIsAlertSaveConfirmOpen(prev => !prev)
    }

    function closeAlertLoghi() {
        setAlertLoghiObj({
            ...alertLoghiObj,
            isOpen: false
        })
    }

    function changeFile(file, isLoghiGenerali) {
        if (alertLoghiObj.isOpen)   //Se è aperto l'alert lo chiudo
            closeAlertLoghi();
        if (isLoghiGenerali)
            setFileLoghiGenerali(file);
        else setFileLogoComuneRoma(file);
        setIsConfirmUploadLogoOpen(true);
    }

    async function getPrecompilazioneConvenzioni(sedeIdTmp, annoFormativoTmp) {
        const objData = {
            idSede: sedeIdTmp,
            annoFormativo: annoFormativoTmp
        }

        TirociniAPI.getPrecompilazioneConvenzioni(objData)
            .then((result) => {
                setShowError(false);
                setObjDirettore({
                    nome: result?.anagrafica_direttore?.nome ?? null,
                    cognome: result?.anagrafica_direttore?.cognome ?? null,
                    luogoNascita: result?.anagrafica_direttore?.luogoNascita ?? null,
                    dataNascita: result?.anagrafica_direttore?.dataNascita ? convertUtcInDatetime(result?.anagrafica_direttore?.dataNascita) : null
                });
                setPolizzaAssicurativa(result?.polizza_assicurativa);
                setInail(result?.inail);
                setRcd(result?.rcd);
                // setDataInizioTirocinio(result?.tirocinio_data_inizio ? convertUtcInDatetime(result?.tirocinio_data_inizio) : null);
                // setDataFineTirocinio(result?.tirocinio_data_fine ? convertUtcInDatetime(result?.tirocinio_data_fine) : null);
            })
            .catch(function (error) {
                if (error.response) {
                    setShowError(true);
                }
            })
    }
    async function confirmSalvataggio() {
        const objData = {
            "idSede": sedeId,
            "annoFormativo": parseInt(annoFormativo),
            "anagrafica_direttore": {
                "nome": objDirettore?.nome ?? null,
                "cognome": objDirettore?.cognome ?? null,
                "dataNascita": objDirettore?.dataNascita ? convertDataGetProssimeLezione(objDirettore.dataNascita, true) : null,
                "luogoNascita": objDirettore.luogoNascita ?? null
            },
            "inail": inail ? inail : null,
            "rcd": rcd ? rcd : null,
            "polizza_assicurativa": polizzaAssicurativa ? polizzaAssicurativa : null,
        }


        TirociniAPI.savePrecompilazioneConvenzioni(objData)
            .then((result) => {
                setIsAlertSaveConfirmOpen(false);
                showSnackbar("Dati salvati correttamente", "success")
            })
            .catch(function (error) {
                if (error.response) {
                    const errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio dei dati";
                    showSnackbar(errorMsg, "error")
                }
            })
    }
    async function saveLogo(isLoghiGenerali) {
        setIsUploadingImage(true);

        const objData = isLoghiGenerali
            ? { testa_copertina_registro_stage: fileLoghiGenerali }
            : { citta_metropolitana_roma: fileLogoComuneRoma };

        const saveFunction = isLoghiGenerali
            ? TirociniAPI.saveLogoGenerali
            : TirociniAPI.saveLogoComuneRoma;

        try {
            await saveFunction(objData);
            setIsConfirmUploadLogoOpen(false);
            setAlertLoghiObj({
                isOpen: true,
                msg: "Immagine caricata correttamente",
                severity: "success",
                indexRiga: isLoghiGenerali ? 0 : 1
            })
            //showSnackbar("Immagine caricata correttamente", "success");
            setFileLoghiGenerali(null);
            setFileLogoComuneRoma(null);
            setIndexRefreshImmagine(prev => prev + 1); // Aggiorna immagine senza ricaricare la pagina
            setTimeout(() => setIsUploadingImage(false), 400);
        } catch (error) {
            let errorMsg = "Errore durante il salvataggio dell'immagine";

            if (error?.response?.data) {
                if (Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                    errorMsg = error.response.data.data[0]?.msg || errorMsg;
                } else if (typeof error.response.data === "string") {
                    errorMsg = error.response.data;
                } else if (error.response.data.message) {
                    errorMsg = error.response.data.message;
                }
            }
            setAlertLoghiObj({
                isOpen: true,
                msg: errorMsg,
                severity: "error"
            })
            // showSnackbar(errorMsg, "error");
            setIsConfirmUploadLogoOpen(false);
            setIsUploadingImage(false);
        }
    }


    return (
        <>
            <Breadcrumb isTirocinio={true} sections={["Gestione dati ricorrenti per sedi"]} />
            <Container>
                {showError ? (
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
                        <Alert severity="error">Errore durante il recupero dei dati. Riprovare tra qualche minuto.</Alert>
                    </Box>
                ) : (
                    <>
                        <Typography sx={{ mb: 2 }} variant="h4">Gestione dati ricorrenti per sedi</Typography>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={4}>
                                <TextField
                                    value={inail ?? ""}
                                    label="INAIL posizione n°"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    onChange={(e) => { setInail(e.target.value); }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    value={polizzaAssicurativa ?? ""}
                                    label="Polizza Assicurativa n°"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => { setPolizzaAssicurativa(e.target.value); }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    value={rcd ?? ""}
                                    label="R.C.D. posizione n°"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => { setRcd(e.target.value); }}
                                />
                            </Grid>
                        </Grid>

                        <Typography variant="h6" sx={{ mt: 2 }}>Anagrafica direttore</Typography>
                        <Divider sx={{ mb: 2 }}></Divider>

                        <Grid container spacing={2}>
                            <Grid xs={12} md={3}>
                                <TextField value={objDirettore?.nome ?? ""} label="Nome" fullWidth type="text" variant='outlined'
                                    onChange={(e) => setObjDirettore({ ...objDirettore, nome: e.target.value })} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField value={objDirettore?.cognome ?? ""} label="Cognome" fullWidth type="text" variant='outlined'
                                    onChange={(e) => setObjDirettore({ ...objDirettore, cognome: e.target.value })} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField value={objDirettore?.luogoNascita ?? ""} label="Luogo di nascita" fullWidth type="text" variant='outlined'
                                    onChange={(e) => setObjDirettore({ ...objDirettore, luogoNascita: e.target.value })} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                    <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                        label="Data nascita"
                                        value={objDirettore?.dataNascita ?? null}
                                        format="dd/MM/yyyy"
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                        onChange={(newValue) => setObjDirettore({ ...objDirettore, dataNascita: newValue })} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid xs={12} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="h6" sx={{ mt: 2 }}>Loghi prima riga registro fogli mobili</Typography>
                                <Divider sx={{ mb: 2 }}></Divider>
                                {(alertLoghiObj.isOpen && alertLoghiObj.indexRiga === 0) && (
                                    <Alert severity={alertLoghiObj.severity} action={
                                        <IconButton color="inherit" size="small" onClick={closeAlertLoghi}>
                                            <Close />
                                        </IconButton>
                                    }>
                                        {alertLoghiObj.msg}
                                    </Alert>
                                )}
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>

                                    <UploadButton acceptedFormats={["image/png"]} maxFileDimension={1048576} fileChanged={(file) => changeFile(file, true)} />
                                    <Box sx={{ p: 2, border: '1px solid black' }}>
                                        <img draggable={false} alt="Loghi prima riga registro fogli mobili" src={`${process.env.REACT_APP_FETCH_URL}/static/tirociniFormativi/testa_copertina_registro_stage.png?refresh=${indexRefreshImmagine}`} style={{ maxWidth: '100%', maxHeight: '50px' }} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs={12} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Typography variant="h6" sx={{ mt: 2 }}>Logo seconda riga registro fogli mobili</Typography>
                                <Divider sx={{ mb: 2 }}></Divider>
                                {(alertLoghiObj.isOpen && alertLoghiObj.indexRiga === 1) && (
                                    <Alert severity={alertLoghiObj.severity} action={
                                        <IconButton color="inherit" size="small" onClick={closeAlertLoghi}>
                                            <Close />
                                        </IconButton>
                                    }>
                                        {alertLoghiObj.msg}
                                    </Alert>
                                )}
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    <UploadButton acceptedFormats={["image/png"]} maxFileDimension={1048576} fileChanged={(file) => changeFile(file, false)} />
                                    <Box sx={{ p: 2, border: '1px solid black' }}>
                                        <img draggable={false} alt="Logo seconda riga registro fogli mobili" src={`${process.env.REACT_APP_FETCH_URL}/static/tirociniFormativi/citta_metropolitana_roma.png?refresh=${indexRefreshImmagine}`} style={{ maxWidth: '100%', maxHeight: '50px' }} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: "flex", justifyContent: alertLoghiObj.isOpen ? "space-between" : "flex-end", mt: 4 }}>

                            <Button color="success" variant="contained" onClick={openIsAlertConfirmDialog}>
                                Conferma e salva
                            </Button>
                        </Box>

                        <DialogConferma
                            isDialogOpen={isAlertSaveConfirmOpen}
                            title={'Conferma modifiche'}
                            // icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                            text={`Confermi di voler procedere con il salvataggio delle modifiche?`}
                            showAlunni={false}
                            confermaButton={'Conferma salvataggio'}
                            severity={'success'}
                            closeDialog={openIsAlertConfirmDialog}
                            conferma={confirmSalvataggio}
                            disabledButton={false}
                        />

                        <DialogConferma
                            isDialogOpen={isConfirmUploadLogoOpen}
                            title={'Conferma modifica immagine'}
                            // icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                            text={`Confermi di voler procedere con la modifica dell'immagine? La precedente immagine verrà sovrascritta.`}
                            showAlunni={false}
                            confermaButton={'Conferma'}
                            severity={'success'}
                            closeDialog={() => setIsConfirmUploadLogoOpen(false)}
                            conferma={() => saveLogo(fileLoghiGenerali ? true : false)}
                            disabledButton={isUploadingImage}
                        />

                    </>
                )}
            </Container>

            <Helmet>
                <title>{t("name")} - Gestione dati ricorrenti sedi</title>
            </Helmet>

        </>
    )
}
