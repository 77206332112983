import { Box,  Link, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, FormControl, Select, Breadcrumbs, InputLabel, MenuItem } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"
import ElencoAlunniSelezionabili from "../elencoAlunniSelezionabili";

import DialogNoItemInSessionStorage from "../../shared/dialogNoItemInSessionStorage";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";

import CryptoJS from 'crypto-js';

import { EventiAPI } from "../../../api/eventiApi";
import { RegistroAPI } from "../../../api/registroApi";
import { convertDataGetProssimeLezione, convertToSqlFormat } from "../../shared/metodiDateUtility";
import { PresenzeAPI } from "../../../api/presenzeApi";
import DialogConferma from "../../shared/dialogConferma";
import { Check } from "@mui/icons-material";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";
import functionCheckAlunniSelezionabili from "../functionCheckAlunniSelezionabili";

export default function AggiuntaAnnotazione(props) {

    const inputAnnotazioneRef = useRef(null);

    const [data, setData] = useState(null);

    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [oraSelected, setOraSelected] = useState(-1);
    const [elencoOre, setElencoOre] = useState([]);
    const [tipoRegistro, setTipoRegistro] = useState("classe");

    const [nomeClasse, setNomeClasse] = useState("");
    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);

    // const [titoloAnnotazioni, setTitoloAnnotazioni] = useState("");
    const [testoAnnotazioni, setTestoAnnotazioni] = useState("");
    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("")
    const [dataFinePeriodo, setDataFinePeriodo] = useState("")


    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null

        setDataInizioPeriodo(inizioPeriodoTmp ?? null);
        setDataFinePeriodo(finePeriodoTmp ?? null);

        /* if (props.dataInizioPeriodo === undefined || props.dataInizioPeriodo === null)
             setDataInizioPeriodo(inizioPeriodoTmp ?? null);
         else setDataInizioPeriodo(props.dataInizioPeriodo)
 
         if (props.dataFinePeriodo === undefined || props.dataFinePeriodo === null)
             setDataFinePeriodo(finePeriodoTmp ?? null);
         else setDataFinePeriodo(props.dataFinePeriodo)
 */
    }, [])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined && props.alunniSelezionati !== undefined && props.dataRichiesta !== undefined) {
          
            let dataRichiestaTmp = DateTime.fromSQL(props.dataRichiesta);
            if (props.listAlunni == null)
                getAlunniByClasseID();
            else {
                manageStatesAlunni(props.listAlunni, props.alunniSelezionati, dataRichiestaTmp, props.idDocente, props.idCorso, props.idMateria)
                setGetAlunniIsLoading(false);
            }

            setData(dataRichiestaTmp)
            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
            setTipoRegistro(props.tipoRegistro)
        }
    }, [props.idCorso, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati, props.dataRichiesta, props.tipoRegistro]);




    useEffect(() => {

        if (isDialog === false) {
            if (sessionStorage.getItem("classe") != null) {
                let encryptedIdCorso = sessionStorage.getItem("classe");
                let decryptedIdCorso = CryptoJS.AES.decrypt(encryptedIdCorso, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
                //TODO: Poi imposterò questo ID
                setAlertNoIdIsOpen(false);

            } else {
                //Se non trovo l'item ho acceduto tramite url e allora mostro l'errore
                setAlertNoIdIsOpen(true);
            }
        }
    }, [isDialog])



    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])

    useEffect(() => {
        if (props.nomeClasse !== undefined) {
            setNomeClasse(props.nomeClasse);
        }
    }, [props.nomeClasse])

    async function inserisciAnnotazione() {

        let arrayAlunniSelezionatiID = [];
        for (let alunno of alunniSelezionati) {
            arrayAlunniSelezionatiID.push(alunno.id)
        }

        let obj = {
            idOrario: oraSelected,
            note: testoAnnotazioni,
            is_interaClasse: alunniSelezionati.length === alunniSelezionabiliNumber ? true : false,
            idAlunno: arrayAlunniSelezionatiID,
            idMateria: idMateria,
            idClasse: idCorso
        }
        setPulsanteConfermaDisabled(true);
        EventiAPI.insertAnnotazioni(obj)
            .then((result) => {
                if (isDialog) {
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Annotazione aggiunta", "success");
                } else {
                    setAlunniSelezionati([]);
                    setTestoAnnotazioni("");

                    if (inputAnnotazioneRef != null)
                        inputAnnotazioneRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Annotazione aggiunta");
                    setAlertIsOpen(true);
                }
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setConfirmAlertIsOpen(false);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) logout();
                }
                setConfirmAlertIsOpen(false);

                setAlertSeverity("error");
                setAlertMsg("Al momento non è possibile completare l'operazione");
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setAlertIsOpen(true);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
    }

    function logout() {
        if (props.logout) props.logout()
    }

    function selectAlunni(listAlunni) {
        if (listAlunni !== undefined)
            setAlunniSelezionati([...listAlunni]);
    }

    function changeTestoAnnotazione(e) {
        setTestoAnnotazioni(e.target.value)
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        manageStatesAlunni(listAlunni, alunniSelezionati, value, idDocente, idCorso, idMateria);
    }

    function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);
                isAlunnoIscrittoInData(DateTime.now(), [...result.alunni])
            })
    }

    function isAlunnoIscrittoInData(data, listAlunni) {
        if (data !== null) {
            let dataConvertedIso = data.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp;// setAlunniNonSelezionabili([...listTmp]);
        }
        else {
            let listTmp = [];
            for (let alunno of listAlunni) {
                listTmp.push(alunno);
            }
            setAlunniNonSelezionabili([...listTmp]);
        }
    }


    function changeOra(e) {
        setOraSelected(e.target.value);
        manageStatesAlunni(listAlunni, alunniSelezionati, data, idDocente, idCorso, idMateria, e.target.value);
    }

    function manageStatesAlunni(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp, tipoRegistroTmp) {
            functionCheckAlunniSelezionabili(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp ?? undefined)
                .then(objCheckAlunniSelezionabili => {
                    setListAlunni([...objCheckAlunniSelezionabili.listAlunniTotali]);
                    setElencoOre([...objCheckAlunniSelezionabili.listOre]);
                    if (idOrarioTmp === undefined) //Se non è undefined ho già settato l'ora in precedenza, quindi non la risetto
                        setOraSelected(objCheckAlunniSelezionabili.oraSelected);
                    setIdMateria(tipoRegistroTmp !== undefined && tipoRegistroTmp === "docente" ? idMateriaTmp : objCheckAlunniSelezionabili.idMateriaSelected)
                    setAlunniSelezionati([...objCheckAlunniSelezionabili.listAlunniSelezionati]);
                    const alunniNonSelezionabiliObj = objCheckAlunniSelezionabili.alunniNonSelezionabili.map(id => {
                        return { id: id };
                    });
                    setAlunniNonSelezionabili([...alunniNonSelezionabiliObj]);
                    setAlunniSelezionabiliNumber(objCheckAlunniSelezionabili.numberAlunniSelezionabili)
                });
        }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!isDialog ? (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                        <Typography color="text.disabled">Aggiunta eventi</Typography>
                        <Typography color="text.disabled">Argomenti svolti</Typography>
                    </Breadcrumbs>) : null}
                <Typography variant="h4" mt={1}>Aggiungi annotazione</Typography>
                {!alertNoIdIsOpen ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                                {!getAlunniIsLoading ? (
                                    <ElencoAlunniSelezionabili alunniSelezionabiliNumber={alunniSelezionabiliNumber} checkAssenze={true} selectAlunni={selectAlunni} alunniNonSelezionabili={alunniNonSelezionabili} nomeClasse={nomeClasse}
                                        listAlunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                                ) : (
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} sx={{ pt: 3, width: '100%' }}>
                                            <TextField sx={{ width: '100%' }} disabled label='Classe' value={nomeClasse} />
                                        </Grid>
                                        <Grid xs={12} md={6} sx={{ pt: { xs: 1, md: 3 }, width: '100%' }}>
                                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                                <MobileDatePicker sx={{ width: '100%' }} views={['month', 'day']}
                                                    minDate={dataInizioPeriodo}
                                                    maxDate={dataFinePeriodo}
                                                    label="Data lezione"
                                                    value={data}
                                                    format='dd/MM/yyyy'
                                                    onChange={(value) => changeData(value)}
                                                    dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="materia-label">
                                                    {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                                    Seleziona ora</InputLabel>
                                                <Select
                                                    data-testid='selOraProgrammaSelect'
                                                    label='Seleziona ora '
                                                    labelId="materia-label"
                                                    value={oraSelected}
                                                    onChange={changeOra}
                                                    disabled={elencoOre.length === 0}
                                                // disabled={!modifica}
                                                >
                                                    <MenuItem key={-1} value={-1}>
                                                    </MenuItem>
                                                    {elencoOre.map((ora) => (
                                                        <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                            {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta</Typography>) : null}
                                        </Grid>

                                        <Grid xs={12} sx={{ width: '100%', pt: 1 }}>
                                            {/* <TextField fullWidth sx={{ mt: 1 }} label='Titolo' onChange={changeTitoloAnnotazione} /> */}
                                            <TextField fullWidth sx={{ mt: 1 }} inputRef={inputAnnotazioneRef} label='Testo'
                                                onChange={changeTestoAnnotazione} placeholder="Scrivi qui..." multiline rows={4} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                            {isDialog ? (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                            ) : null}
                            <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={testoAnnotazioni === '' || oraSelected === -1 || alunniSelezionati.length === 0} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                color="success" variant="contained" >
                                Nuova annotazione
                            </Button>
                        </Box>
                    </Fragment>
                ) : null}

            </Container>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma aggiunta annotazione'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={'Confermi di voler aggiungere una annotazione per '}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma aggiunta'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={inserisciAnnotazione}
                disabledButton={pulsanteConfermaDisabled}
            />

            <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} />


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}