import { useState, useEffect, Fragment } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Paper, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, Box, Snackbar, Alert, Divider, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Radio, RadioGroup, CircularProgress } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { Edit, Delete, Check } from "@mui/icons-material";

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import { BesAPI } from "../../../api/besApi";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'; import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import DialogConferma from "../../shared/dialogConferma";
import { getAnnoFormativo } from "../../shared/utilityMethods";

export default function BesAlunno(props) {
    const { t, i18n } = useTranslation();

    const [alunno, setAlunno] = useState('');
    const [idStudente, setIdStudente] = useState('');
    const [idCorso, setIdCorso] = useState(-1);

    const [hasLoaded, setHasLoaded] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [showDeleteBesButton, setShowDeleteBesButton] = useState(false);

    const [operatori, setOperatori] = useState([]);


    const [oreASAllievo, setOreASAllievo] = useState(0);

    const [comesFromTracciato, setComesFromTracciato] = useState(false);
    const [permessiUtente, setPermessiUtente] = useState([]);

    const [isModificaMode, setIsModificaMode] = useState(false);
    const [isModificaModeButton, setIsModificaModeButton] = useState(false);
    const [isAnnoFormativoConcluso, setIsAnnoFormativoConcluso] = useState(true);
    const [pin, setPin] = useState(1234); //TODO: bisogna verificare il pin dell'utente

    const [nomeAddOperatore, setNomeAddOperatore] = useState('');
    const [numeroOreAddOperatore, setNumeroOreAddOperatore] = useState('');

    const [deleteBesDialogOpen, setDeleteBesDialogOpen] = useState(false);
    const [confirmEditDialogIsOpen, setConfirmEditDialogIsOpen] = useState(false);

    const [diagnosiPrimaria, setDiagnosiPrimaria] = useState("");
    const [diagnosiSecondaria, setDiagnosiSecondaria] = useState("");

    //Accertamento condizione disabilita
    const [accertamentoCondizioneDisabilitaEnabled, setAccertamentoCondizioneDisabilitaEnabled] = useState(false);
    const [accertamentoCondizioneDisabilitaDataRilascio, setAccertamentoCondizioneDisabilitaDataRilascio] = useState(null);
    const [accertamentoCondizioneDisabilitaDataScadenza, setAccertamentoCondizioneDisabilitaDataScadenza] = useState(null);
    const [accertamentoCondizioneDisabilitaRivedibile, setAccertamentoCondizioneDisabilitaRivedibile] = useState(false);

    //Certificazione di disabilita
    const [certificazioneDisabilita104ListTipologie, setCertificazioneDisabilita104ListTipologie] = useState([]);
    const [certificazioneDisabilita104Enabled, setCertificazioneDisabilita104Enabled] = useState(false);
    const [certificazioneDisabilita104Tipologia, setCertificazioneDisabilita104Tipologia] = useState(null);
    const [certificazioneDisabilita104DataRilascio, setCertificazioneDisabilita104DataRilascio] = useState(null);
    const [certificazioneDisabilita104DataScadenza, setCertificazioneDisabilita104DataScadenza] = useState(null);
    const [certificazioneDisabilita104Rivedibile, setCertificazioneDisabilita104Rivedibile] = useState(false);
    //CIS
    const [cisEnabled, setCisEnabled] = useState(false);
    const [cisData, setCisData] = useState(null);
    //Verbale 289/90
    const [verbaleLegge289Enabled, setVerbaleLegge289Enabled] = useState(false);
    const [verbaleLegge289Data, setverbaleLegge289Data] = useState(null);
    //Verbale 114
    const [attestazioneLegge114Enabled, setattestazioneLegge114Enabled] = useState(false);
    const [attestazioneLegge114Data, setattestazioneLegge114Data] = useState(null);
    //profiloFunzionamento
    const [profiloFunzionamentoEnabled, setProfiloFunzionamentoEnabled] = useState(false);
    const [profiloFunzionamentoData, setProfiloFunzionamentoData] = useState(null);
    //diagnosiFunzionale
    const [diagnosiFunzionaleEnabled, setDiagnosiFunzionaleEnabled] = useState(false);
    const [diagnosiFunzionaleData, setDiagnosiFunzionaleData] = useState(null);
    //profiloDinamicoFunzionale
    const [profiloDinamicoFunzionaleEnabled, setProfiloDinamicoFunzionaleEnabled] = useState(false);
    const [profiloDinamicoFunzionaleData, setProfiloDinamicoFunzionaleData] = useState(null);
    //diagnosiFunzionale
    const [progettoIndividualeEnabled, setProgettoIndividualeEnabled] = useState(false);
    const [progettoIndividualeData, setProgettoIndividualeData] = useState(null);

    const [tipoRelazioneCertificazioniSanitarie, setTipoRelazioneCertificazioniSanitarie] = useState("");
    const [listRelazioneCertificazioniSanitarie, setListRelazioneCertificazioniSanitarie] = useState([]);
    const [altraCertificazioneSanitaria, setAltraCertificazioneSanitaria] = useState(null)

    const [tipoDocumentazionePDP, setTipoDocumentazionePDP] = useState("");
    const [dataDocumentazionePDP, setDataDocumentazionePDP] = useState(null);
    const [listDocumentazionePDP, setListDocumentazionePDP] = useState([]);

    const [interventoAttivato, setInteventoAttivato] = useState("0");

    const [operatoreSelezionabili, setOperatoreSelezionabili] = useState([]);

    // const [annoFormativo, setAnnoFormativo] = useState("20232024")
    const [annoFormativo, setAnnoFormativo] = useState('');
    const [annoFormativoObj, setAnnoFormativoObj] = useState(null);

    useEffect(() => {
        let annoFormativoObjTmp = JSON.parse(getAnnoFormativo(true));
        setAnnoFormativoObj(annoFormativoObjTmp)
        const dateToCheck = DateTime.fromISO(annoFormativoObjTmp.dataA);
        const currentDate = DateTime.now();

        if (dateToCheck > currentDate && annoFormativoObjTmp.isAnnoFormativoCorrente) //Anno formativo corrente e non concluso
            setIsAnnoFormativoConcluso(false)
        else setIsAnnoFormativoConcluso(true);

        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
    }, [])

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        if (props.dontCallBesBE !== undefined && props.id !== undefined) {
            setIdStudente(props.id);
            if (!props.dontCallBesBE) {
                getBesList(annoFormativoTmp);
                visualizzaBesOperatori(props.id, annoFormativoTmp);
            }
        }
    }, [props.dontCallBesBE, props.id])

    useEffect(() => {
        if (props.besData !== undefined && props.dontCallBesBE !== undefined) {
            if (props.dontCallBesBE) {
                visualizzaBesNoBE(props.besData)
            }
        }
    }, [props.besData, props.dontCallBesBE])

    useEffect(() => {
        if (props.comesFromTracciato !== undefined) {
            setComesFromTracciato(props.comesFromTracciato);
            setIsModificaModeButton(props.comesFromTracciato);
        }
        else setIsModificaModeButton(false)

    }, [props.comesFromTracciato])

    useEffect(() => {
        if (props.permessiUtente !== undefined) {
            setPermessiUtente(props.permessiUtente);
        }
    }, [props.permessiUtente])




    useEffect(() => {
        if (props.idCorso !== undefined)
            setIdCorso(props.idCorso);
        // visualizzaBesDocente(props.idCorso)
    }, [props.idCorso])

    useEffect(() => {
        if (props.nomeAlunno !== undefined)
            setAlunno(props.nomeAlunno);
    }, [props.nomeAlunno])



    //ACCERTAMENTO DELLA CONDIZIONE DI DISABILITÀ IN ETÀ EVOLUTIVA AI FINI DELL'INCLUSIONE SCOLASTICA
    function changeCondizioneDisabilitaEnabled(e) {
        let isEnabled = !accertamentoCondizioneDisabilitaEnabled;
        setAccertamentoCondizioneDisabilitaEnabled(isEnabled)
        if (isEnabled === false) {
            setAccertamentoCondizioneDisabilitaRivedibile(true)
            setAccertamentoCondizioneDisabilitaDataRilascio(null)
            setAccertamentoCondizioneDisabilitaDataScadenza(null)
        }
    }
    function changeCondizioneDisabilitaRivedibile(e) {
        setAccertamentoCondizioneDisabilitaRivedibile(prev => !prev)
    }
    function changeCondizioneDisabilitaDataRilascio(value) {
        setAccertamentoCondizioneDisabilitaDataRilascio(value)
    }
    function changeCondizioneDisabilitaDataScadenza(value) {
        setAccertamentoCondizioneDisabilitaDataScadenza(value)
    }

    //CERTIFICAZIONE DI DISABILITÀ AI SENSI DELLA LEGGE 104/92
    function changeCertificazioneDisabilita104Enabled(e) {
        let isEnabled = !certificazioneDisabilita104Enabled;
        setCertificazioneDisabilita104Enabled(isEnabled)
        if (isEnabled === false) {
            setCertificazioneDisabilita104Tipologia(null)
            setCertificazioneDisabilita104Rivedibile(true)
            setCertificazioneDisabilita104DataRilascio(null)
            setCertificazioneDisabilita104DataScadenza(null)
        }
    }
    function changeCertificazioneDisabilita104Rivedibile(e) {
        setCertificazioneDisabilita104Rivedibile(prev => !prev)
    }
    function changeCertificazioneDisabilita104DataRilascio(value) {
        setCertificazioneDisabilita104DataRilascio(value)
    }
    function changeCertificazioneDisabilita104DataScadenza(value) {
        setCertificazioneDisabilita104DataScadenza(value)
    }
    function changeCertificazioneDisabilita104Tipologia(e) {
        setCertificazioneDisabilita104Tipologia(e.target.value)
    }

    //CIS
    function changeCisEnabled(e) {
        let isEnabled = !cisEnabled;
        setCisEnabled(isEnabled)
        if (isEnabled === false)
            setCisData(null)
    }
    function changeCisData(value) {
        setCisData(value)
    }

    //289
    function changeLegge289Enabled(e) {
        let isEnabled = !verbaleLegge289Enabled;
        if (isEnabled === false)
            setverbaleLegge289Data(null)
        setVerbaleLegge289Enabled(isEnabled)
    }
    function changeLegge289Data(value) {
        setverbaleLegge289Data(value)
    }

    //114
    function changeLegge114Enabled(e) {
        let isEnabled = !attestazioneLegge114Enabled;
        if (isEnabled === false)
            setattestazioneLegge114Data(null)
        setattestazioneLegge114Enabled(isEnabled)
    }
    function changeLegge114Data(value) {
        setattestazioneLegge114Data(value)
    }

    //profiloFunzionamento
    function changeProfiloFunzionamentoEnabled(e) {
        let isEnabled = !profiloFunzionamentoEnabled;
        if (isEnabled === false)
            setProfiloFunzionamentoData(null)
        setProfiloFunzionamentoEnabled(isEnabled)
    }
    function changeProfiloFunzionamentoData(value) {
        setProfiloFunzionamentoData(value)
    }

    //diagnosi funzionale
    function changeDiagnosiFunzionaleEnabled(e) {
        let isEnabled = !diagnosiFunzionaleEnabled;
        if (isEnabled === false)
            setDiagnosiFunzionaleData(null)
        setDiagnosiFunzionaleEnabled(isEnabled)
    }
    function changeDiagnosiFunzionaleData(value) {
        setDiagnosiFunzionaleData(value)
    }


    //profiloDinamicoFunzionale
    function changeProfiloDinamicoFunzionaleEnabled(e) {
        let isEnabled = !profiloDinamicoFunzionaleEnabled;
        if (isEnabled === false)
            setProfiloDinamicoFunzionaleData(null)
        setProfiloDinamicoFunzionaleEnabled(isEnabled)
    }
    function changeProfiloDinamicoFunzionaleData(value) {
        setProfiloDinamicoFunzionaleData(value)
    }

    //progettoIndividuale
    function changeProgettoIndividualeEnabled(e) {
        let isEnabled = !progettoIndividualeEnabled;
        if (isEnabled === false)
            setProgettoIndividualeData(null)
        setProgettoIndividualeEnabled(isEnabled)
    }
    function changeProgettoIndividualeData(value) {
        setProgettoIndividualeData(value)
    }

    function changeTipoRelazioneCertificazioniSanitarie(e) {
        setTipoRelazioneCertificazioniSanitarie(e.target.value)
        if (e.target.value !== "Altra documentazione")
            setAltraCertificazioneSanitaria(null)
    }
    function changeAltraCertificazione(e) {
        setAltraCertificazioneSanitaria(e.target.value)
    }

    function changeTipologiaPDP(e) {
        setTipoDocumentazionePDP(e.target.value)
        if (e.target.value === "")
            setDataDocumentazionePDP(null)
    }
    function changeDataPDP(value) {
        setDataDocumentazionePDP(value)
    }

    function changeInterventoAttivo(e) {
        setInteventoAttivato(e.target.value)
    }

    async function visualizzaBesOperatori(idStudente, annoFormativoTmp) {

        let dataObj = {
            idAlunno: idStudente,
            "annoFormativo": annoFormativoTmp !== undefined ? annoFormativoTmp : annoFormativo
        }
        if (idStudente !== -1) {
            BesAPI.visualizzaBesOperatori(dataObj)
                .then((result) => {

                    if (result?.hasBes) {
                        setShowDeleteBesButton(true);
                        //ACCERTAMENTO DELLA CONDIZIONE DI DISABILITÀ IN ETÀ EVOLUTIVA AI FINI DELL'INCLUSIONE SCOLASTICA
                        setAccertamentoCondizioneDisabilitaEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.enabled ?? false)
                        setAccertamentoCondizioneDisabilitaRivedibile(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.rivedibile ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataRilascio != null) {
                            let accertamentoCondizioneDisabilitaDataRilascioTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataRilascio);
                            setAccertamentoCondizioneDisabilitaDataRilascio(accertamentoCondizioneDisabilitaDataRilascioTmp)
                        }
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataScadenza != null) {
                            let accertamentoCondizioneDisabilitaDataScadenzaTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataScadenza);
                            setAccertamentoCondizioneDisabilitaDataScadenza(accertamentoCondizioneDisabilitaDataScadenzaTmp)
                        }

                        //CERTIFICAZIONE DI DISABILITÀ AI SENSI DELLA LEGGE 104/92
                        setCertificazioneDisabilita104Enabled(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.enabled ?? false)
                        setCertificazioneDisabilita104Rivedibile(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.rivedibile ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataRilascio != null) {

                            let certificazioneDisabilitaDataRilascioTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataRilascio ?? null);
                            setCertificazioneDisabilita104DataRilascio(certificazioneDisabilitaDataRilascioTmp)
                        }
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataScadenza != null) {
                            let certificazioneDisabilitaDataScadenzaTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataScadenza ?? null);
                            setCertificazioneDisabilita104DataScadenza(certificazioneDisabilitaDataScadenzaTmp)
                        }

                        setDiagnosiPrimaria(result?.BESData?.documentazioneConsegnataBes?.diagnosiPrimaria ?? "")
                        setDiagnosiSecondaria(result?.BESData?.documentazioneConsegnataBes?.diagnosiSecondaria ?? "")

                        setCertificazioneDisabilita104Tipologia(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.tipologia ?? null)

                        //CIS
                        setCisEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.cis?.enabled ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.cis?.data != null) {
                            let cisDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.cis?.data);
                            setCisData(cisDataTmp)
                        }
                        //289
                        setVerbaleLegge289Enabled(result?.BESData?.documentazioneConsegnataInvalidita?.verbaleLegge289?.enabled ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.verbaleLegge289?.data != null) {
                            let legge289DataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.verbaleLegge289?.data);
                            setverbaleLegge289Data(legge289DataTmp)
                        }
                        //114
                        setattestazioneLegge114Enabled(result?.BESData?.documentazioneConsegnataInvalidita?.attestazioneLegge114?.enabled ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.attestazioneLegge114?.data != null) {
                            let legge114DataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.attestazioneLegge114?.data);
                            setattestazioneLegge114Data(legge114DataTmp)
                        }

                        //PROFILO DI FUNZIONAMENTO
                        setProfiloFunzionamentoEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.profiloFunzionamento?.enabled ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.profiloFunzionamento?.data != null) {
                            let profiloFunzionamentoDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.profiloFunzionamento?.data);
                            setProfiloFunzionamentoData(profiloFunzionamentoDataTmp)
                        }
                        //DIAGNOSI FUNZIONALE
                        setDiagnosiFunzionaleEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.diagnosiFunzionale?.enabled ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.diagnosiFunzionale?.data != null) {
                            let diagnosiFunzionaleDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.diagnosiFunzionale?.data);
                            setDiagnosiFunzionaleData(diagnosiFunzionaleDataTmp)
                        }
                        //PROFILO DINAMICO FUNZIONALE
                        setProfiloDinamicoFunzionaleEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.profiloDinamicoFunzionale?.enabled ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.profiloDinamicoFunzionale?.data != null) {
                            let profiloDinamicoDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.profiloDinamicoFunzionale?.data);
                            setProfiloDinamicoFunzionaleData(profiloDinamicoDataTmp)
                        }
                        //PROGETTO INDIVIDUALE
                        setProgettoIndividualeEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.progettoIndividuale?.enabled ?? false)
                        if (result?.BESData?.documentazioneConsegnataInvalidita?.progettoIndividuale?.data != null) {
                            let progettoIndividualeDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.progettoIndividuale?.data);
                            setProgettoIndividualeData(progettoIndividualeDataTmp)
                        }
                        setTipoRelazioneCertificazioniSanitarie(result?.BESData?.documentazioneConsegnataBes?.tipoRelazioneCertificazioniSanitarie ?? null)
                        setAltraCertificazioneSanitaria(result?.BESData?.documentazioneConsegnataBes?.altraDocumentazione ?? null)
                        setTipoDocumentazionePDP(result?.BESData?.documentazioneConsegnataBes?.documentazionePDP?.tipologia ?? "")
                        if (result?.BESData?.documentazioneConsegnataBes?.documentazionePDP?.data != null) {
                            let dataPDP = DateTime.fromISO(result?.BESData?.documentazioneConsegnataBes?.documentazionePDP?.data);
                            setDataDocumentazionePDP(dataPDP)
                        }
                        setInteventoAttivato(result?.BESData?.documentazioneConsegnataBes?.interventoAttivo ?? "0")

                        setOperatori(result?.BESData?.operatori?.operatori ?? []);
                        let oreTmp = 0;
                        let operatoriTmp = result?.BESData?.operatori?.operatori;
                        for (let operatore of operatoriTmp) {
                            oreTmp += operatore.ore;
                        }
                        setOreASAllievo(oreTmp);

                    } else {
                        setShowDeleteBesButton(false)
                    }
                    setHasLoaded(true)

                })
                .catch((error) => {
                    setHasLoaded(true)

                })
        } else {
            setHasLoaded(true)
        }
    }
    async function visualizzaBesNoBE(result) {
        if (result?.hasBes === true || result?.hasBes === undefined) {
            setShowDeleteBesButton(true);
            //ACCERTAMENTO DELLA CONDIZIONE DI DISABILITÀ IN ETÀ EVOLUTIVA AI FINI DELL'INCLUSIONE SCOLASTICA
            setAccertamentoCondizioneDisabilitaEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.enabled ?? false)
            setAccertamentoCondizioneDisabilitaRivedibile(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.rivedibile ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataRilascio != null) {
                let accertamentoCondizioneDisabilitaDataRilascioTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataRilascio);
                setAccertamentoCondizioneDisabilitaDataRilascio(accertamentoCondizioneDisabilitaDataRilascioTmp)
            }
            if (result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataScadenza != null) {
                let accertamentoCondizioneDisabilitaDataScadenzaTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.accertamentoCondizioneDisabilita?.dataScadenza);
                setAccertamentoCondizioneDisabilitaDataScadenza(accertamentoCondizioneDisabilitaDataScadenzaTmp)
            }

            //CERTIFICAZIONE DI DISABILITÀ AI SENSI DELLA LEGGE 104/92
            setCertificazioneDisabilita104Enabled(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.enabled ?? false)
            setCertificazioneDisabilita104Rivedibile(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.rivedibile ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataRilascio != null) {

                let certificazioneDisabilitaDataRilascioTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataRilascio ?? null);
                setCertificazioneDisabilita104DataRilascio(certificazioneDisabilitaDataRilascioTmp)
            }
            if (result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataScadenza != null) {
                let certificazioneDisabilitaDataScadenzaTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.dataScadenza ?? null);
                setCertificazioneDisabilita104DataScadenza(certificazioneDisabilitaDataScadenzaTmp)
            }


            setDiagnosiPrimaria(result?.BESData?.documentazioneConsegnataBes?.diagnosiPrimaria ?? "")
            setDiagnosiSecondaria(result?.BESData?.documentazioneConsegnataBes?.diagnosiSecondaria ?? "")

            setCertificazioneDisabilita104Tipologia(result?.BESData?.documentazioneConsegnataInvalidita?.certificazioneDisabilitaLegge104?.tipologia ?? null)

            //CIS
            setCisEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.cis?.enabled ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.cis?.data != null) {
                let cisDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.cis?.data);
                setCisData(cisDataTmp)
            }
            //289
            setVerbaleLegge289Enabled(result?.BESData?.documentazioneConsegnataInvalidita?.verbaleLegge289?.enabled ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.verbaleLegge289?.data != null) {
                let legge289DataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.verbaleLegge289?.data);
                setverbaleLegge289Data(legge289DataTmp)
            }
            //114
            setattestazioneLegge114Enabled(result?.BESData?.documentazioneConsegnataInvalidita?.attestazioneLegge114?.enabled ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.attestazioneLegge114?.data != null) {
                let legge114DataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.attestazioneLegge114?.data);
                setattestazioneLegge114Data(legge114DataTmp)
            }

            //PROFILO DI FUNZIONAMENTO
            setProfiloFunzionamentoEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.profiloFunzionamento?.enabled ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.profiloFunzionamento?.data != null) {
                let profiloFunzionamentoDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.profiloFunzionamento?.data);
                setProfiloFunzionamentoData(profiloFunzionamentoDataTmp)
            }
            //DIAGNOSI FUNZIONALE
            setDiagnosiFunzionaleEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.diagnosiFunzionale?.enabled ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.diagnosiFunzionale?.data != null) {
                let diagnosiFunzionaleDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.diagnosiFunzionale?.data);
                setDiagnosiFunzionaleData(diagnosiFunzionaleDataTmp)
            }
            //PROFILO DINAMICO FUNZIONALE
            setProfiloDinamicoFunzionaleEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.profiloDinamicoFunzionale?.enabled ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.profiloDinamicoFunzionale?.data != null) {
                let profiloDinamicoDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.profiloDinamicoFunzionale?.data);
                setProfiloDinamicoFunzionaleData(profiloDinamicoDataTmp)
            }
            //PROGETTO INDIVIDUALE
            setProgettoIndividualeEnabled(result?.BESData?.documentazioneConsegnataInvalidita?.progettoIndividuale?.enabled ?? false)
            if (result?.BESData?.documentazioneConsegnataInvalidita?.progettoIndividuale?.data != null) {
                let progettoIndividualeDataTmp = DateTime.fromISO(result?.BESData?.documentazioneConsegnataInvalidita?.progettoIndividuale?.data);
                setProgettoIndividualeData(progettoIndividualeDataTmp)
            }
            setTipoRelazioneCertificazioniSanitarie(result?.BESData?.documentazioneConsegnataBes?.tipoRelazioneCertificazioniSanitarie ?? null)
            setAltraCertificazioneSanitaria(result?.BESData?.documentazioneConsegnataBes?.altraDocumentazione ?? null)
            setTipoDocumentazionePDP(result?.BESData?.documentazioneConsegnataBes?.documentazionePDP?.tipologia ?? "")
            if (result?.BESData?.documentazioneConsegnataBes?.documentazionePDP?.data != null) {
                let dataPDP = DateTime.fromISO(result?.BESData?.documentazioneConsegnataBes?.documentazionePDP?.data);
                setDataDocumentazionePDP(dataPDP)
            }
            setInteventoAttivato(result?.BESData?.documentazioneConsegnataBes?.interventoAttivo ?? "0")

            setOperatori(result?.BESData?.operatori?.operatori ?? []);
            let oreTmp = 0;
            let operatoriTmp = result?.BESData?.operatori?.operatori ?? [];
            for (let operatore of operatoriTmp) {
                oreTmp += operatore.ore;
            }
            setOreASAllievo(oreTmp);

        } else {
            setShowDeleteBesButton(false)
        }
        setHasLoaded(true)

    }

    async function visualizzaBesDocente(idCorso) {

        let dataObj = {
            idCorso: idCorso,
            pin: pin
        }
        if (idCorso !== -1) {
            BesAPI.visualizzaBesDocenti(dataObj)
                .then((result) => {

                })
                .catch((error) => {

                })
        }
    }

    async function getBesList(annoFormativo) {

        let dataObj = {
            annoFormativo: annoFormativo
        }

        BesAPI.getBesList()
            .then((result) => {
                setCertificazioneDisabilita104ListTipologie([...result?.certificazioneDisabilitaLegge104]);
                setListRelazioneCertificazioniSanitarie([...result?.tipoRelazioneCertificazioniSanitarie])
                setListDocumentazionePDP([...result?.documentazionePDP])
            })
            .catch((error) => { })


        BesAPI.getElencoOperatori({ annoFormativo: annoFormativo })
            .then((result) => {
                setOperatoreSelezionabili([...result?.operatori])
            })
            .catch((error) => {

            })
    }


    async function insertBes(idStudente) {

        let accertamentoCondizioneDisabilitaEnabledTmp = accertamentoCondizioneDisabilitaDataRilascio != null ? accertamentoCondizioneDisabilitaEnabled : false;
        let certificazioneDisabilita104EnabledTmp = certificazioneDisabilita104DataRilascio != null && certificazioneDisabilita104Tipologia != null ? certificazioneDisabilita104Enabled : false;
        let attestazioneLegge114EnabledTmp = attestazioneLegge114Data != null ? attestazioneLegge114Enabled : false;
        let cisEnabledTmp = cisData != null ? cisEnabled : false;
        let verbaleLegge289EnabledTmp = verbaleLegge289Data != null ? verbaleLegge289Enabled : false;
        let profiloFunzionamentoEnabledTmp = profiloFunzionamentoData != null ? profiloDinamicoFunzionaleEnabled : false;
        let diagnosiFunzionaleEnabledTmp = diagnosiFunzionaleData != null ? diagnosiFunzionaleEnabled : false;
        let profiloDinamicoEnabledTmp = profiloDinamicoFunzionaleData != null ? profiloDinamicoFunzionaleEnabled : false;
        let progettoIndividualeEnabledTmp = progettoIndividualeData != null ? progettoIndividualeEnabled : false;
        const objBes = {
            documentazioneConsegnataInvalidita: {
                accertamentoCondizioneDisabilita: {
                    enabled: accertamentoCondizioneDisabilitaEnabledTmp ?? false,
                    dataRilascio: accertamentoCondizioneDisabilitaEnabledTmp && accertamentoCondizioneDisabilitaDataRilascio != null ? convertDataGetProssimeLezione(accertamentoCondizioneDisabilitaDataRilascio, true) : null,
                    dataScadenza: accertamentoCondizioneDisabilitaEnabledTmp && accertamentoCondizioneDisabilitaDataScadenza != null ? convertDataGetProssimeLezione(accertamentoCondizioneDisabilitaDataScadenza, true) : null,
                    rivedibile: accertamentoCondizioneDisabilitaEnabledTmp && accertamentoCondizioneDisabilitaRivedibile ? accertamentoCondizioneDisabilitaRivedibile : false
                },
                certificazioneDisabilitaLegge104: {
                    enabled: certificazioneDisabilita104EnabledTmp,
                    dataRilascio: certificazioneDisabilita104EnabledTmp && certificazioneDisabilita104DataRilascio != null ? convertDataGetProssimeLezione(certificazioneDisabilita104DataRilascio, true) : null,
                    dataScadenza: certificazioneDisabilita104EnabledTmp && certificazioneDisabilita104DataScadenza != null ? convertDataGetProssimeLezione(certificazioneDisabilita104DataScadenza, true) : null,
                    rivedibile: certificazioneDisabilita104EnabledTmp && certificazioneDisabilita104Rivedibile ? certificazioneDisabilita104Rivedibile : false,
                    tipologia: certificazioneDisabilita104EnabledTmp && certificazioneDisabilita104Tipologia != null ? certificazioneDisabilita104Tipologia : null
                },
                cis: {
                    enabled: cisEnabledTmp ?? false,
                    data: cisEnabledTmp && cisData != null ? convertDataGetProssimeLezione(cisData, true) : null
                },
                verbaleLegge289: {
                    enabled: verbaleLegge289EnabledTmp ?? false,
                    data: verbaleLegge289EnabledTmp && verbaleLegge289Data != null ? convertDataGetProssimeLezione(verbaleLegge289Data, true) : null
                },
                attestazioneLegge114: {
                    enabled: attestazioneLegge114EnabledTmp ?? false,
                    data: attestazioneLegge114EnabledTmp && attestazioneLegge114Data != null ? convertDataGetProssimeLezione(attestazioneLegge114Data, true) : null
                },
                profiloFunzionamento: {
                    enabled: profiloFunzionamentoEnabledTmp ?? false,
                    data: profiloFunzionamentoEnabledTmp && profiloFunzionamentoData != null ? convertDataGetProssimeLezione(profiloFunzionamentoData, true) : null
                },
                diagnosiFunzionale: {
                    enabled: diagnosiFunzionaleEnabledTmp ?? false,
                    data: diagnosiFunzionaleEnabledTmp && diagnosiFunzionaleData != null ? convertDataGetProssimeLezione(diagnosiFunzionaleData, true) : null
                },
                profiloDinamicoFunzionale: {
                    enabled: profiloDinamicoEnabledTmp ?? false,
                    data: profiloDinamicoEnabledTmp && profiloDinamicoFunzionaleData != null ? convertDataGetProssimeLezione(profiloDinamicoFunzionaleData, true) : null
                },
                progettoIndividuale: {
                    enabled: progettoIndividualeEnabledTmp ?? false,
                    data: progettoIndividualeEnabledTmp && progettoIndividualeData != null ? convertDataGetProssimeLezione(progettoIndividualeData, true) : null
                }
            },
            documentazioneConsegnataBes: {
                tipoRelazioneCertificazioniSanitarie: tipoRelazioneCertificazioniSanitarie ?? null,
                documentazionePDP: {
                    tipologia: tipoDocumentazionePDP != "" ? tipoDocumentazionePDP : null,
                    data: dataDocumentazionePDP != null ? convertDataGetProssimeLezione(dataDocumentazionePDP, true) : null
                },
                altraDocumentazione: altraCertificazioneSanitaria ?? null,
                interventoAttivo: interventoAttivato,
                diagnosiPrimaria: diagnosiPrimaria ?? "",
                diagnosiSecondaria: diagnosiSecondaria ?? "",
            },

            operatori: {
                oreAsAllievo: oreASAllievo,
                operatori: operatori
            }
        }

        let dataObj = {
            idAlunno: idStudente,
            annoFormativo: annoFormativo,
            BESobj: objBes
        }

        if (idStudente !== -1) {
            BesAPI.insertBes(dataObj)
                .then((result) => {
                    resetBes();
                    visualizzaBesOperatori(idStudente);
                    setAlertSeverity("success")
                    setAlertMsg("Modifiche applicate")
                    setAlertOpen(true);
                    setIsModificaMode(false)
                    setConfirmEditDialogIsOpen(false)
                })
                .catch((error) => {
                    let errorMsg = "";
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                    setAlertSeverity("error")
                    setAlertMsg(errorMsg)
                    setAlertOpen(true);
                    setIsModificaMode(false)
                })
        }


    }


    function resetBes() {
        setAccertamentoCondizioneDisabilitaEnabled(false);
        setAccertamentoCondizioneDisabilitaDataRilascio(null)
        setAccertamentoCondizioneDisabilitaDataScadenza(null)
        setAccertamentoCondizioneDisabilitaRivedibile(false);

        setCertificazioneDisabilita104Enabled(false);
        setCertificazioneDisabilita104DataRilascio(null)
        setCertificazioneDisabilita104DataScadenza(null)
        setCertificazioneDisabilita104Rivedibile(false);
        setCertificazioneDisabilita104Tipologia(null);

        setCisEnabled(false)
        setCisData(null);

        setVerbaleLegge289Enabled(false);
        setverbaleLegge289Data(null)

        setattestazioneLegge114Enabled(false)
        setattestazioneLegge114Data(null)

        setProfiloFunzionamentoEnabled(false)
        setProfiloFunzionamentoData(null)

        setDiagnosiFunzionaleEnabled(false)
        setDiagnosiFunzionaleData(null)

        setProfiloDinamicoFunzionaleEnabled(false)
        setProfiloDinamicoFunzionaleData(null)

        setProgettoIndividualeEnabled(false);
        setProgettoIndividualeData(null)

        setTipoDocumentazionePDP(null)
        setDataDocumentazionePDP(null)
        setTipoRelazioneCertificazioniSanitarie(null)
        setInteventoAttivato("0");
        setOperatori([]);
        setOreASAllievo(0);

        setDiagnosiPrimaria("");
        setDiagnosiSecondaria("");
    }

    async function deleteBes() {
        let dataObj = {
            idAlunno: idStudente,
            annoFormativo: annoFormativo
        }

        if (idStudente !== -1) {
            BesAPI.deleteBes(dataObj)
                .then((result) => {
                    setDeleteBesDialogOpen(false)
                    visualizzaBesOperatori(idStudente);
                    setAlertSeverity("success")
                    setAlertMsg("Bes rimosso correttamente")
                    setAlertOpen(true);
                    setIsModificaMode(false)
                    resetBes();
                })
                .catch((error) => {
                    setDeleteBesDialogOpen(false)
                    setAlertSeverity("error")
                    setAlertMsg("Errore durante l'eliminazione del BES")
                    setAlertOpen(true);
                    setIsModificaMode(false)
                })
        }
    }



    function changeOreOperatore(e, index) {


        let num = parseFloat(e.target.value);
        if (isNaN(num)) {
            num = 0;
        } else {
            num = Math.abs(num);
        }

        let newStr = num;

        let operatoriTmp = operatori;

        operatoriTmp[index].ore = (newStr);

        let sommaOre = 0;
        for (let operatore of operatoriTmp) {
            sommaOre = sommaOre !== 0 ? sommaOre + parseInt(operatore.ore) : parseInt(operatore.ore);
        }

        setOperatori([...operatoriTmp]);
        setOreASAllievo(parseInt(sommaOre));
    }


    function changeNomeOperatore(e, index) {

        let operatoriTmp = operatori;

        operatoriTmp[index].nome = (e.target.value)

        setOperatori([...operatoriTmp]);
    }

    function changeOreASAllievo(e) {
        setOreASAllievo(e.target.value)
    }

    function annullaModifiche() {
        resetBes();
        setTimeout(() => {
            visualizzaBesOperatori(idStudente)

        }, 200)
        setIsModificaMode(false)
    }

    function confermaModifiche() {

        insertBes(idStudente);

    }

    function enableEditMode() {
        setIsModificaMode(true)
    }

    function addOperatore() {
        let idTmp = operatori.length;
        for (let operatore of operatori) {
            if (operatore.id == idTmp) {
                idTmp += 1;
            }
        }

        const newOperatore = {
            id: idTmp,
            nome: nomeAddOperatore,
            ore: parseInt(numeroOreAddOperatore)
        };
        operatori.push(newOperatore);

        setOreASAllievo(oreASAllievo + parseInt(numeroOreAddOperatore));
        setNomeAddOperatore('')
        setNumeroOreAddOperatore('')

    }


    function deleteOperatore(index, oreOperatore) {
        let operatoriTmp = operatori;
        operatoriTmp.splice(index, 1);
        setOperatori([...operatoriTmp]);
        setOreASAllievo(oreASAllievo - parseInt(oreOperatore))
    }


    function changeNomeAddOperatore(e) {
        setNomeAddOperatore(e.target.value)
    }

    function changeNumeroOreAddOperatore(e) {
        let num = parseFloat(e.target.value);
        if (isNaN(num)) {
            num = 0;
        } else {
            num = Math.abs(num);
        }
        setNumeroOreAddOperatore(num)
    }

    function changeDiagnosiPrimaria(e) {
        setDiagnosiPrimaria(e.target.value)
    }
    function changeDiagnosiSecondaria(e) {
        setDiagnosiSecondaria(e.target.value)
    }

    function isButtonDisabled() {
        if (interventoAttivato === "0") {
            if (diagnosiFunzionaleEnabled || profiloDinamicoFunzionaleEnabled || progettoIndividualeEnabled || profiloFunzionamentoEnabled || attestazioneLegge114Enabled || verbaleLegge289Enabled || cisEnabled || certificazioneDisabilita104Enabled || accertamentoCondizioneDisabilitaEnabled) {
                if (!accertamentoCondizioneDisabilitaEnabled || (accertamentoCondizioneDisabilitaEnabled && accertamentoCondizioneDisabilitaRivedibile != null && accertamentoCondizioneDisabilitaDataRilascio != null)) {
                    if (!certificazioneDisabilita104Enabled || (certificazioneDisabilita104Enabled && certificazioneDisabilita104Tipologia != null && certificazioneDisabilita104Rivedibile != null && certificazioneDisabilita104DataRilascio != null)) {
                        if (!cisEnabled || (cisEnabled && cisData != null)) {
                            if (!verbaleLegge289Enabled || (verbaleLegge289Enabled && verbaleLegge289Data != null)) {
                                if (!attestazioneLegge114Enabled || (attestazioneLegge114Enabled && attestazioneLegge114Data != null)) {
                                    if (!profiloFunzionamentoEnabled || (profiloFunzionamentoEnabled && profiloFunzionamentoData != null)) {
                                        if (!diagnosiFunzionaleEnabled || (diagnosiFunzionaleEnabled && diagnosiFunzionaleData != null)) {
                                            if (!profiloDinamicoFunzionaleEnabled || (profiloDinamicoFunzionaleEnabled && profiloDinamicoFunzionaleData != null)) {
                                                if (!progettoIndividualeEnabled || (progettoIndividualeEnabled && progettoIndividualeData != null)) {
                                                    return false;
                                                } else return true;
                                            } else return true;
                                        } else return true;
                                    } else return true;
                                } else return true;
                            } else return true;
                        } else return true;
                    } else return true
                } else return true;
            } else return false;
        } else return false;
    }


    function isButtonDisabledPDPSACampiAbilitati() {
        if (interventoAttivato === "1") {
            if (!accertamentoCondizioneDisabilitaEnabled || (accertamentoCondizioneDisabilitaEnabled && accertamentoCondizioneDisabilitaRivedibile != null && accertamentoCondizioneDisabilitaDataRilascio != null)) {
                if (!certificazioneDisabilita104Enabled || (certificazioneDisabilita104Enabled && certificazioneDisabilita104Tipologia != null && certificazioneDisabilita104Rivedibile != null && certificazioneDisabilita104DataRilascio != null)) {
                    if (!cisEnabled || (cisEnabled && cisData != null)) {
                        if (!verbaleLegge289Enabled || (verbaleLegge289Enabled && verbaleLegge289Data != null)) {
                            if (!attestazioneLegge114Enabled || (attestazioneLegge114Enabled && attestazioneLegge114Data != null)) {
                                if (!profiloFunzionamentoEnabled || (profiloFunzionamentoEnabled && profiloFunzionamentoData != null)) {
                                    if (!diagnosiFunzionaleEnabled || (diagnosiFunzionaleEnabled && diagnosiFunzionaleData != null)) {
                                        if (!profiloDinamicoFunzionaleEnabled || (profiloDinamicoFunzionaleEnabled && profiloDinamicoFunzionaleData != null)) {
                                            if (!progettoIndividualeEnabled || (progettoIndividualeEnabled && progettoIndividualeData != null)) {
                                                return false;
                                            } else return true;
                                        } else return true;
                                    } else return true;
                                } else return true;
                            } else return true;
                        } else return true;
                    } else return true;
                } else return true
            } else return true;
        } else return false;
    }


    function isButtonDisabledPDPDSA() {
        if (interventoAttivato === '1' && tipoRelazioneCertificazioniSanitarie === '' && diagnosiPrimaria === '' && diagnosiSecondaria === '' && (tipoDocumentazionePDP === "" || dataDocumentazionePDP == null)) {
            return true;
        } else return false
    }

    function isButtonDisabled104() {
        if ((interventoAttivato === "4" || interventoAttivato === "3" || interventoAttivato === "2") && !certificazioneDisabilita104Enabled) {
            return true
        } else return false
    }

    function isButtonDisabled104CampiAbilitati() {
        if ((interventoAttivato === "4" || interventoAttivato === "3" || interventoAttivato === "2") && certificazioneDisabilita104Enabled) {
            if (!accertamentoCondizioneDisabilitaEnabled || (accertamentoCondizioneDisabilitaEnabled && accertamentoCondizioneDisabilitaRivedibile != null && accertamentoCondizioneDisabilitaDataRilascio != null)) {
                if (!certificazioneDisabilita104Enabled || (certificazioneDisabilita104Enabled && certificazioneDisabilita104Tipologia != null && certificazioneDisabilita104Rivedibile != null && certificazioneDisabilita104DataRilascio != null)) {
                    if (!cisEnabled || (cisEnabled && cisData != null)) {
                        if (!verbaleLegge289Enabled || (verbaleLegge289Enabled && verbaleLegge289Data != null)) {
                            if (!attestazioneLegge114Enabled || (attestazioneLegge114Enabled && attestazioneLegge114Data != null)) {
                                if (!profiloFunzionamentoEnabled || (profiloFunzionamentoEnabled && profiloFunzionamentoData != null)) {
                                    if (!diagnosiFunzionaleEnabled || (diagnosiFunzionaleEnabled && diagnosiFunzionaleData != null)) {
                                        if (!profiloDinamicoFunzionaleEnabled || (profiloDinamicoFunzionaleEnabled && profiloDinamicoFunzionaleData != null)) {
                                            if (!progettoIndividualeEnabled || (progettoIndividualeEnabled && progettoIndividualeData != null)) {
                                                return false;
                                            } else return true;
                                        } else return true;
                                    } else return true;
                                } else return true;
                            } else return true;
                        } else return true;
                    } else return true;
                } else return true
            } else return true;
        } else return false;
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>

                    {comesFromTracciato && (
                        <Fragment>
                            <Typography color="primary" sx={{ mb: 1 }} variant="h4">{showDeleteBesButton ? "MODIFICA BES" : "AGGIUNGI BES"}</Typography>

                            <Typography variant="h6" >DOCUMENTAZIONE CONSEGNATA DALLA FAMIGLIA DELL'ALLIEVO/A CON DISABILITÀ</Typography>

                            {/* {(interventoAttivato !== "0" && isModificaMode && !diagnosiFunzionaleEnabled && !profiloDinamicoFunzionaleEnabled && !progettoIndividualeEnabled && !profiloFunzionamentoEnabled && !attestazioneLegge114Enabled && !verbaleLegge289Enabled && !cisEnabled && !certificazioneDisabilita104Enabled && !accertamentoCondizioneDisabilitaEnabled) && (
                                <Alert sx={{ mt: 1, display: "flex", alignItems: "center" }} severity="error">Per poter proseguire con il salvataggio, è necessario selezionare almeno un'opzione di questa sezione.</Alert>
                            )} */}

                            {/*ACCERTAMENTO DELLA CONDIZIONE DI DISABILITÀ IN ETÀ EVOLUTIVA AI FINI DELL'INCLUSIONE SCOLASTICA*/}
                            <Grid sx={{ border: (accertamentoCondizioneDisabilitaEnabled && (accertamentoCondizioneDisabilitaRivedibile == null || accertamentoCondizioneDisabilitaDataRilascio == null)) ? '1px solid red' : '0px', borderRadius: "5px" }} container rowSpacing={1} columnSpacing={2} mt={1}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeCondizioneDisabilitaEnabled} disabled={!isModificaMode} checked={accertamentoCondizioneDisabilitaEnabled} />} label={"ACCERTAMENTO DELLA CONDIZIONE DI DISABILITÀ IN ETÀ EVOLUTIVA AI FINI DELL'INCLUSIONE SCOLASTICA"} />
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!accertamentoCondizioneDisabilitaEnabled || !isModificaMode}
                                            label="Data rilascio"
                                            value={accertamentoCondizioneDisabilitaDataRilascio}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeCondizioneDisabilitaDataRilascio(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!accertamentoCondizioneDisabilitaEnabled || !isModificaMode || accertamentoCondizioneDisabilitaDataRilascio == null}
                                            label="Data scadenza"
                                            minDate={accertamentoCondizioneDisabilitaDataRilascio != null ? accertamentoCondizioneDisabilitaDataRilascio.plus({ days: 1 }) : null}
                                            value={accertamentoCondizioneDisabilitaDataScadenza}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeCondizioneDisabilitaDataScadenza(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <FormControl>
                                        <RadioGroup row value={accertamentoCondizioneDisabilitaRivedibile} onChange={changeCondizioneDisabilitaRivedibile}>
                                            <FormControlLabel value="true" control={<Radio disabled={!accertamentoCondizioneDisabilitaEnabled || !isModificaMode} />} label="Rivedibile" />
                                            <FormControlLabel value="false" control={<Radio disabled={!accertamentoCondizioneDisabilitaEnabled || !isModificaMode} />} label="Non rivedibile" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {(accertamentoCondizioneDisabilitaEnabled && (accertamentoCondizioneDisabilitaRivedibile == null || accertamentoCondizioneDisabilitaDataRilascio == null)) &&
                                    (
                                        <Grid xs={12}>
                                            <Typography variant="subtitle2" color="error">È necessario compilare la <b>data di rilascio</b>  per poter salvare l'informazione.</Typography>
                                        </Grid>
                                    )}
                            </Grid>
                            {/*CERTIFICAZIONE DI DISABILITÀ AI SENSI DELLA LEGGE 104/92*/}
                            <Grid sx={{ border: (certificazioneDisabilita104Enabled && (certificazioneDisabilita104Rivedibile == null || certificazioneDisabilita104Tipologia === "" || certificazioneDisabilita104Tipologia == null || certificazioneDisabilita104DataRilascio == null)) ? '1px solid red' : '0px', borderRadius: "5px" }} container rowSpacing={1} columnSpacing={2} mt={1}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeCertificazioneDisabilita104Enabled} disabled={!isModificaMode} checked={certificazioneDisabilita104Enabled} />} label={"CERTIFICAZIONE DI DISABILITÀ AI SENSI DELLA LEGGE 104/92"} />
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!certificazioneDisabilita104Enabled || !isModificaMode}
                                            label="Data rilascio"
                                            value={certificazioneDisabilita104DataRilascio}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeCertificazioneDisabilita104DataRilascio(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!certificazioneDisabilita104Enabled || !isModificaMode || certificazioneDisabilita104DataRilascio == null}
                                            label="Data scadenza"
                                            minDate={certificazioneDisabilita104DataRilascio != null ? certificazioneDisabilita104DataRilascio.plus({ days: 1 }) : null}

                                            value={certificazioneDisabilita104DataScadenza}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeCertificazioneDisabilita104DataScadenza(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="materia-label">Tipologia </InputLabel>
                                        <Select label='Tipologia'
                                            value={certificazioneDisabilita104Tipologia}
                                            onChange={changeCertificazioneDisabilita104Tipologia}
                                            disabled={certificazioneDisabilita104ListTipologie.length === 0 || !certificazioneDisabilita104Enabled || !isModificaMode}
                                        >
                                            <MenuItem key={"0"} value={null}>
                                            </MenuItem>
                                            {certificazioneDisabilita104ListTipologie.map((tipologia) => (
                                                <MenuItem key={tipologia} value={tipologia} >
                                                    {tipologia}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <FormControl>
                                        <RadioGroup row value={certificazioneDisabilita104Rivedibile} onChange={changeCertificazioneDisabilita104Rivedibile}>
                                            <FormControlLabel value="true" control={<Radio disabled={!certificazioneDisabilita104Enabled || !isModificaMode} />} label="Rivedibile" />
                                            <FormControlLabel value="false" control={<Radio disabled={!certificazioneDisabilita104Enabled || !isModificaMode} />} label="Non rivedibile" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {(certificazioneDisabilita104Enabled && (certificazioneDisabilita104Rivedibile == null || certificazioneDisabilita104Tipologia === "" || certificazioneDisabilita104Tipologia == null || certificazioneDisabilita104DataRilascio == null)) &&
                                    (
                                        <Grid xs={12}>
                                            <Typography variant="subtitle2" color="error">È necessario compilare la <b>data di rilascio</b> e la <b>tipologia</b> per poter salvare l'informazione.</Typography>
                                        </Grid>
                                    )}
                            </Grid>
                            {/*CIS*/}
                            <Grid container rowSpacing={1} columnSpacing={2} mt={1} sx={{ border: (cisEnabled && cisData == null) ? '1px solid red' : '0px', borderRadius: "5px" }}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox disabled={!isModificaMode} onChange={changeCisEnabled} checked={cisEnabled} />} label={"CERTIFICATO PER L'INTEGRAZIONE SCOLASTICA (CIS)"} />
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!cisEnabled || !isModificaMode}
                                            label="Data"
                                            value={cisData}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeCisData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                {(cisEnabled && cisData == null) &&
                                    (
                                        <Grid xs={12}>
                                            <Typography variant="subtitle2" color="error">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                        </Grid>
                                    )}
                            </Grid>
                            {/*VERBALE 289/90*/}
                            <Grid container rowSpacing={1} columnSpacing={2} mt={1} sx={{ border: (verbaleLegge289Enabled && verbaleLegge289Data == null) ? '1px solid red' : '0px', borderRadius: "5px" }}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeLegge289Enabled} disabled={!isModificaMode} checked={verbaleLegge289Enabled} />} label={"VERBALE DI INVALIDITÀ LEGGE 289/90"} />
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!verbaleLegge289Enabled || !isModificaMode}
                                            label="Data"
                                            value={verbaleLegge289Data}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeLegge289Data(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                {(verbaleLegge289Enabled && verbaleLegge289Data == null) &&
                                    (
                                        <Grid xs={12}>
                                            <Typography variant="subtitle2" color="error">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                        </Grid>
                                    )}
                            </Grid>
                            {/*VERBALE 114/2014*/}
                            <Grid container rowSpacing={1} columnSpacing={2} mt={1} sx={{ border: (attestazioneLegge114Enabled && attestazioneLegge114Data == null) ? '1px solid red' : '0px', borderRadius: "5px" }}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeLegge114Enabled} disabled={!isModificaMode} checked={attestazioneLegge114Enabled} />} label={"ATTESTAZIONE LEGGE 114/2014"} />

                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!attestazioneLegge114Enabled || !isModificaMode}
                                            label="Data"
                                            value={attestazioneLegge114Data}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeLegge114Data(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                {(attestazioneLegge114Enabled && attestazioneLegge114Data == null) &&
                                    (
                                        <Grid xs={12}>
                                            <Typography variant="subtitle2" color="error">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                        </Grid>
                                    )}
                            </Grid>
                            {/*PROFILO FUNZIONAMENTO*/}
                            <Grid container rowSpacing={1} columnSpacing={2} mt={1} sx={{ border: (profiloFunzionamentoEnabled && profiloFunzionamentoData == null) ? '1px solid red' : '0px', borderRadius: "5px" }}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeProfiloFunzionamentoEnabled} disabled={!isModificaMode} checked={profiloFunzionamentoEnabled} />} label={"PROFILO DI FUNZIONAMENTO"} />

                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!profiloFunzionamentoEnabled || !isModificaMode}
                                            label="Data"
                                            value={profiloFunzionamentoData}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeProfiloFunzionamentoData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                {(profiloFunzionamentoEnabled && profiloFunzionamentoData == null) &&
                                    (
                                        <Grid xs={12}>
                                            <Typography variant="subtitle2" color="error">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                        </Grid>
                                    )}
                            </Grid>

                            {/*DIAGNOSI FUNZIONALE*/}
                            <Grid container rowSpacing={1} columnSpacing={2} mt={1} sx={{ border: (diagnosiFunzionaleEnabled && diagnosiFunzionaleData == null) ? '1px solid red' : '0px', borderRadius: "5px" }}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeDiagnosiFunzionaleEnabled} disabled={!isModificaMode} checked={diagnosiFunzionaleEnabled} />} label={"DIAGNOSI FUNZIONALE"} />
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!diagnosiFunzionaleEnabled || !isModificaMode}
                                            label="Data"
                                            value={diagnosiFunzionaleData}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeDiagnosiFunzionaleData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                {(diagnosiFunzionaleEnabled && diagnosiFunzionaleData == null) && (
                                    <Grid xs={12}>
                                        <Typography variant="subtitle2" color="error">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                    </Grid>
                                )}
                            </Grid>

                            {/*PROFILO DINAMICO FUNZIONALE IN VIGORE*/}
                            <Grid container rowSpacing={1} columnSpacing={2} mt={1} sx={{ border: (profiloDinamicoFunzionaleEnabled && profiloDinamicoFunzionaleData == null) ? '1px solid red' : '0px', borderRadius: "5px" }}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeProfiloDinamicoFunzionaleEnabled} disabled={!isModificaMode} checked={profiloDinamicoFunzionaleEnabled} />} label={"PROFILO DINAMICO FUNZIONALE IN VIGORE"} />
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!profiloDinamicoFunzionaleEnabled || !isModificaMode}
                                            label="Data"
                                            value={profiloDinamicoFunzionaleData}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeProfiloDinamicoFunzionaleData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                {(profiloDinamicoFunzionaleEnabled && profiloDinamicoFunzionaleData == null) &&
                                    <Grid xs={12}>
                                        <Typography variant="subtitle2" color="error">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                    </Grid>
                                }
                            </Grid>

                            {/*PROGETTO INDIVIDUALE*/}
                            <Grid container rowSpacing={1} columnSpacing={2} mt={1} sx={{ border: (progettoIndividualeEnabled && progettoIndividualeData == null) ? '1px solid red' : '0px', borderRadius: "5px" }}>
                                <Grid xs={12}>
                                    <FormControlLabel control={<Checkbox onChange={changeProgettoIndividualeEnabled} disabled={!isModificaMode} checked={progettoIndividualeEnabled} />} label={"PROGETTO INDIVIDUALE"} />
                                </Grid>
                                <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                            disabled={!progettoIndividualeEnabled || !isModificaMode}
                                            label="Redatto in data"
                                            value={progettoIndividualeData}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeProgettoIndividualeData(value)}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                {(progettoIndividualeEnabled && progettoIndividualeData == null) &&
                                    <Grid xs={12}>
                                        <Typography variant="subtitle2" color="error">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                    </Grid>
                                }
                            </Grid>
                            <Divider sx={{ mt: 5 }}></Divider>

                            <Box sx={{ p: isButtonDisabledPDPDSA() ? 2 : 0, borderRadius: "5px", border: isButtonDisabledPDPDSA() ? "1px solid red" : "0px" }}>
                                <Typography variant="h6" mt={5}>DOCUMENTAZIONE CONSEGNATA DALLA FAMIGLIA DELL' ALLIEVO/A BES</Typography>

                                {isButtonDisabledPDPDSA() && (
                                    <Alert sx={{ my: 1, display: "flex", alignItems: "center" }} severity="error">Per poter proseguire con il salvataggio, è necessario selezionare almeno un'opzione di questa sezione.</Alert>
                                )}

                                <Typography variant="body" mt={5}>CERTIFICAZIONI SANITARIE RELAZIONE CON DIAGNOSI ALTRA DOCUMENTAZIONE</Typography>
                                <Grid rowSpacing={1} columnSpacing={2} mt={1} mb={3}>
                                    <Grid xs={12} alignItems={"center"} display={"flex"}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                value={tipoRelazioneCertificazioniSanitarie}
                                                onChange={changeTipoRelazioneCertificazioniSanitarie}
                                                disabled={listRelazioneCertificazioniSanitarie.length === 0 || !isModificaMode}
                                            >
                                                <MenuItem key={"0"} value={""}>
                                                </MenuItem>
                                                {listRelazioneCertificazioniSanitarie.map((tipologia) => (
                                                    <MenuItem key={tipologia} value={tipologia} >
                                                        {tipologia}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {tipoRelazioneCertificazioniSanitarie === "Altra documentazione" && (
                                        <Box sx={{ mt: 1 }}>
                                            <Typography variant="body">ALTRA DOCUMENTAZIONE</Typography>
                                            <TextField sx={{ mt: 1 }} fullWidth disabled={!isModificaMode} onBlur={changeAltraCertificazione} defaultValue={altraCertificazioneSanitaria} />
                                        </Box>
                                    )}
                                </Grid>

                                <Typography variant="body" >DOCUMENTAZIONE CONSEGNATA PER ATTIVAZIONE PDP</Typography>
                                <Grid container rowSpacing={1} columnSpacing={2} mt={1} mb={3}>
                                    <Grid xs={12} md={8} alignItems={"center"} display={"flex"}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel >Tipologia </InputLabel>
                                            <Select
                                                label="Tipologia"
                                                value={tipoDocumentazionePDP}
                                                onChange={changeTipologiaPDP}
                                                disabled={listDocumentazionePDP.length === 0 || !isModificaMode}
                                            >
                                                <MenuItem key={"0"} value={""}>
                                                </MenuItem>
                                                {listDocumentazionePDP.map((tipologia) => (
                                                    <MenuItem key={tipologia} value={tipologia} >
                                                        {tipologia}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4} alignItems={"center"} display={"flex"}>
                                        <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                            <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                                disabled={!isModificaMode || tipoDocumentazionePDP === ""}
                                                label="Redatto/a in data"
                                                value={dataDocumentazionePDP}
                                                format="dd/MM/yyyy"
                                                onChange={(value) => changeDataPDP(value)}
                                                dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>


                            </Box>
                        </Fragment>
                    )}

                    <Typography variant="h6" mt={5}>DIAGNOSI PRIMARIA</Typography>
                    <TextField fullWidth multiline rows={3} value={diagnosiPrimaria} disabled={!isModificaMode} onChange={changeDiagnosiPrimaria} />
                    <Typography variant="h6" mt={2}>DIAGNOSI SECONDARIA</Typography>
                    <TextField fullWidth multiline rows={3} value={diagnosiSecondaria} disabled={!isModificaMode} onChange={changeDiagnosiSecondaria} />

                    <Typography variant="h6" mt={5}>INTERVENTO ATTIVATO</Typography>
                    <Grid xs={12} md={3} alignItems={"center"} display={"flex"}>
                        <FormControl>
                            <RadioGroup row value={interventoAttivato} onChange={changeInterventoAttivo}>
                                <FormControlLabel value="0" control={<Radio disabled={!isModificaMode} />} label="PDP/B E.S." />
                                <FormControlLabel value="1" control={<Radio disabled={!isModificaMode} />} label="PDP/D S.A." />
                                <FormControlLabel value="2" control={<Radio disabled={!isModificaMode || !certificazioneDisabilita104Enabled} />} label="P.E.I. ORDINARIO" />
                                <FormControlLabel value="3" control={<Radio disabled={!isModificaMode || !certificazioneDisabilita104Enabled} />} label="P.E.I. PERSONALIZZATO" />
                                <FormControlLabel value="4" control={<Radio disabled={!isModificaMode || !certificazioneDisabilita104Enabled} />} label="P.E.I. DIFFERENZIATO" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Divider sx={{ mt: 5 }}></Divider>
                    <Typography variant="h6" mt={2}>OPERATORE ASSISTENZA SPECIALISTICA</Typography>
                    <TextField sx={{ width: '25%', mt: 4 }} data-testid='oreASAlievoInput'
                        label="Ore A.S. Alievo"
                        value={oreASAllievo}
                        onChange={changeOreASAllievo}
                        disabled
                    />
                    <Box sx={{ mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center' }}>
                        <TableContainer sx={{ width: '100%', p: 2 }} component={Paper}>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Box sx={{ p: 2 }}>
                                                <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mr: 1 }}>ID</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Box className="spacebetween-direction" sx={{ p: 2 }}>
                                                <Typography sx={{ fontWeight: 'bold', mr: 1 }}>Cognome e nome</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ p: 0 }}>
                                            <Box className="spacebetween-direction" sx={{ p: 2 }}>
                                                <Typography sx={{ fontWeight: 'bold', mr: 1 }}>Ore</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ p: 0 }}>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {operatori.map((operatore, index) => (
                                        <TableRow key={index}  >
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', width: '50px' }}>
                                                <Typography textAlign={'center'}>{operatore.id}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {comesFromTracciato ? (
                                                    <Select fullWidth
                                                        value={operatore.nome}
                                                        onChange={(e) => changeNomeOperatore(e, index)}
                                                        disabled={!isModificaMode}
                                                    >
                                                        {/* <MenuItem key={""} value={""}>
                                            </MenuItem> */}
                                                        {operatoreSelezionabili.map((operatore) => (
                                                            <MenuItem key={operatore} value={operatore} >
                                                                {operatore}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    <Typography>{operatore?.nome}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth onChange={(e) => changeOreOperatore(e, index)}
                                                    placeholder="Scrivi qui..."
                                                    value={operatore?.ore}
                                                    disabled={!isModificaMode}
                                                />
                                            </TableCell>
                                            {isModificaMode && (
                                                <TableCell>
                                                    <Button fullWidth startIcon={<Delete />} variant="outlined" color="error" onClick={() => deleteOperatore(index, operatore?.ore)}>
                                                        Rimuovi  operatore
                                                    </Button>

                                                </TableCell>
                                            )}

                                        </TableRow>
                                    ))}
                                    {(isModificaMode && operatori.length < 4) && (<TableRow>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', width: '50px' }}>

                                        </TableCell>
                                        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Select fullWidth
                                                value={nomeAddOperatore}
                                                onChange={changeNomeAddOperatore}
                                                disabled={!isModificaMode}
                                            >
                                                {operatoreSelezionabili.map((operatore) => (
                                                    <MenuItem key={operatore} value={operatore} >
                                                        {operatore}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </TableCell>
                                        <TableCell>
                                            <TextField fullWidth
                                                onChange={changeNumeroOreAddOperatore}
                                                placeholder="Scrivi qui..." value={numeroOreAddOperatore} />
                                        </TableCell>
                                        <TableCell>
                                            <Button fullWidth startIcon={<AddCircle />} variant="contained" color="success" onClick={addOperatore} disabled={nomeAddOperatore === '' || numeroOreAddOperatore === ''}>
                                                Conferma aggiungi operatore
                                            </Button>
                                        </TableCell>
                                    </TableRow>)}

                                </TableBody>

                            </Table>
                        </TableContainer>

                    </Box>

                    {comesFromTracciato && permessiUtente.some(permesso => permesso.nome === "canEditBES" && permesso.value === true) ? (
                        <Grid container spacing={2} sx={{ width: '100%', py: 2, px: { xs: 0, md: 3 } }} justifyContent={"end"}>

                            {isModificaMode ? (
                                <Fragment>
                                    <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }} justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
                                        {showDeleteBesButton ? (
                                            <Button startIcon={<Delete />} sx={{ mr: { xs: 0, md: 1 }, width: { xs: '100%', md: 'fit-content' } }}
                                                onClick={() => { setDeleteBesDialogOpen(true) }}
                                                data-testid='modificaValutazioneBtn' color="error" variant="contained">
                                                Elimina BES
                                            </Button>
                                        ) : (<Box></Box>)}
                                        <Box>
                                            <Button onClick={annullaModifiche} sx={{ mr: { xs: 0, md: 1 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined" color="error">
                                                Annulla modifiche
                                            </Button>
                                            <Button disabled={(isButtonDisabled() || isButtonDisabled104CampiAbilitati() || isButtonDisabled104()) || (isButtonDisabledPDPDSA() || isButtonDisabledPDPSACampiAbilitati())} onClick={() => { setConfirmEditDialogIsOpen(true) }} sx={{ mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} data-testid='modificaValutazioneBtn' variant="contained">
                                                {showDeleteBesButton ? "Conferma modifiche" : "Conferma aggiunta bes"}
                                            </Button>
                                        </Box>
                                    </Box>
                                    {(isButtonDisabled104CampiAbilitati() || isButtonDisabledPDPSACampiAbilitati() || isButtonDisabled()) && (
                                        <Box display="flex" sx={{ mt: 2, flexDirection: { xs: 'column', md: 'row' } }} justifyContent={"end"} width={"100%"} alignItems={"center"}>
                                            <Typography textAlign={"end"} color="red">È necessario compilare tutti i campi per poter salvare l'informazione.</Typography>
                                        </Box>
                                    )}
                                    {isButtonDisabled104() && (
                                        <Box display="flex" sx={{ mt: 2, flexDirection: { xs: 'column', md: 'row' } }} justifyContent={"end"} width={"100%"} alignItems={"center"}>
                                            <Typography textAlign={"end"} color="red" sx={{ width: '100%' }}>Impossibile salvare un <b>intervento attivato di tipo P.E.I.</b> senza una <b>CERTIFICAZIONE DI DISABILITÀ AI SENSI DELLA LEGGE 104/92</b>.</Typography>
                                        </Box>
                                    )}
                                    {isButtonDisabledPDPDSA() && (
                                        <Box display="flex" sx={{ mt: 2, flexDirection: { xs: 'column', md: 'row' } }} justifyContent={"end"} width={"100%"} alignItems={"center"}>
                                            <Typography textAlign={"end"} color="red" sx={{ width: '100%' }}>Per poter continuare, è necessario inserire almeno un'opzione della sezione <b >"DOCUMENTAZIONE CONSEGNATA DALLA FAMIGLIA DELL' ALLIEVO/A BES"</b>.</Typography>
                                        </Box>
                                    )}
                                </Fragment>
                            ) : (
                                !isAnnoFormativoConcluso && (
                                    <Box display="flex" sx={{ width: { xs: '100%', md: 'fit-content' } }} justifyContent={"end"} alignItems={"center"}>
                                        {showDeleteBesButton && (

                                            <Button startIcon={<Delete />} sx={{ mr: { xs: 0, md: 1 }, width: { xs: '100%', md: 'fit-content' } }}
                                                onClick={() => { setDeleteBesDialogOpen(true) }}
                                                data-testid='modificaValutazioneBtn' color="error" variant="contained">
                                                Elimina BES
                                            </Button>
                                        )}
                                        <Button startIcon={<Edit />}
                                            onClick={enableEditMode}

                                            data-testid='modificaValutazioneBtn' variant="contained">
                                            {showDeleteBesButton ? "Modifica informazioni" : "Aggiungi informazioni"}
                                        </Button>

                                    </Box>
                                )
                            )}

                        </Grid>
                    ) : null}
                </Fragment>
            ) : (
                <Box flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress sx={{ mb: 4 }} />
                    <Typography >Caricamento in corso...</Typography>
                </Box>
            )}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <DialogConferma
                isDialogOpen={deleteBesDialogOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler procedere con l'eliminazione dei dati di ${alunno}? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setDeleteBesDialogOpen(false) }}
                conferma={deleteBes}
                disabledButton={false}
            />

            <DialogConferma
                isDialogOpen={confirmEditDialogIsOpen}
                title={showDeleteBesButton ? "Conferma modifica" : "Conferma aggiunta bes"}
                icon={showDeleteBesButton ? <Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" /> : <Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={showDeleteBesButton ? `Confermi di voler modificare i dati di ${alunno}? Una volta confermato, non potrai annullare l'operazione.` : `Confermi aggiunta bes per l'alunno ${alunno}?`}
                showAlunni={false}
                confermaButton={showDeleteBesButton ? 'Conferma modifica bes' : 'Conferma aggiunta bes'}
                severity={showDeleteBesButton ? 'warning' : 'success'}
                closeDialog={() => { setConfirmEditDialogIsOpen(false) }}
                conferma={confermaModifiche}
                disabledButton={false}
            />
            <Helmet>
                <title>{t("name")} - {t("pages.bes.title")}</title>
            </Helmet>
        </Fragment >
    )
}