import { convertDataDiNascita } from "../../../../components/shared/metodiDateUtility";
import { changeColorVoto } from "../../../../components/shared/utilityMethods";
import { Paper, Typography, Grid, Box, Divider, Button, ToggleButtonGroup, ToggleButton, Alert, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEvent, Fragment, RefObject, useEffect, useRef, useState } from "react";
import { returnVotoConverted } from "./utilityFunctionsScrutini";
import { AlunnoScrutinio, Materia, VotoObj } from "./scrutini.types";

export default function InserimentoVotiAlunno(props: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const votiScrutinioRef = useRef<any[]>([])
    const votoCondottaMotRef = useRef<any>(null)
    const votoCondottaIntRef = useRef<any>(null)
    const votoCondottaRelRef = useRef<any>(null)
    const provaDiPassaggioRef = useRef<any>(null)

    const [canCalculateCrediti, setCanCalculateCrediti] = useState(true);
    const [provaDiPassaggioInput, setProvaDiPassaggioInput] = useState<number | null>(-1);
    const [ordinaleUltimoScrutinioPassato, setOrdinaleUltimoScrutinioPassato] = useState(0);

    useEffect(() => {
        //Prendo la prima materia della lista per capire se ci sono votiScrutiniPassati
        if (props.listMaterie && props.listMaterie.length > 0) {
            setOrdinaleUltimoScrutinioPassato(props.listMaterie[0].votiScrutiniPassati.length)
        } else setOrdinaleUltimoScrutinioPassato(0);
    }, [props.listMaterie])

    useEffect(() => {

    }, [props.condottaObj, props.periodiPassati])

    useEffect(() => {
        if (props.votiScrutinio) {
            for (let i = 0; i < props.votiScrutinio.length; i++) {
                votiScrutinioRef.current[i].value = returnVotoConverted(props.votiScrutinio[i].voto, false);
            }
        }
    }, [props.votiScrutinio])

    useEffect(() => {
        if (provaDiPassaggioRef?.current?.value !== undefined) {
            if (props.provaDiPassaggio == -1)
                provaDiPassaggioRef.current.value = "/"
            if (props.provaDiPassaggio == 0)
                provaDiPassaggioRef.current.value = "N.V."
            if (props.provaDiPassaggio != -1 && props.provaDiPassaggio != 0)
                provaDiPassaggioRef.current.value = props.provaDiPassaggio ?? "/"
        }
    }, [props.provaDiPassaggio])

    useEffect(() => {
        if (votoCondottaMotRef.current !== undefined) {
            votoCondottaMotRef.current.value = returnVotoConverted(props.condottaScrutinioMotivazione);
        }

        if (votoCondottaIntRef.current !== undefined) {
            votoCondottaIntRef.current.value = returnVotoConverted(props.condottaScrutinioInterazione);
        }

        if (votoCondottaRelRef.current !== undefined) {
            votoCondottaRelRef.current.value = returnVotoConverted(props.condottaScrutinioRelazione);
        }
    }, [props.condottaScrutinioInterazione, props.condottaScrutinioMotivazione, props.condottaScrutinioRelazione])

    useEffect(() => {
        if (props.canCalculateCrediti !== undefined)
            setCanCalculateCrediti(props.canCalculateCrediti)
    }, [props.canCalculateCrediti])

    useEffect(() => {
        setProvaDiPassaggioInput(props.provaDiPassaggioInput)
    }, [props.provaDiPassaggioInput])

    function handleVotoChange(e: ChangeEvent<HTMLInputElement>, ref: RefObject<HTMLInputElement> | HTMLInputElement[], index?: number, afterChange?: () => void) {
        let newValue = e.target.value;
        let newStr = "";

        // Se "N.V" => reset
        if (newValue === "N.V") {
            newStr = "";
        }
        // Se "/" => lo accettiamo
        else if (newValue === "/") {
            newStr = "/";
        }
        // Se contiene "n" o "N" => trasformiamo in "N.V."
        else if (/[nN]/.test(newValue)) {
            newStr = "N.V.";
        }
        // Se contiene numeri
        else if (/[0-9]/.test(newValue)) {
            let numeriPuliti = newValue.replace(/[^0-9]/g, ""); // Rimuove lettere e simboli
            newStr = numeriPuliti.replace(/^0+(?=\d)/, "");     // Elimina zeri iniziali

            if (parseInt(newStr) > 30) newStr = "30";           // Limite massimo
            if (parseInt(newStr) === 0) newStr = "N.V.";        // Zero non valido
        }

        // Aggiorna il ref corretto
        if (Array.isArray(ref)) {
            if (index !== undefined && ref[index]) {
                ref[index].value = newStr;
            }
        } else if (ref.current) {
            ref.current.value = newStr;
        }

        // Esegue callback (es: setIsButtonConfermaDisabled)
        if (afterChange) afterChange();
    }

    function checkIfButtonIsDisabled() {
        if (votiScrutinioRef.current.some(r => r.value == "") || votiScrutinioRef.current.some(r => r.value == "-") ||
            (votoCondottaMotRef?.current?.value == "" || votoCondottaMotRef?.current?.value == "-") ||
            (votoCondottaIntRef?.current?.value == "" || votoCondottaIntRef?.current?.value == "-") ||
            (votoCondottaRelRef?.current?.value == "" || votoCondottaRelRef?.current?.value == "-")) return true
        else return false
    }

    function returnHeadLegenda() { //Per ritornare l'head con primo periodo, secondo periodo...
        return (
            <Fragment>
                {ordinaleUltimoScrutinioPassato > 0 && (
                    <Box sx={{ px: 1, display: "flex", justifyContent: "end", gap: 1 }}>
                        {Array.from({ length: ordinaleUltimoScrutinioPassato }).map((_, index) => (
                            <Box key={index} sx={{ textAlign: "center", width: "50px" }} >
                                <Typography variant="subtitle2">{index + 1}°</Typography>
                            </Box>
                        ))}
                        <Box sx={{ textAlign: "center", width: "50px" }} > {/*Voto proposto */}</Box>
                        <Box sx={{ textAlign: "center", width: "50px" }} > {/*Voto scrutinio */} </Box>
                    </Box>
                )}
            </Fragment>
        )
    }

    return (
        <Fragment>
            {!props.votiScrutiniHasErrors ? (
                <Fragment>
                    <Paper sx={{ mb: 2, p: 2 }}>
                        <Typography variant='h6'>
                            <b>Disciplina</b>
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                {returnHeadLegenda()}
                                {props.listMaterie.map((materia, index) => (
                                    ((!isTablet && index <= props.firstColLimit) || isMobile || (isTablet && index < Math.round(props.listMaterie.length / 2))) && (
                                        <Fragment>
                                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }} key={materia?.id + index + materia?.nome}>
                                                <Box sx={{ p: 0 }}>
                                                    <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                        <b>{materia?.nome}</b>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {materia.docente}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                    <Box sx={{ p: 1, display: "flex", justifyContent: 'center', gap: 1 }}>
                                                        {materia.votiScrutiniPassati && materia.votiScrutiniPassati.length > 0 && (
                                                            materia.votiScrutiniPassati.map((votoPassato) => (
                                                                <Box className="fakeInputBox"  >
                                                                    <Typography key={materia.id + votoPassato.idPeriodo} sx={{ color: changeColorVoto(votoPassato?.voto) }}>
                                                                        {returnVotoConverted(votoPassato?.voto)}
                                                                    </Typography>
                                                                </Box>
                                                            ))
                                                        )}
                                                        <Box className="fakeInputBox"  >
                                                            <Typography sx={{ color: changeColorVoto(props.votiProposti[index]?.voto) }}>
                                                                {returnVotoConverted(props.votiProposti[index]?.voto)}
                                                            </Typography>
                                                        </Box>

                                                        <input
                                                            className="inputEdit"
                                                            ref={el => votiScrutinioRef.current[index] = el}
                                                            defaultValue={returnVotoConverted(props.votiScrutinio[index]?.voto ?? null)}
                                                            style={{
                                                                width: '50px',
                                                                color: changeColorVoto(votiScrutinioRef?.current[index]?.value ?? null)
                                                            }}
                                                            onChange={(e) =>
                                                                handleVotoChange(e, votiScrutinioRef.current, index,
                                                                    () => {
                                                                        const isButtonDisabled = checkIfButtonIsDisabled()
                                                                        props.changeButtonConfirmDisabled(isButtonDisabled);
                                                                        if (!canCalculateCrediti) setCanCalculateCrediti(true);
                                                                    }
                                                                )
                                                            }
                                                            onBlur={(e) => props.onBlurVotoScrutinio(e, index)}
                                                        />
                                                    </Box>

                                                </Box>
                                            </Box>
                                            <Divider />
                                        </Fragment>
                                    )
                                ))}
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} style={{ borderLeft: '1px solid #e0e0e0', borderRight: '1px solid #e0e0e0' }}>
                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                    {returnHeadLegenda()}
                                </Box>
                                {props.listMaterie.map((materia, index) => (
                                    ((!isMobile && isTablet && index >= Math.round(props.listMaterie.length / 2)) || (!isMobile && !isTablet && index > props.firstColLimit && index <= props.secondColLimit)) && (
                                        <Fragment>
                                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }} key={materia?.id + index + materia?.nome}>
                                                <Box sx={{ p: 0 }}>
                                                    <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                        <b>{materia?.nome}</b>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {materia.docente}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                    <Box sx={{ p: 1, display: "flex", justifyContent: 'center', gap: 1 }}>
                                                        {materia.votiScrutiniPassati && materia.votiScrutiniPassati.length > 0 && (
                                                            materia.votiScrutiniPassati.map((votoPassato) => (
                                                                <Box className="fakeInputBox"  >
                                                                    <Typography key={materia.id + votoPassato.idPeriodo} sx={{ color: changeColorVoto(votoPassato?.voto) }}>
                                                                        {returnVotoConverted(votoPassato?.voto)}
                                                                    </Typography>
                                                                </Box>
                                                            ))
                                                        )}
                                                        <Box className="fakeInputBox"  >

                                                            <Typography sx={{ color: changeColorVoto(props.votiProposti[index]?.voto) }}>
                                                                {returnVotoConverted(props.votiProposti[index]?.voto)}
                                                            </Typography>
                                                        </Box>

                                                        <input
                                                            className="inputEdit"
                                                            ref={el => votiScrutinioRef.current[index] = el}
                                                            defaultValue={returnVotoConverted(props.votiScrutinio[index]?.voto ?? null)}
                                                            style={{ width: '50px', color: changeColorVoto(votiScrutinioRef?.current[index]?.value ?? null) }}
                                                            onChange={(e) =>
                                                                handleVotoChange(e, votiScrutinioRef.current, index,
                                                                    () => {
                                                                        const isButtonDisabled = checkIfButtonIsDisabled()
                                                                        props.changeButtonConfirmDisabled(isButtonDisabled);
                                                                        if (!canCalculateCrediti) setCanCalculateCrediti(true);
                                                                    }
                                                                )
                                                            }
                                                            onBlur={(e) => props.onBlurVotoScrutinio(e, index)}
                                                        />
                                                    </Box>

                                                </Box>
                                            </Box>
                                            <Divider />
                                        </Fragment>
                                    )
                                ))}
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                                    {returnHeadLegenda()}
                                </Box>
                                {props.listMaterie.map((materia, index) => (
                                    (!isMobile && !isTablet && index > props.secondColLimit) && (
                                        <Fragment>
                                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }} key={materia?.id + index + materia?.nome}>
                                                <Box sx={{ p: 0 }}>
                                                    <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                        <b>{materia?.nome}</b>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {materia.docente}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                    <Box sx={{ p: 1, display: "flex", justifyContent: 'center', gap: 1 }}>
                                                        {materia.votiScrutiniPassati && materia.votiScrutiniPassati.length > 0 && (
                                                            materia.votiScrutiniPassati.map((votoPassato) => (
                                                                <Box className="fakeInputBox"  >
                                                                    <Typography key={materia.id + votoPassato.idPeriodo} sx={{ color: changeColorVoto(votoPassato?.voto) }}>
                                                                        {returnVotoConverted(votoPassato?.voto)}
                                                                    </Typography>
                                                                </Box>
                                                            ))
                                                        )}
                                                        <Box className="fakeInputBox"  >
                                                            <Typography sx={{ color: changeColorVoto(props.votiProposti[index]?.voto) }}>
                                                                {returnVotoConverted(props.votiProposti[index]?.voto)}
                                                            </Typography>
                                                        </Box>
                                                        <input
                                                            className="inputEdit"
                                                            ref={el => votiScrutinioRef.current[index] = el}
                                                            defaultValue={returnVotoConverted(props.votiScrutinio[index]?.voto ?? null)}
                                                            style={{ width: '50px', color: changeColorVoto(votiScrutinioRef?.current[index]?.value ?? null) }}
                                                            onChange={(e) =>
                                                                handleVotoChange(e, votiScrutinioRef.current, index,
                                                                    () => {
                                                                        const isButtonDisabled = checkIfButtonIsDisabled()
                                                                        props.changeButtonConfirmDisabled(isButtonDisabled);
                                                                        if (!canCalculateCrediti) setCanCalculateCrediti(true);
                                                                    }
                                                                )
                                                            }
                                                            onBlur={(e) => props.onBlurVotoScrutinio(e, index)}
                                                        />
                                                    </Box>

                                                </Box>
                                            </Box>
                                            <Divider />
                                        </Fragment>
                                    )
                                ))}

                            </Grid>
                        </Grid>
                    </Paper>

                    {/*COMPORTAMENTO */}
                    <Grid container spacing={2}>


                        <Grid item xs={12} md={6}>
                            <Paper sx={{ mb: 2, p: 0, height: '100%' }}>
                                <Typography variant='h6' sx={{ p: 2 }}>
                                    <b>Comportamento</b>
                                </Typography>

                                <Box sx={{ px: 1 }}>
                                    {returnHeadLegenda()}

                                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                        <Box sx={{ p: 0 }}>
                                            <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                Motivazione e interesse
                                            </Typography>
                                        </Box>
                                        <Box sx={{ p: 1,display: "flex", alignItems: 'center',gap:1}}>
                                                {props.periodiPassati && props.periodiPassati.length > 0 && (
                                                    props.periodiPassati.map((periodo) => (
                                                        <Box className="fakeInputBox"  >
                                                            <Typography key={periodo.idPeriodo + "mot"} sx={{ color: changeColorVoto(periodo.condottaScrutinio.motivazione) }}>
                                                                {returnVotoConverted(periodo.condottaScrutinio.motivazione)}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                )}
                                                <Box sx={{  display: "flex", justifyContent: 'center' }}>
                                                    <Box className="fakeInputBox"  >
                                                        <Typography sx={{ color: changeColorVoto(props.condottaObj?.condottaTutor?.motivazione ?? null) }} >
                                                            {returnVotoConverted(props.condottaObj?.condottaTutor?.motivazione)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                    <input
                                                        ref={el => votoCondottaMotRef.current = el}
                                                        className="inputEdit"
                                                        defaultValue={returnVotoConverted(props.condottaScrutinioMotivazione)}
                                                        style={{ width: '50px', color: changeColorVoto(props.condottaScrutinioMotivazione ?? null) }}
                                                        onChange={(e) =>
                                                            handleVotoChange(
                                                                e, votoCondottaMotRef, undefined,
                                                                () => {
                                                                    const isButtonDisabled = checkIfButtonIsDisabled()
                                                                    props.changeButtonConfirmDisabled(isButtonDisabled);
                                                                    if (!canCalculateCrediti) setCanCalculateCrediti(true);
                                                                }
                                                            )
                                                        }
                                                        onBlur={props.onBlurScrutinioMotivazione}
                                                    />
                                        </Box>
                                    </Box>


                                    <Divider />

                                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                        <Box sx={{ p: 0 }}>
                                            <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                Interazione con il contesto
                                            </Typography>
                                        </Box>
                                        <Box sx={{ p: 1, display: "flex", alignItems: 'center', gap:1 }}>
                                            {props.periodiPassati && props.periodiPassati.length > 0 && (
                                                props.periodiPassati.map((periodo) => (
                                                    <Box className="fakeInputBox"  >
                                                        <Typography key={periodo.idPeriodo + "int"} sx={{ color: changeColorVoto(periodo.condottaScrutinio.interazione) }}>
                                                            {returnVotoConverted(periodo.condottaScrutinio.interazione)}
                                                        </Typography>
                                                    </Box>
                                                ))
                                            )}
                                            <Box sx={{ display: "flex", justifyContent: 'center' }}>
                                                <Box className="fakeInputBox"  >
                                                    <Typography sx={{ color: changeColorVoto(props.condottaObj?.condottaTutor?.interazione ?? null) }}>
                                                        {returnVotoConverted(props.condottaObj?.condottaTutor?.interazione)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                                <input
                                                    className="inputEdit"
                                                    ref={el => votoCondottaIntRef.current = el}
                                                    defaultValue={returnVotoConverted(props.condottaScrutinioInterazione)}
                                                    style={{ width: '50px', color: changeColorVoto(props.condottaScrutinioInterazione ?? null) }}
                                                    onChange={(e) =>
                                                        handleVotoChange(e, votoCondottaIntRef, undefined,
                                                            () => {
                                                                const isButtonDisabled = checkIfButtonIsDisabled()
                                                                props.changeButtonConfirmDisabled(isButtonDisabled);
                                                                if (!canCalculateCrediti) setCanCalculateCrediti(true);
                                                            }
                                                        )
                                                    }
                                                    onBlur={props.onBlurScrutinioInterazione}
                                                />
                                        </Box>
                                    </Box>
                                    <Divider />

                                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                        <Box sx={{ p: 0 }}>
                                            <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                Relazione con gli altri
                                            </Typography>
                                        </Box>
                                        <Box sx={{ p: 1, display: "flex", alignItems: 'center',gap:1 }}>
                                            {props.periodiPassati && props.periodiPassati.length > 0 && (
                                                props.periodiPassati.map((periodo) => (
                                                    <Box className="fakeInputBox"  >
                                                        <Typography key={periodo.idPeriodo + "rel"} sx={{ color: changeColorVoto(periodo.condottaScrutinio.relazione) }}>
                                                            {returnVotoConverted(periodo.condottaScrutinio.relazione)}
                                                        </Typography>
                                                    </Box>
                                                ))
                                            )}
                                            <Box sx={{ display: "flex", justifyContent: 'center' }}>
                                                <Box className="fakeInputBox"  >
                                                    <Typography sx={{ color: changeColorVoto(props.condottaObj?.condottaTutor?.relazione ?? null) }}>
                                                        {returnVotoConverted(props.condottaObj?.condottaTutor?.relazione)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <input
                                                className="inputEdit"
                                                ref={el => votoCondottaRelRef.current = el}
                                                defaultValue={returnVotoConverted(props.condottaScrutinioRelazione)}
                                                style={{ width: '50px', color: changeColorVoto(props.condottaScrutinioRelazione ?? null) }}
                                                onChange={(e) =>
                                                    handleVotoChange(
                                                        e,
                                                        votoCondottaRelRef,
                                                        undefined,
                                                        () => {
                                                            const isButtonDisabled = checkIfButtonIsDisabled()
                                                            props.changeButtonConfirmDisabled(isButtonDisabled);
                                                            if (!canCalculateCrediti) setCanCalculateCrediti(true);
                                                        }
                                                    )
                                                }
                                                onBlur={props.onBlurScrutinioRelazione}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant='h6' sx={{ mb: 1 }}>
                                    <b> {props.isUltimoPeriodo ? "Frequenza complessiva dell'anno" : "Frequenza"} </b>
                                </Typography>
                                {props.alunnoSelezionato?.isRitirato !== undefined && props.alunnoSelezionato?.isRitirato === 1 && (
                                    <Typography color="error" variant="body1"><b>Alunno ritirato in data {convertDataDiNascita(props.alunnoSelezionato?.iscrittoAl)}</b></Typography>
                                )}
                                <Typography variant="subtitle2">Ore svolte: <b>{props.frequenzaObj?.oreSvolte ?? "-"}</b></Typography>
                                <Typography variant="subtitle2">Ore di assenza: <b>{props.frequenzaObj?.oreAssenza ?? "-"} ({props.frequenzaObj?.percentuale ?? "0"}%) </b></Typography>
                            </Paper>

                            {props.showCrediti && (
                                <Paper sx={{ p: 2, mt: 2 }}>
                                    <Typography variant='h6' sx={{ mb: 1 }}>
                                        <b>Totale crediti formativi</b>
                                    </Typography>
                                    <Box >
                                        <Typography>Crediti ottenuti: {props.creditiFormativi}</Typography>
                                        {canCalculateCrediti && (
                                            <Button color="success" variant="contained" fullWidth onClick={props.getCreditiFormativi} sx={{ mt: 2 }}>
                                                Calcola crediti formativi
                                            </Button>
                                        )}
                                    </Box>
                                </Paper>
                            )}
                            {(props.isUltimoPeriodo && props.showEsito && props.showProvaPassaggio) && (
                                <Paper sx={{ p: 2, mt: 2, display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                    <Box sx={{ p: 0 }}>
                                        <Typography variant="h6" sx={{ mr: 2 }}>
                                            Prova di passaggio
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                                        <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                            <Box className="fakeInputBox"  >
                                                <Typography sx={{ color: changeColorVoto(props.provaDiPassaggio ?? null) }}>
                                                    {returnVotoConverted(props.provaDiPassaggio)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                            <input
                                                className="inputEdit"
                                                ref={el => provaDiPassaggioRef.current = el}
                                                defaultValue={returnVotoConverted(provaDiPassaggioInput)}
                                                style={{ width: '50px', color: changeColorVoto(provaDiPassaggioInput ?? null) }}
                                                onChange={(e) =>
                                                    handleVotoChange(e, provaDiPassaggioRef)
                                                }
                                                onBlur={props.onBlurProvaDiPassaggio}
                                            />
                                    </Box>
                                </Paper>
                            )}

                            {(props.showEsito && (props.alunnoSelezionato?.isRitirato === undefined || props.alunnoSelezionato?.isRitirato !== 1)) && (
                                <Paper sx={{ p: 2, mt: 2 }}>

                                    <Typography variant='h6' sx={{ mb: 1 }}>
                                        <b>Esito</b>
                                    </Typography>

                                    <Box display={"flex"} alignItems={"center"}>

                                        <Typography sx={{ mr: 2 }}>Esito alunno: </Typography>
                                        <ToggleButtonGroup
                                            color={props.esitoAlunnoIdoneo !== undefined && props.esitoAlunnoIdoneo == "true" ? 'success' : 'error'}
                                            value={props.esitoAlunnoIdoneo}
                                            exclusive
                                            onChange={props.changeEsitoAlunnoIdoneo}
                                        >
                                            <ToggleButton value="true">Idoneo</ToggleButton>
                                            <ToggleButton value="false">Non idoneo</ToggleButton>
                                        </ToggleButtonGroup>

                                    </Box>

                                </Paper>
                            )}
                        </Grid>

                    </Grid>
                </Fragment>
            ) : (
                <Paper sx={{ mb: 2, p: 2 }}>
                    <Alert severity='error'>
                        Al momento non è possibile inserire i voti.
                        {/* {errorMsg} */}
                    </Alert>
                </Paper>
            )}
        </Fragment>
    )
}

interface Props {
    onBlurVotoScrutinio: (e: any, index: number) => void,
    onBlurScrutinioInterazione: (e: any) => void,
    onBlurScrutinioMotivazione: (e: any) => void,
    onBlurProvaDiPassaggio: (e: any) => void,
    onBlurScrutinioRelazione: (e: any) => void,
    changeEsitoAlunnoIdoneo: (e: any) => void
    changeButtonConfirmDisabled: (isDisabled: boolean) => void,
    getCreditiFormativi: () => void,
    creditiFormativi: number,
    esitoAlunnoIdoneo: string | undefined,
    alunnoSelezionato: AlunnoScrutinio,
    showCrediti: boolean,
    isUltimoPeriodo: boolean,
    showEsito: boolean,
    votiScrutiniHasErrors: boolean,
    canCalculateCrediti: boolean,
    votiScrutinio: VotoObj[],
    votiProposti: VotoObj[],
    condottaObj: {
        condottaScrutinio: {
            interazione: number
            is_prescrutinio: boolean
            mediaCondotta: number
            motivazione: number
            relazione: number
        }
        condottaDocenti: {
            interazione: number
            mediaCondotta: number
            motivazione: number
            relazione: number
        },
        condottaTutor: {
            interazione: number
            mediaCondotta: number
            motivazione: number
            relazione: number
        }
    }
    frequenzaObj: {
        oreAssenza: number,
        percentuale: number,
        oreSvolte: number
    }
    periodiPassati: {
        idPeriodo: number,
        nome: string,
        condottaScrutinio: {
            interazione: number
            mediaCondotta: number
            motivazione: number
            relazione: number
        }
    }[]
    condottaScrutinioInterazione: number | null
    condottaScrutinioMotivazione: number | null
    provaDiPassaggio: number | null
    provaDiPassaggioInput: number | null
    condottaScrutinioRelazione: number | null
    listMaterie: Materia[],
    firstColLimit: number,
    secondColLimit: number,
    showProvaPassaggio: boolean
}






