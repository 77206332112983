import { useState, useEffect, Fragment, useRef } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DialogActions, Divider, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, Snackbar, Alert, useTheme, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { debounce } from '@mui/material/utils';

import { AddCircle, ArrowBack, Close, Help, VisibilityOff, Visibility, AccessibilityNew, AttachFile } from "@mui/icons-material";
import DettaglioAlunno from "../dettaglio-utente/dettaglioAlunno";
import Presenza from "./presenza";
import AggiuntaEventi from "../eventi/aggiuntaEventi";
import TimerBes from "./shared/besTimer";


import useMediaQuery from '@mui/material/useMediaQuery';
import EventoChip from "./shared/eventoChip";
import Legende from "./shared/legende";
import { DateTime, Interval } from "luxon";
import { convertDataDiNascita, convertDataGetProssimeLezione, getDayMonthAndYearErrorDialog } from "../shared/metodiDateUtility";
import { RegistroAPI } from "../../api/registroApi";
import { PresenzeAPI } from "../../api/presenzeApi";
import { BesAPI } from "../../api/besApi";
import RiepilogoArgomentiSvolti from "../eventi/riepilogoArgomentiSvolti";

export default function RegistroDocente(props) {

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();

  const [permessiUtente, setPermessiUtente] = useState([])
  const [ruoliUtente, setRuoliUtente] = useState({});

  const [objPermessiExAnte, setObjPermessiExAnte] = useState({
    insertExAnte: true,
    prerequisitiPassaggio: false,
    prerequisitiRecupero: false
  })
  const [showIniziaAppello, setShowIniziaAppello] = useState(false);
  const [showModificaAppello, setShowModificaAppello] = useState(false);
  const [showFirmaOra, setShowFirmaOra] = useState(false);
  const [addEventoTuttaClasse, setAddEventoTuttaClasse] = useState(false);

  const [dataRichiesta, setDataRichiesta] = useState(null)
  const [idDocente, setIdDocente] = useState(-1);
  const [idCorso, setIdCorso] = useState(-1);
  const [idMateria, setIdMateria] = useState(-1);
  const [idOrario, setIdOrario] = useState(-1);
  const [nomeClasse, setNomeClasse] = useState("");
  const [nomeMateria, setNomeMateria] = useState("");
  const [codiceCorso, setCodiceCorso] = useState('');
  const [codiceSimon, setCodiceSimon] = useState('');

  const [hasLoaded, setHasLoaded] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMsg, setAlertMsg] = useState(false);
  const [showScrollMessage, setShowScrollMessage] = useState(false);
  const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);

  const [hasDocenteFunctions, setHasDocenteFunctions] = useState(true);
  const [dialogAnnotazioniListOpen, setDialogAnnotazioniListOpen] = useState(false);

  //Aggiunta evento
  const [aggiuntaEventoOpen, setAggiuntaEventoOpen] = useState(false);
  const [alunniSelezionati, setAlunniSelezionati] = useState([]);

  //DETTAGLIO ALUNNO
  const [dettaglioAlunnoOpen, setDettaglioAlunnoOpen] = useState(false);
  const [besObjAlunnoSelezionato, setBesObjAlunnoSelezionato] = useState(null);
  const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
  const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(null);
  const [indexTabToOpen, setIndexTabToOpen] = useState(0);

  //PRESENZE/ASSENZE
  const [alunni, setAlunni] = useState([]);
  const [oreGiornata, setOreGiornata] = useState([]);
  const [arrayNote, setArrayNote] = useState([]);

  const [typeLezione, setTypeLezione] = useState(-1)

  const refRegistro = useRef(null);
  const refReset = useRef(null);

  const [openDialogRitardo, setOpenDialogRitardo] = useState(false);
  const [canAddEvents, setCanAddEvents] = useState(false);

  const [sedeUtente, setSedeUtente] = useState('');

  const ref = useRef({});

  const refPinConfermaBes = useRef(null);

  const [dataInizioPeriodo, setDataInizioPeriodo] = useState("");
  const [dataFinePeriodo, setDataFinePeriodo] = useState("");


  //BES
  const [showBes, setShowBes] = useState(false);
  const [showBesPinDialog, setShowBesPinDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [pinConfermaBes, setPinConfermaBes] = useState("")
  const [pinBesTmp, setPinBesTmp] = useState("")
  const [arrayBes, setArrayBes] = useState([]);

  const [isConsultaRegistro, setIsConsultaRegistro] = useState(false);

  const [isRiepilogoArgomentiSvoltiDialogOpen, setIsRiepilogoArgomentiSvoltiDialogOpen] = useState(false);

  const [objRiepilogoArgomentiSvolti, setObjRiepilogoArgomentiSvolti] = useState({});
  const [canAddEventsPostPeriodo, setCanAddEventsPostPeriodo] = useState(false);

  const changePinConfermaBesLength = debounce((event) => {
    setPinBesTmp(event.target.value);
  }, 100);

  useEffect(() => {
    if (props.isConsultaRegistro !== undefined)
      setIsConsultaRegistro(props.isConsultaRegistro)
  }, [props.isConsultaRegistro])



  useEffect(() => {
    if (props.codiceCorso !== undefined)
      setCodiceCorso(props.codiceCorso)
  }, [props.codiceCorso])
  useEffect(() => {
    if (props.ruoliUtente !== undefined) {
      setRuoliUtente(props.ruoliUtente);
    }
  }, [props.ruoliUtente])
  useEffect(() => {
    if (props.codiceSimon !== undefined)
      setCodiceSimon(props.codiceSimon)
  }, [props.codiceSimon])
  useEffect(() => {

    if (props.idCorso != undefined && props.dataRichiesta != undefined && props.idMateria != undefined && props.isConsultaRegistro != undefined &&
      props.idDocente != undefined && props.idOrario !== undefined) {
      getRegistroDocente(props.dataRichiesta, props.idCorso, props.idMateria, props.idDocente, props.idOrario, props.isConsultaRegistro);
      setIdCorso(props.idCorso);
      setDataRichiesta(props.dataRichiesta);
      setIdMateria(props.idMateria);
      setIdDocente(props.idDocente);
      setIdOrario(props.idOrario);
      setIsConsultaRegistro(props.isConsultaRegistro);
      let dataConverted = DateTime.fromSQL(props.dataRichiesta);
      dataConverted = dataConverted.toUTC().toISO();

      getPermessiRE(props.idOrario, props.idCorso, dataConverted, props.isConsultaRegistro)
    }
  }, [props.idCorso, props.dataRichiesta, props.idMateria, props.idDocente, props.isConsultaRegistro])

  useEffect(() => {
    if (props.permessiUtente !== undefined)
      setPermessiUtente([...props.permessiUtente])
  }, [props.permessiUtente])

  useEffect(() => {
    if (props.sedeUtente !== undefined) {
      setSedeUtente(props.sedeUtente)
    }
  }, [props.sedeUtente])



  useEffect(() => {
    if (props.nomeMateria != undefined) {
      setNomeMateria(props.nomeMateria)
    }
  }, [props.nomeMateria])

  useEffect(() => {
    if (props.nomeClasse !== undefined)
      setNomeClasse(props.nomeClasse);
  }, [props.nomeClasse])


  useEffect(() => {
    if (props.hasDocenteFunctions !== undefined) {
      setHasDocenteFunctions(props.hasDocenteFunctions)
    }
  }, [props.hasDocenteFunctions])


  function logout() {
    if (props.logout) props.logout()
  }

  function vediDettaglioAlunno(id, indexTab, nome, cognome, ritardo, besObj) {
    setOpenDialogRitardo(ritardo)
    setIdAlunnoSelezionato(id);
    setBesObjAlunnoSelezionato(besObj)
    setNomeAlunnoSelezionato(nome + " " + cognome);
    setDettaglioAlunnoOpen(true);
    setIndexTabToOpen(indexTab);
  }
  function closeDettaglioAlunno() {
    setDettaglioAlunnoOpen(false);
    getRegistroDocente(dataRichiesta, idCorso, idMateria, idDocente, idOrario)
  }
  function openDialogLegenda() {
    setDialogLegendaOpen(true);
  }
  function closeDialogLegenda() {
    setDialogLegendaOpen(false);
  }


  //Eventi
  function openAggiuntaEvento(alunnoObj, isTuttaClasse) {
    let alunniSelezionatiTmp = [];
    if (isTuttaClasse === undefined || isTuttaClasse === false) {
      setAddEventoTuttaClasse(false);
      alunniSelezionatiTmp.push(alunnoObj);
    } else {
      setAddEventoTuttaClasse(true);
      for (let alunno of alunni) {
        alunniSelezionatiTmp.push(alunno)
      }
    }
    setAlunniSelezionati([...alunniSelezionatiTmp]);
    setAggiuntaEventoOpen(true);
  }
  function closeAggiuntaEvento() {
    setAggiuntaEventoOpen(false);
    getRegistroDocente(dataRichiesta, idCorso, idMateria, idDocente, idOrario)

  }

  function closeRiepilogoArgomentiSvoltiDialog() {
    setIsRiepilogoArgomentiSvoltiDialogOpen(false)
  }
  function eventoAggiunto(alertMsg, alertSeverity) {
    setAggiuntaEventoOpen(false);
    openAlertMsg(alertMsg, alertSeverity)
    getRegistroDocente(dataRichiesta, idCorso, idMateria, idDocente, idOrario)
  }



  function openAnnotazioneList(alunnoObj, isTuttaClasse) {
    let alunniSelezionatiTmp = [];
    if (isTuttaClasse === undefined || isTuttaClasse === false) {
      alunniSelezionatiTmp.push(alunnoObj);
    } else {
      for (let alunno of alunni) {
        alunniSelezionatiTmp.push(alunno)
      }
    }
    setAlunniSelezionati([...alunniSelezionatiTmp]);
    setDialogAnnotazioniListOpen(true)
  }

  function closeAnnotazioneListDialog() {
    setDialogAnnotazioniListOpen(false);
    setAlunniSelezionati([])
  }


  //Funzioni utility
  function openAlertMsg(msg, severity) {
    setAlertMsg(msg);
    setAlertSeverity(severity);
    setAlertOpen(true);
  }

  function eventoModificato(alertMsg, alertSeverity) {
    openAlertMsg(alertMsg, alertSeverity)
    getRegistroDocente(dataRichiesta, idCorso, idMateria, idDocente, idOrario)
  }

  function checkScrollRegistro(e) {
    if (e.target.scrollLeft >= e.target.scrollLeftMax) {
      setShowScrollMessage(false);
    } else {
      if (showScrollMessage === false) {
        setShowScrollMessage(true);
      }
    }

  }
  function isAlunnoIscrittoInData(dataCella, alunno) {
    let dataSql = DateTime.fromSQL(dataCella);
    let dataConvertedIso = dataSql.toUTC().toISO()
    let iscrittoDalIso = alunno?.iscrittoDal;
    let iscrittoAlIso = alunno?.iscrittoAl;

    let startDate = DateTime.fromISO(iscrittoDalIso);
    let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
    let someDate = DateTime.fromISO(dataConvertedIso);
    let interval = Interval.fromDateTimes(startDate, endDate);
    if (interval.contains(someDate)) {
      return true
    } else return false;
  }
  function isAlunnoIscrittoInRange(dataDiInteresseInizio, dataDiInteresseFine, alunno) {
    let dataInizioTmp = convertDataGetProssimeLezione(dataDiInteresseInizio, true);
    let dataFineTmp = convertDataGetProssimeLezione(dataDiInteresseFine, true);

    let dataInizioObject = DateTime.fromISO(dataInizioTmp);
    let dataFineObject = DateTime.fromISO(dataFineTmp);

    let iscrittoDal = DateTime.fromISO(alunno?.iscrittoDal);
    let iscrittoAl = DateTime.fromISO(alunno?.iscrittoAl);
    if (alunno?.showDataRitiro == 1 || alunno?.showDataIscritto == 1) {
      if (iscrittoAl.startOf('day') < dataFineObject.startOf('day')) // Ritirato
      {
        return "Ritirato il " + convertDataDiNascita(iscrittoAl)
      }
      if (iscrittoAl.startOf('day') < dataInizioObject.startOf('day')) // Ritirato
      {
        return "Ritirato il " + convertDataDiNascita(iscrittoAl)
      }
      else //if (iscrittoDal.startOf('day') > dataInizioObject.startOf('day') && iscrittoDal.startOf('day') < dataFineObject.startOf('day')) // Non ancora iscritto in quella data
      {
        return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
      }
    } else return "";
    // else if (iscrittoDal.startOf('day') > dataFineObject.startOf('day')) {
    //   return "Iscritto dal " + convertDataDiNascita(iscrittoDal);
    // }
    // else return null
  }
  function goBackToVistaSintetica() {
    if (props.closeRegistro)
      props.closeRegistro();
  }

  function checkIfUscitaAnticipataIsAssenza(uscita, inizioLezione) {
    var dataUscita = new Date(`1970-01-01T${uscita}Z`);
    var dataInizioLezione = new Date(`1970-01-01T${inizioLezione}Z`);
    return dataUscita <= dataInizioLezione;
  }

  function checkIfEntrataInRitardoIsAssenza(ingresso, fineLezione) {
    var dataIngresso = new Date(`1970-01-01T${ingresso}Z`);
    var dataFineLezione = new Date(`1970-01-01T${fineLezione}Z`);
    return dataIngresso >= dataFineLezione;
  }

  function checkIfEntrataAndUscitaIsAssenza(statoTmp, uscita, inizioLezione, ingresso, fineLezione) {
    let stato = statoTmp;
    let dataIngresso = new Date(`1970-01-01T${ingresso}Z`);
    let dataFineLezione = new Date(`1970-01-01T${fineLezione}Z`);
    let dataUscita = new Date(`1970-01-01T${uscita}Z`);
    let dataInizioLezione = new Date(`1970-01-01T${inizioLezione}Z`);
    if (dataIngresso >= dataFineLezione && dataUscita <= dataInizioLezione) stato = "ER/UA";
    else {
      if (dataUscita <= dataInizioLezione) {
        stato = "UA"
      }
      else if (dataIngresso >= dataFineLezione) {

        stato = "ER"
      }
    }
    //return dataIngresso >= dataFineLezione;
    return stato;
    // return dataUscita <= dataInizioLezione;
  }



  function getRegistro() {
    getRegistroDocente(dataRichiesta, idCorso, idMateria, idDocente, idOrario, isConsultaRegistro)
  }

  function getIdOrarioAttuale(oreGiornata, idMateria, idDocente, idCorso, dataConverted, idOrario, isConsulta) {
    if (oreGiornata.length > 0) {
      let idOrarioTmp; // = oreDiLezioneClasseSelezionata[0].idOrario
      let checked = false;
      for (let ora of oreGiornata) {
        if (ora?.materia?.idMateria == idMateria && checked === false) {
          if (idOrario == null)
            idOrarioTmp = ora?.id;
          else idOrarioTmp = idOrario;
          checked = true;
        }
      }

      if (checked) {
        checkOraFirmata(idOrarioTmp, idDocente);
        getPermessiRE(idOrarioTmp, idCorso, dataConverted, isConsulta);

      }
    }
    /* for (let ora of oreGiornata) {
       if (oreDiLezioneClasseSelezionata.some((oraTmp => oraTmp?.idOrario === ora?.id))) {
         let idOrarioTmp = localStorage.getItem("oraLez");
         let idOrarioSelected = CryptoJS.AES.decrypt(idOrarioTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
         if (ora?.id == idOrarioSelected) {
           setOraSelezionata(ora?.ora)
         }
         setIdOrarioFirma(idOrarioSelected);
         checkOraFirmata(idOrarioSelected, idDocente)
 
 
       }
     }
     */
  }

  //Funzioni chiamate

  async function getRegistroDocente(dataRichiesta, idCorso, idMateria, idDocente, idOrario, isConsulta) {
    let dataConverted = DateTime.fromSQL(dataRichiesta);
    dataConverted = dataConverted.toUTC().toISO();
    setHasLoaded(false)
    let dataObj = {
      "dataRichiesta": dataConverted,
      "idCorso": idCorso,
      "idMateria": idMateria
    }
    RegistroAPI.getRegistroDocente(dataObj)
      .then((result) => {
        let alunniTmp = result?.alunni;
        let oreTmp = result?.ore;
        let arrayNote = [];

        //Per aggiungere l'index della colonna
        for (let i = 0; i < alunniTmp.length; i++) {
          alunniTmp[i].msgIscrizione = isAlunnoIscrittoInRange(dataConverted, dataConverted, alunniTmp[i]);
          if (alunniTmp[i]?.comunicazioni.length > 0) {
            for (let j = 0; j < alunniTmp[i]?.comunicazioni.length; j++) {
              for (let ora of oreTmp) {
                //Comunicazioni di classe
                if (ora?.comunicazioni.length > 0) {
                  for (let x = 0; x < ora?.comunicazioni.length; x++) {
                    if (alunniTmp[i]?.comunicazioni[j]?.contenuto?.idOrario === ora?.id) {
                      let indexColonna = oreTmp.findIndex(ora => ora?.id === alunniTmp[i]?.comunicazioni[j]?.contenuto?.idOrario)
                      alunniTmp[i].comunicazioni[j].indexColonna = indexColonna
                    }
                    alunniTmp[i].comunicazioni[j].ora = ora?.ora;
                  }
                }
                //Comunicazioni dell'alunno (voti-valutazioni)

                if (alunniTmp[i]?.comunicazioni.length > 0) {
                  if (alunniTmp[i].comunicazioni[j]?.contenuto?.idOrario === ora?.id) {
                    let indexColonna = oreTmp.findIndex(oraTmp => oraTmp?.id == ora?.id);
                    alunniTmp[i].comunicazioni[j].indexColonna = indexColonna
                    alunniTmp[i].comunicazioni[j].ora = ora?.ora;
                  }
                }
              }

              alunniTmp[i].comunicazioni.sort((a, b) => a?.tipologia_comunicazioni - b?.tipologia_comunicazioni);
            }

            for (let x = 0; x < alunniTmp[i]?.comunicazioni.length; x++) {
              for (let ora of oreTmp) {
                if (alunniTmp[i]?.comunicazioni.length > 0) {
                  if (alunniTmp[i].comunicazioni[x]?.contenuto?.idOrario !== undefined) {
                    if (alunniTmp[i].comunicazioni[x]?.contenuto?.idOrario === ora?.id) {
                      let indexColonna = oreTmp.findIndex(oraTmp => oraTmp?.id == ora?.id);
                      alunniTmp[i].comunicazioni[x].indexColonna = indexColonna
                      alunniTmp[i].comunicazioni[x].ora = ora?.ora;
                    }
                  }
                }
              }

              alunniTmp[i].comunicazioni.sort((a, b) => a?.tipologia_comunicazioni - b?.tipologia_comunicazioni);
            }
          }
        }

        for (let i = 0; i < oreTmp.length; i++) {
          //Toglie i secondi dall'ora
          oreTmp[i].oraInizio = oreTmp[i]?.oraInizio.substring(0, oreTmp[i]?.oraInizio.length - 3);
          oreTmp[i].oraFine = oreTmp[i]?.oraFine.substring(0, oreTmp[i]?.oraFine.length - 3);
        }

        if (result?.classe?.comunicazioni.length > 0) {
          for (let i = 0; i < result?.classe?.comunicazioni.length; i++) {
            arrayNote.push(result?.classe?.comunicazioni[i])
          }
        }
        oreTmp.sort((a, b) => a.ora - b.ora);

        //setAlunni([...alunniTmp]);
        setArrayNote([...arrayNote])
        setOreGiornata([...oreTmp])
        getPresenze(alunniTmp, dataRichiesta, oreTmp);

        //let idOrarioTmp = localStorage.getItem("oraLez");
        //let idOrarioSelected = CryptoJS.AES.decrypt(idOrarioTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);

        let objPermessiExAnteTmp = {
          insertExAnte: result?.insertExAnte ?? true,
          prerequisitiPassaggio: result?.prerequisitiPassaggio ?? false,
          prerequisitiRecupero: result?.prerequisitiRecupero ?? false
        }
        setObjPermessiExAnte(objPermessiExAnteTmp);
        getIdOrarioAttuale(oreTmp, idMateria, idDocente, idCorso, dataConverted, idOrario, isConsulta);

        setHasLoaded(true);
        setTimeout(() => {
          if (refRegistro.current != null)
            refRegistro.current.scrollLeft += 1;
        }, 500)

      })
      .catch(function (error) {
        setHasLoaded(true);
        setAlunni([]);
        setOreGiornata([])
      })

  }

  async function getPresenze(alunniTmp, dataRichiesta, ore) {
    let idAlunni = [];
    for (let alunno of alunniTmp) {
      idAlunni.push(alunno?.id)
    }

    let dataObj = {
      "data": dataRichiesta,
      "idAlunno": idAlunni
    }

    PresenzeAPI.getPresenzeGiornaliere(dataObj)
      .then((result) => {
        let newAlunniArray = alunniTmp;
        if (result.alunni.length > 0) {
          let arrayAppello = result.alunni;
          for (let i = 0; i < newAlunniArray.length; i++) {
            for (let j = 0; j < arrayAppello.length; j++) {
              for (let x = 0; x < ore.length; x++) {
                if (newAlunniArray[i].id === arrayAppello[j].idAlunno && arrayAppello[j].stato_presenza !== "P") {
                  let uscitaConverted = null;
                  let ingressoConverted = null;
                  let statoPresenzaTmp = arrayAppello[j].stato_presenza;
                  if (arrayAppello[j].orario_uscita_reale != null && arrayAppello[j].orario_ingresso_reale == null) {
                    uscitaConverted = arrayAppello[j].orario_uscita_reale.substring(0, arrayAppello[j].orario_uscita_reale.length - 3);
                    if (checkIfUscitaAnticipataIsAssenza(uscitaConverted, ore[x].oraInizio))
                      statoPresenzaTmp = "UA";
                    else statoPresenzaTmp = "P"
                  }
                  if (arrayAppello[j].orario_ingresso_reale != null && arrayAppello[j].orario_uscita_reale == null) {
                    ingressoConverted = arrayAppello[j].orario_ingresso_reale.substring(0, arrayAppello[j].orario_ingresso_reale.length - 3)
                    if (checkIfEntrataInRitardoIsAssenza(ingressoConverted, ore[x].oraFine) === true)
                      statoPresenzaTmp = "ER";
                    else statoPresenzaTmp = "P"
                  }

                  if (arrayAppello[j].orario_ingresso_reale != null && arrayAppello[j].orario_uscita_reale != null) {
                    uscitaConverted = arrayAppello[j].orario_uscita_reale.substring(0, arrayAppello[j].orario_uscita_reale.length - 3);
                    ingressoConverted = arrayAppello[j].orario_ingresso_reale.substring(0, arrayAppello[j].orario_ingresso_reale.length - 3)
                    if (checkIfEntrataInRitardoIsAssenza(ingressoConverted, ore[x].oraFine) === true)
                      statoPresenzaTmp = "ER";
                    if (checkIfUscitaAnticipataIsAssenza(uscitaConverted, ore[x].oraInizio))
                      statoPresenzaTmp = "UA";
                    // statoPresenzaTmp = checkIfEntrataAndUscitaIsAssenza(uscitaConverted, ore[x].oraInizio, ingressoConverted, ore[x].oraFine)
                  }
                  if (statoPresenzaTmp === "A") {
                    let objPresenza = {
                      id: arrayAppello[j].id,
                      data: arrayAppello[j].data,
                      statoPresenza: "A",
                      note: arrayAppello[j].note,
                      orario_uscita_reale: uscitaConverted,
                      orario_ingresso_reale: ingressoConverted
                    }
                    newAlunniArray[i].presenza = objPresenza;
                  }
                  else if (statoPresenzaTmp == "ER" || statoPresenzaTmp == "UA") { //Solo su ER, UA e ER/UA aggiungo questo campo

                    let objPresenza = {
                      id: arrayAppello[j].id,
                      data: arrayAppello[j].data,
                      statoPresenza: "A",
                      note: arrayAppello[j].note,
                      orario_uscita_reale: statoPresenzaTmp === "ER" ? null : uscitaConverted,
                      orario_ingresso_reale: statoPresenzaTmp === "UA" ? null : ingressoConverted,
                      indexOra: x
                    }
                    newAlunniArray[i].presenza = objPresenza;

                  }

                }

              }
            }
          }

        }

        setHasLoaded(true);
        showBes ? addBesToArray(newAlunniArray, arrayBes) : setAlunni([...newAlunniArray])
      })
      .catch((error) => { })
  }

  async function checkOraFirmata(idOrarioFirma, idDocente) {
    let dataObj = {
      "idDocente": idDocente,
      "idOrario": idOrarioFirma
    }
    RegistroAPI.checkOraFirmata(dataObj)
      .then((result) => {
        if (result.check === true) {
          if (result.tipoOra === "pratica")
            setTypeLezione(2)
          else setTypeLezione(1)
        }
      })
      .catch((error) => { })
  }

  async function getPermessiRE(idOrario, idCorso, data, isConsulta) { //Se puoi fare l'appello o firmare l'ora
    let dataObj = {
      idCorso: idCorso,
      idOrario: (idOrario !== undefined && idOrario != -1) ? idOrario : null,
      data: data,
      consulta: isConsulta ?? false
    }
    RegistroAPI.getPermessiRE(dataObj)
      .then((result) => {
        setShowFirmaOra(false);// setShowFirmaOra(result?.showFirmaOra);
        setShowModificaAppello(result?.showModificaAppello ?? false);
        setShowIniziaAppello(result?.showIniziaAppello ?? false);
        setCanAddEvents(result?.canAddEvents ?? false);

        if (result?.dataPeriodo?.inizio !== undefined)
          setDataInizioPeriodo(DateTime.fromISO(result?.dataPeriodo?.inizio))
        else setDataInizioPeriodo(null)

        if (result?.dataPeriodo?.fine !== undefined)
          setDataFinePeriodo(DateTime.fromISO(result?.dataPeriodo?.fine))
        else setDataFinePeriodo(null)

        setCanAddEventsPostPeriodo(result?.canAddEventsPostPeriodo ?? false);

      })
      .catch((error) => {
        setShowFirmaOra(false);
        setShowModificaAppello(false);
        setShowIniziaAppello(false)
        setCanAddEvents(false);

      })

  }



  //BES
  function besPinDialog() {
    setPinBesTmp("");
    setShowBesPinDialog(prev => !prev);
  }
  function confirmBesPin() {
    //se il pin è corretto
    if (pinBesTmp.length >= 1) {
      let dataObj = {
        pin: pinBesTmp,
        idCorso: idCorso,
        dataRichiesta: dataRichiesta
      }
      BesAPI.visualizzaBesDocenti(dataObj)
        .then((result) => {
          setPinConfermaBes(pinBesTmp)
          setShowBes(true);
          setShowBesPinDialog(false);
          openAlertMsg("Bes abilitati", "info");
          setArrayBes([...result])
          addBesToArray(alunni, result);

        })
        .catch((err) => {
          let errorMsg = "";
          if (err.response.status === 420) {
            errorMsg = err?.response?.data?.data[0]?.msg ?? "Pin errato";
          } else {
            errorMsg = "Pin errato";
          }
          openAlertMsg(errorMsg, "error")

        })
    } else openAlertMsg("Il campo PIN non può essere lasciato vuoto", "error")

  }
  function addBesToArray(alunni, result) {
    let alunniTmp = alunni;
    for (let i = 0; i < alunniTmp.length; i++) {
      let indexArrayBes = result.findIndex(a => a.id === alunniTmp[i].id)
      if (indexArrayBes !== -1) {
        alunniTmp[i].hasBes = result[indexArrayBes]?.hasBes ?? false;
        let interventoAttivo = result[indexArrayBes]?.BESData?.documentazioneConsegnataBes?.interventoAttivo;
        alunniTmp[i].besObj = {
          id: alunniTmp[i].id,
          hasBes: result[indexArrayBes]?.hasBes,
          colorBes: selColorBes(interventoAttivo),
          BESData: result[indexArrayBes]?.BESData,
          recentlyModified: result[indexArrayBes]?.recentlyModified ?? false
        }
      } else {
        alunniTmp[i].besObj = {
          hasBes: false
        }
      }
    }
    setAlunni([...alunniTmp]);
    setHasLoaded(true);

  }
  function selColorBes(valore) {
    let colorTmp;
    switch (valore) {
      case '0':
        colorTmp = '#4169e1';
        break;
      case '1':
        colorTmp = '#1ebf24';
        break;
      case '2':
        colorTmp = '#ffbfaa';
        break;
      case '3':
        colorTmp = '#ff7b5a';
        break;
      case '4':
        colorTmp = '#d11507';
        break;
      default:
        colorTmp = '';
    }
    return colorTmp
  }
  function stopViewBes() {
    setShowBes(false)
  }

  const changePinConfermaBes = debounce((event) => {
    setPinBesTmp(event.target.value);
  }, 100);



  function showPasswordInput() {
    setShowPassword(prev => !prev);
  }

  function openRiepilogoArgomentiSvoltiDialog() {
    setIsRiepilogoArgomentiSvoltiDialogOpen(prev => !prev)
    let obj = {
      idCorsoSelezionato: idCorso,
      idMateriaSelezionata: idMateria,
      nomeClasse: nomeClasse,
      codiceCorso: codiceCorso,
      codiceSimon: codiceSimon,
      periodoDiInteresseInizio: null,
      periodoDiInteresseFine: null,
    }
    setObjRiepilogoArgomentiSvolti(obj)
  }

  return (
    <Fragment >
      <Box sx={{ height: '5vh' }}></Box>

      <Box sx={{ px: 5 }}>
        {alunni.length > 0 && oreGiornata.length > 0 ? (
          <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"} flexDirection={{ xs: 'column', md: 'row' }} width={{ xs: '100%', md: 'unset' }} >
              <Button sx={{ mt: { xs: 5, md: 0 }, width: { xs: '100%', md: 'unset' } }} onClick={goBackToVistaSintetica} variant="contained" startIcon={<ArrowBack />}>
                Torna alla vista sintetica
              </Button>
              {!isConsultaRegistro ? (
                <Button sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" onClick={besPinDialog}>
                  {showBes === true ? 'Bes visibili per ' : 'Mostra Bes '} <TimerBes startTimer={showBes} stopViewBes={stopViewBes} />
                </Button>) : null}
              <Button startIcon={<AttachFile />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="success" onClick={openRiepilogoArgomentiSvoltiDialog}>
                Riepilogo argomenti svolti
              </Button>
              <Button startIcon={<Help />} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                Mostra legenda
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>

      {hasLoaded ? (
        oreGiornata.length > 0 ? (
          <Box sx={{

            mt: 3, mb: 3,
            width: '100%', // TODO: rimetterre al 100%
            maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center'
          }}>
            {alunni.length > 0 || oreGiornata.length > 0 ? (
              <TableContainer ref={refRegistro} onScroll={checkScrollRegistro} sx={{ width: '95%', border: '5px solid orange' }} component={Paper}>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className='row-head' ref={refReset} sx={{ backgroundColor: 'white', py: 2, pl: 2, pr: 0, minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }} >
                        <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography color={'orange'}
                            variant={mobile ? 'caption' : 'body1'}
                            sx={{ fontWeight: 'bold', mr: 1, mb: 2 }}>{(hasDocenteFunctions ? "Registro del docente" : 'Consulta Registro del docente').toUpperCase()}</Typography>
                          <Typography fontWeight={"bold"} textAlign={"center"}>{getDayMonthAndYearErrorDialog(dataRichiesta)}</Typography>
                          <Box display={"flex"} alignItems={"center"}>
                            {arrayNote.length > 0 && (
                              arrayNote.map((nota, index) => (
                                <EventoChip dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} idDocente={idDocente} tipoRegistro={"docente"} eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} key={nota.id} oreRegistro={oreGiornata} objComunicazione={nota} aggiornaRegistro={eventoModificato} listAlunni={alunni} />
                              ))
                            )}
                          </Box>
                        </Box>
                      </TableCell>

                      {oreGiornata.map((ora, index) => (
                        <TableCell className='row-head' key={ora.id} ref={(element) => ref.current[index] = element} style={{ backgroundColor: 'white', p: 2, minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }} > {/*ref={index === oreGiornata.length - 1 ? lastColumnRef : null} */}
                          <Typography variant="subtitle2" color={"primary"} textAlign={"center"}>{ora?.ora + "^ ORA"}</Typography>
                          <Typography textAlign={"center"}><b>{ora?.materia?.nomeMateriaBreve}</b></Typography>
                          <Typography variant="subtitle2" textAlign={"center"}>{"(" + ora?.oraInizio + " - " + ora?.oraFine + ")"}</Typography>
                          {ora?.isFirmata?.isFirmata ? (
                            <Fragment>
                              <Typography variant="subtitle2" textAlign={"center"} color="primary">PRESENZA FIRMATA</Typography>
                              <Typography variant="subtitle2" textAlign={"center"} color="primary">{"(" + ora?.isFirmata?.tipoOra + ")"}</Typography>
                            </Fragment>
                          ) :
                            <Typography variant="subtitle2" textAlign={"center"} >PRESENZA NON FIRMATA</Typography>
                          }
                        </TableCell>
                      ))}

                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow key={0}>

                      <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Box className="spacebetween-direction" sx={{ backgroundColor: 'white', p: 2 }}>
                          <Box sx={{ backgroundColor: 'white', p: 2, display: 'flex', flexDirection: 'column' }}>
                            {/* <Typography color={'orange'}
                              variant={mobile ? 'caption' : 'body1'}
                              sx={{ fontWeight: 'bold', mr: 1 }}>{("Registro del docente").toUpperCase()}</Typography> */}
                            <Typography variant="caption" sx={{ mt: 1 }}>Corso:</Typography>
                            <Typography color={'primary'}
                              variant={mobile ? 'caption' : 'body1'}
                              sx={{ fontWeight: 'bold', mr: 1 }}>{(codiceCorso + " - " + nomeClasse + " (" + codiceSimon + ")" ?? "").toUpperCase()}</Typography>
                            <Typography variant="caption" sx={{ mt: 1 }}>Sede:</Typography>
                            <Typography
                              variant={mobile ? 'caption' : 'subtitle2'}
                              sx={{ fontWeight: 'bold', mr: 1 }}>{sedeUtente !== '' ? sedeUtente : ''}</Typography>
                          </Box>
                          {(hasDocenteFunctions && (canAddEvents || canAddEventsPostPeriodo)) ? (
                            <IconButton onClick={() => openAggiuntaEvento(null, true)}>
                              <AddCircle />
                            </IconButton>
                          ) : null}
                        </Box>
                      </TableCell>

                      {oreGiornata.map((ora, index) => (
                        <TableCell key={ora.id} style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                          {ora.comunicazioni.length > 0 ? (
                            <Box justifyContent={"center"} display="flex">
                              {ora.comunicazioni.map((comunicazioneClasse) => (
                                <EventoChip dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} idDocente={idDocente} tipoRegistro={"docente"} eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} key={comunicazioneClasse.id} oreRegistro={oreGiornata} objComunicazione={comunicazioneClasse} aggiornaRegistro={eventoModificato} listAlunni={alunni} />
                              ))}
                            </Box>
                          ) : null}


                        </TableCell>))}
                    </TableRow>
                    {alunni.map((alunno, index) => (
                      <TableRow style={{ height: '100px' }} key={alunno.id} >
                        <TableCell
                          className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell colonna-nomi' : "colonna-nomi"}
                          sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', width: '35vw' }}>
                          <Box className="spacebetween-direction" sx={{ backgroundColor: isAlunnoIscrittoInData(dataRichiesta, alunno) ? 'white' : '#eee', px: 2 }}>
                            <Box display={"flex"} flexDirection={'row'}>
                              <Typography sx={{ mr: 2 }}>  {(index + 1) + ")"}</Typography>
                              <Typography variant={mobile ? 'caption' : 'body1'} onClick={() => vediDettaglioAlunno(alunno.id, 0, alunno.nome, alunno.cognome, false, alunno?.besObj)} sx={{ cursor: 'pointer', mr: 2, fontWeight: alunno.isMaggiorenne ? 'bold' : 'unset' }}> {(alunno.cognome).toUpperCase() + " " + (alunno.nome).toUpperCase()}</Typography>

                            </Box>

                            {isAlunnoIscrittoInData(dataRichiesta, alunno) === true ? (
                              <Box display={"flex"} alignItems={"center"}>
                                {(hasDocenteFunctions && (canAddEvents || canAddEventsPostPeriodo)) ? (
                                  <IconButton onClick={() => openAggiuntaEvento(alunno, false)}>
                                    <AddCircle />
                                  </IconButton>
                                ) : null}
                                {showBes && alunno?.hasBes ? (
                                  <Box width={"10px"} height={"10px"} sx={{ boxShadow: alunno?.besObj?.recentlyModified ? "0 0 10px 2px rgb(255, 100, 100)" : "", cursor: 'pointer', p: 1, mr: 1, borderRadius: '100%', border: '1px solid ' + alunno?.besObj?.colorBes, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1976d2', ml: 1 }}   >
                                    <AccessibilityNew sx={{ color: alunno?.besObj?.colorBes }} onClick={() => vediDettaglioAlunno(alunno?.id, 4, alunno?.nome, alunno?.cognome, false, alunno?.besObj)} />
                                  </Box>
                                ) : null}
                              </Box>
                            ) : null}
                          </Box>
                          {alunno?.msgIscrizione != null ? (
                            <Box sx={{ px: 2 }}>
                              <Typography color="primary" variant="subtitle2">{alunno?.msgIscrizione}</Typography>
                            </Box>
                          ) : null}
                        </TableCell>




                        {oreGiornata.map((ora, indexOra) => (
                          <TableCell className={isAlunnoIscrittoInData(dataRichiesta, alunno) === false ? 'disabledCell' : ""} key={ora.id + alunno.id} style={{ minWidth: '200px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Box sx={{ display: "flex", justifyContent: 'center' }}>
                              {(alunno.comunicazioni.length > 0 && isAlunnoIscrittoInData(dataRichiesta, alunno)) ? (
                                alunno.comunicazioni.map((comunicazione) => (
                                  <Fragment key={alunno.id + comunicazione.id}>
                                    {(comunicazione?.ora == ora.ora && comunicazione?.tipologia_comunicazioni != 1) ? (
                                      <EventoChip dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} idDocente={idDocente} tipoRegistro={"docente"}
                                        eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} oreRegistro={oreGiornata} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={alunni} />
                                    ) : null}
                                    {/* {comunicazione.tipologia_comunicazioni === 7 && (
                                      <EventoChip eventoAggiunto={eventoAggiunto} getRegistro={getRegistro} permessiUtente={permessiUtente} oreRegistro={oreGiornata} objComunicazione={comunicazione} alunno={alunno} aggiornaRegistro={eventoModificato} listAlunni={alunni} />
                                    )} */}
                                  </Fragment>
                                ))
                              ) : null}

                              {/* {alunno.presenza != undefined && (
                                alunno?.presenza?.statoPresenza === "ER/UA" && (
                                  (alunno?.presenza?.statoPresenza === "ER/UA" && checkIfEntrataInRitardoIsAssenza(alunno?.presenza?.orario_ingresso_reale, ora.oraFine)) ||
                                  (alunno?.presenza?.statoPresenza === "ER/UA" && checkIfUscitaAnticipataIsAssenza(alunno?.presenza?.orario_uscita_reale, ora.oraInizio)) && (
                                    <Presenza key={"pres_" + index} presenza={alunno?.presenza} isRegistroDettaglio={false} />
                                  )
                                )
                              )} */}

                              {/*  (alunno.presenza.statoPresenza === "ER/UA" && checkIfEntrataInRitardoIsAssenza(alunno?.presenza?.orario_ingresso_reale, ora.oraFine)) ||
                                  (alunno.presenza.statoPresenza === "ER/UA" && checkIfUscitaAnticipataIsAssenza(alunno?.presenza?.orario_uscita_reale, ora.oraInizio)) */}

                              {alunno.presenza != undefined && (
                                (alunno.presenza.statoPresenza === "A" && alunno?.presenza?.orario_uscita_reale == null && alunno?.presenza?.orario_ingresso_reale == null)
                                  || (indexOra >= alunno?.presenza?.indexOra && alunno?.presenza?.orario_uscita_reale != null) ||
                                  (indexOra <= alunno?.presenza?.indexOra && alunno?.presenza?.orario_ingresso_reale != null) ||
                                  (alunno?.presenza?.indexOra === undefined && alunno?.presenza?.orario_ingresso_reale != null)

                                  ? (
                                    <Presenza key={"pres_" + index} presenza={alunno?.presenza} isRegistroDettaglio={false} />
                                  ) : null

                              )}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>
              </TableContainer>
            ) : (
              <Box height={"70vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Typography variant="h5">Per la data selezionata (<b>{(getDayMonthAndYearErrorDialog(dataRichiesta))}</b>) non è possibile visualizzare il registro giornaliero</Typography>
                <Button sx={{ mt: 2 }} onClick={goBackToVistaSintetica} variant="contained" startIcon={<ArrowBack />}>
                  Torna alla vista sintetica
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ height: '60vh' }} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
            <Typography variant="h5">
              Nella data selezionata non hai ore di lezione
            </Typography>
            <Button onClick={goBackToVistaSintetica} startIcon={<ArrowBack />} sx={{ mt: 1 }} variant="contained">Torna alla vista sintetica</Button>

          </Box>
        )
      ) : (
        <Box className="centered-column-direction" sx={{ minHeight: '80vh' }}>
          <CircularProgress />
          <Typography variant="body2" sx={{ mt: 3 }}>Caricamento registro...</Typography>
        </Box>
      )
      }

      <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Legenda
          </Typography>
          <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Legende />
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
        </DialogActions>
      </Dialog>

      <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dettaglioAlunnoOpen} onClose={closeDettaglioAlunno}>
        <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio alunno ({nomeAlunnoSelezionato})</Typography>
          <IconButton onClick={closeDettaglioAlunno} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 0 }}>
          <DettaglioAlunno idCorso={idCorso} dontCallBesBE={true} canTouchAssenze={!hasDocenteFunctions} dataAttuale={dataRichiesta} tipo={true} tipoRegistro={'docente'} besData={besObjAlunnoSelezionato} showBes={showBes} ruoliUtente={ruoliUtente}
            permessiUtente={permessiUtente} tabIndex={indexTabToOpen} id={idAlunnoSelezionato} nomeAlunno={nomeAlunnoSelezionato} ritardo={openDialogRitardo} closeDettaglioAlunno={closeDettaglioAlunno} openAlertMsg={openAlertMsg} />
        </DialogContent>
      </Dialog>

      <Dialog sx={{ marginTop: '7vh' }} open={aggiuntaEventoOpen} fullWidth maxWidth="xl" onClose={closeAggiuntaEvento}>
        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }} variant="h6">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {addEventoTuttaClasse ? "Azioni di classe" : "Azioni studente"}
          </Typography>
          <IconButton onClick={closeAggiuntaEvento} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <AggiuntaEventi canAddEventsPostPeriodo={canAddEventsPostPeriodo} objPermessiExAnte={objPermessiExAnte} dataInizioPeriodo={dataInizioPeriodo} dataFinePeriodo={dataFinePeriodo} isTuttaClasse={addEventoTuttaClasse} typeLezione={typeLezione} permessiUtente={permessiUtente} nomeClasse={nomeClasse} idDocente={idDocente} eventoAggiunto={eventoAggiunto} closeDialog={closeAggiuntaEvento} idCorso={idCorso} idMateria={idMateria}
            listAlunni={alunni} alunniSelezionati={alunniSelezionati} dataRegistro={dataRichiesta} tipoRegistro={"docente"} logout={logout} />
        </DialogContent>
      </Dialog>

      <Dialog sx={{ marginTop: '7vh' }} open={isRiepilogoArgomentiSvoltiDialogOpen} fullWidth maxWidth="xl" onClose={closeRiepilogoArgomentiSvoltiDialog}>
        <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }} variant="h6">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Riepilogo argomenti svolti
          </Typography>
          <IconButton onClick={closeRiepilogoArgomentiSvoltiDialog} sx={{ ml: 5 }}  >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <RiepilogoArgomentiSvolti objRiepilogoArgomentiSvolti={objRiepilogoArgomentiSvolti} isDialog={true} />
        </DialogContent>
      </Dialog>

      <Dialog hideBackdrop open={showBesPinDialog}>
        <DialogTitle>Inserimento codice Bes</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Dopo l'inserimento del codice di conferma, sarà possibile visualizzare i bes degli studenti per <b>5 minuti</b>,
            dopodiché sarà necessario ripetere l'operazione di inserimento del codice.
          </Typography>
          <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
            <FormControl sx={{ width: '50%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Pin di conferma</InputLabel>
              <OutlinedInput type={showPassword ? 'text' : 'password'}
                inputRef={refPinConfermaBes}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={showPasswordInput}  >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={changePinConfermaBesLength}
                // onBlur={changePinConfermaBes}
                label="Pin di conferma"
              />
            </FormControl>
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Button variant="outlined" color="error" onClick={besPinDialog}>Annulla</Button>
            <Button sx={{ ml: 1, px: 5 }}
              // disabled={!valuePinConfermaBes} //TODO: 
              disabled={pinBesTmp.length === 0}
              variant="contained" color="success" onClick={confirmBesPin}>Conferma</Button>
          </Box>
        </DialogContent>
      </Dialog>


      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
        <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMsg}
        </Alert>
      </Snackbar>


    </Fragment >

  );
}



