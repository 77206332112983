import { Box, Link, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, FormControl, Select, Breadcrumbs, IconButton } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useState, useEffect, Fragment, useRef } from "react"
import ElencoAlunniSelezionabili from "../elencoAlunniSelezionabili";
import DialogNoItemInSessionStorage from "../../shared/dialogNoItemInSessionStorage";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";
import CryptoJS from 'crypto-js';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { EventiAPI } from "../../../api/eventiApi";
import { RegistroAPI } from "../../../api/registroApi";
import { convertDataGetProssimeLezione, convertToSqlFormat } from "../../shared/metodiDateUtility";
import { Check, Close } from "@mui/icons-material";
import { PresenzeAPI } from "../../../api/presenzeApi";
import DialogConferma from "../../shared/dialogConferma";
import { getDataInizioFinePeriodo } from "../../shared/utilityMethods";
import functionCheckAlunniSelezionabili from "../functionCheckAlunniSelezionabili";

export default function AggiuntaVerifica(props) {

    const inputVerificaRef = useRef(null);

    const [data, setData] = useState(null);
    const [materie, setMaterie] = useState([{ idMateria: -1, nomeMateria: '', nomeMateriaBreve: '' }]);
    const [materiaSelected, setMateriaSelected] = useState(-1);
    const [elencoOre, setElencoOre] = useState([]);
    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);

    const [idMateria, setIdMateria] = useState(-1);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);
    const [oraSelected, setOraSelected] = useState(-1);
    const [tipoRegistro, setTipoRegistro] = useState("classe");

    const [testoVerifica, setTestoVerifica] = useState("");
    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertNoIdIsOpen, setAlertNoIdIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)

    useEffect(() => {
        let objDateInteresse = getDataInizioFinePeriodo();
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null
        setDataFinePeriodo(finePeriodoTmp ?? null);
    }, [])

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(props.dataInizioPeriodo)
    }, [props.dataInizioPeriodo])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.idMateria != undefined && props.alunniSelezionati !== undefined && props.dataRichiesta !== undefined) {

            if (props.listAlunni == null) {
                getAlunniByClasseID();
            }
            else {
                let dataRichiestaTmp = DateTime.fromSQL(props.dataRichiesta).toUTC(); //Datetime
                manageStatesAlunni(props.listAlunni, props.alunniSelezionati, dataRichiestaTmp, props.idDocente, props.idCorso, props.idMateria)
                setGetAlunniIsLoading(false);
            }

            setIdCorso(props.idCorso)
            setIdDocente(props.idDocente)
            setIdMateria(props.idMateria)
            setTipoRegistro(props.tipoRegistro)
        }
    }, [props.idCorso, props.idDocente, props.idMateria, props.listAlunni, props.alunniSelezionati, props.dataRichiesta, props.tipoRegistro]);

    useEffect(() => {
        if (isDialog === false) {
            if (sessionStorage.getItem("classe") != null) {
                let encryptedIdCorso = sessionStorage.getItem("classe");
                let decryptedIdCorso = CryptoJS.AES.decrypt(encryptedIdCorso, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
                //TODO: Poi imposterò questo ID
                setAlertNoIdIsOpen(false);

            } else {
                //Se non trovo l'item ho acceduto tramite url e allora mostro l'errore
                setAlertNoIdIsOpen(true);
            }
        }
    }, [isDialog])

    useState(() => {
        if (props.nota !== undefined) {
            let newDataTmp = props?.nota?.dataLezione;
            newDataTmp = newDataTmp.replaceAll("-", "/");
            setData(newDataTmp);
            setMaterie([...materie, props.nota.materia])
            setMateriaSelected(props?.nota?.materia.idMateria)
        }
    }, [props.nota]);


    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])


    async function aggiungiVerifica() {
        let arrayAlunniSelezionatiID = [];
        for (let alunno of alunniSelezionati) {
            if (!alunniNonSelezionabili.some(a => a.id === alunno.id))
                arrayAlunniSelezionatiID.push(alunno.id)
        }




        let obj = {
            note: testoVerifica,
            idAlunno: arrayAlunniSelezionatiID,
            idOrario: oraSelected !== -1 ? oraSelected : null,
            is_interaClasse: alunniSelezionati.length === alunniSelezionabiliNumber ? true : false,
            idClasse: idCorso,
            idMateria: idMateria,
            idDocente: idDocente
        }

        setPulsanteConfermaDisabled(true);

        EventiAPI.insertVerifica(obj)
            .then((result) => {
                if (isDialog) {
                    if (props.eventoAggiunto)
                        props.eventoAggiunto("Verifica aggiunta", "success");
                } else {
                    setAlunniSelezionati([]);
                    setTestoVerifica("");

                    if (inputVerificaRef != null)
                        inputVerificaRef.current.value = "";

                    setAlertSeverity("success");
                    setAlertMsg("Verifica aggiunta");
                    setAlertIsOpen(true);
                }
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setConfirmAlertIsOpen(false);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
            .catch((error) => {
                let errorMsg = "";
                if (error.response.status === 409) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                    // setAlertMsg(error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione");
                } else {
                    errorMsg = "Al momento non è possibile completare l'operazione";
                    //setAlertMsg("Al momento non è possibile completare l'operazione");
                }
                setConfirmAlertIsOpen(false);
                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
                setAlertIsOpen(true);
                setTimeout(() => {
                    setPulsanteConfermaDisabled(false);
                }, 500)
            })
    }


    function getAlunniByClasseID() {
        //Placeholder, qui passeremo l'id salvato in localstorage
        RegistroAPI.getRegistroDiClasse()
            .then((result) => {
                setListAlunni([...result.alunni]);
                setGetAlunniIsLoading(false);
            })
    }


    function logout() {
        if (props.logout) props.logout()
    }

    function selectAlunni(listAlunni) {
        if (listAlunni !== undefined)
            setAlunniSelezionati([...listAlunni]);
    }
    function changeTestoVerifica(e) {
        setTestoVerifica(e.target.value)
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        setData(value);
        manageStatesAlunni(listAlunni, alunniSelezionati, value, idDocente, idCorso, idMateria);
    }
    function changeOra(e) {
        setOraSelected(e.target.value);
        manageStatesAlunni(listAlunni, alunniSelezionati, data, idDocente, idCorso, idMateria, e.target.value);
    }

    function resetData() {
        setData(null);
        setOraSelected(-1);
        setElencoOre([]);
    }

    function isButtonSaveDisabled() {
        if (testoVerifica === "" || alunniSelezionati.length === 0 || (data != null && oraSelected === -1))
            return true
        else return false;
    }

    function manageStatesAlunni(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp, tipoRegistroTmp) {
        functionCheckAlunniSelezionabili(listAlunniTmp, alunniSelezionatiTmp, dataTmp, idDocenteTmp, idCorsoTmp, idMateriaTmp, idOrarioTmp ?? undefined)
            .then(objCheckAlunniSelezionabili => {
                console.log(objCheckAlunniSelezionabili)
                setListAlunni([...objCheckAlunniSelezionabili.listAlunniTotali]);
                setElencoOre([...objCheckAlunniSelezionabili.listOre]);
                setOraSelected(objCheckAlunniSelezionabili.oraSelected);
                setIdMateria(tipoRegistroTmp !== undefined && tipoRegistroTmp === "docente" ? idMateriaTmp : objCheckAlunniSelezionabili.idMateriaSelected)
                setAlunniSelezionati([...objCheckAlunniSelezionabili.listAlunniSelezionati]);
                const alunniNonSelezionabiliObj = objCheckAlunniSelezionabili.alunniNonSelezionabili.map(id => {
                    return { id: id };
                });
                setAlunniNonSelezionabili([...alunniNonSelezionabiliObj]);
                setAlunniSelezionabiliNumber(objCheckAlunniSelezionabili.numberAlunniSelezionabili)
            });
    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!isDialog ? (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                        <Typography color="text.disabled">Aggiunta eventi</Typography>
                        <Typography color="text.disabled">Programma verifica</Typography>
                    </Breadcrumbs>) : null}
                {/* <Typography variant="h4" mt={1}>Programma verifica</Typography> */}
                {!alertNoIdIsOpen ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={6}
                            // mt={{ xs: 3, md: 0 }}
                            >
                                {!getAlunniIsLoading ? (
                                    <ElencoAlunniSelezionabili checkAssenze={true} selectAlunni={selectAlunni} alunniSelezionabiliNumber={alunniSelezionabiliNumber}
                                        listAlunniSelezionati={alunniSelezionati} alunniNonSelezionabili={alunniNonSelezionabili} listAlunni={listAlunni} />
                                ) : (
                                    <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Box>
                                    <Grid container rowSpacing={2} columnSpacing={2}
                                    // sx={{ width: '100%', pt: 2 }}
                                    >
                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                                    <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                                        minDate={dataInizioPeriodo}
                                                        maxDate={dataFinePeriodo}
                                                        label="Data di svolgimento"
                                                        value={data}
                                                        format="dd/MM/yyyy"
                                                        onChange={(value) => changeData(value)}
                                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                                </LocalizationProvider>
                                                <IconButton onClick={resetData} sx={{ border: '1px rgba(190, 190, 190, 1) solid', borderRadius: '0 4px 4px 0', borderLeft: 'none' }}>
                                                    <Close />
                                                </IconButton>
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="materia-label">
                                                    {/* {sedeName === '' ? t("pages.ricercaAlunni.sede") : t("pages.ricercaAlunni.sedeS")} */}
                                                    Seleziona ora</InputLabel>
                                                <Select
                                                    data-testid='selOraVerificaSelect'
                                                    label='Seleziona ora '
                                                    labelId="materia-label"
                                                    value={oraSelected}
                                                    onChange={changeOra}
                                                    disabled={elencoOre.length === 0}
                                                >
                                                    <MenuItem
                                                        key={-1}
                                                        value={-1}
                                                    >
                                                    </MenuItem>
                                                    {elencoOre.map((ora) => (
                                                        <MenuItem key={ora.ora} value={ora.idOrario} idmateria={ora.materia.idMateria}>
                                                            {ora.ora}^ ora - {'(' + ora.materia.nomeMateriaBreve + ')'}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {data != null && elencoOre.length === 0 ? (<Typography color="error" variant="caption">Nessuna ora di lezione nella data scelta</Typography>) : null}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
                                    <TextField fullWidth sx={{ mt: 1 }} inputRef={inputVerificaRef}
                                        onChange={changeTestoVerifica} placeholder="Scrivi qui..." multiline rows={4} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                            {isDialog ? (
                                <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                            ) : null}
                            <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isButtonSaveDisabled()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                color="success" variant="contained" >
                                Aggiungi Verifica
                            </Button>
                        </Box>
                    </Fragment>
                ) : null}

            </Container>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma aggiunta verifica'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={'Confermi di voler aggiungere una verifica per '}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma aggiunta'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={aggiungiVerifica}
                disabledButton={pulsanteConfermaDisabled}
            />

            <DialogNoItemInSessionStorage alertNoIdIsOpen={alertNoIdIsOpen} />


            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}