import EventoChip from "../../../../components/registri/shared/eventoChip";
import { convertDataDiNascita } from "../../../../components/shared/metodiDateUtility";
import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, Typography, TableBody, useMediaQuery, useTheme, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { returnVotoConverted, calcolaFontWeightPercentuale, calcolaColorePercentuale, calcolaColoreMedia } from "./utilityFunctionsScrutini";
import { Alunno, Periodo, Voto } from "../../../../types/utility.types"
import { AlunnoScrutinio, Materia } from "./scrutini.types";

export default function TableSchedaAlunni(props: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [listMaterie, setListMaterie] = useState<Materia[]>([]);

    useEffect(() => {
        if (props.listMaterie) { //Faccio un sorting dei votiScrutiniPassati sul list materie
            const sortedVotiPeriodiPassatiListMaterie = props.listMaterie.map(materia => ({
                ...materia,
                votiScrutiniPassati: [...materia.votiScrutiniPassati].sort((a, b) => a.idPeriodo - b.idPeriodo)
            }));
            setListMaterie(sortedVotiPeriodiPassatiListMaterie);
        }
    }, [props.listMaterie]);

    return (
        <Box sx={{ mb: 3, mt: 2, width: '100%', maxHeight: { xs: 'unset', md: '70vh' }, display: "flex", justifyContent: 'center' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976d2' }}>
                            <TableCell colSpan={1} sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                <Typography textAlign={"center"} sx={{ color: 'white' }}>Materie</Typography>
                            </TableCell>
                            <TableCell colSpan={2} sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                <Typography textAlign={"center"} sx={{ color: 'white' }}>Ore</Typography>
                            </TableCell>
                            <TableCell colSpan={5} sx={{ p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                <Typography textAlign={"center"} sx={{ color: 'white' }}>Voti</Typography>
                            </TableCell>
                            <TableCell colSpan={3} sx={{ p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                <Typography textAlign={"center"} sx={{ color: 'white' }}>Media</Typography>
                            </TableCell>
                            <TableCell colSpan={2 + props.periodiPassati.length} sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" rowSpan={2}
                                className="colonna-nomi row-head" sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}
                            >
                                <Box sx={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    height: '100px',
                                    px: 2,
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "start"
                                }}>
                                    {/* <Typography variant="caption" >Alunno:</Typography> */}
                                    <Typography color="secondary" variant='body1' sx={{ fontWeight: 'bold', textAlign: 'start' }}>{props.alunnoSelezionato?.numeroRegistro + ") " + props.alunnoSelezionato?.cognome + " " + props.alunnoSelezionato?.nome}</Typography>
                                    {(props.isUltimoPeriodo && props.numericoCorso < 3) && (
                                        <Fragment>
                                            <Typography variant="body2"><b>Prova di passaggio</b>: {returnVotoConverted(props.provaDiPassaggio, true)}</Typography>
                                            {props.showEsito && (
                                                props.alunnoSelezionato?.isRitirato === undefined || props.alunnoSelezionato?.isRitirato === 0 ? (
                                                    <Typography variant="body2"><b>Esito scrutinio</b>:
                                                        {props.esitoAlunnoVisualizzazione !== undefined ? (
                                                            <span style={{ color: props.esitoAlunnoVisualizzazione == "true" ? 'green' : 'red' }}>{props.esitoAlunnoVisualizzazione == "true" ? ' Idoneo' : ' Non idoneo'}</span>
                                                        ) : (
                                                            <span> -</span>
                                                        )}
                                                    </Typography>
                                                ) : (
                                                    <Typography color="error"><b>Ritirato il {convertDataDiNascita(props.alunnoSelezionato?.iscrittoAl)}</b></Typography>
                                                )
                                            )}
                                        </Fragment>
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ minWidth: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>{props?.periodoSelezionato?.nome ?? ""}</Typography>
                                    <Typography variant='caption' >*<b >A</b>/<b >S</b></Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ minWidth: '80px', p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Complessive</Typography>
                                    <Typography variant='caption' >*<b >A</b>/ <b>S</b>/ <b >T</b></Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Ex ante</Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Scritti</Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Pratici</Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Orali</Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Valutazioni</Typography>
                                </TableHeadTextBox>
                            </TableCell>

                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Didattica</Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Delle valutazioni</Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Suggerita</Typography>
                                </TableHeadTextBox>
                            </TableCell>


                            {props.periodiPassati && (
                                <Fragment>
                                    {props.periodiPassati.map((periodoPassato) => (
                                        <TableCell align="center" rowSpan={2} key={periodoPassato.idPeriodo}
                                            className="row-head" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                        >
                                            <TableHeadTextBox sx={{ backgroundColor: '#cecece' }}>
                                                <Typography variant='body2'>{periodoPassato.nome}</Typography>
                                            </TableHeadTextBox>
                                        </TableCell>
                                    ))}
                                </Fragment>
                            )}

                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox sx={{ backgroundColor: '#cecece' }}>
                                    <Typography variant='body2'>Voto proposto docente</Typography>
                                </TableHeadTextBox>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}
                                className="row-head" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <TableHeadTextBox>
                                    <Typography variant='body2'>Voto scrutinio</Typography>
                                </TableHeadTextBox>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listMaterie.map((materia) => (
                            <TableRow key={materia?.id + materia?.docente + materia?.nome}>
                                <TableCell className="colonna-nomi" sx={{ minWidth: isMobile ? '300px' : '400px', p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)' }} >
                                    <Box sx={{
                                        backgroundColor: 'white',
                                        height: materia?.nome.length > 40 ? '100px' : '80px',
                                        px: 2,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Box display={"flex"} width={'100%'} alignItems={"center"} justifyContent={'space-between'}>
                                            <Box >
                                                <Typography variant={isMobile ? 'caption' : 'body1'} sx={{ mr: 2 }}>
                                                    <b>{materia?.nome}</b>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {materia.docente}
                                                </Typography>
                                            </Box>


                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                    <Typography> {materia?.orePeriodo?.oreAssenza ?? "-"}/{materia?.orePeriodo?.oreSvolte ?? "-"}</Typography>
                                    <Typography sx={{ fontWeight: calcolaFontWeightPercentuale(materia?.orePeriodo?.percentuale), color: calcolaColorePercentuale(materia?.orePeriodo?.percentuale) }} variant='subtitle1'>
                                        {materia?.orePeriodo?.percentuale ?? "-"}</Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', }}>
                                    <Typography> {materia?.oreComplessive?.oreAssenza ?? "-"}/{materia?.oreComplessive?.oreSvolte ?? "-"}/{materia?.oreComplessive?.oreTotali ?? "-"}</Typography>
                                    <Typography sx={{ fontWeight: calcolaFontWeightPercentuale(materia?.oreComplessive?.percentuale), color: calcolaColorePercentuale(materia?.oreComplessive?.percentuale) }} variant='subtitle1'>{materia?.oreComplessive?.percentuale ?? "-"}</Typography>
                                </TableCell>

                                <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {materia?.votiExAnte !== undefined && (
                                            materia?.votiExAnte.map((voto: Voto, indexVoto: number) => (
                                                <Fragment key={materia?.id + indexVoto + "exAnte"}>
                                                    <EventoChip canChangeColorVoto={true} alunno={props.alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                                </Fragment>
                                            ))
                                        )}
                                    </Box>
                                </TableCell>

                                <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {materia.votiScritti.map((voto: Voto, indexVoto: number) => (
                                            <Fragment key={materia?.id + indexVoto + "scritto"}>
                                                <EventoChip canChangeColorVoto={true} alunno={props.alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                            </Fragment>
                                        ))}
                                    </Box>
                                </TableCell>

                                <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {materia.votiPratici.map((voto: Voto, indexVoto: number) => (
                                            <Fragment key={materia?.id + indexVoto + "pratico"}>
                                                <EventoChip canChangeColorVoto={true} alunno={props.alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                            </Fragment>
                                        ))}
                                    </Box>
                                </TableCell>

                                <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {materia?.votiOrali.map((voto: Voto, indexVoto: number) => (
                                            <Fragment key={materia?.id + indexVoto + "orale"}>
                                                <EventoChip canChangeColorVoto={true} alunno={props.alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                            </Fragment>
                                        ))}
                                    </Box>
                                </TableCell>
                                <TableCell align="center" sx={{ p: 0, borderRight: '2px solid black', }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {materia?.valutazioni.map((voto: Voto, indexVoto: number) => (
                                            <Fragment key={materia?.id + indexVoto + "valu"}>
                                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                    <Typography color={'secondary'}>{voto?.peso}</Typography>

                                                    <Box width={"30px"} height={"30px"} minWidth={'30px'}
                                                        border={"1px solid #ba68c8"}
                                                        display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                        sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                                                        <Typography sx={{ color: '#ba68c8', fontWeight: 'bold' }}>
                                                            {voto?.contenuto?.tipologia}
                                                        </Typography>
                                                    </Box>
                                                    {/* <EventoChip alunno={props.alunnoSelezionato} cantModify={true} objComunicazione={voto} /> */}
                                                </Box>
                                            </Fragment>
                                        ))}
                                    </Box>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                    <Typography sx={{ color: calcolaColoreMedia(materia?.medie?.mediaDidattica) }}>
                                        {returnVotoConverted(materia?.medie?.mediaDidattica)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                    <Typography sx={{ color: calcolaColoreMedia(materia?.medie?.mediaValutazioni) }}>
                                        {returnVotoConverted(materia?.medie?.mediaValutazioni)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '80px', p: 0, borderRight: '2px solid black', }}>
                                    <Typography sx={{ color: calcolaColoreMedia(materia?.medie?.mediaSuggerita) }}>
                                        {returnVotoConverted(materia?.medie?.mediaSuggerita)}
                                    </Typography>
                                </TableCell>
                                {materia.votiScrutiniPassati && (
                                    <Fragment>
                                        {materia.votiScrutiniPassati.map((votoScrutinioPassato, index) => (
                                            <TableCell key={votoScrutinioPassato.idPeriodo + "_" + index} align="center" sx={{ width: '80px', p: 0, background: "#cecece", borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                                <Typography sx={{ color: calcolaColoreMedia(votoScrutinioPassato?.voto) }}>
                                                    {returnVotoConverted(votoScrutinioPassato?.voto)}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </Fragment>
                                )}

                                <TableCell align="center" sx={{ width: '80px', p: 0, background: "#cecece", borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                    <Typography sx={{ color: calcolaColoreMedia(materia?.votoProposto) }}>
                                        {returnVotoConverted(materia?.votoProposto)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '80px', p: 0, background: materia?.is_prescrutinio ? '#cecece' : 'white' }}>
                                    <Typography sx={{ color: calcolaColoreMedia(materia?.votoScrutinio) }}>
                                        {returnVotoConverted(materia?.votoScrutinio)}
                                    </Typography>

                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const TableHeadTextBox = styled(Box)(({ theme }) => ({
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: 'white',
    height: '100px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

interface Props {
    provaDiPassaggio: number | null,
    showEsito: boolean,
    numericoCorso: number,
    esitoAlunnoVisualizzazione: "true" | "false"
    isUltimoPeriodo: boolean
    periodoSelezionato: Periodo,
    alunnoSelezionato: AlunnoScrutinio,
    listMaterie: Materia[],
    periodiPassati: {
        idPeriodo: number,
        nome: string,
        condottaScrutinio: {
            interazione: number
            mediaCondotta: number
            motivazione: number
            relazione: number
        }
    }[]
}




