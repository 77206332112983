import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography, useTheme, useMediaQuery, Skeleton, Switch, Button } from "@mui/material";

interface Item {
  id?: string | number;
  stato: boolean;
  descrizione?: string;
}

interface TableSwitchAttivitaProps {
  list?: Item[];
  isCanEdit?: boolean;
  title?: string;
  showToggleAllButton?: boolean;
  changeList?: (newList: Item[]) => void;
}

export default function TableSwitchAttivita({ list = [], isCanEdit = true, title = "", showToggleAllButton = false, changeList }: TableSwitchAttivitaProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<Item[]>(list);
  const [allEnabled, setAllEnabled] = useState(false);

  useEffect(() => {
    if (list) {
      setItems([...list]);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [list]);

  useEffect(() => {
    const allChecked = items.every(item => item.stato);
    setAllEnabled(allChecked);
  }, [items]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const updatedList = [...items];
    updatedList[index] = { ...updatedList[index], stato: e.target.checked };
    setItems(updatedList);
    if (changeList) changeList(updatedList);
  }

  function toggleAllSwitches() {
    const newState = !allEnabled;
    const updatedList = items.map(item => ({ ...item, stato: newState }));
    setItems(updatedList);
    if (changeList) changeList(updatedList);
  }

  return (
    <Paper>
      <TableContainer sx={{ width: "100%" }}>
        <Table sx={{ maxHeight: "40vh" }}>
          <TableHead>
            <TableRow>
              {(showToggleAllButton && isCanEdit)? (
                <TableCell sx={{ p: 1, borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}>
                  <Button size="small" variant="contained" onClick={toggleAllSwitches}>
                    {allEnabled ? "Deseleziona tutto" : "Seleziona tutto"}
                  </Button>
                </TableCell>
              ) : null}
              <TableCell align="center" colSpan={showToggleAllButton && isCanEdit ? 1 : 2} sx={{ p: 1, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                <Typography variant="body2" textAlign="center">
                  <b>{title}</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map((item, index) => (
              <TableRow style={{ height: "40px" }} key={item.id ?? index}>
                <TableCell sx={{ p: 1, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                  {!isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                      <Switch disabled={!isCanEdit} checked={item.stato} onChange={(e) => handleChange(e, index)} />
                    </Box>
                  ) : (
                    <Skeleton sx={{ mx: 2 }} />
                  )}
                </TableCell>
                <TableCell sx={{ p: 1, borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                  {!isLoading ? (
                    <Box display="flex" alignItems="center" sx={{ height: "100%" }}>
                      <Typography variant={mobile ? "caption" : "body2"} sx={{ ml: 2 }}>
                        {(item.descrizione ?? "").toUpperCase()}
                      </Typography>
                    </Box>
                  ) : (
                    <Skeleton sx={{ mx: 2 }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
