import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const TirociniAPI = {
    getElencoCorsiFormatori: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/elencoClassiFormatore`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getDettaglioGiornalieroPresenze: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/registroGiornaliero`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getRegistroSintesi: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/registroSintesi`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getRegistroAlunno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/registroAlunno`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getElencoAlunniTirocini: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/elencoAlunniTirocinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveAnnotazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/saveAnnotazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getVotiTirocinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/getVoti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveVotiTirocinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/saveVoti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    savePresenzeAlunno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/savePresenzeAlunno`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    savePresenzeTirocinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/savePresenze`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getAdgTirocinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/elencoADG`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },

    elencoFirmeTutorTirocinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/elencoFirmeTutor`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getDettaglioVisita: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/dettaglioPresenza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deletePresenza: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/annullaFirmaTutor`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveFirmaTutorTirocinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/saveFirmaTutor`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    sintesiValidazionePresenze: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/sintesiValidazionePresenze`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },

    saveValidazioniPresenze: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/saveValidazioniPresenze`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    elencoTutorCorso: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/tirociniFormativi/elencoTutorCorso`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveValidazioniPresenzeTutor: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `tirociniFormativi/saveValidazioniPresenzeTutor`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    giustificaAssenzaTirocinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `tirociniFormativi/giustificaAssenza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    verificaEsistenzaAzieda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/verificaEsistenza`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getDatiPrecompilati: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/datiPrecompilati`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    salvataggioAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/azienda/save`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    salvataggioRappresentanteLegaleAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/persona/save`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    salvataggioSedeAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/sede/save`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    ricercaPersoneAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/ricercaPersoneAzienda`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    savePersoneAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/persona/save`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deletePersoneAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/persona/delete`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },

    ricercaSediAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/ricercaSediAzienda`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveSediAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/sede/save`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deleteSediAzienda: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/sede/delete`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    legaleAsOperativa: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/sede/LegaleAsOperativa`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    ricercaAziende: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/ricercaAziende`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getAziendaById: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/azienda/dettaglio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deleteAziendaById: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneAziende/azienda/delete`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    percorsiIstruzioneSede: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/percorsiIstruzioneSede`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveConvenzione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/convenzione/create`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    ricercaConvenzione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/ricercaConvenzioni`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getConvenzioneById: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/convenzione/dettaglio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deleteConvenzioneById: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/convenzione/delete`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getPercorsiIstruzioneSede: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/percorsiIstruzioneSede`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getProgettoFormativoStatiList: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/statiProgetto`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    ricercaProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/ricercaProgetto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    ricercaAlunniConvenzione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/elencoAlunniCorsiConvenzione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    createProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/createProgetto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getTirocinanteByIdProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/getTirocinante`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveTirocinante: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/saveTirocinante`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    elencoTitoliStudio: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/elencoTitoliStudio`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getAziendaByIdProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/getAzienda`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveSedeOperativaAziendaProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/saveAzienda`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getOrarioByIdProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/getOrarioAttivita`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveOrarioProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/saveOrarioAttivita`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    changeStatoProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/changeStatoProgetto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getAltriDati: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/getAltriDati`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    elencoTutorSede: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/elencoTutorSede`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveAltriDati: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/saveAltriDati`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getObiettiviModalita: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/getObiettiviModalita`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveObiettiviModalita: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/saveObiettiviModalita`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    tipologieAttivita: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/tipologieAttivita`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getElencoDocumentiConvenzione: async function (id, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneConvenzioni/elencoDocumentiConvenzione/` + id,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    variazioneProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/variazioneProgetto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    elencoVariazioni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/elencoVariazioni`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    eliminaVariazioneProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/eliminaVariazioneProgetto`,
            method: "POST",
            data: obj,
        })
        return response.data
    },
    getElencoDocumentiProgetto: async function (id, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneProgettiFormativi/elencoDocumentiProgetto/` + id,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    calcoloOreTotali: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneProgettiFormativi/calcoloOreTotali",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getDateAttualiProgetto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneProgettiFormativi/dateAttuali",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getElencoAlunniFascie: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "tirociniFormativi/elencoAlunniFascie",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getPrecompilazioneConvenzioni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneConvenzioni/getPrecompilazioneConvenzioni",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    savePrecompilazioneConvenzioni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneConvenzioni/savePrecompilazioneConvenzioni",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getPrecompilazioneTirocini: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneProgettiFormativi/getPrecompilazioneTirocini",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    savePrecompilazioneTirocini: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneProgettiFormativi/savePrecompilazioneTirocini",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getElencoAlunniAziendaSimulata : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneProgettiFormativi/elencoAlunniAziendaSimulata",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveAlunnoAziendaSimulata : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "gestioneProgettiFormativi/saveAlunnoAziendaSimulata",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveLogoGenerali : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            url: "gestioneProgettiFormativi/caricaLogo1",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveLogoComuneRoma : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            url: "gestioneProgettiFormativi/caricaLogo2",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    // getPrecompilazioneTirocini : async function (obj, cancel = false) {
    //     const response = await api.request({
    //         sendToken: true,
    //         data: obj,
    //         url: "gestioneProgettiFormativi/getPrecompilazioneTirocini",
    //         method: "POST",
    //         signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    //     })
    //     return response.data
    // },
    deleteAlunnoAziendaSimulata : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "/gestioneProgettiFormativi/deleteAlunnoAziendaSimulata",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deleteAlunnoAziendaSimulata : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "/gestioneProgettiFormativi/deleteAlunnoAziendaSimulata",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getGiorniSospensioneTirocinio : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "/gestioneProgettiFormativi/getGiorniSospensioneTirocinio",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveGiorniSospensioneTirocinio : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "/gestioneProgettiFormativi/saveGiorniSospensioneTirocinio",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deleteGiorniSospensioneTirocinio : async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            data: obj,
            url: "/gestioneProgettiFormativi/deleteGiorniSospensioneTirocinio",
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },

    
}
const cancelApiObject = defineCancelApiObject(TirociniAPI)