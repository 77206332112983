import { Alert, Autocomplete, Box, Button, Divider, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowBack, ArrowForward, Check } from "@mui/icons-material";
import { getAnnoFormativo } from "../../../shared/utilityMethods";
import TableLivelliAutonomia from "./tableLivelliAutonomia";
import TableSwitchAttivitaAndFacilitazioni from "./tableSwitchAttivitaAndFacilitazioni";

export default function AnagraficaObiettiviModalita(props) {
    // eslint-disable-next-line no-unused-vars
    const [annoFormativo, setAnnoFormativo] = useState('');
    const [idProgetto, setIdProgetto] = useState(-1);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');


    const [attivitaPreviste, setAttivitaPreviste] = useState([]);
    const [attivitaPrevisteValid, setAttivitaPrevisteValid] = useState(true);
    const [facilitazioniPreviste, setFacilitazioniPreviste] = useState([]);
    const [livelliAutonomia, setLivelliAutonomia] = useState([]);
    const [listTipologieAttivita, setListTipologieAttivita] = useState([]);


    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [isVisualizza, setIsVisualizza] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);

    useEffect(() => {
        if (props.isVisualizza !== undefined) {
            setIsVisualizza(props.isVisualizza)
        }
    }, [props.isVisualizza])


    useEffect(() => {
        if (props.idProgettoFormativo !== undefined) {
            setIdProgetto(props.idProgettoFormativo);
            let annoFormativoTmp = getAnnoFormativo();
            if (props.idProgettoFormativo !== -1) getObiettiviModalita(props.idProgettoFormativo, annoFormativoTmp);
        }
    }, [props.idProgettoFormativo])


    useEffect(() => {
        // tipologieAttivita()
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        setIsConfermaLoading(false);
    }, []);


    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog()
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function validateFormData() {
        let isNotValidLivelliAutonomia = livelliAutonomia.some(livello => livello.stato === true);
        let isNotValidAttivitaPreviste = attivitaPreviste.some(attivita => attivita.stato === true);
        if (
            isNotValidLivelliAutonomia
            &&
            isNotValidAttivitaPreviste
        ) return false
        else return true
    }

    function changeAttivitaPreviste(attivita) {
        setAttivitaPrevisteValid(true)
        setAttivitaPreviste(attivita)
    }
    function changeFacilitazioniPreviste(facilitazioni) {
        setFacilitazioniPreviste(facilitazioni)
    }

    function changeLivelloAutonomia(livelliAutonomia) {
        setLivelliAutonomia(livelliAutonomia);
    }

    function previousTab() {
        if (props.previousTab) {
            props.previousTab();
        }
    }

    // function changeAttivitaPreviste(e, value) {
    //     if (value.length !== 0) setAttivitaPrevisteValid(true)
    //     else setAttivitaPrevisteValid(false)
    //     setAttivitaPreviste(...[value]);
    // }

    function proceduraConclusa() {
        if (props.proceduraConclusa) props.proceduraConclusa();
    }

    async function getObiettiviModalita(idProgetto, annoFormativo) {
        let obj = {
            idProgetto: idProgetto,
            annoFormativo: annoFormativo
        }
        TirociniAPI.getObiettiviModalita(obj)
            .then((result) => {
                let objProgetto = result;
                setAttivitaPreviste(objProgetto?.attivitaPreviste ?? []);
                setFacilitazioniPreviste(objProgetto?.facilitazioniPreviste ?? []);
                setLivelliAutonomia(objProgetto?.livelliAutonomia ?? [])

                setIsCanEdit(objProgetto?.isEditable ?? true);
            })
            .catch(function (error) {
            })

    }

    async function tipologieAttivita() {

        TirociniAPI.tipologieAttivita()
            .then((result) => {
                let listAttivitaTmp = result;
                setListTipologieAttivita([...listAttivitaTmp])
            })
            .catch(function (error) {
            })

    }

    async function saveObiettiviModalita() {
        setButtonConfermaDisabled(true);
        let obj = {
            idProgetto: idProgetto,
            attivitaPreviste: attivitaPreviste,
            facilitazioniPreviste: facilitazioniPreviste,
            livelliAutonomia: livelliAutonomia,
        }
        TirociniAPI.saveObiettiviModalita(obj)
            .then((result) => {
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                setConfirmAlertIsOpen(false);
                proceduraConclusa();
            })
            .catch(function (error) {
                setConfirmAlertIsOpen(false);
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    return (
        <Fragment>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Paper elevation={1} sx={{ p: 2, mt: isVisualizza ? 2 : 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Obiettivi e modalità</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={2}>
                        {/* <Grid xs={12} md={12}>
                            <Autocomplete
                                multiple
                                onChange={changeAttivitaPreviste}
                                options={[...listTipologieAttivita]}
                                getOptionLabel={(option) => option.descrizione}
                                defaultValue={[...attivitaPreviste]}
                                value={[...attivitaPreviste]}
                                disabled={!isCanEdit}
                                renderInput={(params) => (
                                    <TextField
                                        error={attivitaPreviste.length === 0 && !attivitaPrevisteValid}
                                        disabled={!isCanEdit}
                                        {...params}
                                        label="Attività previste *"
                                    />
                                )}
                            />
                        </Grid> */}
                        <Grid xs={12} md={12}>
                            <TableSwitchAttivitaAndFacilitazioni
                            showToggleAllButton
                                title={"ATTIVITÀ"}
                                list={attivitaPreviste ?? []}
                                changeList={changeAttivitaPreviste}
                                isCanEdit={isCanEdit}
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TableLivelliAutonomia
                                list={livelliAutonomia ?? []}
                                changeList={changeLivelloAutonomia}
                                isCanEdit={isCanEdit}
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TableSwitchAttivitaAndFacilitazioni
                                title={'FACILITAZIONI'}
                                list={facilitazioniPreviste ?? []}
                                changeList={changeFacilitazioniPreviste}
                                isCanEdit={isCanEdit}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Divider />
            <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                    onClick={closeDialog}
                    variant="outlined" fullWidth color={'error'} >{isCanEdit ? 'Chiudi procedura di inserimento' : 'Torna a gestione progetto formativo'}</Button>
                <Button startIcon={<ArrowBack />}
                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                    onClick={previousTab}
                    variant="contained" fullWidth color={'secondary'} > torna indietro</Button>
                <Button endIcon={<ArrowForward />}
                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                    onClick={() => isCanEdit ? setConfirmAlertIsOpen(true) : closeDialog()}
                    disabled={validateFormData()}
                    variant="contained" fullWidth color={'success'} >{isCanEdit ? 'Conferma e concludi' : 'Chiudi'}</Button>
            </Box>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma salvataggio'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler salvare i dati del tirocinante? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveObiettiviModalita}
                disabledButton={buttonConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}