import { Collapse, Grid, Box, Typography } from "@mui/material";

export default function CondottaCollapse(props: Props) {
    return (
        <Collapse in={props.showCondotta}>
            <Grid container spacing={2} sx={{ display: "flex", mt: 2, flexDirection: 'row', alignItems: "stretch" }}>
                <Grid item xs={12} md={3}>
                    <CondottaCard title="Condotta (media voti proposti docenti)" condottaData={props.condottaObj?.condottaDocenti} />
                </Grid>
                <Grid item xs={12} md={3} >
                    <CondottaCard title="Condotta (voti proposti collegio formatori)" condottaData={props.condottaObj?.condottaTutor} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <CondottaCard title={!props.isPrescrutinio ? "Condotta (voto scrutinio)" : "Condotta (voto prescrutinio)"} condottaData={props.condottaObj?.condottaScrutinio} />
                </Grid>
                {props.periodiPassati && (
                    props.periodiPassati.map((periodo) => (
                        <Grid item xs={12} md={3}>
                            <CondottaCard title={"Condotta " + periodo.nome} condottaData={periodo.condottaScrutinio} />
                        </Grid>
                    ))
                )}

            </Grid>
        </Collapse>
    )
}


function CondottaCard(props: CondottaCardProps) {
    return (
        <Box sx={{ border: '1px solid #1976d2', p: 2, borderRadius: '5px' }}>
            <Typography sx={{ mb: 1 }} variant="body1">{props.title}</Typography>
            <Box sx={{ display: "flex", alignItems: 'start', flexWrap: 'wrap', gap:1 }}>
                {[
                    { label: "Motivazione e interesse", value: props.condottaData?.motivazione },
                    { label: "Interazione con il contesto", value: props.condottaData?.interazione },
                    { label: "Relazione con gli altri", value: props.condottaData?.relazione },
                    { label: "Media", value: props.condottaData?.mediaCondotta },
                ].map((item, index) => (
                    <Box key={index} sx={{
                        display: 'flex', flexGrow: 1,
                         alignItems: 'center', background: '#EFEFEF',
                        p: 0.5, border: '1px solid #707070', borderRadius: '10px'
                    }}>
                        <Typography variant="body2"><b>{item.label}</b>: {item.value ?? "-"}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
interface Props {
    isPrescrutinio: boolean,
    showCondotta: boolean
    condottaObj: {
        condottaScrutinio: {
            interazione: number
            is_prescrutinio: boolean
            mediaCondotta: number
            motivazione: number
            relazione: number
        }
        condottaDocenti: {
            interazione: number
            mediaCondotta: number
            motivazione: number
            relazione: number
        },
        condottaTutor: {
            interazione: number
            mediaCondotta: number
            motivazione: number
            relazione: number
        }
    }
    periodiPassati: {
        idPeriodo: number,
        nome: string,
        condottaScrutinio: {
            interazione: number
            mediaCondotta: number
            motivazione: number
            relazione: number
        }
    }[]
}

interface CondottaCardProps {
    title: string;
    condottaData?: {
        motivazione?: number | string | null;
        interazione?: number | string | null;
        relazione?: number | string | null;
        mediaCondotta?: number | string | null;
    };
}