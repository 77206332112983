import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert, Skeleton, Stepper, Step, StepLabel, Divider } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowBack, ArrowForward, Send } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import CryptoJS from 'crypto-js';

import { useTranslation } from 'react-i18next';
import RicercaStudente from '../ricerca/ricercaStudente';
import RicercaDocente from '../ricerca/ricercaDocente';
import { ComunicazioniAPI } from '../../api/comunicazioniApi';
import UploadButton from '../ui/uploadButton';

export default function InserimentoComunicazioni(props) {
  const { t } = useTranslation();

  const [comunicazioneTypeSel, setComunicazioneTypeSel] = useState(-1);
  const [comunicazioneTypes, setComunicazioneTypes] = useState([]);
  const destinatariTypes = ['Alunni', 'Docenti', 'Entrambi (Alunni e docenti)']
  const [destinatariTypeSel, setDestinatariTypeSel] = useState(1);
  const [indexTab, setIndexTab] = useState(0);
  const [oggetto, setOggetto] = useState('');
  const [testo, setTesto] = useState('');

  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMsg, setAlertMsg] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const [checkedDocenti, setCheckedDocenti] = useState(new Set());
  const [checkedStudenti, setCheckedStudenti] = useState(new Set());
  const [checked, setChecked] = useState(new Set());

  const [checkedDestinatari, setCheckedDestinatari] = useState([]);

  const [annoFormativoObj, setAnnoFormativoObj] = useState(null);

  const [fileToUpload, setFileToUpload] = useState(null);
  const [idSede, setIdSede] = useState(-1)


  useEffect(() => {
    let sedeTmp = localStorage.getItem("sede");
    let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
    let sedeParsed = JSON.parse(decryptedSede);
    setIdSede(sedeParsed.id);
    getTipiComunicazioni();
  }, [])

  useEffect(() => {
    if (props.annoFormativo !== undefined) {
      setAnnoFormativoObj(props.annoFormativo);
    }
  }, [props.annoFormativo])


  function setCheckedNodesDocentiFunction(value) {

    setCheckedDocenti(value);
    if (destinatariTypeSel === 1) {
      let checkedTMp = [...checkedDocenti, ...value]; //A.G: Cosa fa?
      setCheckedDestinatari(value);
      //generateObjectDestinatari(value)
    };
    //if (destinatariTypeSel === 1) setChecked(value);
  }

  function setCheckedNodesStudentiFunction(value) {
    setCheckedStudenti(value);
    if (destinatariTypeSel === 2) {
      let checkedTMp = [...checkedStudenti, ...value];
      setChecked(checkedTMp);
    };
    if (destinatariTypeSel === 0) setChecked(value);

  }

  function goToNextIndexTab() {
    setIndexTab(prev => prev + 1)
  }

  function goToPreviousIndexTab() {
    setIndexTab(prev => prev - 1)
  }

  function changeComunicazioneType(e) {
    setComunicazioneTypeSel(e.target.value)
  }

  function changeDestinatariType(e) {
    setDestinatariTypeSel(e.target.value)
  }

  function changeTesto(e) {
    setTesto(e.target.value)
  }

  function changeOggetto(e) {
    setOggetto(e.target.value)
  }

  function fileChanged(file) {
    setFileToUpload(file);
  }

  function generateObjectDestinatari(arr) {
    let result = { "sedi": [] };
    arr.forEach(item => {
      let sedeIndex = result.sedi.findIndex(sede => sede.idSede === item.idSede);
      if (sedeIndex === -1) {
        result.sedi.push({ "idSede": item.idSede, "corsi": [{ "idCorso": item.idCorso, "idUtenti": [item.id] }] });
      } else {
        let corsoIndex = result.sedi[sedeIndex].corsi.findIndex(corso => corso.idCorso === item.idCorso);
        if (corsoIndex === -1) {
          result.sedi[sedeIndex].corsi.push({ "idCorso": item.idCorso, "idUtenti": [item.id] });
        } else {
          result.sedi[sedeIndex].corsi[corsoIndex].idUtenti.push(item.id);
        }
      }
    });
    return result;
  }

  async function getTipiComunicazioni() {
    ComunicazioniAPI.getTipologieComunicazioni()
      .then((result) => {
        const tipologieSelezionabili = result.filter((c) => c.canSend === true)
        setComunicazioneTypes([...tipologieSelezionabili]);
        if (tipologieSelezionabili.length === 1) {
          setComunicazioneTypeSel(tipologieSelezionabili[0]?.id ?? -1);
          setIndexTab(prev => prev + 1);
        }
      })
      .catch((err) => { })
  }
  async function sendComunicazione() {
    let objDestinatari = generateObjectDestinatari(checkedDestinatari);
    let idDestinatari = [];
    for (let sede of objDestinatari.sedi) {
      for (let corso of sede.corsi) {
        if (corso.idCorso !== undefined) { //Se c'è undefined sono gli idCorsi che per qualche motivo vengono raggruppati sotto un'array
          for (let i = 0; i < corso.idUtenti.length; i++) {
            if (!idDestinatari.includes(corso.idUtenti[i]))
              idDestinatari.push(corso.idUtenti[i]);
          }
        }
      }
    }
    let dataObj = {
      idSede: idSede,
      annoFormativo: annoFormativoObj?.id !== undefined ? annoFormativoObj.id.toString() : -1,
      oggetto: oggetto,
      testo: testo,
      destinatari: "[" + idDestinatari + "]",
      tipologia: comunicazioneTypeSel,
      allegato: fileToUpload ?? null
    }


    ComunicazioniAPI.nuovaComunicazione(dataObj)
      .then((result) => {
        setAlertMsg("Comunicazione inviata correttamente");
        setAlertSeverity("success");
        setAlertOpen(true);
        if (props.comunicazioneInviata) props.comunicazioneInviata();

        setCheckedDocenti(new Set());
        setCheckedStudenti(new Set());

        setCheckedDestinatari([]);

        setDestinatariTypeSel(1)
        setTesto("");
        setOggetto("");
        setIndexTab(0);

      })
      .catch(function (error) {
        setAlertOpen(true);
        setAlertMsg("Errore durante l'invio della comunicazione");
        setAlertSeverity("error");
      })



  }

  return (
    <Fragment>

      {indexTab === 0 &&
        (
          <Grid container rowSpacing={2} spacing={2}>
            <Grid xs={12} md={6} sx={{ width: '100%', display: 'flex' }}>
              {comunicazioneTypes.length > 0 ? (
                <FormControl sx={{ width: { xs: '100%', md: '50%' } }}>
                  <InputLabel id="comType-label">
                    Tipo di comunicazione</InputLabel>
                  <Select
                    disabled={comunicazioneTypes.length <= 1}
                    data-testid='selMateriaNotaSelect'
                    label='Tipo di comunicazione '
                    labelId="comType-label"
                    value={comunicazioneTypeSel}
                    onChange={changeComunicazioneType}
                  // disabled={!modifica}
                  >
                    {comunicazioneTypes.map((type, index) => (
                      <MenuItem
                        key={index}
                        value={type.id}
                      >
                        {type?.descrizione ?? ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Skeleton width={200} />
              )}
            </Grid>
            <Grid xs={12} md={6} sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignContent: 'center' }}>
              <Button
                disabled={comunicazioneTypeSel === -1}
                endIcon={<ArrowForward />}
                onClick={() => goToNextIndexTab()}
                data-testid='giustificaAssenzaBtn' variant="contained" color='success' sx={{ my: 1, width: { xs: '100%', md: '50%' } }} >
                Prosegui
              </Button>
            </Grid>
          </Grid>)}

      {indexTab === 1 && (
        <Box>
          <Typography sx={{ mb: 2 }} variant="h5">Destinatari</Typography>

          <Grid container rowSpacing={2} spacing={2}>
            <Grid xs={12} md={6} sx={{ width: '100%', display: 'flex' }}>
              <FormControl sx={{ width: { xs: '100%', md: '50%' } }}>
                <InputLabel id="comType-label">Tipo di destinatari</InputLabel>
                <Select
                  disabled
                  data-testid='selMateriaNotaSelect'
                  label='Tipo di destinatari'
                  labelId="comType-label"
                  value={destinatariTypeSel}
                  onChange={changeDestinatariType}
                // disabled={!modifica}
                >
                  {destinatariTypes.map((dest, index) => (
                    <MenuItem
                      key={index}
                      value={index}
                    >
                      {dest}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} md={6} sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignContent: 'center' }}>
              {/* <Button startIcon={<ArrowBack />}
                onClick={() => goToPreviousIndexTab()}
                data-testid='giustificaAssenzaBtn' variant='outlined' color='primary' sx={{ my: 1, mr: 2, width: '100%' }} >
                Torna indietro
              </Button> */}
              <Button endIcon={<ArrowForward />} onClick={() => goToNextIndexTab()} disabled={(checkedDestinatari.length === 0)}
                data-testid='giustificaAssenzaBtn' variant="contained" color='success' sx={{ my: 1, width: '100%' }} >
                Prosegui
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} sx={{ width: '100%', mt: 2 }}>
            {(checkedDestinatari.length === 0) ? (
              <Alert severity='warning' sx={{ my: 1 }}>Prima di proseguire è necessario selezionare almeno un destinatario.</Alert>
            ) : null}

            {(destinatariTypeSel === 0 || destinatariTypeSel === 2) && (
              <Fragment>
                <RicercaStudente showSediChips={true} setCheckedNodes={setCheckedNodesStudentiFunction} isFullWidth={true} isInsideOtherComponent={true} showCheckbox={true} />
              </Fragment>
            )}

            {(destinatariTypeSel === 1 || destinatariTypeSel === 2) && (
              <Fragment>
                <RicercaDocente showSediChips={true} annoFormativo={annoFormativoObj} isFullWidth={true} setCheckedNodes={setCheckedNodesDocentiFunction} isInsideOtherComponent={true} showCheckbox={true} />
              </Fragment>
            )}


          </Grid>
        </Box>
      )}

      {indexTab === 2 && (
        <Box>
          <Grid xs={12} md={6} sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'end', alignContent: 'center' }}>

            <Button startIcon={<ArrowBack />}
              onClick={() => goToPreviousIndexTab()}
              data-testid='giustificaAssenzaBtn' variant='outlined' color='primary' sx={{ my: 1, mr: 2, width: '100%' }} >
              Torna indietro
            </Button>
            <Button onClick={sendComunicazione} disabled={oggetto === '' || testo === ''} endIcon={<Send />}
              data-testid='giustificaAssenzaBtn' variant="contained" color='success' sx={{ my: 1, width: '100%' }} >
              Invia Comunicazione
            </Button>
          </Grid>
          <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
            <TextField fullWidth sx={{ mt: 1 }}
              onChange={changeOggetto} label='Oggetto' maxRows={1} />
            {/* {!oggettoValid && (<Typography variant="caption" color={'error'}>Oggetto non valido</Typography>)} */}
          </Grid>
          <Grid xs={12} md={12} sx={{ width: '100%', pt: 1 }}>
            <TextField fullWidth sx={{ mt: 1 }}
              onChange={changeTesto} placeholder="Scrivi qui..." multiline rows={4} label='Testo comunicazione' />
            {/* {!testoValid && (<Typography variant="caption" color={'error'}>Testo non valido</Typography>)} */}
          </Grid>
          <Grid xs={12} md={12} sx={{ width: '100%', mt: 1, display: "flex", alignItems: "center" }}>
            <UploadButton acceptedFormats={["application/pdf"]} maxFileDimension={5242880} fileChanged={fileChanged} />
          </Grid>
        </Box>
      )}

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
        <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMsg}
        </Alert>
      </Snackbar>

      <Helmet>
        <title>{t("name")} - {t("pages.newComunicazioni.title")}</title>
      </Helmet>
    </Fragment>
  );
}